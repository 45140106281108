import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Tooltip } from '@patternfly/react-core';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Notifications.scss';
import { getUnreadCount } from '../../api/notification/Notification';

export default function Notifications() {
	const navigate = useNavigate();
	const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
	const [firstLoad, setFirstLoad] = useState(true);
	const [animate, setAnimate] = useState(false);
	const unreadCountRef = useRef(unreadNotificationCount);
	unreadCountRef.current = unreadNotificationCount;

	const getNotificationCount = () => {
		getUnreadCount()
			.then((count) => {
				if (unreadCountRef.current !== count) {
					setAnimate(true);
					setTimeout(() => {
						setAnimate(false);
					}, 1000);
				}

				setUnreadNotificationCount(count);
			})
			.catch((error) => {
				setUnreadNotificationCount(-1);
			})
			.finally(() => {
				if (firstLoad) {
					setFirstLoad(false);
				}
			});
	};

	useEffect(() => {
		getNotificationCount();

		// Set up the interval
		const intervalId = setInterval(getNotificationCount, 60 * 1000);

		// Cleanup function to clear the interval
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return (
		<Tooltip
			aria="none"
			content={'Notifications'}
			flipBehavior={['bottom', 'bottom-end']}
		>
			<Icon
				style={{
					width: '2rem',
					height: '1.5rem',
					color: '#000000',
					cursor: 'pointer',
					margin: 8,
				}}
				size="lg"
				alt={'Notifications'}
				isInline
				onClick={() => {
					navigate('/notifications');
				}}
			>
				<FontAwesomeIcon icon={faBell} />
				{unreadNotificationCount > 0 && (
					<span className={`zi-notification-count ${animate ? 'bounce-animation' : ''}`}>
						{unreadNotificationCount}
					</span>
				)}
			</Icon>
		</Tooltip>
	);
}
