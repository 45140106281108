import {
	Button,
	Dropdown,
	DropdownToggle,
	DropdownItem,
	Modal,
	ModalBoxFooter,
	Text,
	TextInput,
	FormGroup,
} from '@patternfly/react-core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { GenericKeyValueResponse, TDataframe, TDataframeData } from '../../api/types';
import { TKeyMeasure } from '../../api/analytics/KeyMeasure';
import { DraggableMenuItemData } from '../../types/databuilder/databuilder';
import { FormulaOperationEnum } from '../../enums/formula-operation.enum';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	onSubmit?: () => void;
	dataframe: TDataframe | undefined;
	keyMeasures: TKeyMeasure[];
	facts: DraggableMenuItemData[];
	operatorText?: string;
	setOperatorText?: Dispatch<SetStateAction<string>>;
	KMF1?: GenericKeyValueResponse<string>;
	setKMF1?: Dispatch<SetStateAction<GenericKeyValueResponse<string>>>;
	KMF2?: GenericKeyValueResponse<string>;
	setKMF2?: Dispatch<SetStateAction<GenericKeyValueResponse<string>>>;
	setCalculatedFactAlias?: Dispatch<React.SetStateAction<string | undefined>>;
	calculatedFactAlias?: string;
	handleAliasInputChange?: (value: string, _event: React.FormEvent<HTMLInputElement>) => void;
	validatedAlias?: 'default' | 'warning' | 'success' | 'error' | undefined;
	setValidatedAlias?: Dispatch<
		SetStateAction<'default' | 'warning' | 'success' | 'error' | undefined>
	>;
	existingCalculatedDataset?: TDataframeData;
};

function ConfigureCalculationModal(props: Props) {
	const [isOpenMeasure1, setIsOpenMeasure1] = useState(false);
	const [isOpenMeasure2, setIsOpenMeasure2] = useState(false);
	const [isOpenOperation, setIsOpenOperation] = useState(false);
	const [dropdownKeyMeasureItems, setDropdownKeyMeasureItems] = useState<JSX.Element[]>();
	const [dropdownOperatorItems, _setDropdownOperatorItems] = useState<JSX.Element[]>([
		<DropdownItem id={FormulaOperationEnum.Divide}>Divide</DropdownItem>,
		<DropdownItem id={FormulaOperationEnum.Multiply}>Multiply</DropdownItem>,
		<DropdownItem id={FormulaOperationEnum.Add}>Add</DropdownItem>,
		<DropdownItem id={FormulaOperationEnum.Subtract}>Subtract</DropdownItem>,
	]);

	useEffect(() => {
		//load existing values
		if (props.existingCalculatedDataset) {
			const kmf1Name = props.facts.find(
				(x) => x.data?.id == props.existingCalculatedDataset?.formula_variable_1
			)?.data?.title;
			const kmf2Name = props.facts.find(
				(x) => x.data?.id == props.existingCalculatedDataset?.formula_variable_2
			)?.data?.title;

			const kmf1: GenericKeyValueResponse<string> = {
				id: props.existingCalculatedDataset.formula_variable_1!,
				value: kmf1Name ?? '',
			};
			const kmf2: GenericKeyValueResponse<string> = {
				id: props.existingCalculatedDataset.formula_variable_2!,
				value: kmf2Name ?? '',
			};

			props.setCalculatedFactAlias &&
				props.setCalculatedFactAlias(props.existingCalculatedDataset.alias);

			props.setOperatorText &&
				props.setOperatorText(props.existingCalculatedDataset.formula_operation!);
			props.setKMF1 && props.setKMF1(kmf1);
			props.setKMF2 && props.setKMF2(kmf2);
		} //load default select placeholders
		else {
			props.setOperatorText && props.setOperatorText('Select an Operation');
			props.setKMF1 &&
				props.setKMF1({
					id: 0,
					value: 'Select Key Measure 1',
				});
			props.setKMF2 &&
				props.setKMF2({
					id: 0,
					value: 'Select Key Measure 2',
				});

			props.setCalculatedFactAlias && props.setCalculatedFactAlias(undefined);
		}
		props.setValidatedAlias && props.setValidatedAlias('default');
	}, [props.isOpen]);

	useEffect(() => {
		const kmDropdownItems: JSX.Element[] = [];

		props.dataframe?.datasets.map((dataset) => {
			if (!dataset.calculated) {
				const dropdownItem = (
					<DropdownItem
						id={dataset.keyMeasureFact?.toString()}
						name={dataset.title}
					>
						{dataset.title}
					</DropdownItem>
				);
				kmDropdownItems.push(dropdownItem);
			}
		});

		setDropdownKeyMeasureItems(kmDropdownItems);
	}, [props.facts]);

	const onToggleMeasure1 = (isOpen: boolean) => {
		setIsOpenMeasure1(isOpen);
	};

	const onFocusMeasure1 = () => {
		const element = document.getElementById('toggle-m1');
		element?.focus();
	};

	const onSelectMeasure1 = (event?: React.SyntheticEvent<HTMLDivElement, Event> | undefined) => {
		setIsOpenMeasure1(false);
		onFocusMeasure1();

		const id = event?.currentTarget.getAttribute('id');
		const title = event?.currentTarget.children[0].getAttribute('name');
		if (id && title && props.setKMF1) props.setKMF1({ id: parseInt(id), value: title });
	};

	const onToggleMeasure2 = (isOpen: boolean) => {
		setIsOpenMeasure2(isOpen);
	};

	const onFocusMeasure2 = () => {
		const element = document.getElementById('toggle-m2');
		element?.focus();
	};

	const onSelectMeasure2 = (event?: React.SyntheticEvent<HTMLDivElement, Event> | undefined) => {
		setIsOpenMeasure2(false);
		onFocusMeasure2();
		const id = event?.currentTarget.getAttribute('id');
		const title = event?.currentTarget.children[0].getAttribute('name');
		if (id && title && props.setKMF2) props.setKMF2({ id: parseInt(id), value: title });
	};

	const onToggleOperation = (isOpen: boolean) => {
		setIsOpenOperation(isOpen);
	};

	const onFocusMeasureOperation = () => {
		const element = document.getElementById('toggle-op');
		element?.focus();
	};

	const onSelectOperation = (event?: React.SyntheticEvent<HTMLDivElement, Event> | undefined) => {
		const value = event?.currentTarget.getAttribute('id');
		if (value) props.setOperatorText && props.setOperatorText(capitalizeFirstLetter(value));
		setIsOpenOperation(false);
		onFocusMeasureOperation();
	};

	function capitalizeFirstLetter(str: string): string {
		if (str.length === 0) return str;
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	return (
		<Modal
			title="Configure Fact Calculation"
			variant="small"
			isOpen={props.isOpen}
			onClose={props.onClose}
		>
			<hr />
			<br />
			<div>
				{' '}
				<Text className="bold">
					Measure 1: <span className="pf-m-danger">*</span>
				</Text>
				<Dropdown
					className="fullwidth-dropdown"
					onSelect={onSelectMeasure1}
					toggle={
						<DropdownToggle
							id="toggle-m1"
							onToggle={onToggleMeasure1}
						>
							{props.KMF1 && props.KMF1.value}
						</DropdownToggle>
					}
					isOpen={isOpenMeasure1}
					dropdownItems={dropdownKeyMeasureItems}
				/>
				<span className="spacer-sm"></span>
				<Text className="bold">
					Operation: <span className="pf-m-danger">*</span>
				</Text>
				<Dropdown
					className="fullwidth-dropdown"
					onSelect={onSelectOperation}
					toggle={
						<DropdownToggle
							id="toggle-op"
							onToggle={onToggleOperation}
						>
							{props.operatorText}
						</DropdownToggle>
					}
					isOpen={isOpenOperation}
					dropdownItems={dropdownOperatorItems}
				/>
				<span className="spacer-sm"></span>
				<Text className="bold">
					Measure 2: <span className="pf-m-danger">*</span>
				</Text>
				<Dropdown
					className="fullwidth-dropdown"
					onSelect={onSelectMeasure2}
					toggle={
						<DropdownToggle
							id="toggle-m2"
							onToggle={onToggleMeasure2}
						>
							{props.KMF2 && props.KMF2.value}
						</DropdownToggle>
					}
					isOpen={isOpenMeasure2}
					dropdownItems={dropdownKeyMeasureItems}
				/>
				<span className="spacer-sm"></span>
				<Text className="bold">
					Fact Alias Name: <span className="pf-m-danger">*</span>
				</Text>
				<FormGroup
					type="text"
					isRequired
					fieldId="name"
					helperTextInvalid={'Alias Name is required'}
					helperTextInvalidIcon={<ExclamationCircleIcon className="pf-m-error" />}
					validated={props.validatedAlias}
				>
					<TextInput
						type="text"
						placeholder="Enter Fact Alias name"
						onChange={props.handleAliasInputChange}
						value={props.calculatedFactAlias}
						className={props.validatedAlias == 'error' ? 'required-input' : ''}
					/>
				</FormGroup>
			</div>
			<br />
			<ModalBoxFooter className="pull-right">
				<Button
					variant="primary"
					onClick={props.onSubmit}
				>
					Save
				</Button>
			</ModalBoxFooter>
		</Modal>
	);
}

export default ConfigureCalculationModal;
