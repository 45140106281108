import axiosConfig from '../axiosConfig';

export enum DataImportEntityTypeEnum {
	KEYMEASURE_SOURCE = 'keymeasure-source',
	DIMENSION_SOURCE = 'dimension-source',
}

export class AdminActions {
	public static DataImport(entityType: DataImportEntityTypeEnum, id: number): Promise<boolean> {
		return axiosConfig.instance.post(`/app/admin/data-import/${entityType}/${id}`).then(() => {
			return true;
		});
	}

	static DataImportDS(entityType: DataImportEntityTypeEnum, id: number): Promise<boolean> {
		return axiosConfig.instance
			.post(`/app/admin/data-import-ds/`, {
				entityType,
				id,
			})
			.then(() => {
				return true;
			});
	}

	public static SchemaUpdate(): Promise<boolean> {
		return axiosConfig.instance.post(`/app/admin/schema-update`).then(() => {
			return true;
		});
	}
}
