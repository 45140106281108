import React, { Dispatch, SetStateAction } from 'react';
import { Form, FormGroup, Grid, GridItem, TextInput } from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFolder } from '../../../api/foundational-elements/Folder';
import './DefaultPresentOptionsView.scss';
import { PresentCreationOptions } from './ZiPresentOptionsModal';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import TreeViewSelect from '../../form/Select/TreeViewSelect';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { TPresent } from '../../../api/present/Present';

export type BlankPresentOptionsViewProps = {
	onViewChange: Dispatch<SetStateAction<PresentCreationOptions>>;
	presentation: TPresent;
	folders: TFolder[];
	handleNameChange: (value: string, event: React.FormEvent<HTMLInputElement>) => void;
	selectedFolder?: TFolder;
	onSelectFolder: Dispatch<SetStateAction<TFolder | undefined>>;
};

const BlankPresentOptionsView = (props: BlankPresentOptionsViewProps) => {
	const { presentation, folders, handleNameChange, onSelectFolder, selectedFolder } = props;

	return (
		<div
			className="component-container"
			data-testid="default-view"
		>
			<Form>
				<Grid>
					<GridItem span={12}>
						<TextInput
							placeholder="Presentation Name"
							value={presentation.name}
							onChange={handleNameChange}
						/>
					</GridItem>
					<FormGroup
						label={<FontAwesomeIcon icon={faFolder} />}
						type="text"
						fieldId="folder"
						className="folder-container"
					>
						<TreeViewSelect
							hasFolderIcon
							onSelect={(e, item) => {
								if (item && item.id) {
									onSelectFolder(
										folders.find((f) => f.id.toString() === item.id)
									);
								}
							}}
							data={
								folders
									? folders.map((_: TFolder) => BuildTreeViewItem(_, _.items))
									: []
							}
							placeHolderText={
								selectedFolder ? selectedFolder.name : 'Select a Folder'
							}
						/>
					</FormGroup>
				</Grid>
			</Form>
		</div>
	);
};

export default BlankPresentOptionsView;
