import React from 'react';

interface Props {
	searchTerm?: string;
}

const ImageWidgets = (_props: Props) => {
	return <></>;
};

export default ImageWidgets;
