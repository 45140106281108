import { Button, Flex, FlexItem, Grid, GridItem } from '@patternfly/react-core';
import React, { useState } from 'react';
import ZiCard from '../../components/card/ZiCard';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { PresentContext, Presentation } from '../../types/present';
import { CardTypesEnum } from '../../enums/card-types.enum';
import ZiPresentOptionsModal, {
	ZiPresentOptionsModalProps,
} from '../../components/modals/present/ZiPresentOptionsModal';

export enum ListType {
	All,
	Recent,
	Favorite,
}

type Props = {
	pageContext: PresentContext;
	listType?: ListType;
};

const PresentationList = (props: Props) => {
	const { pageContext, listType } = props;
	const _navigate = useNavigate();
	const { folderId } = useParams();
	const [searchParams] = useSearchParams();
	const searchTerm = searchParams.get('term');
	const [_isOpen, setIsOpen] = useState<boolean>(false);
	const [isAddPresentModalOpen, setIsAddPresentModalOpen] = useState<boolean>(false);

	const handlePresentModalToggle = () => {
		setIsAddPresentModalOpen((prev) => !prev);
		setIsOpen(false);
	};

	const getPresentations = () => {
		let presentationsResults: Presentation[] = pageContext.presentations;

		switch (listType) {
			case ListType.Favorite:
				presentationsResults = presentationsResults.filter((pr) => pr.isFavorite);
				break;
			case ListType.Recent:
				presentationsResults = presentationsResults.filter((pr) => pr.isRecent);
				break;
			default:
				break;
		}

		if (folderId) {
			presentationsResults = getPresentationsByFolderId(parseInt(folderId ?? '0'));
		}
		if (searchTerm) {
			presentationsResults = getPresentationsBySearchTerm(presentationsResults, searchTerm);
		}

		return presentationsResults;
	};

	const getPresentationsByFolderId = (folderId: number) => {
		let presentations = pageContext.presentations;
		if (folderId > 0) {
			presentations = presentations.filter((p) => p.folderId == folderId);
		}
		return presentations;
	};

	const getPresentationsBySearchTerm = (presentationsList: Presentation[], term: string) => {
		let presentationsResults: Presentation[] = [];
		if (term) {
			presentationsResults.push(...presentationsList.filter((p) => p.title.startsWith(term)));
			presentationsResults.push(
				...presentationsList.filter((p) => p.title.indexOf(term) > 0)
			);
		} else {
			presentationsResults = presentationsList;
		}
		return presentationsResults;
	};

	const presentOptionsModalProps: ZiPresentOptionsModalProps = {
		isOpen: isAddPresentModalOpen,
		onClose: handlePresentModalToggle,
	};

	return (
		<>
			<Grid hasGutter>
				<Grid>
					<GridItem span={12}>
						<Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
							<Button
								data-testid={'presentation-filter'}
								variant={'primary'}
								onClick={() => {
									handlePresentModalToggle();
									// let url = `/presentation`;
									// if (folderId) url = `/present/${folderId}/presentation`;
									// navigate(url);
								}}
							>
								New Presentation
							</Button>
						</Flex>
					</GridItem>
				</Grid>
				<Grid
					hasGutter
					span={12}
				>
					<Flex>
						{getPresentations().map((presentation: Presentation, index: number) => {
							return (
								<FlexItem key={presentation.id}>
									<ZiCard
										key={index}
										type={CardTypesEnum.Chart}
										title={presentation.title}
										url={`/present/edit/${presentation.id}`}
										icon={presentation.icon}
									/>
								</FlexItem>
							);
						})}
					</Flex>
				</Grid>
			</Grid>
			<ZiPresentOptionsModal {...presentOptionsModalProps} />
		</>
	);
};

export default PresentationList;
