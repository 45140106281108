import { Button, Dropdown, DropdownToggle, Icon, Tooltip } from '@patternfly/react-core';
import React, { ReactElement } from 'react';
import { FilterOptions } from '../../../types/dataframes/FilterOptions';
import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon';
import DatePeriodSelector from '../../date-period-selector/DatePeriodSelector';
import { TDateRange } from '../../../api/types';
import DatabaseIcon from '@patternfly/react-icons/dist/esm/icons/database-icon';
import DataSourceIcon from '@patternfly/react-icons/dist/esm/icons/data-source-icon';
import GripVerticalIcon from '@patternfly/react-icons/dist/esm/icons/grip-vertical-icon';
import TimesCircleIcon from '@patternfly/react-icons/dist/esm/icons/times-circle-icon';
import TrendDownIcon from '@patternfly/react-icons/dist/esm/icons/trend-down-icon';
import MigrationIcon from '@patternfly/react-icons/dist/esm/icons/migration-icon';
import './DataBuilderGridItem.scss';
import { DraggableMenuItemData } from '../../../types/databuilder/databuilder';
import { css } from '@patternfly/react-styles';
import styles from '@patternfly/react-styles/css/components/Form/form';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import { KeyMeasureFactOptions } from '../../../types/dataframes/dataframe-key-measure-fact-options';

export type DataBuilderGridItemProps = {
	options: JSX.Element[];
	name: string;
	optionData: DraggableMenuItemData;
	icon?: 'database' | 'dataSource' | 'vertical-grip';
	type: 'dropdown' | 'button' | 'date' | 'icon-text';
	handleSelect: (key: string) => void;
	onDateSelect?: (startDateRange: TDateRange, endDateRange: TDateRange) => void;
	datePeriodSelectorLabel?: string;
	datePeriodSelectorDisabledMessage?: string;
};

const DataBuilderGridItem = (props: DataBuilderGridItemProps): ReactElement => {
	const [isOpen, setIsOpen] = React.useState(false);

	const onSelect = (
		e?: React.SyntheticEvent<HTMLDivElement, Event> | undefined,
		key?: string
	): void => {
		const currentElementId = (e?.currentTarget as HTMLDivElement)?.id;
		if (
			currentElementId &&
			currentElementId.trim() != '' &&
			currentElementId == KeyMeasureFactOptions.CalculationInvalid
		) {
			return;
		}
		setIsOpen(false);
		props.handleSelect(key ? key : currentElementId);
	};

	const onToggle = (isOpen: boolean) => {
		setIsOpen(isOpen);
	};

	const onDateSelect = (startDateRange: TDateRange, endDateRange: TDateRange) => {
		setIsOpen(false);
		props.onDateSelect && props.onDateSelect(startDateRange, endDateRange);
	};

	const getIcon = (): JSX.Element | undefined => {
		if (props.icon) {
			switch (props.icon) {
				case 'database':
					return (
						<Icon>
							<DatabaseIcon />
						</Icon>
					);
				case 'dataSource':
					return (
						<Icon>
							<DataSourceIcon />
						</Icon>
					);
				case 'vertical-grip':
					return (
						<Icon>
							<GripVerticalIcon />
						</Icon>
					);
			}
		}
	};

	const getGridItemName = () => {
		if (props.optionData.data?.totaled) {
			return (
				<span>
					{props.name}
					&nbsp;
					{
						<Tooltip content={<div>{props.name} is set as a breakout group</div>}>
							<Icon>
								<TrendDownIcon />
							</Icon>
						</Tooltip>
					}
				</span>
			);
		} else if (props.optionData.data?.drillable) {
			return (
				<span>
					{props.name}
					&nbsp;
					{
						<Tooltip content={<div>{props.name} is set as drillable</div>}>
							<Icon>
								<MigrationIcon />
							</Icon>
						</Tooltip>
					}
				</span>
			);
		} else {
			return props.name;
		}
	};

	const dropwdownTemplate = () => {
		return (
			<Dropdown
				className={'data-builder-grid-item-dropdown'}
				onSelect={onSelect}
				toggle={
					<DropdownToggle
						icon={getIcon()}
						onToggle={onToggle}
					>
						{getGridItemName()}
					</DropdownToggle>
				}
				isOpen={isOpen}
				dropdownItems={props.options}
				menuAppendTo={() => document.body}
			/>
		);
	};

	const buttonTemplate = (): JSX.Element => {
		return (
			<div>
				<div
					className={`data-builder-grid-item-button ${
						props.optionData?.data?.isInvalid ? 'invalid' : ''
					}`}
				>
					<div
						className="label"
						onClick={() => {
							onSelect(undefined, FilterOptions.Click);
						}}
					>
						{props.optionData?.data?.title}
					</div>
					<div className="remove-btn">
						<Button
							variant="plain"
							onClick={() => {
								onSelect(undefined, FilterOptions.Remove);
							}}
						>
							<TimesIcon />
						</Button>
					</div>
				</div>
				{props.optionData?.data?.isInvalid && (
					<div className={css(styles.formHelperText, styles.modifiers.error)}>
						<span className={css(styles.formHelperTextIcon)}>
							<ExclamationCircleIcon /> Click to apply a filter
						</span>
					</div>
				)}
			</div>
		);
	};

	const dateTemplate = (): JSX.Element => {
		return (
			<div className="date-selector-container">
				<DatePeriodSelector
					label={props.datePeriodSelectorLabel}
					disabled={props.datePeriodSelectorLabel != undefined}
					disabledMessage={props.datePeriodSelectorDisabledMessage}
					applyDateRange={onDateSelect}
				/>
			</div>
		);
	};

	const iconTextTemplate = (): JSX.Element => {
		return (
			<div className="drilldown-item">
				<span>
					{getIcon()}
					<span>{props.optionData?.data?.title}</span>
				</span>
				{!props.optionData?.static && (
					<Button
						variant="plain"
						aria-label="Remove drill down item"
						onClick={() => {
							onSelect(undefined, FilterOptions.Remove);
						}}
					>
						<TimesCircleIcon />
					</Button>
				)}
			</div>
		);
	};

	const getTemplate = () => {
		switch (props.type) {
			case 'dropdown': {
				return dropwdownTemplate();
			}
			case 'button': {
				return buttonTemplate();
			}
			case 'date': {
				return dateTemplate();
			}
			case 'icon-text': {
				return iconTextTemplate();
			}
		}
	};

	return <div className={'data-builder-grid-item-container'}>{getTemplate()}</div>;
};

export default DataBuilderGridItem;
