import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

export const defaultPlaceholderHtml =
	'<p style="text-align:center;"><span style="font-size: 18px;">AaBbCcYyZz</span></p>';

// Function to initialize the default editor state with "AaBbCcYyZz"
export const getDefaultEditorState = (): EditorState => {
	const defaultHtml = defaultPlaceholderHtml;
	const contentBlock = htmlToDraft(defaultHtml);
	const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
	return EditorState.createWithContent(contentState);
};
