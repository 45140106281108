export const getNumberByKey = (
	objects: [{ [key: string]: number }] | undefined,
	partialKey?: string
) => {
	const b = partialKey ? objects?.find((obj) => obj[partialKey]) : null;

	if (b && partialKey) {
		return b[partialKey];
	}

	return 0;
};
