import { Brand, Bullseye, Flex, FlexItem } from '@patternfly/react-core';
import logo from '../img/zi-light-logo.png';
import React from 'react';
export type Props = {
	children: React.ReactNode;
	description: string;
	notice: React.ReactNode;
};

export default function CenteredPage(props: Props) {
	return (
		<Bullseye className="pf-u-justify-content-center">
			<Flex
				direction={{ default: 'column' }}
				flex={{ default: 'flex_2' }}
				style={{ width: '356px' }}
			>
				<FlexItem>
					<Flex
						direction={{ default: 'column' }}
						flex={{ default: 'flex_1' }}
						width={'100%'}
						alignItems={{ default: 'alignItemsCenter' }}
					>
						<FlexItem>
							<Brand
								src={logo}
								alt={'ZeroedIn Technologies'}
								className="zin-login-logo"
							></Brand>
						</FlexItem>
						<FlexItem>
							<div>{props.description}</div>
						</FlexItem>
						<FlexItem>{props.notice}</FlexItem>
					</Flex>
				</FlexItem>
				<FlexItem>{props.children}</FlexItem>
			</Flex>
		</Bullseye>
	);
}
