import { SubNavContext, TipAndTrickType } from './sub-nav-context';

const DiscoverSubNavContext: SubNavContext = {
	tipsAndTricks: {
		title: 'Tips & Tricks',
		allTutorialsLink: '#',
		tipsAndTricksList: [
			{
				title: 'How to Search',
				url: '#',
				type: TipAndTrickType.VIDEO,
			},
			{
				title: 'How to use Tagging',
				url: '#',
				type: TipAndTrickType.PDF,
			},
		],
	},
};

export default DiscoverSubNavContext;
