import React, { useEffect, useState } from 'react';
import {
	Button,
	DragDrop,
	Draggable,
	Droppable,
	InputGroup,
	TextInput,
	Select,
	SelectOption,
	SelectOptionObject,
	DraggableItemPosition,
} from '@patternfly/react-core';
import { IVariableInputListData } from '../form/SchnurForm/SchnurForm';
import './VariableInputGroup.css';
import GripVerticalIcon from '@patternfly/react-icons/dist/esm/icons/grip-vertical-icon';
import { DirectionEnum } from '../../api/analytics/KeyMeasureFact';
import { useApplication } from '../user/ApplicationProvider';

type Props = {
	name: string;
	data: IVariableInputListData[];
	onChange: (changedData: IVariableInputListData[]) => void;
	shouldResetOnSelect?: boolean;
	isDisabled?: boolean;
	placeholder?: string;
	isRequired?: boolean;
};

export default function VariableInputGroup(props: Props) {
	const [isOpenList, setIsOpenList] = useState<boolean[]>([]);
	const { unitTypes } = useApplication();
	const [variableInputId] = useState<number>(new Date().getTime());
	const [isOpenDirectionList, setOpenDirectionList] = useState<boolean[]>([]);

	useEffect(() => {
		if (props.data) {
			const isOpenList: boolean[] = [];

			props.data.map(() => {
				isOpenList.push(false);
			});

			setIsOpenList(isOpenList);
		}
	}, [props.data]);

	const submitOnChange = (newData: IVariableInputListData[]) => {
		props.onChange(newData);
	};

	const removeItem = (index: number) => {
		const list = [...props.data];
		list.splice(index, 1);
		submitOnChange(list);

		const isOpenListCopy = [...isOpenList];
		isOpenListCopy.splice(index, 1);
		setIsOpenList(isOpenListCopy);
	};

	const addItem = () => {
		const list = [
			...props.data,
			{
				name: '',
				unitType: -1,
				sequence: props.data.length + 1,
				alias: '',
				direction: DirectionEnum.UPWARD,
				minimum_value: 0,
				maximum_value: 0,
			},
		];

		submitOnChange(list);
		setIsOpenList(() => [...isOpenList, false]);
	};

	const onToggle = (index: number, isOpen: boolean) => {
		const list = [...isOpenList];
		list[index] = isOpen;
		setIsOpenList(list);
	};

	const onToggleDirection = (index: number, isOpen: boolean) => {
		const list = [...isOpenDirectionList];
		list[index] = isOpen;
		setOpenDirectionList(list);
	};

	const dropdownItems = [
		<SelectOption
			key={-1}
			value="Select a Unit Type"
			isPlaceholder
		/>,
	];

	unitTypes.map((unitType) => {
		dropdownItems.push(
			<SelectOption
				key={unitType.id}
				value={unitType.name}
			/>
		);
	});

	const onSelect = (
		index: number,
		selection: string | SelectOptionObject,
		isPlaceholder?: boolean
	) => {
		const unitType = unitTypes.find((unitType) => unitType.name === selection);
		if (!unitType || isPlaceholder) {
			return;
		}

		const list = [...props.data];
		list[index].unitType = unitType.id;
		submitOnChange(list);
		onToggle(index, false);
	};

	const getUnitType = (fact: IVariableInputListData) => {
		const unitType = unitTypes.find((unitType) => {
			if (typeof fact.unitType === 'object') {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return unitType.id === fact.unitType.id;
			} else {
				return unitType.id === fact.unitType;
			}
		});

		if (!unitType) {
			return null;
		}

		return unitType.name;
	};

	const onDrop = (
		source: DraggableItemPosition,
		dest: DraggableItemPosition | undefined
	): boolean => {
		if (!dest) {
			return false;
		}

		const data = [...props.data];
		const [removed] = data.splice(source.index, 1);
		data.splice(dest.index, 0, removed);

		data.forEach((fact, index) => {
			data[index].sequence = index + 1;
		});
		submitOnChange(data);

		return true;
	};

	return (
		<DragDrop onDrop={onDrop}>
			<Button onClick={addItem}>Add Fact</Button>
			<Droppable
				zone={`vig-${variableInputId}-droppable`}
				className={'zi-vig-droppable'}
			>
				{props.data.map((fact, index) => (
					<Draggable
						key={`vig-${variableInputId}-draggable-${index}`}
						style={{ padding: '8px 0 8px 0' }}
					>
						<InputGroup className={'zi-inputgroup'}>
							<GripVerticalIcon className={'zi-drag-grip'} />
							<Select
								onSelect={(_, selection, isPlaceholder) => {
									onSelect(index, selection, isPlaceholder);
								}}
								isOpen={isOpenList[index]}
								menuAppendTo="parent"
								selections={getUnitType(fact) ?? undefined}
								onToggle={(isOpen) => onToggle(index, isOpen)}
								shouldResetOnSelect={true}
							>
								{dropdownItems}
							</Select>
							<Select
								onSelect={(_, selection, isPlaceholder) => {
									if (isPlaceholder) {
										return;
									}

									let val = '';
									if (selection === 'Upward') {
										val = 'UPWARD';
									} else if (selection === 'Downward') {
										val = 'DOWNWARD';
									}

									const list = [...props.data];
									list[index].direction = val;
									submitOnChange(list);
									onToggleDirection(index, false);
								}}
								isOpen={isOpenDirectionList[index]}
								menuAppendTo="parent"
								selections={fact.direction === 'UPWARD' ? 'Upward' : 'Downward'}
								onToggle={(isOpen) => onToggleDirection(index, isOpen)}
								shouldResetOnSelect={true}
							>
								<SelectOption
									key={-1}
									value="Select a Direction"
									isPlaceholder
								/>
								<SelectOption
									key="UPWARD"
									value="Upward"
								/>
								<SelectOption
									key="DOWNWARD"
									value="Downward"
								/>
							</Select>
							<TextInput
								id={`vig-${variableInputId}-alias-${index}`}
								aria-label="Fact Alias"
								placeholder="Fact Alias"
								value={fact.alias}
								onChange={(value) => {
									const list = [...props.data];
									list[index].alias = value;
									submitOnChange(list);
								}}
							/>
							<TextInput
								id={`vig-${variableInputId}-name-${index}`}
								aria-label="Fact Name"
								placeholder="Fact Name"
								value={fact.name}
								onChange={(value) => {
									const list = [...props.data];
									list[index].name = value;
									submitOnChange(list);
								}}
							/>
							<TextInput
								id={`vig-${variableInputId}-minval-${index}`}
								aria-label="Min Value"
								placeholder="Min Value"
								value={fact.minimum_value}
								onChange={(value) => {
									const list = [...props.data];
									list[index].minimum_value = Number(value);
									submitOnChange(list);
								}}
							/>
							<TextInput
								id={`vig-${variableInputId}-maxval-${index}`}
								aria-label="Max Value"
								placeholder="Max Value"
								value={fact.maximum_value}
								onChange={(value) => {
									const list = [...props.data];
									list[index].maximum_value = Number(value);
									submitOnChange(list);
								}}
							/>
							<Button
								variant="control"
								isDanger={true}
								onClick={() => removeItem(index)}
							>
								Remove
							</Button>
						</InputGroup>
					</Draggable>
				))}
			</Droppable>
		</DragDrop>
	);
}
