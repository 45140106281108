export const buildUrlWithFilters = (url: string, filter: Record<string, string>) => {
	let joinedFilterString = '';
	for (const property in filter) {
		if (joinedFilterString !== '') {
			joinedFilterString = `${joinedFilterString}&`;
		}
		joinedFilterString = `${joinedFilterString}filter[${property}]=${filter[property]}`;
	}
	return `${url}?${joinedFilterString}`;
};
