import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction } from 'react';
import { useState, useEffect } from 'react';

type Props = {
	iconElements: IconDefinition[];
	selectedIcon?: IconDefinition;
	setSelectedCategoryicon: (icon: IconDefinition) => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const IconCardDropdown = React.memo(
	(props: Props) => {
		const [isOpen, setIsOpen] = useState(false);

		const toggleDropdown = () => {
			setIsOpen(!isOpen);

			setTimeout(() => {
				props.setIsLoading(false);
			}, 300);
		};

		const closeExpandedDropdown = () => {
			setIsOpen(false);
		};

		useEffect(() => {
			const handleClickOutside = (event: MouseEvent) => {
				const target = event.target as HTMLElement;
				const classes = target.classList;
				const isDropdownItemClicked =
					classes.contains('c-dropdown') ||
					classes.contains('c-dropdown__toggle') ||
					classes.contains('fa-caret-down') ||
					classes.contains('svg-inline--fa') ||
					classes.contains('pr-5');
				if (!isDropdownItemClicked) {
					setIsOpen(false);
				}
			};

			document.addEventListener('click', handleClickOutside);

			return () => {
				document.removeEventListener('click', handleClickOutside);
			};
		}, []);

		return (
			<div className="c-dropdown">
				<button
					className="c-dropdown-toggle"
					onClick={toggleDropdown}
				>
					<label className="pr-5">
						{props.selectedIcon ? (
							<FontAwesomeIcon icon={props.selectedIcon as IconProp} />
						) : (
							'-Select an Icon-'
						)}
					</label>
					<FontAwesomeIcon
						icon={faCaretDown}
						style={{ cursor: 'pointer' }}
					/>
				</button>
				{isOpen && (
					<ul className="c-dropdown-menu">
						{props.iconElements.map((item, index) => (
							<li
								key={index}
								className="c-dropdown-item"
								onClick={(e) => {
									props.setSelectedCategoryicon(item);
									closeExpandedDropdown();
								}}
							>
								<FontAwesomeIcon icon={item as IconProp} />
							</li>
						))}
					</ul>
				)}
			</div>
		);
	},
	(prevProps, nextProps) => {
		// Custom comparison function to prevent re-renders
		// Only re-render if iconElements or selectedIcon changes
		return (
			prevProps.iconElements === nextProps.iconElements &&
			prevProps.selectedIcon === nextProps.selectedIcon
		);
	}
);

export default IconCardDropdown;
