import { INavigationLink } from '../../layout/PageNavigationLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCirclePlus, faClock, faStar } from '@fortawesome/pro-light-svg-icons';

export const ReportSidebarContext: INavigationLink[] = [
	{
		name: 'All',
		icon: <FontAwesomeIcon icon={faCirclePlus} />,
		to: '/report',
		id: 'report-all',
	},
	{
		name: 'Recents',
		icon: <FontAwesomeIcon icon={faClock} />,
		to: '/report/recents',
		id: 'report-recents',
	},
	{
		name: 'Favorites',
		icon: <FontAwesomeIcon icon={faStar} />,
		to: '/report/favorites',
		id: 'report-favorites',
	},
	{
		name: 'Schedules',
		icon: <FontAwesomeIcon icon={faCalendar} />,
		to: '/report/schedules',
		id: 'report-schedules',
	},
	// {
	// 	name: 'Queue',
	// 	icon: <FontAwesomeIcon icon={faCloud} />,
	// 	to: '/report/queue',
	// 	id: 'report-queue',
	// },
];
