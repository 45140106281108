import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { OutletContext } from '../../layout/Layout';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import DiscoverSubNavContext from '../../helpers/sub-nav-contexts/discover-sub-nav-context';
import {
	Card,
	CardBody,
	Flex,
	Form,
	FormGroup,
	Grid,
	GridItem,
	Label,
	SearchInput,
	Text,
	Title,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import ZiCard from '../../components/card/ZiCard';
import { CardTypesEnum } from '../../enums/card-types.enum';
import './Discover.scss';
import { DiscoverCategory, QuickFilter } from '../../types/discover';
import { useMount } from 'react-use';
import { QuestionCategory } from '../../api/question-category/QuestionCategory';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import EmptyResults from '../../components/EmptyResults';
import { iconMap } from '../../helpers/global-icon-map.helper';
import { Tag } from '../../api/tags/Tags';

const Discover = () => {
	const navigate = useNavigate();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [searchTerm, setSearchTerm] = useState<string>();
	const [categories, setCategories] = useState<DiscoverCategory[]>();
	const [hasLoadedCategories, setHasLoadedCategories] = useState<boolean>(false);
	const [quickFilters, setQuickFilters] = useState<QuickFilter[]>();

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Discover"
					pageDescription="Welcome to where you can find something specific or have a topic or area of interest. Looking for a specific Report, Chart, or Dashboard? You can find it here. Not sure what you are looking for or what you want to do, we got you."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
					subNavContext={DiscoverSubNavContext}
				/>
			),
			sidebarContext: null,
			hideLeftSideBar: true,
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	useMount(() => {
		void QuestionCategory.GetPermitted().then((response) => {
			const discoverCategories: DiscoverCategory[] = [];

			response.map((item) => {
				const newCategory: DiscoverCategory = {
					icon: iconMap[item.icon],
					id: item.id!,
					key: `Category_${item.title}`,
					name: item.title,
					questions: [],
				};
				discoverCategories.push(newCategory);
			});
			setCategories(discoverCategories);
			setHasLoadedCategories(true);
		});

		void Tag.GetPermitted().then((response) => {
			const filters: QuickFilter[] = [];

			response.map((tag) => {
				const tagName = tag.id!.toString();
				const quickFilter: QuickFilter = {
					key: `QuickFilter_${tag.name}_${tagName}`,
					name: tag.name,
				};
				filters.push(quickFilter);
			});
			setQuickFilters(filters);
		});
	});

	return (
		<Card>
			<CardBody>
				<Form>
					<Grid
						hasGutter
						md={6}
					>
						<GridItem span={8}>
							<FormGroup>
								<SearchInput
									placeholder="Discover"
									value={searchTerm}
									onChange={(_event, value: string) => setSearchTerm(value)}
									onClear={() => setSearchTerm(undefined)}
									onSearch={(_event, value) => {
										navigate(`/discover/search?term=${value}`);
									}}
									aria-label="Discover Search"
									data-testid="discover-search"
								/>
							</FormGroup>
						</GridItem>
						<GridItem span={12}>
							<FormGroup>
								<Flex>
									<Text>Quick Filters:</Text>
									{quickFilters &&
										quickFilters.slice(0, 10).map((filter, index) => {
											return (
												<Label
													key={index}
													className="quickfilter-label"
													style={{ backgroundColor: '#f5f5f5' }}
													render={({ className, content }) => (
														<Link
															to={`/discover/search?tag=${filter.name}`}
															className={className}
														>
															{content}
														</Link>
													)}
													data-testid={`discover-quickfilter-${filter.key}`}
												>
													{filter.name}
												</Label>
											);
										})}
								</Flex>
							</FormGroup>
						</GridItem>
						<GridItem span={12}>
							<FormGroup>
								<Title headingLevel="h1">What do you want to Zero In on?</Title>
							</FormGroup>
						</GridItem>
						<GridItem span={12}>
							<FormGroup>
								{categories?.length == 0 && hasLoadedCategories && (
									<div className="text-center">
										{' '}
										<EmptyResults
											emptyStateBody="No Discover Categories were found"
											iconName={faInfoCircle}
											backComponent={<></>}
										/>
									</div>
								)}
								<Flex className="list-items">
									{categories &&
										hasLoadedCategories &&
										categories.map((category, index) => {
											return (
												<ZiCard
													key={index}
													url={`/discover/search?id=${category.id}`}
													title={category.name}
													type={CardTypesEnum.Chart}
													icon={category.icon}
													hidePin={true}
												></ZiCard>
											);
										})}
								</Flex>
							</FormGroup>
						</GridItem>
					</Grid>
				</Form>
			</CardBody>
		</Card>
	);
};

export default Discover;
