import { Button, Grid, GridItem, TextInput } from '@patternfly/react-core';
import React from 'react';
import { DatePeriodFormatter } from './DatePeriodFormatter';
import { TDateRange } from '../../api/types';

type footerDateInput = {
	startDate?: number;
	endDate?: number;
	applySelectedDates: () => (TDateRange | undefined)[];
};
function PopoverFooterHtml({ startDate, endDate, applySelectedDates }: footerDateInput) {
	return (
		<div>
			<hr />
			<br />
			<Grid span={12}>
				<GridItem
					span={2}
					className="lbl-dates-selected"
				>
					{' '}
					Dates Selected:
				</GridItem>
				<GridItem span={2}>
					{' '}
					<TextInput
						placeholder={'mm/dd/yyyy'}
						value={startDate ? DatePeriodFormatter(startDate) : ''}
						isDisabled
					></TextInput>
				</GridItem>
				<GridItem
					span={1}
					className="lbl-dates-selected"
				>
					<label>to</label>
				</GridItem>
				<GridItem span={2}>
					<TextInput
						placeholder={'mm/dd/yyyy'}
						value={endDate ? DatePeriodFormatter(endDate) : ''}
						isDisabled
					></TextInput>
				</GridItem>
				<GridItem span={2}> </GridItem>
				<GridItem span={3}>
					{' '}
					<Button
						variant="primary"
						className="pf-m-sm"
						onClick={() => applySelectedDates()}
					>
						Apply Date Ranges
					</Button>
				</GridItem>
			</Grid>
			<br />
		</div>
	);
}
export default PopoverFooterHtml;
