import Highcharts from 'highcharts';

export const numberFormatter = (input?: string | number, numberOfDecimals?: number): string => {
	if (input) {
		//If input is a number, return the formatted number as a string
		if (!isNaN(input as number)) {
			return Highcharts.numberFormat((input as number) ?? 0, numberOfDecimals ?? 2, '.', ',');
		}

		const value = input.toString();
		const regexString = /[a-zA-Z-/\\*]+/;
		const matchString = value.match(regexString);

		//If string contains alphabet letters, return the original string
		if (matchString) {
			return value;
		}

		const regexNumber = /[0-9,.]+/;
		const matchNumber = value.match(regexNumber);

		//If string contains no numbers, return the original string
		if (!matchNumber) {
			return value;
		}

		const number = matchNumber[0].replace(/,/g, '');
		const specialChars = value.replace(number, '');

		//If string is not a number, return the original string
		if (isNaN(number as unknown as number)) {
			return value;
		}

		const formattedNumber = Highcharts.numberFormat(
			(number as unknown as number) ?? 0,
			numberOfDecimals ?? 2,
			'.',
			','
		);

		return specialChars.length > 0
			? `${specialChars[0]}${formattedNumber}${specialChars.slice(1)}`
			: formattedNumber;
	}

	return input as string;
};
