import axiosConfig from '../axiosConfig';

export type TFavorites = {
	id?: number;
	user?: number;
	object_id?: number;
	created_at?: number;
	name: string;
	type: string;
	chartType?: string;
	showOnMain?: boolean;
};

export class Favorites implements TFavorites {
	id?: number;
	user?: number;
	object_id?: number;
	created_at?: number;
	name: string;
	type: string;
	chartType?: string;
	showOnMain?: boolean;

	constructor(favorite: TFavorites) {
		this.id = favorite.id;
		this.user = favorite.user;
		this.object_id = favorite.object_id;
		this.created_at = favorite.created_at;
		this.name = favorite.name;
		this.type = favorite.type;
		this.chartType = favorite.chartType;
		this.showOnMain = favorite.showOnMain;
	}

	public static ToggleFavorite(favorite: TFavorites): Promise<TFavorites> {
		return axiosConfig.instance.post('app/favorite', favorite).then((res) => {
			return new Favorites(res.data as TFavorites);
		});
	}

	public static Get(object_id: number, user: number): Promise<TFavorites> {
		return axiosConfig.instance
			.get<TFavorites>(`app/favorite/${object_id}/user/${user}`)
			.then((res) => {
				return res.data;
			});
	}

	public static GetByType(user: number, type: string): Promise<TFavorites[]> {
		return axiosConfig.instance
			.get<TFavorites[]>(`app/favorite/${user}/type/${type}`)
			.then((res) => {
				return res.data;
			});
	}

	public static Update(favorite: TFavorites): Promise<TFavorites> {
		return axiosConfig.instance.patch('app/favorite', favorite).then((res) => {
			return new Favorites(res.data as TFavorites);
		});
	}
}
