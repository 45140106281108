import { faChartLine, faClock, faPresentation, faStar } from '@fortawesome/pro-regular-svg-icons';
import { PresentContext } from '../../types/present';
import { TipAndTrickType } from '../sub-nav-contexts/sub-nav-context';
import { Presentations } from './presentation-context';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';

const PresentPageContext: PresentContext = {
	subNavContext: {
		actions: {
			title: 'Common Actions',
			actionsList: [
				{
					url: '#',
					title: 'Add Presentation',
					icon: faPresentation,
				},
				{
					url: '#',
					title: 'Add Slide Template',
					icon: faChartLine,
				},
			],
		},
		tipsAndTricks: {
			title: 'Tips & Tricks',
			allTutorialsLink: '#',
			tipsAndTricksList: [
				{
					title: 'How to Add A Presentation',
					type: TipAndTrickType.VIDEO,
					url: '#',
				},
				{
					title: 'How to Make a Killer Template',
					type: TipAndTrickType.PDF,
					url: '#',
				},
			],
		},
	},
	sideBarContext: [
		{
			name: 'Recents',
			iconDefinition: faClock,
			to: '/present/recents',
			id: 'present-recents',
		},
		{
			name: 'Favorites',
			iconDefinition: faStar,
			to: '/present/favorites',
			id: 'present-favorites',
		},
	],
	presentations: Presentations,
	folders: [
		{
			name: 'Compensation',
			id: '1',
			iconDefinition: faFolder,
			to: '/present/1',
		},
		{
			name: 'Learning',
			id: '2',
			iconDefinition: faFolder,
			to: '/present/2',
		},
		{
			name: 'People',
			id: '3',
			iconDefinition: faFolder,
			to: '/present/3',
		},
		{
			name: 'Performance',
			id: '4',
			iconDefinition: faFolder,
			to: '/present/4',
		},
		{
			name: 'Recognition',
			id: '5',
			iconDefinition: faFolder,
			to: '/present/5',
		},
		{
			name: 'Recruiting',
			id: '6',
			iconDefinition: faFolder,
			to: '/present/6',
		},
		{
			name: 'Time & Labor',
			id: '7',
			iconDefinition: faFolder,
			to: '/present/7',
		},
	],
};

export default PresentPageContext;
