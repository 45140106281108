export const rgbToHex = (rgb: string): string => {
	// Extract the numbers from the rgb string
	const result = rgb.match(/\d+/g);

	if (!result || result.length < 3) {
		throw new Error('Invalid RGB format');
	}

	// Convert each RGB value to its hexadecimal equivalent
	const hex = result
		.slice(0, 3) // Take the first 3 values (R, G, B)
		.map((num) => {
			const hexVal = parseInt(num, 10).toString(16);
			return hexVal.length === 1 ? '0' + hexVal : hexVal; // Pad single digit hex values with a 0
		})
		.join('');

	return `#${hex}`;
};

export const extractRgbColor = (
	html: string,
	property: 'color' | 'background-color'
): string | null => {
	// Regular expression to match 'color: rgb(...)' or 'background-color: rgb(...)'
	const rgbRegex = new RegExp(`${property}:\\s*rgb\\(\\d+,\\s*\\d+,\\s*\\d+\\)`);

	// Find the first match in the HTML string
	const match = html.match(rgbRegex);

	// If a match is found, return it; otherwise, return null
	return match ? match[0] : null;
};
