import { useEffect, useState } from 'react';
import { OutletContext } from '../../layout/Layout';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { ReportSidebarContext } from '../../helpers/sidebar-contexts/report-sidebar-context';
import AddNewDropdown from '../../components/ui/addNewDropdownButton/AddNewDropdown';
import { Card, CardBody } from '@patternfly/react-core';
import { FolderTypesEnum } from '../../enums/folder-types-enum';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router';
import MainSubNavContext from '../../helpers/sub-nav-contexts/main-sub-nav-context';
import { SubNavContext } from '../../helpers/sub-nav-contexts/sub-nav-context';
import { useUser } from '../../components/user/ApplicationProvider';
import ReportSubNavContext from '../../helpers/sub-nav-contexts/report-sub-nav-context';

const Report = () => {
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const navigate = useNavigate();
	const pathname = useLocation().pathname;
	const [subNavContext, setSubNavContext] = useState<SubNavContext>(MainSubNavContext);
	const currentUser = useUser();

	useMount(() => {
		const prevReportPage = localStorage.getItem('prevReportPage');
		const currentNestedFolderPath = localStorage.getItem('currentNestedFolderPath');

		if (prevReportPage) {
			navigate(prevReportPage);
		}
		if (currentNestedFolderPath) {
			localStorage.removeItem('currentNestedFolderPath');
		}

		setSubNavContext({
			...subNavContext,
			tipsAndTricks: ReportSubNavContext.tipsAndTricks,
		});
	});

	useEffect(() => {
		const headerContext = {
			header: '',
			subheader: '',
		};

		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Report"
					pageDescription=""
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
					subNavContext={subNavContext}
				/>
			),
			sidebarContext: ReportSidebarContext,
			sidebarHeaderContext: headerContext,
			folderHeadingTitle: 'Reports',
			folderType: FolderTypesEnum.Report,
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded, subNavContext]);

	return (
		<Card>
			<CardBody>
				{!pathname.includes('/recents') && !pathname.includes('/favorites') && (
					<AddNewDropdown
						folderHeadingTitle="Reports"
						folderType={FolderTypesEnum.Report}
						onSetSubSide={setSubSide}
					/>
				)}
				<Outlet />
			</CardBody>
		</Card>
	);
};

export default Report;
