import {
	faBell,
	faBookMedical,
	faChartSimple,
	faObjectGroup,
	faPersonChalkboard,
	faDatabase,
} from '@fortawesome/pro-regular-svg-icons';
import { SubNavContext } from './sub-nav-context';
import { Permission } from '../../enums/permission.enum';

const MainSubNavContext: SubNavContext = {
	actions: {
		title: 'What Do You want to Zero In on Today?',
		actionsList: [
			{
				title: 'Create Dataframe',
				url: '/analyze/dataframes/create',
				icon: faDatabase,
				permission: Permission.CreateDataframe,
				prevUrlRefObject: 'prevAnalyzePage',
				type: 'create',
				order: 1,
			},
			{
				title: 'View Dataframes',
				url: '/analyze/dataframes',
				icon: faDatabase,
				permission: Permission.ViewDataframe,
				prevUrlRefObject: 'prevAnalyzePage',
				type: 'view',
				order: 1,
			},
			{
				title: 'Create Chart',
				url: '/analyze/charts',
				icon: faChartSimple,
				permission: Permission.CreateChart,
				prevUrlRefObject: 'prevAnalyzePage',
				type: 'create',
				order: 2,
			},
			{
				title: 'View Charts',
				url: '/analyze/charts',
				icon: faChartSimple,
				permission: Permission.ViewChart,
				prevUrlRefObject: 'prevAnalyzePage',
				type: 'view',
				order: 2,
			},
			{
				title: 'Create Dashboard',
				url: '/analyze/dashboards',
				icon: faObjectGroup,
				permission: Permission.CreateDashboard,
				prevUrlRefObject: 'prevAnalyzePage',
				type: 'create',
				order: 3,
			},
			{
				title: 'View Dashboards',
				url: '/analyze/dashboards',
				icon: faObjectGroup,
				permission: Permission.ViewDashboard,
				prevUrlRefObject: 'prevAnalyzePage',
				type: 'view',
				order: 3,
			},
			{
				title: 'Create Report',
				url: '/report',
				icon: faBell,
				permission: Permission.CreateReport,
				prevUrlRefObject: 'prevReportPage',
				type: 'create',
				order: 4,
			},
			{
				title: 'View Reports',
				url: '/report',
				icon: faBell,
				permission: Permission.ViewReport,
				prevUrlRefObject: 'prevReportPage',
				type: 'view',
				order: 4,
			},
			{
				title: 'Schedule a Report',
				url: '/report/schedules',
				icon: faBookMedical,
				permission: Permission.CreateSchedule,
				prevUrlRefObject: 'prevReportPage',
				type: 'create',
				order: 5,
			},
			{
				title: 'View Report Schedules',
				url: '/report/schedules',
				icon: faBookMedical,
				permission: Permission.ViewReportSchedule,
				prevUrlRefObject: 'prevReportPage',
				type: 'view',
				order: 5,
			},
			{
				title: 'Create Presentation',
				url: '#',
				tooltip: 'Presentations are coming soon',
				icon: faPersonChalkboard,
				prevUrlRefObject: 'prevPresentPage',
				type: 'create',
				order: 6,
			},
			// Will add this one back once we have correct permissions setup for presentations
			// {
			// 	title: 'View Presentations',
			// 	url: '#',
			// 	tooltip: 'Presentations are coming soon',
			// 	icon: faPersonChalkboard,
			// 	prevUrlRefObject: 'prevPresentPage',
			//  type: 'view',
			//	order: 6
			// },
		],
	},
};

export default MainSubNavContext;
