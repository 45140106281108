import { useEffect, useState } from 'react';
import { OutletContext } from '../../layout/Layout';
import { Outlet, useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { AnalyzeSidebarContext } from '../../helpers/sidebar-contexts/analyze-sidebar-context';
import AddNewDropdown from '../../components/ui/addNewDropdownButton/AddNewDropdown';
import { Card, CardBody } from '@patternfly/react-core';
import { FolderTypesEnum } from '../../enums/folder-types-enum';
import { useMount } from 'react-use';
import { useNavigate, useLocation } from 'react-router';
import MainSubNavContext from '../../helpers/sub-nav-contexts/main-sub-nav-context';
import { SubNavContext } from '../../helpers/sub-nav-contexts/sub-nav-context';
import { useUser } from '../../components/user/ApplicationProvider';
import AnalyzeSubNavContext from '../../helpers/sub-nav-contexts/analyze-sub-nav-context';

function Analyze() {
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const navigate = useNavigate();
	const pathname = useLocation().pathname;
	const [subNavContext, setSubNavContext] = useState<SubNavContext>(MainSubNavContext);
	const currentUser = useUser();

	useMount(() => {
		const prevAnalyzePage = localStorage.getItem('prevAnalyzePage');
		const isFolderClicked = localStorage.getItem('isBreadcrumbFolderClicked');
		const currentNestedFolderPath = localStorage.getItem('currentNestedFolderPath');

		if (currentNestedFolderPath) {
			localStorage.removeItem('currentNestedFolderPath');
		}

		if (prevAnalyzePage && !isFolderClicked) {
			navigate(`/analyze/${prevAnalyzePage}`);
		}

		if (isFolderClicked) {
			localStorage.removeItem('isBreadcrumbFolderClicked');
		}

		setSubNavContext({
			...subNavContext,
			tipsAndTricks: AnalyzeSubNavContext.tipsAndTricks,
		});
	});

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Analyze"
					pageDescription=""
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
					subNavContext={subNavContext}
				/>
			),
			sidebarContext: AnalyzeSidebarContext,
			folderHeadingTitle: 'Dashboard',
			folderType: FolderTypesEnum.Analyze,
			sidebarHeaderContext: { header: '', subheader: '' },
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded, subNavContext]);

	return (
		<Card>
			<CardBody>
				{!pathname.includes('/recents') && !pathname.includes('/favorites') && (
					<AddNewDropdown
						folderHeadingTitle="Dashboards"
						folderType={
							pathname.includes('/table/') || pathname.includes('/tables')
								? FolderTypesEnum.Tables
								: FolderTypesEnum.Analyze
						}
						onSetSubSide={setSubSide}
					/>
				)}

				<Outlet />
			</CardBody>
		</Card>
	);
}

export default Analyze;
