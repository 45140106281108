import axiosConfig from '../axiosConfig';

export type TFolder = {
	id: number;
	name: string;
	type: string;
	items: { id: number; name: string }[];
	isFolder?: boolean;
};

export class Folder implements TFolder {
	id: number;
	name: string;
	type: string;
	items: { id: number; name: string }[];
	isFolder?: boolean;

	constructor(folder: TFolder) {
		this.id = folder.id;
		this.name = folder.name;
		this.type = folder.type;
		this.items = [];
		this.isFolder = folder.isFolder;
	}

	public static Default(): TFolder {
		return {
			id: 0,
			name: '',
			type: '',
			items: [],
			isFolder: false,
		};
	}

	public static New(folder: TFolder): Promise<TFolder> {
		return axiosConfig.instance.post<TFolder>('app/folders', folder).then((res) => {
			return res.data;
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete<boolean>(`app/folders/${id}`).then((res) => {
			return res.data;
		});
	}

	public static Update(folder: TFolder): Promise<TFolder> {
		return axiosConfig.instance.patch<TFolder>('app/folders', folder).then((res) => {
			return res.data;
		});
	}

	public static GetAll(
		filter?: Record<string, string>,
		specificFolderId?: string | undefined
	): Promise<TFolder[]> {
		let url = 'app/folders';

		if (filter) {
			url = `app/entities/${filter.type}`;
		}

		if (specificFolderId) {
			const endsWithSlash = url.endsWith('/');
			url += endsWithSlash ? specificFolderId : '/' + specificFolderId;
		}

		return axiosConfig.instance.get<TFolder[]>(url).then((res) => {
			return res.data ?? [];
		});
	}
}
