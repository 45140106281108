import { faClock, faStar } from '@fortawesome/pro-regular-svg-icons';
import { PresentionationContext } from '../../types/presentation';

const PresentationPageContext: PresentionationContext = {
	sideBarContext: {
		isRight: true,
		items: [
			{
				name: 'Recents',
				iconDefinition: faClock,
				to: '/present/recents',
				id: 'present-recents',
			},
			{
				name: 'Favorites',
				iconDefinition: faStar,
				to: '/present/favorites',
				id: 'present-favorites',
			},
		],
	},
	rightSideContext: {
		fixedSlideLayout: false,
	},
	presentations: [
		{
			id: 1,
			name: 'Presentation 1',
			slides: [
				{
					widgets: [],
				},
			],
		},
		{
			id: 2,
			name: 'Presentation 2',
			slides: [
				{
					widgets: [],
				},
			],
		},
		{
			id: 3,
			name: 'Presentation 3',
			slides: [
				{
					widgets: [],
				},
			],
		},
	],
};

export default PresentationPageContext;
