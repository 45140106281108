import React from 'react';
import { EmptyState, EmptyStateBody, EmptyStatePrimary, Title } from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceHandPeeking } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
	emptyStateBody: string;
	backComponent: React.ReactNode;
	iconName?: IconProp;
	noResultsMsg?: string;
	heading?: string;
};

const EmptyResults = (props: Props) => {
	const { emptyStateBody, backComponent } = props;
	return (
		<EmptyState>
			<FontAwesomeIcon
				icon={props.iconName ? props.iconName : faFaceHandPeeking}
				size="7x"
			/>
			<Title
				size="lg"
				headingLevel={'h4'}
				data-testid="empty-results-title"
			>
				{props.heading ? props.heading : 'No results found'}
			</Title>
			<EmptyStateBody data-testid="empty-results-body">{emptyStateBody}</EmptyStateBody>
			<EmptyStatePrimary data-testid="empty-results-primary">
				{backComponent}
			</EmptyStatePrimary>
		</EmptyState>
	);
};

export default EmptyResults;
