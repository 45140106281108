import axiosConfig from '../axiosConfig';

export type TTag = {
	id?: number;
	name: string;
};

export class Tag implements TTag {
	id?: number;
	name: string;

	constructor(recent: TTag) {
		this.id = recent.id;
		this.name = recent.name;
	}

	public static Default(): TTag {
		return {
			id: 0,
			name: '',
		};
	}

	public static New(tag: TTag): Promise<TTag> {
		return axiosConfig.instance.post<TTag>(`app/tag`, tag).then((res) => {
			return res.data;
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete<boolean>(`app/tag/${id}`).then((res) => {
			return res.data;
		});
	}

	public static Update(tag: TTag): Promise<TTag> {
		return axiosConfig.instance.patch<TTag>('app/tag', tag).then((res) => {
			return res.data;
		});
	}

	public static GetAll(): Promise<TTag[]> {
		return axiosConfig.instance.get<TTag[]>('app/tags/').then((res) => {
			return res.data ?? [];
		});
	}

	public static GetPermitted(): Promise<TTag[]> {
		return axiosConfig.instance.get<TTag[]>('app/permitted-tags/').then((res) => {
			return res.data ?? [];
		});
	}

	public static GetOne(id: number): Promise<TTag> {
		return axiosConfig.instance.get<TTag>(`app/tag/${id}/`).then((res) => {
			return res.data ?? [];
		});
	}
}
