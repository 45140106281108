import { TUser, User } from '../../api/security/User';
import { TDateRange } from '../../api/types';
import { DateRange } from '../../api/date-period-selector/DateRange';
import { TKeyMeasure } from '../../api/analytics/KeyMeasure';
import { TDimension } from '../../api/analytics/Dimension';
import { TPeriod } from '../../api/analytics/Period';
import { TUnitType } from '../../api/analytics/UnitType';

export interface TApplicationContext {
	user: TUser;
	datePeriods: TDateRange[];
	currentDatePeriods: TDateRange[];
	measures: TKeyMeasure[];
	dimensions: TDimension[];
	unitTypes: TUnitType[];
	periods: TPeriod[];
}

export class ApplicationContext implements TApplicationContext {
	user: TUser;
	datePeriods: DateRange[];
	currentDatePeriods: TDateRange[];
	measures: TKeyMeasure[];
	dimensions: TDimension[];
	unitTypes: TUnitType[];
	periods: TPeriod[];

	constructor() {
		this.user = User.Default() as TUser;
		this.datePeriods = [];
		this.currentDatePeriods = [];
		this.measures = [];
		this.dimensions = [];
		this.unitTypes = [];
		this.periods = [];
	}

	public static getCurrentPeriod = (datePeriods: DateRange[]): DateRange[] => {
		let currentDatePeriods: DateRange[] = [];
		// Get Current Date
		const currentDate = Math.floor(Date.now() / 1000);
		if (datePeriods.length > 0) {
			currentDatePeriods = datePeriods.filter(
				(dp) => dp.begin_date <= currentDate && dp.end_date >= currentDate
			);
		}
		return currentDatePeriods;
	};

	public static Default(): ApplicationContext {
		return new ApplicationContext();
	}
}
