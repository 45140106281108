export enum RecentTypesEnum {
	Dashboard = 'dashboard',
	Widget = 'widget',
	Dataframe = 'dataframe',
	Charts = 'chart',
	Report = 'report',
	Presentation = 'presentation',
	Question = 'question',
	Table = 'table',
}
