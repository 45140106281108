import { Permission } from '../../enums/permission.enum';
import { INavigationLink } from '../../layout/PageNavigationLinks';
import { faBookOpen, faClock, faDatabase, faStar, faTable } from '@fortawesome/pro-light-svg-icons';

export const AnalyzeSidebarContext: INavigationLink[] = [
	{
		name: 'Recents',
		iconDefinition: faClock,
		to: '/analyze/recents',
		id: 'analyze-recents',
		permission: Permission.ViewRecent,
	},
	{
		name: 'Favorites',
		iconDefinition: faStar,
		to: '/analyze/favorites',
		id: 'analyze-favorites',
		permission: Permission.ViewFavorite,
	},
	{
		name: 'Charts',
		iconDefinition: faBookOpen,
		to: '/analyze/charts',
		id: 'analyze-charts',
		permission: Permission.ViewChart,
	},
	{
		name: 'Tables',
		iconDefinition: faTable,
		to: '/analyze/tables',
		id: 'analyze-tables',
		permission: Permission.ViewTable,
	},
	{
		name: 'Dataframes',
		iconDefinition: faDatabase,
		to: '/analyze/dataframes',
		id: 'analyze-dataframes',
		permission: Permission.ViewDataframe,
	},
];
