import axiosConfig from '../axiosConfig';

export type TQuestionCategory = {
	id?: number;
	creator_id?: number;
	updater_id?: number;
	title: string;
	icon: string;
	description: string;
	sequence?: number;
	updated_at?: number;
	created_at?: number;
};

export class QuestionCategory implements TQuestionCategory {
	id?: number;
	creator_id?: number;
	updater_id?: number;
	title: string;
	icon: string;
	description: string;
	sequence?: number;
	updated_at?: number;
	created_at?: number;

	constructor(questionCategory: TQuestionCategory) {
		this.id = questionCategory.id;
		this.creator_id = questionCategory.creator_id;
		this.updater_id = questionCategory.updater_id;
		this.title = questionCategory.title;
		this.icon = questionCategory.icon;
		this.description = questionCategory.description;
		this.sequence = questionCategory.sequence;
		this.updated_at = questionCategory.updated_at;
		this.created_at = questionCategory.created_at;
	}

	public static New(questionCategory: TQuestionCategory): Promise<TQuestionCategory> {
		return axiosConfig.instance.post('app/question-category', questionCategory).then((res) => {
			return new QuestionCategory(res.data as TQuestionCategory);
		});
	}

	public static Update(questionCategory: TQuestionCategory): Promise<TQuestionCategory> {
		return axiosConfig.instance
			.patch<TQuestionCategory>('app/question-category', questionCategory)
			.then((res) => {
				return res.data;
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/question-category/${id}`).then(() => {
			return true;
		});
	}

	public static Get(questionCategoryId: number, chartId: number): Promise<TQuestionCategory> {
		return axiosConfig.instance
			.get<TQuestionCategory>(`app/question-category/${questionCategoryId}`)
			.then((res) => {
				return res.data;
			});
	}

	public static GetAll(expand: (keyof TQuestionCategory)[] = []): Promise<TQuestionCategory[]> {
		return axiosConfig.instance
			.get<TQuestionCategory[]>('app/question-categories', { params: { expand } })
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}

	public static GetPermitted(
		expand: (keyof TQuestionCategory)[] = []
	): Promise<TQuestionCategory[]> {
		return axiosConfig.instance
			.get<TQuestionCategory[]>('app/permitted-question-categories', { params: { expand } })
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}
}
