import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router';
import PageTitleSubheader from '../../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../../layout/Layout';
import { DashboardContext } from '../../../types/dashboard';
import WidgetLibrary from '../../../components/dnd/widgets/WidgetLibrary';
import { Dashboard as DashboardAPI } from '../../../api/dashbboards/Dashboards';
import { useMount } from 'react-use';
import { AlertVariant } from '@patternfly/react-core';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';

export enum DashboardLayoutType {
	Blank,
	GridTemplate,
	FlexibleTemplate,
	CopyExisting,
}
type Props = {
	pageContext: DashboardContext;
	type: DashboardLayoutType;
};

const Dashboard = (props: Props) => {
	const { pageContext } = props;
	const navigate = useNavigate();
	const { dashboardId, folderId } = useParams();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [gridLayout, setGridLayout] = useState<boolean>(true);
	const [gridBorder, setGridBorder] = useState<boolean>(true);
	const { addToast } = useToast();

	useMount(() => {
		const prevDashboardPage = localStorage.getItem('prevDashboardPage');
		if (prevDashboardPage) {
			navigate(`/analyze/${prevDashboardPage}`);
		}
	});

	const updateGridLayout = useCallback(
		(value: boolean) => {
			setGridLayout(value);
		},
		[gridLayout, setGridLayout]
	);

	const updateGridBorder = useCallback(
		(value: boolean) => {
			setGridBorder(value);
		},
		[gridBorder, setGridBorder]
	);

	type WidgetLibraryProps = {
		updateGridLayout?: (value: boolean) => void;
		updateGridBorder?: (value: boolean) => void;
	};

	useEffect(() => {
		const widgetLibraryProps: WidgetLibraryProps = {
			updateGridLayout: updateGridLayout,
			updateGridBorder: updateGridBorder,
		};

		localStorage.setItem('rightSideBarData', JSON.stringify(widgetLibraryProps));

		if (dashboardId) {
			DashboardAPI.Get(parseInt(dashboardId))
				.then((response) => {
					setGridLayout(response.hasLayout ?? false);
					setGridBorder(response.hasBorders ?? false);
					createSubSide(true, response.hasBorders, response.hasLayout);
				})
				.catch(() => {
					addToast('Get dashboard failed.', AlertVariant.danger);
				});
		} else {
			createSubSide();
		}
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const createSubSide = (isEdit?: boolean, hasBorders?: boolean, hasLayout?: boolean) => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Dashboard"
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
					subNavContext={pageContext.subNavContext}
				/>
			),
			sidebarSearch: {
				title: 'Search Dashboards',
				placeholder: 'Search',
				submit: (term: string | undefined) => {
					let url = `/dashboards`;
					if (folderId) {
						url = `${url}/${folderId}`;
					}
					if (term) {
						url = `${url}/search?term=${term}`;
					}
					navigate(url);
				},
			},
			rightSideBar: (
				<WidgetLibrary
					isDashboard
					gridLayout={isEdit ? hasLayout ?? false : undefined}
					gridBorder={isEdit ? hasBorders ?? false : undefined}
					updateGridLayout={updateGridLayout}
					updateGridBorder={updateGridBorder}
				/>
			),
			hideLeftSideBar: true,
		});
	};

	const newOutletContext: OutletContext = {
		setSubSide,
		subNavExpanded,
		setSubNavExpanded,
	};

	return (
		<>
			<Outlet
				context={{
					...newOutletContext,
					gridLayout: gridLayout,
					gridBorder: gridBorder,
					updateGridLayout: updateGridLayout,
					updateGridBorder: updateGridBorder,
				}}
			/>
		</>
	);
};

export default Dashboard;
