import { Select, SelectVariant, SelectOptionObject, SelectOption } from '@patternfly/react-core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { ISelectOption } from '../form/SchnurForm/SchnurForm';

type Props = {
	name: string;
	options: ISelectOption[];
	shouldResetOnSelect?: boolean;
	isDisabled?: boolean;
	onSelect: (selectedOption: ISelectOption) => void;
	initialSelection?: string;
	placeholder?: string;
	isRequired?: boolean;
};
export default function Typeahead(props: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState<string>(props.initialSelection ?? '');

	useEffect(() => {
		if (props.initialSelection) {
			setSelected(props.initialSelection);
		}
	}, [props.initialSelection]);

	const onSelect = (
		event: React.MouseEvent | React.ChangeEvent,
		selection: string | SelectOptionObject
	) => {
		setSelected(selection as string);

		if (props.shouldResetOnSelect) {
			setIsOpen(false);
		}

		const selectedOption = props.options.find((option) => option.value === selection);
		props.onSelect(selectedOption as ISelectOption);
	};

	return (
		<Select
			variant={SelectVariant.typeahead}
			typeAheadAriaLabel={props.name}
			id={props.name}
			onToggle={() => {
				setIsOpen(!isOpen);
			}}
			onSelect={onSelect}
			isOpen={isOpen}
			menuAppendTo="parent"
			selections={selected}
			shouldResetOnSelect={props.shouldResetOnSelect ?? false}
			placeholderText={props.placeholder ?? 'Select an option...'}
		>
			{props.options.map((option: ISelectOption) => (
				<SelectOption
					key={option.key}
					value={option.value}
					selected
					description={option.description}
				/>
			))}
		</Select>
	);
}
