import React, { useState } from 'react';
import { Select, SelectVariant, TreeView, TreeViewDataItem } from '@patternfly/react-core';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { useMount } from 'react-use';

type SearchableTreeViewSelectProps = {
	data: TreeViewDataItem[];
	onToggle?: () => void;
	placeHolderText?: string;
	selectedItems?: TreeViewDataItem[];
	treeItemsExpanded?: boolean;
	onSelect?: (
		event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem,
		parentItem: TreeViewDataItem
	) => void;
	className?: string;
	isTopLvlSearchOnly?: boolean;
};

function SearchableTreeViewSelect(props: SearchableTreeViewSelectProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [selectedItem, setSelectedItem] = useState<TreeViewDataItem | undefined>();

	useMount(() => {
		if (props.selectedItems && props.selectedItems.length > 0) {
			setSelectedItem(props.selectedItems[0]);
		}
	});

	const onToggle = () => {
		setIsOpen(!isOpen);
	};

	const onSelectTreeItem = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem,
		parentItem: TreeViewDataItem
	) => {
		setSelectedItem(item);

		if (props.onSelect) {
			props.onSelect(_event, item, parentItem);
		}
		onToggle();
	};

	const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	let filteredData = props.data.flatMap((item) => {
		let newFilteredData: TreeViewDataItem[] = [];

		if (!searchQuery) {
			return newFilteredData;
		}

		if (props.isTopLvlSearchOnly) {
			const topLevelItems =
				item.name && item.name.toString().toLowerCase().includes(searchQuery.toLowerCase())
					? [item]
					: [];

			return topLevelItems;
		}
		// Check and filter children
		const filteredChildren = item.children
			? item.children.filter(
					(child) =>
						child.name &&
						child.name.toString().toLowerCase().includes(searchQuery.toLowerCase())
			  )
			: [];

		// Include the parent item even if a match is found in its children
		if (filteredChildren.length > 0) {
			item.children = filteredChildren;
			newFilteredData = [...newFilteredData, ...[item]];
		}

		return newFilteredData;
	});

	if (filteredData.length == 0) {
		filteredData = [BuildTreeViewItem({ id: -1, name: 'No Options were found' }, [])];
	}

	function setChildId(id?: string) {
		const childId = typeof id === 'number' ? id : id?.split('__');

		return childId && childId.length > 1
			? Number(childId[childId.length - 1])
			: childId
			? Number(childId)
			: 0;
	}

	const tree = (
		<TreeView
			data={searchQuery == '' ? props.data : filteredData}
			allExpanded={props.treeItemsExpanded ?? false}
			onSelect={onSelectTreeItem}
		/>
	);

	return (
		<Select
			className={props.className}
			variant={SelectVariant.single}
			isOpen={isOpen}
			menuAppendTo="parent"
			onToggle={onToggle}
			placeholderText={selectedItem?.name ?? props.placeHolderText}
			onSelect={onToggle}
			customContent={
				<>
					<input
						type="text"
						className="pf-c-form-control"
						placeholder="Search..."
						value={searchQuery}
						onChange={onSearchChange}
					/>
					{tree}
				</>
			}
		/>
	);
}

export default SearchableTreeViewSelect;
