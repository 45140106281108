export enum FilterOperatorEnum {
	BEGINS_WITH = 'BEGINS_WITH',
	BETWEEN = 'BETWEEN',
	CONTAINS = 'CONTAINS',
	END_WITH = 'END_WITH',
	EQUALS = 'EQUALS',
	GREATER_THAN = 'GREATER_THAN',
	GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
	LESS_THAN = 'LESS_THAN',
	LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
	NOT = 'NOT',
}
