import { KeyMeasureStatusEnum, TKeyMeasure } from '../api/analytics/KeyMeasure';
import { OptionsBuilderItemTypes } from '../types/dataframes/options-builder-item-types';

export const defaultCalculatedFact: TKeyMeasure = {
	id: -1,
	code: 'Calculated_Measure',
	creator: '1',
	created_at: 1,
	display_name: 'Calculated Measure',
	status: KeyMeasureStatusEnum.ACTIVE,
	description:
		'system measure used for calculating the result of 2 pre-existing key measure facts',
	groups: [],
	name: 'Calculated Measure',
	updated_at: 1,
	updater: undefined,
	alertDefinitions: [],
	control_variable_max: null,
	dimensions: [],
	type: OptionsBuilderItemTypes.CalculatedFact,
	keyMeasureFacts: [],
	last_loaded: null,
	partitioned: false,
	period: 0,
	recalc_percentage: false,
	unitType: 0,
};
