import { Grid, Text } from '@patternfly/react-core';
import React from 'react';
import ActionCard from './ActionCard';
import { SubNavAction, SubNavActions } from '../../helpers/sub-nav-contexts/sub-nav-context';
import { useIsGranted } from '../user/ApplicationProvider';

type Props = {
	actionsSummary: SubNavActions;
};

const SubNavCommonActions = (props: Props) => {
	const {
		actionsSummary: { title, actionsList },
	} = props;
	const isGranted = useIsGranted();

	const getActionList = (): SubNavAction[] => {
		const createList = actionsList?.filter((action) => action.type === 'create');
		const viewList = actionsList?.filter((action) => action.type === 'view');
		const finalList: SubNavAction[] = [];

		createList?.forEach((action) => {
			if (action.permission) {
				if (isGranted(action.permission)) {
					finalList.push(action);
				} else {
					const viewAction = viewList?.find(
						(viewAction) => viewAction.order === action.order
					);

					if (viewAction) {
						if (viewAction.permission && isGranted(viewAction.permission)) {
							finalList.push(viewAction);
						} else {
							// no permission related to action so then display it
							finalList.push(viewAction);
						}
					}
				}
			} else {
				// no permission related to action so then display it
				finalList.push(action);
			}
		});

		return finalList;
	};

	return (
		<Grid>
			<Grid span={12}>
				<Text className="font-size-md">{title}</Text>
			</Grid>
			<Grid span={12}>
				<Grid>
					{getActionList().map((actionItem, index) => (
						<ActionCard
							key={index}
							actionItem={actionItem}
						/>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SubNavCommonActions;
