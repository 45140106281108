import React, { ReactElement } from 'react';
import { AlertVariant, Button } from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';

type CopyButtonProps = {
	textToCopy: string;
	buttonText?: string;
	hasIcon?: boolean;
};

export default function CopyButton(props: CopyButtonProps): ReactElement | null {
	const { addToast } = useToast();
	const { buttonText, hasIcon, textToCopy } = props;

	return (
		<Button
			key="copy_button"
			variant="primary"
			className="btn-copy"
			onClick={() => {
				navigator.clipboard
					.writeText(textToCopy)
					.then(() => {
						addToast('Copied to clipboard', AlertVariant.info);
					})
					.catch(() => {
						addToast('Copy to clipboard failed', AlertVariant.danger);
					});
			}}
		>
			{buttonText}

			{hasIcon && <FontAwesomeIcon icon={faCopy} />}
		</Button>
	);
}
