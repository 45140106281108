import React, { Dispatch, SetStateAction, useState } from 'react';
import { AlertVariant, FormGroup, TreeViewDataItem } from '@patternfly/react-core';
import { useMount } from 'react-use';
import { TDataframe } from '../../../../api/types';
import { Dataframe } from '../../../../api/dataframes/Dataframes';
import Loader from '../../../util/Loader';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import SearchableTreeViewSelect from '../../../form/Select/SearchableTreeViewSelect';
import { BuildTreeViewItem } from '../../../../helpers/tree-view.helper';
import { MetaDataFrame } from '../../../../enums/meta-dataframe-name.enum';

export type DataframeViewProps = {
	selectedFrame?: TDataframe;
	onSelectFrame: Dispatch<SetStateAction<TDataframe | undefined>>;
};

const DataFrameView = (props: DataframeViewProps) => {
	const [dataframes, setDataframes] = useState<TDataframe[]>([]);
	const [_isDataframeOpen, setIsDataframeOpen] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const { addToast } = useToast();

	useMount(() => {
		setIsLoading(true);
		Dataframe.GetAll()
			.then((res) => {
				setDataframes(res);
			})
			.catch((e: Error) =>
				addToast(`Error fetching all dataframes, error: ${e.message}`, AlertVariant.danger)
			)
			.finally(() => {
				setIsLoading(false);
			});
	});

	const onSelectFrame = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	) => {
		if (item.id) {
			const frame = dataframes.find((_) => _.id === +(item.id ?? '0'));
			props.onSelectFrame(frame);
		} else {
			props.onSelectFrame(undefined);
		}
		setIsDataframeOpen(false);
		onFocusDataframes();
	};

	const onFocusDataframes = () => {
		const element = document.getElementById('dataframe-selector');
		element?.focus();
	};

	const emptyTree = [BuildTreeViewItem({ id: -1, name: 'No Dataframes were found' }, [])];

	return isLoading ? (
		<Loader />
	) : (
		<div data-testid="dataframe-view">
			<FormGroup
				label="Dataframe"
				isRequired
				fieldId="dataframe"
			>
				<SearchableTreeViewSelect
					className="selectDataframe"
					data-testid="dataframe-tree"
					data={
						dataframes.length > 0
							? dataframes
									.filter(
										(frame) =>
											!frame.name.includes(MetaDataFrame.Name) &&
											!frame.parent
									)
									.map((km) => BuildTreeViewItem(km, []))
							: emptyTree
					}
					placeHolderText={props.selectedFrame?.name ?? 'Select a Dataframe'}
					onSelect={onSelectFrame}
					treeItemsExpanded={true}
					isTopLvlSearchOnly={true}
				/>
			</FormGroup>
		</div>
	);
};

export default DataFrameView;
