import useResizeObserver from '@react-hook/resize-observer';
import React, { RefObject } from 'react';

export const containerDimensions = (target: RefObject<HTMLDivElement>) => {
	const [dimensions, setDimensions] = React.useState<DOMRectReadOnly | undefined>(undefined);

	React.useLayoutEffect(() => {
		if (target.current) {
			setDimensions(target.current.getBoundingClientRect() as DOMRectReadOnly);
		}
	}, [target]);

	useResizeObserver(target, (entry) => setDimensions(entry.contentRect));

	return dimensions;
};
