import React, { useEffect } from 'react';
import { OutletContext } from '../../layout/Layout';
import { Outlet, useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';

const ChartContainer = () => {
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Dashboards"
					pageDescription=""
					expanded
					setExpanded={setSubNavExpanded}
				/>
			),
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	return (
		<>
			<Outlet context={{ setSubSide }} />
		</>
	);
};

export default ChartContainer;
