import { RecentTypesEnum } from '../../enums/recent-types.enum';
import axiosConfig from '../axiosConfig';
import { TUser } from '../security/User';

export type TRecent = {
	entity_id: number;
	entity_type: string;
	accessed_at?: number;
	chart_type?: string;
	name?: string;
	owner?: number | TUser;
};

export class Recent implements TRecent {
	entity_id: number;
	entity_type: string;
	accessed_at?: number;
	chart_type?: string;
	name?: string;
	owner?: number | TUser;

	constructor(recent: TRecent) {
		this.entity_id = recent.entity_id;
		this.entity_type = recent.entity_type;
		this.accessed_at = recent.accessed_at;
		this.chart_type = recent.chart_type;
		this.name = recent.name;
		this.owner = recent.owner;
	}

	public static Default(): TRecent {
		return {
			entity_id: 0,
			entity_type: '',
			accessed_at: 0,
			chart_type: '',
			name: '',
			owner: 0,
		};
	}

	public static New(recent: TRecent): Promise<TRecent> {
		return axiosConfig.instance
			.post<TRecent>(`app/recent/${recent.entity_type}/${recent.entity_id}`)
			.then((res) => {
				return res.data;
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete<boolean>(`app/recent/${id}`).then((res) => {
			return res.data;
		});
	}

	public static Update(recent: TRecent): Promise<TRecent> {
		return axiosConfig.instance.patch<TRecent>('app/recent', recent).then((res) => {
			return res.data;
		});
	}

	public static GetAll(entity_type: RecentTypesEnum, isTable?: boolean): Promise<TRecent[]> {
		return axiosConfig.instance
			.get<TRecent[]>(
				'app/recents/' +
					entity_type +
					'?limit=18&expand[]=owner' +
					(isTable && entity_type == RecentTypesEnum.Report ? '&isTable=true' : '')
			)
			.then((res) => {
				return res.data ?? [];
			});
	}

	public static GetOne(id: number, entity_type: RecentTypesEnum): Promise<TRecent> {
		return axiosConfig.instance.get<TRecent>(`app/recent/${id}/` + entity_type).then((res) => {
			return res.data ?? [];
		});
	}
}
