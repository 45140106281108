import axiosConfig from '../axiosConfig';
import { TFolder } from '../foundational-elements/Folder';
import { TSharedEntity } from '../shared-entity/SharedEntity';
import { GenericResponse } from '../types';

export type TDashboard = {
	id?: number;
	description: string;
	name: string;
	sequence: number;
	owner: number;
	folder?: TFolder | number;
	sharedDashboards?: number[] | TSharedEntity[];
	hasBorders?: boolean;
	hasLayout?: boolean;
};

export class Dashboard implements TDashboard {
	id?: number;
	description: string;
	name: string;
	sequence: number;
	owner: number;
	folder?: TFolder | number;
	sharedDashboards?: number[] | TSharedEntity[];
	hasBorders?: boolean;
	hasLayout?: boolean;

	constructor(dashboard: TDashboard) {
		this.id = dashboard.id;
		this.description = dashboard.description;
		this.name = dashboard.name;
		this.sequence = dashboard.sequence;
		this.owner = dashboard.owner;
		this.folder = dashboard.folder;
		this.sharedDashboards = dashboard.sharedDashboards;
		this.hasBorders = dashboard.hasBorders;
		this.hasLayout = dashboard.hasLayout;
	}

	public static Default(): TDashboard {
		return {
			description: '',
			name: '',
			sequence: 0,
			owner: 0,
		};
	}

	public static New(dashboard: TDashboard): Promise<TDashboard> {
		return axiosConfig.instance.post('app/dashboard', dashboard).then((res) => {
			return new Dashboard(res.data as TDashboard);
		});
	}

	public static Edit(dashboard: TDashboard): Promise<TDashboard> {
		return axiosConfig.instance.patch(`app/dashboard`, dashboard).then((res) => {
			return new Dashboard(res.data as TDashboard);
		});
	}

	public static Get(dashboardId: number, expand: (keyof TDashboard)[] = []): Promise<TDashboard> {
		return axiosConfig.instance
			.get(`app/dashboard/${dashboardId}`, { params: { expand } })
			.then((res) => {
				return new Dashboard(res.data as TDashboard);
			});
	}

	public static GetAll(expand: (keyof TDashboard)[] = []): Promise<TDashboard[]> {
		return axiosConfig.instance
			.get<TDashboard[]>('app/dashboards', { params: { expand } })
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}

	public static Delete(dashboardId: number): Promise<GenericResponse<any>> {
		return axiosConfig.instance
			.delete<GenericResponse<any>>(`app/dashboard/${dashboardId}`)
			.then((res) => {
				return res.data;
			});
	}
}
