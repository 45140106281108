import React, { ReactElement } from 'react';
import { AlertVariant, Button } from '@patternfly/react-core';
import { AdminActions } from '../../api/admin/AdminActions';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { useIsGranted } from '../user/ApplicationProvider';
import { Permission } from '../../enums/permission.enum';

export default function SchemaUpdateButton(): ReactElement | null {
	const { addToast } = useToast();
	const isGranted = useIsGranted();

	if (!isGranted(Permission.CanTriggerSchemaUpdate)) {
		return null;
	}

	return (
		<Button
			data-testid={'zone-provision'}
			variant={'primary'}
			onClick={() => {
				AdminActions.SchemaUpdate()
					.then(() => {
						addToast('Schema update queued successfully.', AlertVariant.success);
					})
					.catch(() => {
						addToast(
							'An error occurred while trying to queue schema update. Please try again later.',
							AlertVariant.danger
						);
					});
			}}
		>
			Update Schema
		</Button>
	);
}
