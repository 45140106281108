import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/sharp-solid-svg-icons';
import { Button } from '@patternfly/react-core';
import React from 'react';

type Props = {
	name: string | undefined;
	id: number;
	entityId: number;
	handleFilterModalOpen: (dashboardFilterId: number, entityId: number) => void;
	handleRemoveDashboardFilter: (id: number) => void;
	isView?: boolean;
	editable?: boolean;
	isFiltered?: boolean;
};

const DashboardFilter = (props: Props) => {
	const {
		name,
		id,
		entityId,
		handleFilterModalOpen,
		handleRemoveDashboardFilter,
		editable,
		isView,
		isFiltered,
	} = props;
	return (
		<div className={`custom-button-chip`}>
			<div
				className="label"
				onClick={() => {
					handleFilterModalOpen(id, entityId);
				}}
			>
				{name}
			</div>
			{isFiltered && <FontAwesomeIcon icon={faFilter} />}
			{editable && !isView && (
				<div className="remove-btn">
					<Button
						variant="plain"
						onClick={() => {
							editable && handleRemoveDashboardFilter(id);
						}}
					>
						<FontAwesomeIcon icon={faTimes} />
					</Button>
				</div>
			)}
		</div>
	);
};

export default DashboardFilter;
