import React, { useEffect, useState } from 'react';
import { Button, Modal } from '@patternfly/react-core';
import DefaultChartOptionsView, { DefaultChartOptionsViewProps } from './DefaultChartOptionsView';
import KeyMeasureChartOptionsView, {
	KeyMeasureChartOptionsViewProps,
} from './KeyMeasureChartOptionsView';
import DataFrameChartOptionsView, {
	DataframeChartOptionsViewProps,
} from './DataFrameChartOptionsView';
import CopyChartOptionsView, { CopyChartOptionsViewProps } from './CopyChartOptionsView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import './ZiChartOptionsModal.scss';
import { TKeyMeasure } from '../../../api/analytics/KeyMeasure';
import { TDimension } from '../../../api/analytics/Dimension';
import { TDataframe } from '../../../api/types';
import { TKeyMeasureFact } from '../../../api/analytics/KeyMeasureFact';
import { TDimensionAttribute } from '../../../api/analytics/DimensionAttribute';
import { TFolder } from '../../../api/foundational-elements/Folder';
import { TChart } from '../../../api/charts/Chart';
import { useNavigate } from 'react-router-dom';

export type ZiChartOptionsModalProps = {
	isOpen: boolean;
	onClose: () => void;
	selectedValues?: ZiChartOptionsModalSelectedValues;
	dashboardId?: number;
	presentationId?: number;
};

export type ZiChartOptionsModalSelectedValues = {
	selectedKeyMeasure?: TKeyMeasure;
	selectedSeries?: TDimension;
	selectedFrame?: TDataframe;
};

export type ChartCreationOptions = 'default' | 'measure' | 'dataframe' | 'copy';

const ZiChartOptionsModal = (props: ZiChartOptionsModalProps) => {
	const { dashboardId, presentationId } = props;
	const [currentView, setCurrentView] = useState<ChartCreationOptions>('default');

	const [selectedKeyMeasure, setSelectedKeyMeasure] = useState<TKeyMeasure>();
	const [selectedKeyMeasureFact, setSelectedKeyMeasureFact] = useState<TKeyMeasureFact>();
	const [selectedSeries, setSelectedSeries] = useState<TDimension>();
	const [selectedDataframe, setSelectedDataframe] = useState<TDataframe>();
	const [selectedSeriesAttribute, setSelectedSeriesAttribute] = useState<
		TDimensionAttribute | undefined
	>();
	const [selectedFolder, setSelectedFolder] = useState<TFolder | undefined>();
	const [selectedChart, setSelectedChart] = useState<TChart | undefined>();

	const [createDisabled, setCreateDisabled] = useState(true);

	const navigator = useNavigate();

	const defaultViewProps: DefaultChartOptionsViewProps = {
		onViewChange: setCurrentView,
		selectedFolder: selectedFolder,
		onSelectFolder: setSelectedFolder,
	};

	const keyMeasureProps: KeyMeasureChartOptionsViewProps = {
		selectedMeasure: selectedKeyMeasure,
		selectedFact: selectedKeyMeasureFact,
		selectedSeries: selectedSeries,
		selectedSeriesAttribute: selectedSeriesAttribute,
		onSelectMeasure: setSelectedKeyMeasure,
		onSelectSeries: setSelectedSeries,
		onSelectFact: setSelectedKeyMeasureFact,
		onSelectSeriesAttribute: setSelectedSeriesAttribute,
		setCreateDisabled: setCreateDisabled,
	};

	const dataframeProps: DataframeChartOptionsViewProps = {
		selectedFrame: selectedDataframe,
		onSelectFrame: setSelectedDataframe,
	};

	const copyChartProps: CopyChartOptionsViewProps = {
		onSelectChart: setSelectedChart,
		selectedChart: selectedChart,
	};

	const contentMap: Map<ChartCreationOptions, JSX.Element> = new Map<
		ChartCreationOptions,
		JSX.Element
	>([
		['default', <DefaultChartOptionsView {...defaultViewProps} />],
		['measure', <KeyMeasureChartOptionsView {...keyMeasureProps} />],
		['dataframe', <DataFrameChartOptionsView {...dataframeProps} />],
		['copy', <CopyChartOptionsView {...copyChartProps} />],
	]);

	useEffect(() => {
		if (currentView === 'measure') {
			setCreateDisabled(true);
			return;
		}

		if (currentView === 'dataframe') {
			setCreateDisabled(selectedDataframe === void 0);
			return;
		}

		if (currentView === 'copy') {
			setCreateDisabled(selectedChart === void 0);
			return;
		}

		setCreateDisabled(true);
	}, [selectedKeyMeasureFact, selectedDataframe, selectedChart]);

	const beforeClose = () => {
		setCurrentView('default');
		props.onClose();
	};

	const beforeCreate = () => {
		setCurrentView('default');
		let urlBase = `/analyze`;
		if (dashboardId) {
			urlBase = `${urlBase}/dashboards/${dashboardId}/chart`;
		} else if (presentationId) {
			urlBase = `/present/${presentationId}/chart`;
		} else {
			urlBase = `${urlBase}/charts/build`;
		}

		if (selectedKeyMeasureFact) {
			let url = `${urlBase}/key-measure-fact/${selectedKeyMeasureFact.id}`;
			if (selectedSeriesAttribute) {
				url = `${url}/0/${selectedSeriesAttribute.id}`;
			} else if (selectedSeries) {
				url = `${url}/${selectedSeries.id}/0`;
			}
			navigator({
				pathname: url,
			});
		}

		if (selectedDataframe) {
			navigator({
				pathname: `${urlBase}/dataframe/${selectedDataframe.id}`,
			});
		}
	};

	const setDefaultView = (): void => {
		setCurrentView('default');
		setSelectedKeyMeasure(undefined);
		setSelectedKeyMeasureFact(undefined);
		setSelectedSeries(undefined);
		setSelectedSeriesAttribute(undefined);
		setSelectedDataframe(undefined);
	};

	const backIcon = (
		<div className="back-btn">
			<Button
				variant="link"
				onClick={setDefaultView}
			>
				<FontAwesomeIcon icon={faArrowLeft} />
			</Button>
		</div>
	);

	return (
		<>
			<Modal
				data-testid="add-chart-modal"
				isOpen={props.isOpen}
				onClose={beforeClose}
				variant="small"
				title="Add Chart"
				actions={
					currentView === 'default'
						? []
						: [
								<Button
									data-testid="create-button"
									key="create"
									variant="primary"
									onClick={beforeCreate}
									isDisabled={createDisabled}
								>
									Create
								</Button>,
								<Button
									data-testid="cancel-button"
									key="cancel"
									variant="link"
									onClick={beforeClose}
								>
									Cancel
								</Button>,
						  ]
				}
			>
				{currentView !== 'default' ? backIcon : null}
				{contentMap.get(currentView)}
			</Modal>
		</>
	);
};

export default ZiChartOptionsModal;
