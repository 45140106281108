import React, { ReactNode } from 'react';
import { DashboardWidget } from '../../../api/dashbboards/DashboardWidgets';
import './DraggableWidget.scss';

type Props = {
	defaultWidget: DashboardWidget;
	children: ReactNode;
};

const DraggableWidget = (props: Props) => {
	const { defaultWidget, children } = props;
	return (
		<div
			className="droppable-element"
			draggable={true}
			unselectable="on"
			// this is a hack for firefox
			// Firefox requires some kind of initialization
			// which we can do by adding this attribute
			// @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
			onDragStart={(e) => {
				e.dataTransfer.setData('text/plain', '');
				e.dataTransfer.setData('widgetData', JSON.stringify(defaultWidget));
				global.localStorage.setItem(
					'zeroedIn-dragging-item',
					JSON.stringify(defaultWidget)
				);
			}}
		>
			{children}
		</div>
	);
};

export default DraggableWidget;
