import { Modal, Text } from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

function ChangePasswordSuccessModal(props: Props) {
	return (
		<>
			<Modal
				title=" "
				variant="small"
				marginWidth={0}
				isOpen={props.isOpen}
				onClose={props.onClose}
				style={{ marginTop: '15px' }}
			>
				<div className="text-center">
					<FontAwesomeIcon
						icon={faCheckCircle}
						color="green"
						fontSize={'80px'}
					/>
					<br />
					<br />
					<Text component="h2">Password Changed!</Text>
					<br />
					<Text component="p">Your Password has been reset and changed succesfully</Text>
				</div>
			</Modal>
		</>
	);
}

export default ChangePasswordSuccessModal;
