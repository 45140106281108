import axiosConfig from '../axiosConfig';
import { TDateRange } from '../types/TDateRange';
import { TNewDateRange } from '../types/TNewDateRange';

export class DateRange implements TDateRange {
	id: number;
	begin_date: number;
	end_date: number;
	period: number;
	sequence: number;
	isSelected?: boolean;
	title?: string;

	constructor(dateRange: TDateRange) {
		this.id = dateRange.id;
		this.begin_date = dateRange.begin_date;
		this.end_date = dateRange.end_date;
		this.period = dateRange.period;
		this.sequence = dateRange.sequence;
		this.isSelected = dateRange.isSelected;
		this.title = dateRange.title;
	}

	public static Default(): TNewDateRange {
		return {
			begin_date: 0,
			end_date: 0,
			period: 0,
			sequence: 0,
		};
	}

	public static GetAll(periodId?: number): Promise<TDateRange[]> {
		let url = 'app/date-ranges';
		if (periodId) {
			url = 'app/date-ranges?filter[period]=' + periodId.toString();
		}
		return axiosConfig.instance.get(url).then((res) => {
			return ((res.data || []) as TDateRange[]).map((dateRange: TDateRange) => {
				return new DateRange(dateRange);
			});
		});
	}

	public static Get(id: number): Promise<TDateRange> {
		return axiosConfig.instance.get(`app/date-range/${id}`).then((res) => {
			return new DateRange(res.data as TDateRange);
		});
	}

	public static New(unitType: TNewDateRange): Promise<TDateRange> {
		return axiosConfig.instance.post('app/date-range', unitType).then((res) => {
			return new DateRange(res.data as TDateRange);
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/date-range/${id}`).then(() => {
			return true;
		});
	}
}
