import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { AlertVariant, Modal, ModalVariant } from '@patternfly/react-core';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import SchnurForm, {
	EventType,
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { Period, TPeriod } from '../../api/analytics/Period';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';

export default function Periods(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [data, setData] = useState<TPeriod[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [activePeriod, setActivePeriod] = useState<TPeriod>(Period.Default() as TPeriod);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

	const selectedColumns: Column<TPeriod>[] = [
		{
			title: 'Alias',
			columnName: 'code',
			sortable: true,
		},
		{
			title: 'Period Title',
			columnName: 'name',
			sortable: true,
		},
		{
			title: 'Interval',
			columnName: 'interval_units',
			sortable: true,
		},
		{
			title: 'Interval Quantity',
			columnName: 'interval_quantity',
			sortable: false,
		},
	];

	const actions: Action<TPeriod>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActivePeriod(item);
				setIsModalOpen(true);
			},
			permission: Permission.EditPeriod,
		},
		{
			name: 'Delete',
			callback: (item) => {
				setTableLoading(true);
				Period.Delete(item.id)
					.then(() => {
						setTableLoading(false);
						setData((prev) => prev.filter((dimension) => dimension.id !== item.id));
						addToast('Period deleted successfully.', AlertVariant.success);
					})
					.catch(() => {
						setTableLoading(false);
						addToast(
							'An error occurred while trying to delete the period. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.DeletePeriod,
		},
	];

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Periods"
					pageDescription="Manage your periods."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});

		Period.GetAll()
			.then((periods) => {
				setData(periods);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load Dimensions. Please try again later.',
					AlertVariant.danger
				);
			});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = (value: Period) => {
		const index = data.findIndex((period) => period.id === value.id);

		if (index >= 0) {
			setData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const addButton = (
		<PermissionButton
			data-testid={'period-add-button'}
			variant={'primary'}
			permission={Permission.CreatePeriod}
			onClick={() => {
				setActivePeriod(Period.Default() as TPeriod);
				setIsModalOpen(true);
			}}
		>
			New Period
		</PermissionButton>
	);

	const formProperties: Field<TPeriod>[] = [
		{
			title: 'Alias',
			columnName: 'code',
			uiSchema: {
				type: UIType.TEXT,
				placeholder: 'period_alias',
				helpText: 'The alias of the period to use in SQL statements.',
			},
			required: true,
			validate: (eventType, value) => {
				if (eventType !== EventType.BLUR && eventType !== EventType.CHANGE) {
					return true;
				}

				if (value.includes(' ')) {
					return 'An alias must not contain a space!';
				}

				return true;
			},
		},
		{
			title: 'Title',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'The name of the period.',
			},
			required: true,
		},
		{
			title: 'Unit',
			columnName: 'interval_units',
			uiSchema: {
				type: UIType.SELECT,
				options: [
					{ key: 1, value: 'Day' },
					{ key: 7, value: 'Week' },
					{ key: 30, value: 'Month' },
					{ key: 365, value: 'Year' },
					{ key: 91.25, value: 'Quarter' },
				],
				onSelect: (selected: ISelectOption) => {
					return selected.key;
				},
			},
			required: true,
		},
		{
			title: 'Quantity',
			columnName: 'interval_quantity',
			uiSchema: {
				type: UIType.NUMBER,
				helpText: 'The number of units in the period.',
			},
			required: true,
		},
		{
			title: 'Number of ranges before/after to show',
			columnName: 'num_visible_outside_range',
			uiSchema: {
				type: UIType.NUMBER,
			},
			required: true,
		},
		{
			title: 'Visible',
			columnName: 'visible',
			uiSchema: {
				type: UIType.BOOLEAN,
			},
		},
	];

	const dimensionTable = (
		<ZiTable<TPeriod>
			ariaLabel={'Periods table'}
			columns={selectedColumns}
			data={data}
			caption="Periods"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Period Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TPeriod>
					title={'Period'}
					fields={formProperties}
					initialSubject={activePeriod}
					isLoading={isFormLoading}
					onSubmit={(period) => {
						setIsFormLoading(true);

						if (period.id) {
							Period.Update(period)
								.then(() => {
									handleSuccess(period);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save period. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							Period.New(period)
								.then(() => {
									handleSuccess(period);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to create period. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={dimensionTable}
					layoutActions={[addButton]}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
