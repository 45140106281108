import React, { useState } from 'react';
import ZiTable, { Action, Column } from '../../../components/table/ZiTable';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FilterTableLayout from '../../../layout/FilterTableLayout';
import FilterIcon from '@fortawesome/fontawesome-pro/svgs/regular/filter.svg';
import { faFolderBlank, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { AlertVariant, Bullseye, Spinner, Title } from '@patternfly/react-core';
import { Dataframe } from '../../../api/dataframes/Dataframes';
import { TDataframe } from '../../../api/types/dataframe';
import { timestampToMMDDYYYY } from '../../../utilities';
import { TUser } from '../../../api/security/User';
import { Folder } from '../../../api/folders/Folder';
import { FolderTypesEnum } from '../../../enums/folder-types-enum';
import AddFoldersModal, {
	AddFoldersModalProps,
} from '../../../components/modals/folders/AddFoldersModal';
import { Card } from '../../../api/cards/Cards';
import { MetaDataFrame } from '../../../enums/meta-dataframe-name.enum';
import { Permission } from '../../../enums/permission.enum';
import { useMount } from 'react-use';
import { parseFolderSegments } from '../../../helpers/folder-url-helper';
import { GenericResponse } from '../../../api/types';

function ListDataframes() {
	const navigate = useNavigate();
	const { parentFolderId } = useParams();
	const { addToast } = useToast();
	const [loading, setIsLoading] = useState(false);
	const [folderId, setFolderId] = useState<number>(0);
	const [folderName, setFolderName] = useState<string>('');
	const [isFolderModalOpen, setIsFolderModalOpen] = useState<boolean>(false);
	const [dataframes, setDataframes] = useState<TDataframe[]>([]);
	const location = useLocation();
	const [dataframeTitle, setDataframeTitle] = useState<string>('Dataframes');

	useMount(() => {
		const prevDataframePage = localStorage.getItem('prevDataframePage');
		if (prevDataframePage) {
			navigate(prevDataframePage);
		}
		setTableTitle();
	});

	useEffect(() => {
		setTableTitle();
	}, [location]);

	const setTableTitle = () => {
		setTimeout(() => {
			const pathname = window.location.pathname;

			if (pathname.includes('/folder/')) {
				const segments = parseFolderSegments(pathname);
				const folderName = segments[segments.length - 1].value.toString();
				setDataframeTitle(decodeURIComponent(folderName));
			} else {
				setDataframeTitle('Dataframes');
			}
		}, 500);
	};

	const handleFolderModalToggle = () => {
		setIsFolderModalOpen(!isFolderModalOpen);
	};

	const onSelect = () => {
		handleFolderModalToggle();
	};

	const addFolderModalProps: AddFoldersModalProps = {
		isOpen: isFolderModalOpen,
		folderHeadingTitle: '',
		folderId: folderId,
		folderName: folderName,
		folderType: FolderTypesEnum.Analyze,
		successNotification: 'Updated Folder Successfully',
		onClose: handleFolderModalToggle,
		onSetSubSide: () => ({}),
	};

	const selectedColumns: Column<TDataframe>[] = [
		{
			title: 'Dataframe Name',
			columnName: 'name',
			customAccessor: (item) =>
				item.type === FolderTypesEnum.Folder ? (
					<span>
						<FontAwesomeIcon
							className="svg-folder-space"
							icon={faFolderBlank}
						/>
						{item.name}
					</span>
				) : (
					item.name
				),
			sortable: true,
		},
		{
			title: 'Type',
			columnName: 'managed',
			customAccessor: (item) =>
				item.type == FolderTypesEnum.Folder
					? 'Folder'
					: (item.managed !== null || item.managed !== undefined) && item.managed
					? 'System'
					: 'User',
			sortable: true,
		},
		{
			title: 'Owner',
			columnName: 'creator',
			customAccessor: (item) => (item.creator as TUser).name,
			sortable: true,
		},
		{
			title: 'Last Updated',
			columnName: 'updated_at',
			customAccessor: (item) =>
				item.updated_at ? timestampToMMDDYYYY(Number(item.updated_at)) : '',
			sortable: true,
		},
	];

	const actions: Action<TDataframe>[] = [
		{
			name: (
				<>
					<FontAwesomeIcon icon={faPenToSquare} /> Edit
				</>
			),
			callback: (item) => {
				if (item.type === FolderTypesEnum.Folder) {
					setFolderId(item.id);
					setFolderName(item.name);
					onSelect();
				} else {
					navigate(`/analyze/${FolderTypesEnum.DataFrame}/view/${item.id}`);
				}
			},
			permission: Permission.EditDataframe,
		},
		{
			name: (
				<>
					<FontAwesomeIcon icon={faTrashAlt} /> Delete
				</>
			),
			callback: (item) => {
				setIsLoading(true);

				if (item.type === FolderTypesEnum.Folder) {
					Folder.DeleteFolder(item.id)
						.then(() => {
							setIsLoading(false);
							setDataframes((prev) => prev.filter((folder) => folder.id !== item.id));
							addToast('Folder deleted successfully.', AlertVariant.success);
						})
						.catch(() => {
							setIsLoading(false);
							addToast(
								'An error occurred while trying to delete Folder. Please try again later.',
								AlertVariant.danger
							);
						});
				} else {
					Dataframe.DeleteDataframe(item.id)
						.then((response: GenericResponse<any>) => {
							setIsLoading(false);

							if (response && response.success) {
								setDataframes((prev) =>
									prev.filter((dataframe) => dataframe.id !== item.id)
								);
							}
						})
						.catch(() => {
							setIsLoading(false);
							addToast(
								'An error occurred while trying to delete Dataframe. Please try again later.',
								AlertVariant.danger
							);
						});
				}
			},
			permission: Permission.DeleteDataframe,
		},
	];

	useEffect(() => {
		setIsLoading(true);
		const segments = window.location.pathname.split('/');
		const folderId = segments[segments.length - 1];
		const actualParentFolderId = parentFolderId ? Number(parentFolderId) : Number(folderId);

		Dataframe.GetAll()
			.then((frames) => {
				Card.GetAll(FolderTypesEnum.DataFrame, actualParentFolderId)
					.then((cards) => {
						let cardDataframes: TDataframe[] = [];
						let dataframes: TDataframe[] = [];

						if (cards) {
							cardDataframes = cards.map((c) => {
								return getDataframes(c);
							});
						}

						dataframes = frames.filter(
							(frame) => !frame.parent && !frame.name.includes(MetaDataFrame.Name)
						);

						const uniqueIds = new Set(dataframes.map((frame) => frame.id));
						// Filter cardDataframes to keep only those with existing IDs in dataframes OR if the card is a folder item
						cardDataframes = cardDataframes.filter(
							(card) => uniqueIds.has(card.id) || card.folder !== 0
						);

						cardDataframes = cardDataframes.sort((a, b) =>
							a.type && b.type
								? a.type.toLowerCase() < b.type.toLowerCase()
									? -1
									: b.type.toLowerCase() > a.type.toLowerCase()
									? 1
									: 0
								: -1
						);

						setDataframes(cardDataframes);
					})
					.catch(() => {
						addToast(
							'An error occurred while trying to load dataframe cards. Please try again later.',
							AlertVariant.danger
						);
					})
					.finally(() => {
						setIsLoading(false);
					});
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load dataframes. Please try again later.',
					AlertVariant.danger
				);
			});
	}, [parentFolderId]);

	const getDataframes = (card: Card) => {
		return {
			id: card.id,
			folder: card.isFolder ? card.id : 0,
			name: card.name,
			created_at: '',
			creator: card.creator ?? 0,
			updated_at: card.updated_at ?? '',
			updater: 0,
			columnEntry: [],
			datasets: [],
			filters: [],
			order: [],
			rowEntry: [],
			type: card.isFolder ? FolderTypesEnum.Folder : '',
			owner: 0,
			sharedDataframe: [],
		};
	};

	const dataFrameTable = (
		<ZiTable<Dataframe>
			ariaLabel={'DataFrame table'}
			columns={selectedColumns}
			data={dataframes}
			caption="Dataframes"
			actions={actions}
			loading={false}
		/>
	);

	if (loading) {
		return (
			<Bullseye>
				<div>
					<Spinner
						isSVG
						size={'xl'}
					/>
				</div>
			</Bullseye>
		);
	} else {
		return (
			<React.Fragment>
				<span className="spacer-l"></span>
				<Title headingLevel={'h1'}>{dataframeTitle}</Title>
				<span className="spacer-xs"></span>
				<FilterTableLayout
					table={dataFrameTable}
					icon={FilterIcon}
				/>
				<AddFoldersModal {...addFolderModalProps} />
			</React.Fragment>
		);
	}
}

export default ListDataframes;
