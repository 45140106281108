import axiosConfig from '../axiosConfig';
import { TRole } from './Role';
import { TGroup } from './Group';
import { Permission } from '../../enums/permission.enum';
import { TAppNotification } from '../types/AppNotification';
import { TPassword } from '../types/password/password';

export type TUser = {
	id: number;
	account: string;
	email: string;
	failed_logins: number; // Default 0
	force_logout: boolean; // Default false
	last_failed_login: number | null; // Default null
	last_failed_login_ip: string | null; // Default null
	last_login: number | null; // Default null
	last_login_ip: string | null; // Default null
	must_change_password: boolean; // Default false
	name: string;
	password: string;
	permissions: Permission[];
	status: string;
	role: TRole | number;
	groups: TGroup[] | number[];
};

export type TNewUser = Omit<
	TUser,
	| 'id'
	| 'failed_logins'
	| 'last_failed_login'
	| 'force_logout'
	| 'must_change_password'
	| 'last_failed_login_ip'
	| 'last_login'
	| 'last_login_ip'
>;

export class User implements TUser {
	account: string;
	email: string;
	failed_logins: number;
	force_logout: boolean;
	id: number;
	last_failed_login: number | null;
	last_failed_login_ip: string | null;
	last_login: number | null;
	last_login_ip: string | null;
	must_change_password: boolean;
	name: string;
	password: string;
	status: string;
	role: TRole | number;
	permissions: Permission[];
	groups: TGroup[] | number[];

	constructor(user: TUser) {
		this.account = user.account;
		this.email = user.email;
		this.failed_logins = user.failed_logins;
		this.force_logout = user.force_logout;
		this.id = user.id;
		this.last_failed_login = user.last_failed_login;
		this.last_failed_login_ip = user.last_failed_login_ip;
		this.last_login = user.last_login;
		this.last_login_ip = user.last_login_ip;
		this.must_change_password = user.must_change_password;
		this.name = user.name;
		this.password = user.password;
		this.status = user.status;
		this.role = user.role;
		this.groups = user.groups;
		this.permissions = user.permissions || [];
	}

	public static Me(expand: (keyof TUser)[] = []): Promise<TUser> {
		return axiosConfig.instance
			.get('app/me', { params: { expand: expand } })
			.then((response) => {
				return response.data as TUser;
			});
	}

	public static MyRole(expand: (keyof TRole)[] = []): Promise<TRole> {
		return axiosConfig.instance
			.get('app/me/role', { params: { expand: expand } })
			.then((response) => {
				return response.data as TRole;
			});
	}

	public static Logout(): Promise<void> {
		return axiosConfig.instance.post('/logout');
	}

	public static Default(): TNewUser {
		return {
			account: '',
			email: '',
			name: '',
			password: '',
			status: 'Active',
			role: 4,
			groups: [],
			permissions: [],
		};
	}

	public static RequestResetPassword(entity: TUser): Promise<void> {
		return axiosConfig.instance.post('app/user/request-reset-password-by-id', {
			id: entity.id,
		});
	}

	public static ResetPassword(
		id: number,
		newPassword: string,
		validationKey: string,
		is_modal?: boolean,
		current_password?: string
	): Promise<void | TPassword> {
		return axiosConfig.instance.post('app/user/reset-password', {
			id,
			new_password: newPassword,
			validation_key: validationKey,
			is_modal,
			current_password,
		});
	}

	public static ClearPasswordHistory(entity: TUser): Promise<void> {
		return axiosConfig.instance.post('app/user/clear-password-history', {
			id: entity.id,
		});
	}

	public static GetAll(expand: (keyof TUser)[] = []): Promise<TUser[]> {
		// This requires multiple return endpoints to end in 's' in the API
		return axiosConfig.instance.get('app/users', { params: { expand: expand } }).then((res) => {
			return ((res.data || []) as TUser[]).map((entity) => {
				return entity;
			});
		});
	}

	public static Get(id: number): Promise<TUser> {
		return axiosConfig.instance.get(`app/user/${id}`).then((res) => {
			return res.data as TUser;
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/user/` + String(id)).then(() => {
			return true;
		});
	}

	public static New(entity: TNewUser, expand: (keyof TUser)[] = []): Promise<TUser> {
		return axiosConfig.instance
			.post('app/user', entity, { params: { expand: expand } })
			.then((res) => {
				return res.data as TUser;
			});
	}

	public static Update(entity: TUser, expand: (keyof TUser)[] = []): Promise<TUser> {
		return axiosConfig.instance
			.patch('app/user', entity, { params: { expand: expand } })
			.then((res) => {
				return res.data as TUser;
			});
	}

	public static HasPermission(user: TUser, permission: Permission): boolean {
		if (typeof user.role !== 'number') {
			return user.role.name === 'SYSTEM_ADMIN' || user.permissions.includes(permission);
		}

		return false;
	}

	public static GetNotifications(): Promise<TAppNotification[]> {
		return axiosConfig.instance.get('app/notifications').then((res) => {
			return res.data as TAppNotification[];
		});
	}
}
