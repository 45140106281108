import React, { ReactNode } from 'react';
import { Permission } from '../../enums/permission.enum';
import { useApplication } from '../user/ApplicationProvider';
import { User } from '../../api/security/User';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { AlertVariant } from '@patternfly/react-core';

type Props = {
	by: Permission;
	children: ReactNode;
	shouldRedirect?: boolean;
};

const Authorized: React.FC<Props> = (props: Props) => {
	const { by, children, shouldRedirect } = props;
	const { user } = useApplication();
	const navigate = useNavigate();
	const { addToast } = useToast();

	if (!User.HasPermission(user, by)) {
		// Dont Display Children
		if (shouldRedirect) {
			addToast('Not Authorized', AlertVariant.danger);
			// Navigate back to previous page
			navigate(-1);
		} else {
			return <></>;
		}
	}

	// Display Children
	return <>{children}</>;
};

export default Authorized;
