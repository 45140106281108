import { TNewDateRange } from '../api/types/TNewDateRange';

export const selectedPeriod = (): {
	startPeriod: TNewDateRange | null;
	endPeriod: TNewDateRange | null;
} => {
	const startPeriod = localStorage.getItem('currentSelectedStartPeriod');
	const endPeriod = localStorage.getItem('currentSelectedEndPeriod');

	return {
		startPeriod: startPeriod ? (JSON.parse(startPeriod) as TNewDateRange) : null,
		endPeriod: endPeriod ? (JSON.parse(endPeriod) as TNewDateRange) : null,
	};
};
