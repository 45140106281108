import {
	SearchInput,
	Tab,
	TabTitleText,
	Tabs,
	TreeView,
	TreeViewDataItem,
} from '@patternfly/react-core';
import React from 'react';
import { SideBarTabContext } from './Layout';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { DraggableMenuItemData } from '../types/databuilder/databuilder';

export interface ITabNavigationItem extends TreeViewDataItem {
	name: JSX.Element | string;
	icon?: JSX.Element;
	children?: ITabNavigationItem[];
}

interface Props {
	sidebarTabs: SideBarTabContext;
}

interface ITabNavigationItemProps {
	dragElement: string;
	data?: DraggableMenuItemData;
}

const PageTabNavigationItems = ({ sidebarTabs }: Props) => {
	const [activeTabKey, setActiveTabKey] = React.useState<string | number>(0);
	const [isFiltered, setIsFiltered] = React.useState<boolean>(false);
	const [tabContext, setTabContext] = React.useState<SideBarTabContext>(sidebarTabs);
	const [searchTerm, setSearchTerm] = React.useState<string>('');

	const handleTabClick = (
		event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent | MouseEvent,
		tabIndex: string | number
	) => {
		setSearchTerm('');
		setActiveTabKey(tabIndex);
	};

	const onSearch = (value: string) => {
		setSearchTerm(value);
	};

	const filterItems = (item: ITabNavigationItem, input: string): boolean => {
		let found = false;

		if (typeof item.name === 'object') {
			const searchableObject: React.ReactElement<ITabNavigationItemProps> =
				item.name as React.ReactElement<ITabNavigationItemProps>;

			if (searchableObject.props.dragElement) {
				if (
					(item.name as React.ReactElement<ITabNavigationItemProps>).props.dragElement
						.toLowerCase()
						.includes(input.toLowerCase())
				) {
					found = true;
				}
			} else if (searchableObject.props.data?.data) {
				if (
					searchableObject.props.data.data.title
						.toLowerCase()
						.includes(input.toLowerCase())
				) {
					found = true;
				}
			}
		} else {
			if (item.name.toLowerCase().includes(input.toLowerCase())) {
				found = true;
			}
		}

		if (item.children) {
			return (
				(item.children = item.children
					.map((opt) => Object.assign({}, opt))
					.filter((child) => filterItems(child, input))).length > 0
			);
		}

		return found;
	};

	const tabs = tabContext.tabs.map((item, index): JSX.Element => {
		return (
			<Tab
				eventKey={index}
				title={<TabTitleText>{item.label}</TabTitleText>}
				aria-label={item.label}
			>
				{item.items.map((menuItem, index): JSX.Element => {
					if (menuItem.id == '0') {
						menuItem.icon = (
							<FontAwesomeIcon
								icon={faCalendarAlt}
								size="1x"
								color="black"
							/>
						);
					}
					if (menuItem.id == '-1') {
						menuItem.icon = (
							<FontAwesomeIcon
								icon={faCalculator}
								size="1x"
								color="black"
							/>
						);
					}
					return (
						<TreeView
							data={[menuItem]}
							key={menuItem.name.toString() + '_' + index.toString()}
							allExpanded={isFiltered}
						/>
					);
				})}
			</Tab>
		);
	});

	React.useEffect(() => {
		if (searchTerm === '') {
			setTabContext(sidebarTabs);
			setIsFiltered(false);
		} else {
			const context = _.cloneDeep(sidebarTabs);
			context.tabs = context.tabs.map(
				(
					tab
				): {
					label: string;
					items: ITabNavigationItem[];
				} => {
					return {
						label: tab.label,
						items: tab.items.filter((item) => {
							return filterItems(item, searchTerm);
						}),
					};
				}
			);
			setTabContext(context);
			setIsFiltered(true);
		}
	}),
		[searchTerm, setSearchTerm];

	return (
		<React.Fragment>
			{sidebarTabs.hasSearch && (
				<SearchInput
					placeholder={sidebarTabs.searchPlaceholder}
					value={searchTerm}
					onChange={(_event, value) => onSearch(value)}
					onClear={() => onSearch('')}
				/>
			)}
			<Tabs
				activeKey={activeTabKey}
				onSelect={handleTabClick}
				isBox={false}
				role="region"
			>
				{tabs}
			</Tabs>
		</React.Fragment>
	);
};

export default PageTabNavigationItems;
