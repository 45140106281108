import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { OutletContext } from '../../layout/Layout';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import {
	NotificationDrawer,
	NotificationDrawerHeader,
	Spinner,
	Bullseye,
	Button,
	Grid,
	Card,
	CardBody,
	GridItem,
} from '@patternfly/react-core';
import './Notifications.scss';
import { useUser } from '../../components/user/ApplicationProvider';
import { User } from '../../api/security/User';
import { TAppNotification, TJobDetails } from '../../api/types/AppNotification';
import { faEnvelopeCircleCheck, faX } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	dismissAllNotifications,
	markAllNotificationsAsRead,
} from '../../api/notification/Notification';
import NotificationsGroup from './NotificationsGroup';

const Notifications = () => {
	const navigate = useNavigate();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [searchTerm, setSearchTerm] = useState<string>();
	const user = useUser();
	const [notifications, setNotifications] = useState<TAppNotification[]>([]);
	const [notificationsLoaded, setNotificationsLoaded] = useState(false);
	const [unreadCount, setUnreadCount] = useState<number>(0);

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Notifications"
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
			sidebarContext: null,
			hideLeftSideBar: true,
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	useEffect(() => {
		if (notificationsLoaded) {
			setUnreadCount(notifications.filter((n) => !n.read).length);
			return;
		}

		User.GetNotifications()
			.then((notifications) => {
				notifications.map((notification) => {
					if (notification.job_details) {
						notification.job_details = JSON.parse(
							notification.job_details.toString()
						) as TJobDetails;
					}

					return notification;
				});

				setNotifications(notifications);
				setNotificationsLoaded(true);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [notifications]);

	const markAllAsRead = () => {
		const newState = notifications.map((notification) => {
			notification.read = true;

			return notification;
		});

		setNotifications(newState);

		void markAllNotificationsAsRead();
	};

	const dismissAllNotificationsOnClick = () => {
		void dismissAllNotifications();
		setNotifications([]);
	};

	if (!notificationsLoaded) {
		return (
			<Bullseye style={{ paddingTop: '2rem' }}>
				<div>
					<Spinner
						isSVG
						size={'xl'}
					/>
				</div>
			</Bullseye>
		);
	}

	const groupBy = <T, K extends keyof any>(
		array: T[],
		keyGetter: (item: T) => K
	): Record<K, T[]> => {
		return array.reduce((result, currentValue) => {
			const key = keyGetter(currentValue);
			if (!result[key]) {
				result[key] = [];
			}
			result[key].push(currentValue);
			return result;
		}, {} as Record<K, T[]>);
	};

	const groupedNotifications = groupBy(
		notifications,
		(notification) => notification.job_details?.entityType ?? 'null'
	);

	return (
		<Card>
			<CardBody>
				<NotificationDrawer>
					<NotificationDrawerHeader count={unreadCount}>
						<Button
							variant={'plain'}
							disabled={unreadCount === 0}
							title={'Click to mark as read'}
							onClick={() => markAllAsRead()}
						>
							<FontAwesomeIcon icon={faEnvelopeCircleCheck} />
						</Button>
						<Button
							variant={'plain'}
							title={'Click to dismiss(Delete) all notifications'}
							onClick={() => dismissAllNotificationsOnClick()}
						>
							<FontAwesomeIcon icon={faX} />
						</Button>
					</NotificationDrawerHeader>
					<Grid hasGutter>
						<GridItem span={12}>
							{Object.entries(groupedNotifications).map(
								([jobType, groupedNotifications]) => {
									return (
										<NotificationsGroup
											jobGroup={{
												jobType: jobType,
												notifications: groupedNotifications,
											}}
											notifications={notifications}
											setNotifications={setNotifications}
											groupBy={groupBy}
										/>
									);
								}
							)}
						</GridItem>
					</Grid>
				</NotificationDrawer>
			</CardBody>
		</Card>
	);
};

export default Notifications;
