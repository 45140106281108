import axiosConfig from '../axiosConfig';
import { TDimension } from './Dimension';
import { TPeriod } from './Period';
import { TETLSourceDatabase } from './ETLSourceDatabase';
import { currentTimestampZeroed } from '../../utilities';
import { EOccurrence } from './KeyMeasureSource';
import { TUser } from '../security/User';

export type TDimensionSource = {
	created_at: number;
	creator: TUser;
	daily_run_interval: number | null;
	description: string | null;
	dimension: TDimension;
	end_date: number | null;
	ETLSourceDatabase: TETLSourceDatabase;
	id: number;
	name: string;
	next_run_date: number | null;
	occurrence: EOccurrence | null;
	period: TPeriod;
	run_group: number | null;
	run_sequence: number | null;
	sql: string | null;
	start_date: number;
	updated_at: number;
	updater: TUser;
};

export type TNewDimensionSource = Omit<
	TDimensionSource,
	| 'id'
	| 'updater'
	| 'updated_at'
	| 'creator'
	| 'created_at'
	| 'ETLSourceDatabase'
	| 'dimension'
	| 'occurrence'
	| 'period'
> & {
	ETLSourceDatabase: number;
	occurrence: null | EOccurrence;
	period: number;
	dimension: number;
};

export class DimensionSource implements TDimensionSource {
	ETLSourceDatabase: TETLSourceDatabase;
	created_at: number;
	creator: TUser;
	daily_run_interval: number | null;
	description: string | null;
	dimension: TDimension;
	end_date: number | null;
	id: number;
	name: string;
	next_run_date: number | null;
	occurrence: EOccurrence | null;
	period: TPeriod;
	run_group: number | null;
	run_sequence: number | null;
	sql: string | null;
	start_date: number;
	updated_at: number;
	updater: TUser;

	constructor(dimensionSource: TDimensionSource) {
		this.created_at = dimensionSource.created_at;
		this.creator = dimensionSource.creator;
		this.daily_run_interval = dimensionSource.daily_run_interval;
		this.description = dimensionSource.description;
		this.dimension = dimensionSource.dimension;
		this.end_date = dimensionSource.end_date;
		this.ETLSourceDatabase = dimensionSource.ETLSourceDatabase;
		this.id = dimensionSource.id;
		this.name = dimensionSource.name;
		this.next_run_date = dimensionSource.next_run_date;
		this.occurrence = dimensionSource.occurrence;
		this.period = dimensionSource.period;
		this.run_group = dimensionSource.run_group;
		this.run_sequence = dimensionSource.run_sequence;
		this.sql = dimensionSource.sql;
		this.start_date = dimensionSource.start_date;
		this.updated_at = dimensionSource.updated_at;
		this.updater = dimensionSource.updater;
	}

	public static Default(): TNewDimensionSource {
		return {
			daily_run_interval: null,
			description: null,
			dimension: 0,
			end_date: null,
			ETLSourceDatabase: 0,
			name: '',
			next_run_date: null,
			occurrence: EOccurrence.ONE_TIME,
			period: 0,
			run_group: null,
			run_sequence: null,
			sql: null,
			start_date: currentTimestampZeroed(),
		};
	}

	public static New(
		dimensionSource: TNewDimensionSource,
		expand: (keyof TDimensionSource)[] = []
	): Promise<TDimensionSource> {
		return axiosConfig.instance
			.post('app/dimension-source', dimensionSource, { params: { expand: expand } })
			.then((res) => {
				return res.data as TDimensionSource;
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/dimension-source/${id}`).then(() => {
			return true;
		});
	}

	public static Update(
		dimensionSource: TDimensionSource,
		expand: (keyof TDimensionSource)[] = []
	): Promise<TDimensionSource> {
		return axiosConfig.instance
			.patch('app/dimension-source', dimensionSource, { params: { expand: expand } })
			.then((res) => {
				return res.data as TDimensionSource;
			});
	}

	public static GetAll(expand: (keyof TDimensionSource)[] = []): Promise<TDimensionSource[]> {
		return axiosConfig.instance
			.get('app/dimension-sources', { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as TDimensionSource[]).map(
					(dimensionSource: TDimensionSource) => {
						return new DimensionSource(dimensionSource);
					}
				);
			});
	}
}
