export enum ChartTypesEnum {
	Area = 'area',
	Bar = 'bar',
	Chart = 'chart',
	Funnel = 'funnel',
	Heat = 'heat',
	Heatmap = 'heatmap',
	Column = 'column',
	Line = 'line',
	Packed = 'packedbubble',
	Pie = 'pie',
	Sankey = 'sankey',
	Scatter = 'scatter',
	WordCloud = 'wordcloud',
	Lollipop = 'lollipop',
	Pyramid = 'pyramid',
	Solidgauge = 'solidgauge',
	Stackedbar = 'stackedbar',
	Stackedcolumn = 'stackedcolumn',
	Clusteredline = 'clusteredline',
	Clusteredbar = 'clusteredbar',
	Clusteredcolumn = 'clusteredcolumn',
	Dependencywheel = 'dependencywheel',
	Columnrange = 'columnrange',
}
