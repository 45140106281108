import React, { useEffect, useRef, useState } from 'react';
import { DashboardWidget } from '../../../types/dashboards/dashboard';
import { TNewDateRange } from '../../../api/types/TNewDateRange';
import { DashboardFilter } from '../../../api/dashbboards/DashboardFilter';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { useApplication } from '../../user/ApplicationProvider';
import { DateRange } from '../../../api/date-period-selector/DateRange';
import { TDateRange } from '../../../api/types';
import { useMount } from 'react-use';
import { DashboardWidget as DashboardWidgetService } from '../../../api/dashbboards/DashboardWidgets';
import Loader from '../../util/Loader';
import {
	AlertVariant,
	Button,
	Dropdown,
	DropdownItem,
	DropdownToggle,
	InputGroup,
	Modal,
	ModalVariant,
	Text,
} from '@patternfly/react-core';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { IIndexable } from '../../../types/general';
import './TextView.scss';
import ConditionalFormattingModal from '../../modals/databuilder/ConditionalFormattingModal';
import { DraggableMenuItemData } from '../../../types/databuilder/databuilder';
import { populateDataForFact } from '../../../hooks/DataBuilderHooks';
import { generateGUID } from '../../../helpers/guid.helper';
import { OptionsBuilderItemTypes } from '../../../types/dataframes/options-builder-item-types';
import _ from 'lodash';

type Props = {
	widget: DashboardWidget;
	selectedDate?: TNewDateRange;
	filters: DashboardFilter[];
	isEditModalOpen: boolean;
	isEdit: boolean;
	toggleEdit: () => void;
	updateWidget?: (widget: DashboardWidget) => void;
};

export type TextObjectType = {
	type: 'p' | 'h2' | 'h3';
	value: string;
};

const TextView = (props: Props) => {
	const { widget, selectedDate, filters, isEdit, toggleEdit, updateWidget, isEditModalOpen } =
		props;
	const factAliasRegex = new RegExp(/@dataframe-([\w-]+)\.([\w-]+)/);
	const { addToast } = useToast();
	const { currentDatePeriods, measures, unitTypes } = useApplication();
	const defaultDatePeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [widgetData, setWidgetData] = useState<string>();
	const [widgetContent, setWidgetContent] = useState<string>('');
	const previousSelectedStartPeriod = localStorage.getItem('currentSelectedStartPeriod');
	const previousSelectedEndPeriod = localStorage.getItem('currentSelectedEndPeriod');
	const hasPreviouslySelectedPeriod =
		previousSelectedStartPeriod != null || previousSelectedEndPeriod != null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedStartDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedStartPeriod
			? JSON.parse(previousSelectedStartPeriod)
			: null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedEndDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedEndPeriod
			? JSON.parse(previousSelectedEndPeriod)
			: null;
	const [editState, setEditState] = useState<EditorState>(EditorState.createEmpty());
	const [isFactAliasDropdownOpen, setIsFactAliasDropdownOpen] = React.useState(false);
	const [conditionalFormattingModalOpen, setConditionalFormattingModalOpen] = useState(false);
	const [currentFact, setCurrentFact] = useState<DraggableMenuItemData | null>(null);
	const prevSelectedDate = usePrevious(selectedDate);
	const prevFilters = usePrevious(filters);

	useMount(() => {
		setInitialState();
	});

	useEffect(() => {
		if (!_.isEqual(selectedDate, prevSelectedDate) || !_.isEqual(filters, prevFilters)) {
			const handler = setTimeout(() => {
				if (checkForFactAlias() && !isEdit) {
					getWidgetData();
				} else {
					setIsLoading(false);
				}
			}, 500);

			return () => {
				clearTimeout(handler);
			};
		}
	}, [selectedDate, filters]);

	useEffect(() => {
		setWidgetContent(widgetData ?? widget.content ?? '');
	}, [widget, widgetData]);

	const checkForFactAlias = () => {
		return widget.content ? factAliasRegex.test(widget.content) : false;
	};

	const setInitialState = () => {
		let html = '';
		if (widget.widget_type === 'text' && widget.content) {
			if (isJsonString(widget.content)) {
				const jsonObject = JSON.parse(widget.content) as TextObjectType;
				html = jsonObject.value;
			} else {
				html = widget.content;
			}
		}
		setEditState(getEditorStateFromString(html));
		setWidgetContent(widget.content ?? '');

		if (checkForFactAlias() && !isEdit) {
			getWidgetData();
		} else {
			setIsLoading(false);
		}
	};

	const getEditorStateFromString = (value: string) => {
		let currentState = EditorState.createEmpty();
		const contentBlock = htmlToDraft(value);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			currentState = EditorState.createWithContent(contentState);
		}
		return currentState;
	};

	const getWidgetData = () => {
		setIsLoading(true);
		const previousDate = {
			begin_date:
				hasPreviouslySelectedPeriod && previouslySelectedStartDate
					? previouslySelectedStartDate?.begin_date
					: defaultDatePeriod?.begin_date ?? 0,
			end_date:
				hasPreviouslySelectedPeriod && previouslySelectedEndDate
					? previouslySelectedEndDate.end_date
					: defaultDatePeriod?.end_date ?? 0,
			period:
				hasPreviouslySelectedPeriod && previouslySelectedStartDate
					? previouslySelectedStartDate.period
					: defaultDatePeriod?.period ?? 0,
		};

		const dateToUse = selectedDate ?? previousDate;

		const request = {
			widgetId: widget.id ?? 0,
			periodId: dateToUse?.period ?? 0,
			startDate: dateToUse?.begin_date ?? 0,
			endDate: dateToUse?.end_date ?? 0,
			filters:
				filters?.map((filter) => {
					return {
						entity_id: filter.entity_id,
						entity_type: filter.entity_type,
						excluded: filter.excluded,
						isExistingValue: filter.isExistingValue,
						operator: filter.operator,
						value: filter.value,
					};
				}) ?? [],
		};

		DashboardWidgetService.GetTextWidgetData(request)
			.then((response) => {
				setWidgetData(response.data);
				setIsLoading(false);
			})
			.catch((): void => {
				addToast('Error fetching text widget data', AlertVariant.danger);
				setIsLoading(false);
			});
	};

	const isJsonString = (jsonString: string): boolean => {
		try {
			const o = JSON.parse(jsonString) as IIndexable;

			if (o && typeof o === 'object') {
				return true;
			}
		} catch (e) {
			return false;
		}

		return false;
	};

	const onFactAliasDropdownToggle = (isOpen: boolean) => {
		setIsFactAliasDropdownOpen(isOpen);
	};

	const onFactAliasSelect = () => {
		setIsFactAliasDropdownOpen(false);
		onFactAliasDropdownFocus();
	};

	const onFactAliasDropdownFocus = () => {
		const element = document.getElementById('fact-alias-dropdown');
		element?.focus();
	};

	const factAliasDropdownItems = (): JSX.Element[] => {
		const factAliases = widget?.content?.match(factAliasRegex);
		const facts = measures.flatMap((measure) => {
			return measure.keyMeasureFacts.filter(
				(fact) => fact.alias && factAliases?.includes(fact.alias)
			);
		});

		if (facts?.length) {
			return facts.map((fact) => {
				return (
					<DropdownItem
						key={`fact-alias-${fact.id}`}
						tooltip={`Select ${fact.alias ?? ''}`}
						onClick={(e) => {
							setCurrentFact({
								id: generateGUID(),
								entity: fact,
								data: populateDataForFact(
									fact,
									unitTypes.find((type) => type.id === fact.unitType)
								),
								entityType: OptionsBuilderItemTypes.KeyMeasureFact,
								itemType: 'dropdown',
								allowedZones: [],
								static: false,
								icon: 'database',
							});
							setConditionalFormattingModalOpen(true);
						}}
					>
						{fact.alias}
					</DropdownItem>
				);
			});
		}

		return [];
	};

	const getCustomToolbarItems = () => {
		return [
			...(checkForFactAlias()
				? [
						<Dropdown
							onSelect={onFactAliasSelect}
							toggle={
								<DropdownToggle
									id="fact-alias-dropdown"
									onToggle={onFactAliasDropdownToggle}
								>
									Fact Aliases
								</DropdownToggle>
							}
							isOpen={isFactAliasDropdownOpen}
							dropdownItems={factAliasDropdownItems()}
						/>,
				  ]
				: []),
		];
	};

	const onConditionalFormattingModalClose = (): void => {
		setConditionalFormattingModalOpen(false);
	};

	const onConditionalFormattingModalSave = (): void => {
		setConditionalFormattingModalOpen(false);
	};

	function usePrevious(value: any) {
		const ref = useRef();
		useEffect(() => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			ref.current = value;
		});
		return ref.current;
	}

	const componentStyles = {
		height: '100%',
		width: '100%',
		overflow: 'auto',
	};

	let component = <Loader />;

	if (!isLoading) {
		component = (
			<div style={componentStyles}>
				<Text
					id={`text-widget-${widget.id ?? '0'}`}
					className="text-widget"
					dangerouslySetInnerHTML={{
						__html: editState
							? draftToHtml(
									convertToRaw(
										getEditorStateFromString(widgetContent).getCurrentContent()
									)
							  )
							: '',
					}}
				></Text>
				<Modal
					aria-label="Edit Widget Modal"
					isOpen={isEditModalOpen}
					variant={ModalVariant.medium}
					showClose={false}
					actions={[
						<Button
							key="confirm"
							variant="primary"
							onClick={() => {
								widget.id &&
									updateWidget &&
									updateWidget({
										...widget,
										widgetChanged: true,
										content: editState
											? draftToHtml(
													convertToRaw(editState.getCurrentContent())
											  )
											: '',
									});
								toggleEdit();
							}}
						>
							Save
						</Button>,
						<Button
							key="cancel"
							variant="link"
							onClick={() => {
								setInitialState();
								toggleEdit();
							}}
						>
							Cancel
						</Button>,
					]}
				>
					<InputGroup>
						<Editor
							editorState={editState}
							toolbar={{
								options: ['inline', 'fontFamily', 'fontSize', 'textAlign'],
								inline: {
									options: ['bold', 'italic', 'underline', 'strikethrough'],
								},
								fontFamily: {
									options: [
										'Arial',
										'Georgia',
										'Impact',
										'Tahoma',
										'Times New Roman',
										'Verdana',
									],
								},
								fontSize: {
									options: [
										8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
									],
								},
							}}
							onEditorStateChange={(e) => {
								setEditState(e);
							}}
							toolbarCustomButtons={getCustomToolbarItems()}
						/>
					</InputGroup>
				</Modal>
				<>
					{conditionalFormattingModalOpen && (
						<ConditionalFormattingModal
							fact={currentFact}
							rules={[]}
							reportId={props.widget?.id ?? 0}
							handleSave={onConditionalFormattingModalSave}
							handleClose={onConditionalFormattingModalClose}
						/>
					)}
				</>
			</div>
		);
	}
	return component;
};

export default TextView;
