import axiosConfig from '../axiosConfig';

export type TDataframeDataJoin = {
	id?: number;
	key_measure_from: number;
	key_measure_to: number;
	dimensions: number[];
	dataframe_id: number;
	join_type: string;
};

export type TDataframeDataExistingJoin = {
	id?: number;
	key_measure_from: number;
	key_measure_to: number;
	dimension: number;
	dataframe_id: number;
	join_type: string;
};

export class DataframeDataJoin implements TDataframeDataJoin {
	id?: number;
	key_measure_from: number;
	key_measure_to: number;
	dimensions: number[];
	dataframe_id: number;
	join_type: string;

	constructor(dataframeDataJoin: TDataframeDataJoin) {
		this.id = dataframeDataJoin.id;
		this.key_measure_from = dataframeDataJoin.key_measure_from;
		this.key_measure_to = dataframeDataJoin.key_measure_to;
		this.dimensions = dataframeDataJoin.dimensions;
		this.dataframe_id = dataframeDataJoin.dataframe_id;
		this.join_type = dataframeDataJoin.join_type;
	}

	public static Default(): TDataframeDataJoin {
		return {
			dataframe_id: 0,
			dimensions: [],
			join_type: '',
			key_measure_from: 0,
			key_measure_to: 0,
		};
	}

	public static Save(dataframe: TDataframeDataJoin): Promise<TDataframeDataJoin> {
		return axiosConfig.instance.post('/app/dataframe-data-join/', dataframe).then((res) => {
			return new DataframeDataJoin(res.data as TDataframeDataJoin);
		});
	}

	public static Get(id: number): Promise<TDataframeDataExistingJoin[]> {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return (
			axiosConfig.instance
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				.get(`app/get-dataframe-data-join-by-dataframe/${id}`)
				.then((res) => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
					return res.data?.length > 0 ? res.data : [];
				})
		);
	}

	public static Delete(join_ids: number[]): Promise<TDataframeDataJoin> {
		return axiosConfig.instance
			.delete(`/app/dataframe-data-join/`, { data: { join_ids: join_ids } })
			.then((res) => {
				return new DataframeDataJoin(res.data as TDataframeDataJoin);
			});
	}
}
