import { Dropdown, DropdownToggle, Icon, Tooltip } from '@patternfly/react-core';
import { useState } from 'react';
import { TUser, User } from '../../api/security/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { useMount } from 'react-use';
import UserProfileModal from '../../helpers/helper-components/UserProfileModal';

export default function Avatar() {
	const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(false);
	const [loggedinUser, setLoggedinUser] = useState<TUser>();

	useMount(() => {
		void User.Me().then((response) => {
			setLoggedinUser(response);
		});
	});

	const onToggle = () => {
		setIsProfileModalOpen(true);
	};

	const closeProfileModal = () => {
		setIsProfileModalOpen(false);
	};

	return (
		<>
			<Tooltip
				aria="none"
				content={'Profile'}
				flipBehavior={['bottom', 'bottom-end']}
			>
				<Dropdown
					position="right"
					isText
					isPlain
					style={{
						height: '1.5rem',
						borderRadius: '50%',
					}}
					toggle={
						<DropdownToggle
							toggleIndicator={null}
							onToggle={onToggle}
							id="avatar-dropdown"
							icon={
								<Icon
									onClick={() => onToggle()}
									style={{
										width: '2rem',
										height: '1.5rem',
										color: '#000000',
										cursor: 'pointer',
									}}
									size="lg"
									alt={'Profile'}
									isInline
									data-testid="user-avatar-dropdown"
								>
									<FontAwesomeIcon icon={faUser} />
								</Icon>
							}
						></DropdownToggle>
					}
				/>
			</Tooltip>
			<UserProfileModal
				isOpen={isProfileModalOpen}
				onClose={closeProfileModal}
				user={loggedinUser}
			/>
		</>
	);
}
