import React, { useCallback, useEffect, useState } from 'react';
import { AlertVariant, Button, Modal } from '@patternfly/react-core';
import DefaultDashboardOptionsView, {
	DefaultDashboardOptionsViewProps,
} from './DefaultDashboardOptionsView';
import { Folder, TFolder } from '../../../api/foundational-elements/Folder';
import { Dashboard, TDashboard } from '../../../api/dashbboards/Dashboards';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import BlankDashboardOptionsView, {
	BlankDashboardOptionsViewProps,
} from './BlankDashboardOptionsView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { useMount } from 'react-use';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { useApplication } from '../../user/ApplicationProvider';
import { parseFolderSegments } from '../../../helpers/folder-url-helper';

export type ZiDashboardOptionsModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

export type DashboardCreationOptions = 'default' | 'blank' | 'grid' | 'flex' | 'copy';

const ZiDashboardOptionsModal = (props: ZiDashboardOptionsModalProps) => {
	const { user } = useApplication();
	const [currentView, setCurrentView] = useState<DashboardCreationOptions>('default');
	const [filteredFolders, setFilteredFolders] = useState<TFolder[]>([]);
	const [allFolders, setAllFolders] = useState<TFolder[]>([]);
	const [selectedFolder, setSelectedFolder] = useState<TFolder | undefined>();
	const [dashboard, setDashboard] = useState<TDashboard>(Dashboard.Default());
	const navigate = useNavigate();
	const pathname = useLocation().pathname;
	const { addToast } = useToast();
	const { parentFolderId } = useParams();

	useMount(() => {
		Folder.GetAll()
			.then((foldersResponse) => {
				if (foldersResponse) {
					setAllFolders([...foldersResponse]);
					if (parentFolderId) {
						setSelectedFolder(
							foldersResponse.find((f) => f.id === parseInt(parentFolderId))
						);
					}
				}
			})
			.catch(() => {
				addToast('Get all folders failed.', AlertVariant.danger);
			});
	});

	useEffect(() => {
		if (parentFolderId && allFolders) {
			setSelectedFolder(allFolders.find((f) => f.id === parseInt(parentFolderId)));
		} else {
			setSelectedFolder(undefined);
		}

		Folder.GetAll({ type: 'dashboards' }, parentFolderId)
			.then((foldersResponse) => {
				if (foldersResponse) {
					foldersResponse = foldersResponse.filter((x) => x.isFolder);

					if (foldersResponse && foldersResponse.length > 0) {
						setFilteredFolders([...foldersResponse]);
					} else {
						setFilteredFolders([
							{
								id: -1,
								name: 'No folders found',
								type: 'dashboards',
								items: [],
							},
						]);
					}
				}
			})
			.catch(() => {
				addToast('Get all folders failed.', AlertVariant.danger);
			});

		const urlSegments = parseFolderSegments(pathname);

		if (urlSegments.length > 0) {
			const parentFolder = urlSegments[urlSegments.length - 1];
			localStorage.setItem('currentSelectedDashboardFolderId', parentFolder.id.toString());
			setSelectedFolder({
				id: parentFolder.id,
				name: decodeURIComponent(parentFolder.value.toString()),
				type: 'dashboards',
				items: [],
			});
		}
	}, [props.isOpen]);

	const handleNameChange = useCallback(
		(value: string, _event: React.FormEvent<HTMLInputElement>) => {
			setDashboard((dashboard) => {
				return { ...dashboard, name: value };
			});
		},
		[]
	);

	const defaultViewProps: DefaultDashboardOptionsViewProps = {
		onViewChange: setCurrentView,
	};

	const blankViewProps: BlankDashboardOptionsViewProps = {
		onViewChange: setCurrentView,
		dashboard: dashboard,
		folders: filteredFolders,
		handleNameChange: handleNameChange,
		selectedFolder: selectedFolder,
		onSelectFolder: setSelectedFolder,
	};

	const contentMap: Map<DashboardCreationOptions, JSX.Element> = new Map<
		DashboardCreationOptions,
		JSX.Element
	>([
		['default', <DefaultDashboardOptionsView {...defaultViewProps} />],
		['blank', <BlankDashboardOptionsView {...blankViewProps} />],
		['grid', <BlankDashboardOptionsView {...blankViewProps} />],
		['flex', <BlankDashboardOptionsView {...blankViewProps} />],
		//['copy', <CopyDashboardOptionsView {...copyChartProps} />],
	]);

	const handleSave = () => {
		if (selectedFolder?.id == -1) {
			addToast('Select a valid folder, Folder selection is required.', AlertVariant.danger);
			return;
		}

		Dashboard.New({ ...dashboard, owner: user.id, folder: selectedFolder?.id })
			.then((response) => {
				if (response.id) {
					navigate(`/analyze/dashboards/${response.id}`);
				}
			})
			.catch(() => {
				addToast('Create dashboard failed.', AlertVariant.danger);
			});
	};

	const beforeClose = () => {
		setDefaultView();
		props.onClose();
	};

	const setDefaultView = (): void => {
		setCurrentView('default');
		setDashboard(Dashboard.Default());
	};

	const backIcon = (
		<div className="back-btn">
			<Button
				variant="link"
				onClick={setDefaultView}
			>
				<FontAwesomeIcon icon={faArrowLeft} />
			</Button>
		</div>
	);

	return (
		<>
			<Modal
				data-testid="add-dashboard-modal"
				isOpen={props.isOpen}
				onClose={beforeClose}
				variant="small"
				title="Add Dashboard"
				actions={
					currentView !== 'default' && [
						<Button
							data-testid="create-button"
							key="create"
							variant="primary"
							onClick={handleSave}
							isDisabled={dashboard.name === ''}
						>
							Create
						</Button>,
						<Button
							data-testid="cancel-button"
							key="cancel"
							variant="link"
							onClick={beforeClose}
						>
							Cancel
						</Button>,
					]
				}
			>
				{currentView !== 'default' ? backIcon : null}
				{contentMap.get(currentView)}
			</Modal>
		</>
	);
};

export default ZiDashboardOptionsModal;
