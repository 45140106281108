import React, { ReactElement } from 'react';
import { useIsGranted } from '../user/ApplicationProvider';
import { Button, ButtonProps } from '@patternfly/react-core';
import { Permission } from '../../enums/permission.enum';

type PermissionButtonProps = {
	permission?: Permission | Permission[];
	testId?: string;
} & ButtonProps;
export default function PermissionButton(props: PermissionButtonProps): ReactElement | null {
	const isGranted = useIsGranted();

	const { permission, testId, ...buttonProps } = props;

	if (permission && !isGranted(permission)) {
		return null;
	}

	return (
		<Button
			data-testid={testId}
			{...buttonProps}
		>
			{props.children}
		</Button>
	);
}
