import { Flex, FlexItem, Grid, GridItem, Text } from '@patternfly/react-core';
import React, { useState } from 'react';
import DraggableWidget from './DraggableWidget';

type Props = {
	searchTerm?: string;
};

type Asset = {
	title: string;
	dropType: string;
	component: 'h2' | 'h3' | 'p';
};

const TextWidgets = (props: Props) => {
	const { searchTerm } = props;
	const [showAll, _setShowAll] = useState<boolean>(false);
	let assets: Asset[] = [
		{
			title: 'Heading',
			dropType: 'text',
			component: 'h2' as 'h2' | 'h3' | 'p',
		},
		{
			title: 'SubHeading',
			dropType: 'text',
			component: 'h3' as 'h2' | 'h3' | 'p',
		},
		{
			title: 'Body Text',
			dropType: 'text',
			component: 'p' as 'h2' | 'h3' | 'p',
		},
	];
	if (searchTerm) {
		assets = assets.filter(
			(asset) => asset.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
		);
	}
	if (!showAll) {
		assets = assets.slice(0, 4);
	}

	const getWidgetDefaultContent = (asset: Asset) => {
		let contentString = '';
		if (asset.component === 'p') {
			contentString = `<p>${asset.title}</p>`;
		} else if (asset.component === 'h3') {
			contentString = `<span style='font-size: 18px;'>${asset.title}</span>`;
		} else if (asset.component === 'h2') {
			contentString = `<span style='font-size: 24px;'><strong>${asset.title}</strong></span>`;
		}

		return contentString;
	};

	if (assets.length === 0) {
		return <></>;
	}
	return (
		<>
			<Grid span={12}>
				<Flex>
					<FlexItem>
						<Text className="assets-title">Text</Text>
					</FlexItem>
				</Flex>
			</Grid>
			<Grid>
				{assets.map((asset, index) => (
					<GridItem
						key={index}
						span={12}
					>
						<DraggableWidget
							defaultWidget={{
								col: 0,
								row: 0,
								name: asset.title,
								content: getWidgetDefaultContent(asset),
								sizex: 2,
								sizey: 1,
								widget_type: 'text',
								dashboard: 0,
							}}
						>
							<Text
								component={asset.component}
								style={{ padding: '0.25rem' }}
							>
								{asset.title}
							</Text>
						</DraggableWidget>
					</GridItem>
				))}
			</Grid>
		</>
	);
};

export default TextWidgets;
