import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { AlertVariant, Modal, ModalVariant } from '@patternfly/react-core';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import SchnurForm, {
	EventType,
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import {
	TUnitType,
	UnitType,
	UnitTypeFunctionMap,
	UnitTypeFunctionOptions,
} from '../../api/analytics/UnitType';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';

export default function UnitTypes(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [data, setData] = useState<TUnitType[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [activeUnitType, setActiveUnitType] = useState<TUnitType>(
		UnitType.Default() as TUnitType
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

	const selectedColumns: Column<TUnitType>[] = [
		{
			title: 'Alias',
			columnName: 'code',
			sortable: true,
		},
		{
			title: 'Name',
			columnName: 'name',
			sortable: true,
		},
		{
			title: 'Function Dimension Series',
			columnName: 'function_dimension_series',
			sortable: true,
			customAccessor: (item) => UnitTypeFunctionMap[item.function_dimension_series],
		},
		{
			title: 'Function Time Series',
			columnName: 'function_time_series',
			sortable: true,
			customAccessor: (item) => UnitTypeFunctionMap[item.function_time_series],
		},
		{
			title: 'Prefix',
			columnName: 'prefix',
			sortable: true,
		},
		{
			title: 'Decimals',
			columnName: 'precision',
			sortable: true,
		},
	];

	const actions: Action<TUnitType>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActiveUnitType(item);
				setIsModalOpen(true);
			},
			permission: Permission.EditUnitType,
		},
		{
			name: 'Delete',
			callback: (item) => {
				setTableLoading(true);
				UnitType.Delete(item.id)
					.then(() => {
						setTableLoading(false);
						setData((prev) => prev.filter((dimension) => dimension.id !== item.id));
						addToast('Unit Type deleted successfully.', AlertVariant.success);
					})
					.catch(() => {
						setTableLoading(false);
						addToast(
							'An error occurred while trying to delete unit type. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.DeleteUnitType,
		},
	];

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Unit Types"
					pageDescription="Manage your Unit Types."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});

		UnitType.GetAll()
			.then((occurrences) => {
				setData(occurrences);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load unit types. Please try again later.',
					AlertVariant.danger
				);
			});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = (value: UnitType) => {
		const index = data.findIndex((uT) => uT.id === value.id);

		if (index >= 0) {
			setData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const addButton = (
		<PermissionButton
			data-testid={'unitType-add-button'}
			variant={'primary'}
			permission={Permission.CreateUnitType}
			onClick={() => {
				setActiveUnitType(UnitType.Default() as TUnitType);
				setIsModalOpen(true);
			}}
		>
			New Unit Type
		</PermissionButton>
	);

	const formProperties: Field<TUnitType>[] = [
		{
			title: 'Alias',
			columnName: 'code',
			uiSchema: {
				type: UIType.TEXT,
				placeholder: 'unit_type_alias',
			},
			validate: (eventType, value) => {
				if (eventType !== EventType.BLUR && eventType !== EventType.CHANGE) {
					return true;
				}

				if (value.includes(' ')) {
					return 'An alias must not contain a space!';
				}

				return true;
			},
			required: true,
		},
		{
			title: 'Name',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'Function Dimension Series',
			columnName: 'function_dimension_series',
			uiSchema: {
				type: UIType.SELECT,
				options: UnitTypeFunctionOptions,
				onSelect: (value: ISelectOption) => value.key,
				initialSelection:
					'id' in activeUnitType ? activeUnitType.function_dimension_series : undefined,
			},
			required: true,
		},
		{
			title: 'Function Time Series',
			columnName: 'function_time_series',
			uiSchema: {
				type: UIType.SELECT,
				options: UnitTypeFunctionOptions,
				onSelect: (value: ISelectOption) => value.key,
				initialSelection:
					'id' in activeUnitType ? activeUnitType.function_time_series : undefined,
			},
		},
		{
			title: 'Prefix',
			columnName: 'prefix',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'The prefix is prepended to the value when displaying it.',
			},
		},
		{
			title: 'Suffix',
			columnName: 'suffix',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'The prefix is postpend to the value when displaying it.',
			},
		},
		{
			title: 'Decimal Places',
			columnName: 'precision',
			uiSchema: {
				type: UIType.NUMBER,
			},
		},
	];

	const unitTypeTable = (
		<ZiTable<TUnitType>
			ariaLabel={'Unit Type Table'}
			columns={selectedColumns}
			data={data}
			caption="Unit Types"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Unit Type Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TUnitType>
					title={'Unit Types'}
					fields={formProperties}
					initialSubject={activeUnitType}
					isLoading={isFormLoading}
					onSubmit={(unitType) => {
						setIsFormLoading(true);
						if ('id' in unitType) {
							UnitType.Update(unitType)
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the unit type. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							UnitType.New(unitType)
								.then((newSource) => {
									handleSuccess(newSource);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the unit type. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={unitTypeTable}
					layoutActions={[addButton]}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
