import { useMount } from 'react-use';
import AlertTile, { AlertTileProps } from '../../components/alerts/AlertTile';
import { AlertTrendEnum } from '../../enums/alert-trend.enum';
import { AlertDefinition, TAlertDefinition } from '../../api/alerts/AlertDefinition';
import { useState } from 'react';
import { AlertConditionsEnum } from '../../enums/alet-conditions.enum';
import { Spinner } from '@patternfly/react-core';

const getTrendforAlertTile = (alert: TAlertDefinition) => {
	switch (alert.condition) {
		case AlertConditionsEnum.DropsBelow:
			return AlertTrendEnum.Down;
		case AlertConditionsEnum.Exceeds:
		case AlertConditionsEnum.MeetsOrExceeds:
			return AlertTrendEnum.Up;
		case AlertConditionsEnum.Meets:
		case AlertConditionsEnum.MeetsOrDropsBelow:
			return AlertTrendEnum.JustInfo;
		case AlertConditionsEnum.PercentChangeFromPreviousPeriod:
		case AlertConditionsEnum.PercentChangeFromPreviousPeriodPlusMinus:
			return AlertTrendEnum.NoChange;
		default:
			return AlertTrendEnum.JustInfo;
	}
};

const AlertCardsHomePage = () => {
	const [alertTiles, setAlertTiles] = useState<AlertTileProps[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useMount(() => {
		if (alertTiles.length == 0) {
			getAllAlertDefinitions();
		}
	});

	const resetAndGetAllAlertDefinitions = () => {
		const results: AlertTileProps[] = [];
		void AlertDefinition.GetAll().then((response) => {
			response.map((x) => {
				const newTile: AlertTileProps = {
					id: x.id ?? 0,
					alertName: x.name,
					alertValue:
						x.comparison_type == 'Comparison' ? x.comparisonKeyMeasureFact! : x.value,
					trend: getTrendforAlertTile(x),
					existingAlert: x,
					refetchAlertDefinitions: resetAndGetAllAlertDefinitions,
				};
				results.push(newTile);
			});
			setIsLoading(false);
			setAlertTiles(results);
		});
	};

	const getAllAlertDefinitions = () => {
		void AlertDefinition.GetAll().then((response) => {
			response.map((x) => {
				const newTile: AlertTileProps = {
					id: x.id ?? 0,
					alertName: x.name,
					alertValue:
						x.comparison_type == 'Comparison' ? x.comparisonKeyMeasureFact! : x.value,
					trend: getTrendforAlertTile(x),
					existingAlert: x,
					refetchAlertDefinitions: resetAndGetAllAlertDefinitions,
				};
				setAlertTiles((prevTiles) => [...prevTiles, newTile]);
			});
			setIsLoading(false);
		});
	};

	return (
		<>
			{isLoading && (
				<div className="spinner-container">
					<Spinner size={'lg'} />
				</div>
			)}
			{!isLoading && (
				<div className={`alert-tile-container ${!alertTiles.length ? 'no-alerts' : ''}`}>
					{alertTiles.length
						? alertTiles.map((alertProps, index) => (
								<AlertTile
									key={index}
									{...alertProps}
								/>
						  ))
						: 'You have no alerts at this time.'}
				</div>
			)}
		</>
	);
};

export default AlertCardsHomePage;
