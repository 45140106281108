import {
	IconDefinition,
	faAward,
	faBarsProgress,
	faBuilding,
	faBuildingColumns,
	faBuildingUser,
	faCalendarClock,
	faCalendarDays,
	faCalendarImage,
	faChartArea,
	faChartLineUp,
	faChartNetwork,
	faChartPie,
	faChartScatter,
	faChartScatterBubble,
	faChartSimple,
	faChartSimpleHorizontal,
	faChartUser,
	faClipboardListCheck,
	faClockDesk,
	faCloudWord,
	faDiagramSankey,
	faDiploma,
	faDollarSign,
	faFileCertificate,
	faFilter,
	faFolderUser,
	faGauge,
	faGift,
	faHandshake,
	faHeat,
	faHourglassClock,
	faLayerGroup,
	faListCheck,
	faLollipop,
	faMoneyCheckDollar,
	faPause,
	faPeopleArrows,
	faPeopleGroup,
	faPersonArrowDownToLine,
	faPersonArrowUpFromLine,
	faShieldCheck,
	faSitemap,
	faSparkles,
	faTable,
	faTemperatureList,
	faTransporter,
	faTruckClock,
	faUserClock,
	faUserHelmetSafety,
} from '@fortawesome/pro-regular-svg-icons';

export const iconMap: Record<string, IconDefinition> = {
	'diploma': faDiploma,
	'file-certificate': faFileCertificate,
	'award': faAward,
	'dollar-sign': faDollarSign,
	'people-group': faPeopleGroup,
	'building-columns': faBuildingColumns,
	'list-check': faListCheck,
	'clipboard-list-check': faClipboardListCheck,
	'bars-progress': faBarsProgress,
	'calendar-days': faCalendarDays,
	'calendar-image': faCalendarImage,
	'people-arrows': faPeopleArrows,
	'sitemap': faSitemap,
	'building-user': faBuildingUser,
	'building': faBuilding,
	'transporter': faTransporter,
	'sparkles': faSparkles,
	'chart-user': faChartUser,
	'chart-network': faChartNetwork,
	'shield-check': faShieldCheck,
	'user-helmet-safety': faUserHelmetSafety,
	'money-check-dollar': faMoneyCheckDollar,
	'folder-user': faFolderUser,
	'person-arrow-down-to-line': faPersonArrowDownToLine,
	'person-arrow-up-from-line': faPersonArrowUpFromLine,
	'clock-desk': faClockDesk,
	'calendar-clock': faCalendarClock,
	'user-clock': faUserClock,
	'truck-clock': faTruckClock,
	'hourglass-clock': faHourglassClock,
	'pause': faPause,
	'gift': faGift,
	'handshake': faHandshake,
	'temperature-list': faTemperatureList,
	'layer-group': faLayerGroup,
	'chart-area': faChartArea,
	'chart-simple-horizontal': faChartSimpleHorizontal,
	'filter': faFilter,
	'gauge': faGauge,
	'heat': faHeat,
	'chart-line-up': faChartLineUp,
	'chart-pie': faChartPie,
	'diagram-sankey': faDiagramSankey,
	'chart-scatter': faChartScatter,
	'chart-scatter-bubble': faChartScatterBubble,
	'table': faTable,
	'cloud-word': faCloudWord,
	'chart-simple': faChartSimple,
	'lollipop': faLollipop,
};
