import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Permission } from '../../enums/permission.enum';

export type SubNavContext = {
	actions?: SubNavActions;
	tipsAndTricks?: TipsAndTricksSummary;
	report?: ReportSummary;
	reportSnapshot?: ReportSnapshotItem;
};

export type SubNavActions = {
	title: string;
	actionsList?: SubNavAction[];
};

export type SubNavAction = {
	url: string;
	title: string;
	icon: IconProp;
	permission?: Permission;
	tooltip?: string;
	prevUrlRefObject?: string;
	type?: 'create' | 'view';
	order?: number;
};

export type TipsAndTricksSummary = {
	title: string;
	allTutorialsLink?: string;
	tipsAndTricksList: SubNavTipAndTrick[];
};

export type SubNavTipAndTrick = {
	title: string;
	url: string;
	type: TipAndTrickType;
};

export enum TipAndTrickType {
	VIDEO,
	PDF,
}

export type ReportSummary = {
	title: string;
	reportList: ReportSummaryItem[];
};

export type ReportSummaryItem = {
	value: number;
	description: string;
};

export type ReportSnapshotItem = {
	title: string;
	chartId?: number;
	snapshotText: string;
	noFavToggled?: boolean;
};
