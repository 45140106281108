import axiosConfig from '../axiosConfig';
import { TDimension } from '../analytics/Dimension';
import { TDimensionAttribute } from '../analytics/DimensionAttribute';
import { TKeyMeasure } from '../analytics/KeyMeasure';
import { TUser } from './User';

export type TGroup = {
	description: string | null;
	id: number;
	dimensions: TDimension[] | number[];
	dimensionAttributes: TDimensionAttribute[] | number[];
	keyMeasures: TKeyMeasure[] | number[];
	users: TUser[] | number[];
	name: string;
	tag: string;
};

export type TNewGroup = Omit<TGroup, 'id'>;

export class Group implements TGroup {
	description: string | null;
	id: number;
	dimensions: TDimension[] | number[];
	dimensionAttributes: TDimensionAttribute[] | number[];
	keyMeasures: TKeyMeasure[] | number[];
	users: TUser[] | number[];
	name: string;
	tag: string;

	constructor(group: TGroup) {
		this.description = group.description;
		this.id = group.id;
		this.dimensions = group.dimensions;
		this.dimensionAttributes = group.dimensionAttributes;
		this.keyMeasures = group.keyMeasures;
		this.users = group.users;
		this.name = group.name;
		this.tag = group.tag;
	}

	public static Default(): TNewGroup {
		return {
			description: '',
			dimensions: [],
			dimensionAttributes: [],
			keyMeasures: [],
			users: [],
			name: '',
			tag: '',
		};
	}
	public static New(group: TNewGroup, expand: (keyof TGroup)[] = []): Promise<TGroup> {
		return axiosConfig.instance
			.post('app/group', group, { params: { expand: expand } })
			.then((res) => {
				return new Group(res.data as TGroup);
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/group/${id}`).then(() => {
			return true;
		});
	}

	public static Update(group: TGroup, expand: (keyof TGroup)[] = []): Promise<TGroup> {
		return axiosConfig.instance
			.patch('app/group', group, { params: { expand: expand } })
			.then((res) => {
				return new Group(res.data as TGroup);
			});
	}

	public static GetAll(expand: (keyof TGroup)[] = []): Promise<TGroup[]> {
		return axiosConfig.instance
			.get('app/groups', { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as TGroup[]).map((group: TGroup) => {
					return new Group(group);
				});
			});
	}
}
