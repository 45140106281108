import {
	faBell,
	faBookMedical,
	faChartSimple,
	faObjectGroup,
	faPersonChalkboard,
} from '@fortawesome/pro-regular-svg-icons';
import { SubNavContext, TipAndTrickType } from './sub-nav-context';

const AnalyzeSubNavContext: SubNavContext = {
	actions: {
		title: 'What Do You Want To Analyze Today?',
		actionsList: [
			{
				title: 'Set an Alert',
				url: '#',
				icon: faBell,
			},
			{
				title: 'Schedule a Report',
				url: '#',
				icon: faBookMedical,
			},
			{
				title: 'Create Charts',
				url: '#',
				icon: faChartSimple,
			},
			{
				title: 'Create Charts',
				url: '#',
				icon: faChartSimple,
			},
			{
				title: 'Create Dashboard',
				url: '#',
				icon: faObjectGroup,
			},
			{
				title: 'Create Presentation',
				url: '#',
				icon: faPersonChalkboard,
			},
		],
	},
	report: {
		title: 'From Your Reports (This Month)',
		reportList: [
			{
				value: 8497,
				description: 'Headcount Start',
			},
			{
				value: 42,
				description: 'New Hires',
			},
			{
				value: -61,
				description: 'Terminations',
			},
			{
				value: 8478,
				description: 'Headcount End',
			},
		],
	},
	tipsAndTricks: {
		title: 'Tips & Tricks',
		allTutorialsLink: '#',
		tipsAndTricksList: [
			{
				title: 'How to Add A Dataframe',
				url: '#',
				type: TipAndTrickType.PDF,
			},
			{
				title: 'How to Make a Chart',
				url: '#',
				type: TipAndTrickType.VIDEO,
			},
		],
	},
};

export default AnalyzeSubNavContext;
