import {
	faArrowsDownToPeople,
	faCoin,
	faDiploma,
	faFileChartPie,
	faGauge,
	faMoneyBill,
	faPeople,
	faPeopleGroup,
	faPersonChalkboard,
	faUserClock,
} from '@fortawesome/pro-light-svg-icons';
import { DiscoverContext } from '../../types/discover';

const DiscoverPageContext: DiscoverContext = {
	quickFilters: [
		{
			name: 'Diversity',
			key: 'QuickFilter_Diversity',
		},
		{
			name: 'Turnover',
			key: 'QuickFilter_Turnover',
		},
		{
			name: 'Costs',
			key: 'QuickFilter_Cost',
		},
		{
			name: 'Talent',
			key: 'QuickFilter_Talent',
		},
		{
			name: 'Payroll',
			key: 'QuickFilter_Payroll',
		},
		{
			name: 'Skills',
			key: 'QuickFilter_Skills',
		},
	],
	categories: [
		{
			id: 1,
			name: 'People',
			key: 'Category_People',
			icon: faPeople,
			questions: [
				{
					key: '1',
					question: 'What is our employee breakdown by age?',
					priority: 0,
					tags: ['people', 'employee', 'breakdown', 'age'],
					title: 'Employees by Age',
					description:
						'Employee breakdown by age depicts the total number of employees in each generational age group.',
					hasChart: true,
					chartId: '21',
				},
				{
					chartId: '22',
					description:
						'A good way to determine concerning levels of turnover is to look at past trends as well as comparisons to industry benchmarks.',
					hasChart: true,
					key: '2',
					priority: 8,
					question: 'Is our turnover at concerning levels?',
					tags: ['people', 'turnover', 'concern', 'level'],
				},

				{
					key: '3',
					question: 'How is the recruitment process affecting retention?',
					priority: 1,
					tags: ['people', 'recruitment', 'retention'],
					description: 'Factors such as onboarding, .',
				},
				{
					key: '4',
					question: 'What are the contributing factors to turnover?',
					priority: 3,
					tags: ['people', 'turnover', 'reason', 'employee'],
					description:
						'Understanding where turnover is occurring is important  But understanding why turnover is occurring is paramount. Contributing factors to turnover predicts the highest weighted contributors',
					hasChart: true,
					chartId: '23',
				},
				{
					key: '5',
					question: 'What is the cost of turnover to the business?',
					priority: 3,
					tags: ['people', 'turnover', 'costs'],
					description:
						'Cost of turnover is an employee replacement cost calculated by applying weight multipliers (e.g. 0.5x, 1x, 2x, etc) to the median salary of jobs now vacant as a result of turnover ',
					hasChart: true,
					chartId: '25',
				},
				{
					key: '6',
					question: 'What is the average employee tenure?',
					priority: 7,
					tags: ['people', 'average', 'employee', 'tenure'],
				},
				{
					key: '8',
					question: 'What is the percent of employees that are high performers?',
					priority: 9,
					tags: ['people', 'employee', 'performance'],
				},
				{
					key: '9',
					question: 'How does recognition affect retention?',
					priority: 10,
					tags: ['people', 'retention', 'recognition'],
				},
				{
					key: '10',
					question: 'How does voluntary turnover compare to involuntary turnover?',
					priority: 10,
					tags: ['people', 'retention', 'turnover'],
				},
				{
					key: '11',
					question: 'Which groups of employees are at greater risks of leaving?',
					priority: 10,
					tags: ['people', 'retention', 'turnover'],
				},
				{
					key: '12',
					question: 'Are gender differences observed in rewards?',
					priority: 13,
					tags: ['people', 'gender', 'difference', 'rewards'],
				},
				{
					key: '13',
					question: 'Are leave balances in a healthy state?',
					priority: 14,
					tags: ['people', 'leave', 'health'],
				},
			],
		},
		{
			id: 2,
			name: 'Payroll',
			key: 'Category_Payroll',
			icon: faMoneyBill,
			questions: [
				{
					key: '1',
					question: 'What is our average monthly payroll?',
					priority: 1,
					tags: ['employee', 'payroll', 'costs'],
				},
				{
					key: '2',
					question:
						'Where are our monthly payroll costs the highest across the organization?',
					priority: 2,
					tags: ['employee', 'payroll', 'costs', 'highest'],
				},
				{
					key: '3',
					question: 'How is overtime affecting our monthly payroll costs?',
					priority: 3,
					tags: ['employee', 'payroll', 'costs', 'overtime'],
				},
				{
					key: '54',
					question: 'How are employee movements affecting pay rates?',
					priority: 6,
					tags: ['people', 'employee', 'pay', 'rate'],
				},
			],
		},
		{
			id: 3,
			name: 'Performance',
			key: 'Category_Performance',
			icon: faGauge,
			questions: [
				{
					key: '1',
					question: 'What is the percent of employees that are high performers?',
					priority: 1,
					tags: ['people', 'employee', 'performance'],
				},
				{
					key: '2',
					question: 'What is the percent of employees that are low performers?',
					priority: 2,
					tags: ['people', 'employee', 'performance'],
				},
			],
		},
		{
			id: 4,
			name: 'Recruiting',
			key: 'Category_Recruiting',
			icon: faPersonChalkboard,
			questions: [
				{
					key: '10',
					question: 'Is the hiring process efficient?',
					priority: 11,
					tags: ['recruit', 'hire', 'efficient'],
				},
				{
					key: '11',
					question: 'What are the significant drivers of our time to fill positions?',
					priority: 12,
					tags: ['recruit', 'driver', 'fill', 'positions'],
				},
				{
					key: '11',
					question: 'What is our cost per hire?',
					priority: 12,
					tags: ['hiring', 'costs', 'positions'],
				},
				{
					key: '11',
					question: 'What is our revenue per hire?',
					priority: 12,
					tags: ['hiring', 'revenue', 'positions'],
				},
			],
		},
		{
			id: 5,
			name: 'Talent',
			key: 'Category_Talent',
			icon: faUserClock,
			questions: [
				{
					key: '10',
					question: 'What skills and capabilities are most critical going forward?',
					priority: 11,
					tags: ['people', 'skills', 'talent'],
				},
				{
					key: '11',
					question: 'How do we develop the next generation of leaders?',
					priority: 12,
					tags: ['people', 'leaders', 'development'],
				},
				{
					key: '11',
					question: 'What are the optimal models for continuous learning?',
					priority: 12,
					tags: ['people', 'leaders', 'development'],
				},
			],
		},
		{
			id: 6,
			name: 'Compensation',
			key: 'Category_Compensation',
			icon: faCoin,
			questions: [],
		},
		{
			id: 7,
			name: 'Recognition',
			key: 'Category_Recognition',
			icon: faDiploma,
			questions: [],
		},
		{
			id: 8,
			name: 'Diversity',
			key: 'Category_Diversity',
			icon: faPeopleGroup,
			questions: [
				{
					key: '3',
					question: 'How is diversity trending in the organization?',
					priority: 2,
					tags: ['people', 'diversity', 'trend'],
				},
				{
					key: '3',
					question: 'What is the ethnicity composite of the organization?',
					priority: 2,
					tags: ['race', 'diversity', 'ethnicity'],
				},
				{
					key: '3',
					question: 'What is the gender composite of the organization?',
					priority: 2,
					tags: ['gender', 'diversity', 'ethnicity'],
				},
			],
		},
		{
			id: 9,
			name: 'Skill Gaps',
			key: 'Category_Skills',
			icon: faArrowsDownToPeople,
			questions: [],
		},
		{
			id: 10,
			name: 'TIme & Labor',
			key: 'Category_Time',
			icon: faFileChartPie,
			questions: [],
		},
	],
};

export default DiscoverPageContext;
