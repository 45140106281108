import axiosConfig from '../axiosConfig';

export type TUserDimensionFilter = {
	id: number;
	username: string;
	user: number;
	dimension: string;
	value: string;
	logic_group: number;
};

export type TNewUserDimensionFilter = Omit<TUserDimensionFilter, 'id'>;

export class UserDimensionFilter implements TUserDimensionFilter {
	id: number;
	username: string;
	user: number;
	dimension: string;
	value: string;
	logic_group: number;

	constructor(udf: TUserDimensionFilter) {
		this.id = udf.id;
		this.user = udf.user;
		this.username = udf.username;
		this.dimension = udf.dimension;
		this.value = udf.value;
		this.logic_group = udf.logic_group;
	}

	public static Default(): TNewUserDimensionFilter {
		return {
			dimension: '',
			value: '',
			logic_group: 0,
			user: 0,
			username: '',
		};
	}

	public static GetAll(
		userId: number,
		expand: (keyof TUserDimensionFilter)[] = []
	): Promise<TUserDimensionFilter[]> {
		// This requires multiple return endpoints to end in 's' in the API
		return axiosConfig.instance
			.get(`app/userdimensions/${userId}`, { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as TUserDimensionFilter[]).map((entity) => {
					return entity;
				});
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/userdimension/` + String(id)).then(() => {
			return true;
		});
	}

	public static New(entity: TUserDimensionFilter): Promise<TUserDimensionFilter> {
		return axiosConfig.instance.post('app/userdimension', entity).then((res) => {
			return res.data as TUserDimensionFilter;
		});
	}

	public static Update(entity: TUserDimensionFilter): Promise<TUserDimensionFilter> {
		return axiosConfig.instance.patch('app/userdimension', entity).then((res) => {
			return res.data as TUserDimensionFilter;
		});
	}
}
