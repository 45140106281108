import axiosConfig from '../axiosConfig';

export type TUnitType = {
	code: string;
	created_at: number;
	creator: unknown;
	deaggregation_strategy: string;
	function_dimension_series: UnitTypeFunctionEnum;
	function_time_series: UnitTypeFunctionEnum;
	id: number;
	name: string;
	precision: number | null;
	prefix: string | null;
	suffix: string | null;
	updated_at: number;
	updater: unknown;
};

export enum UnitTypeFunctionEnum {
	AVERAGE = 'avg',
	COUNT = 'cnt',
	SUM = 'sum',
	MIN = 'min',
	MAX = 'max',
	STDDEV = 'std',
}

export const UnitTypeFunctionMap = {
	[UnitTypeFunctionEnum.AVERAGE]: 'Average',
	[UnitTypeFunctionEnum.COUNT]: 'Count',
	[UnitTypeFunctionEnum.SUM]: 'Sum',
	[UnitTypeFunctionEnum.MIN]: 'Minimum',
	[UnitTypeFunctionEnum.MAX]: 'Maximum',
	[UnitTypeFunctionEnum.STDDEV]: 'Standard Deviation',
};

export const UnitTypeFunctionOptions = [
	{ value: 'Average', key: UnitTypeFunctionEnum.AVERAGE },
	{ value: 'Count', key: UnitTypeFunctionEnum.COUNT },
	{ value: 'Minimum', key: UnitTypeFunctionEnum.MIN },
	{ value: 'Maximum', key: UnitTypeFunctionEnum.MAX },
	{ value: 'Sum', key: UnitTypeFunctionEnum.SUM },
	{ value: 'Standard Deviation', key: UnitTypeFunctionEnum.STDDEV },
];

export type TNewUnitType = Omit<
	UnitType,
	'id' | 'creator' | 'updater' | 'created_at' | 'updated_at'
>;

export class UnitType implements TUnitType {
	code: string;
	created_at: number;
	creator: unknown;
	deaggregation_strategy: string;
	function_dimension_series: UnitTypeFunctionEnum;
	function_time_series: UnitTypeFunctionEnum;
	id: number;
	name: string;
	precision: number | null;
	prefix: string | null;
	suffix: string | null;
	updated_at: number;
	updater: unknown;

	constructor(unitType: TUnitType) {
		this.code = unitType.code;
		this.created_at = unitType.created_at;
		this.creator = unitType.creator;
		this.deaggregation_strategy = unitType.deaggregation_strategy;
		this.function_dimension_series = unitType.function_dimension_series;
		this.function_time_series = unitType.function_time_series;
		this.id = unitType.id;
		this.name = unitType.name;
		this.precision = unitType.precision;
		this.prefix = unitType.prefix;
		this.suffix = unitType.suffix;
		this.updated_at = unitType.updated_at;
		this.updater = unitType.updater;
	}

	public static Default(): TNewUnitType {
		return {
			code: '',
			deaggregation_strategy: '',
			function_dimension_series: UnitTypeFunctionEnum.AVERAGE,
			function_time_series: UnitTypeFunctionEnum.AVERAGE,
			name: '',
			precision: null,
			prefix: null,
			suffix: null,
		};
	}

	public static GetAll(): Promise<TUnitType[]> {
		return axiosConfig.instance.get('app/unit-types').then((res) => {
			return ((res.data || []) as UnitType[]).map((unitType: TUnitType) => {
				return new UnitType(unitType);
			});
		});
	}

	public static Get(id: number): Promise<TUnitType> {
		return axiosConfig.instance.get(`app/unit-type/${id}`).then((res) => {
			return new UnitType(res.data as TUnitType);
		});
	}

	public static New(unitType: TNewUnitType): Promise<TUnitType> {
		return axiosConfig.instance.post('app/unit-type', unitType).then((res) => {
			return new UnitType(res.data as TUnitType);
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/unit-type/${id}`).then(() => {
			return true;
		});
	}

	public static Update(unitType: TUnitType): Promise<TUnitType> {
		return axiosConfig.instance.patch('app/unit-type', unitType).then((res) => {
			return new UnitType(res.data as TUnitType);
		});
	}
}
