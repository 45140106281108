import React, { useEffect, useState } from 'react';
import { DateRange } from '../../api/date-period-selector/DateRange';

import { Bullseye, Button, Grid, GridItem, Spinner } from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { DatePeriodFormatter } from './DatePeriodFormatter';
import { timestampToMMDDYYYY } from '../../utilities';
import { TDateRange } from '../../api/types/TDateRange';

type PeriodProps = {
	datePeriods: TDateRange[];
	updateSelectedDates: (selectedDateRange: TDateRange) => (TDateRange | undefined)[];
	selectedRange: [TDateRange | undefined, TDateRange | undefined];
};
const MyPeriodButtons = ({ datePeriods, updateSelectedDates, selectedRange }: PeriodProps) => {
	const [selectedDateRangeIds, setSelectedDateRangeIds] = useState<(number | undefined)[]>([
		0, 0,
	]);

	const [dateRange, setDateRangeData] = useState(datePeriods);

	const getPage = (periods: TDateRange[], selectedRange: TDateRange) => {
		let index = -1;

		for (let i = 0; i < periods.length; i++) {
			const period = periods[i];

			const periodKeys = Object.keys(period)
				.filter((e) => e !== 'isSelected')
				.sort();
			const rangeKeys = Object.keys(selectedRange)
				.filter((e) => e !== 'isSelected')
				.sort();

			if (periodKeys.length !== rangeKeys.length) {
				break;
			} else {
				const areEqual = periodKeys.every((key, index) => {
					const periodObjValue = period[key as keyof TDateRange];
					const rangeObjValue = selectedRange[rangeKeys[index] as keyof TDateRange];

					return periodObjValue === rangeObjValue;
				});

				if (areEqual) {
					index = i;
					break;
				}
			}
		}

		return index;
	};

	let page = 0;
	if (selectedRange[0]) {
		page = getPage(datePeriods, selectedRange[0]);
	}

	const [startIndex, setStartIndex] = useState(page ?? 0);

	const rows = [];
	for (let i = startIndex; i < startIndex + 12; i += 3) {
		const row = dateRange.slice(i, i + 3);
		rows.push(row);
	}
	const handlePrevClick = () => {
		if (startIndex > 0) {
			setStartIndex(startIndex - 12);
		}
	};

	const handleNextClick = () => {
		if (startIndex < dateRange.length - 12) {
			setStartIndex(startIndex + 12);
		}
	};
	const dateRangeClick = (currentDate: DateRange) => {
		const selectedDateRanges = updateSelectedDates(currentDate);
		setSelectedDateRangeIds(selectedDateRanges.map((d) => d?.id));
	};

	useEffect(() => {
		const newDateRangeData = dateRange.map((dr) => {
			if (selectedDateRangeIds) {
				const startId = selectedDateRangeIds[0];
				const endId = selectedDateRangeIds[1];
				dr.isSelected = !!(startId && dr.id >= startId && endId && dr.id <= endId);
			}
			return dr;
		});
		setDateRangeData(newDateRangeData);
	}, [setSelectedDateRangeIds, setDateRangeData]);

	if (!dateRange.length) {
		return (
			<Bullseye style={{ paddingTop: '2rem' }}>
				<div>
					<Spinner
						isSVG
						size={'xl'}
					/>
				</div>
			</Bullseye>
		);
	} else {
		return (
			<div>
				<br />
				<Grid span={12}>
					<GridItem span={1}>
						<button
							onClick={handlePrevClick}
							disabled={startIndex === 0}
							className="period-selector-nav-btn"
						>
							<FontAwesomeIcon icon={faAngleLeft} />
						</button>
					</GridItem>
					<GridItem span={10}>
						<div className="carousel">
							<div className="button-row-container">
								{rows.map((row, rowIndex) => (
									<div
										className="button-row"
										key={rowIndex}
									>
										{row.map((currentDate) => (
											<div
												className={`date-button ${
													selectedRange[0] &&
													currentDate.id >= selectedRange[0]?.id &&
													selectedRange[1] &&
													currentDate.id <= selectedRange[1]?.id
														? 'selected'
														: ''
												}`}
												key={currentDate.id}
												onClick={() => dateRangeClick(currentDate)}
											>
												<Button variant="secondary">
													<strong className="font-size-sm">
														{currentDate.title ??
															DatePeriodFormatter(
																currentDate.begin_date,
																currentDate.period
															)}
													</strong>
													<br />
													<label className="font-size-sm">
														{timestampToMMDDYYYY(
															currentDate.begin_date
														)}{' '}
														-{' '}
														{timestampToMMDDYYYY(currentDate.end_date)}
													</label>
												</Button>
											</div>
										))}
									</div>
								))}
							</div>
						</div>
					</GridItem>
					<GridItem span={1}>
						<button
							onClick={handleNextClick}
							disabled={startIndex >= dateRange.length - 12}
							className="period-selector-nav-btn nav-btn-right"
						>
							<FontAwesomeIcon icon={faAngleRight} />
						</button>
					</GridItem>
				</Grid>
			</div>
		);
	}
};
export default MyPeriodButtons;
