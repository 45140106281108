import React, { useState } from 'react';
import { Select, TreeView, TreeViewDataItem } from '@patternfly/react-core';

import FolderIcon from '@patternfly/react-icons/dist/esm/icons/folder-icon';
import FolderOpenIcon from '@patternfly/react-icons/dist/esm/icons/folder-open-icon';

export type TreeViewSelectProps = {
	data: TreeViewDataItem[];
	hasFolderIcon?: boolean;
	onToggle?: () => void;
	placeHolderText?: string;
	selectedItem?: TreeViewDataItem;
	treeItemsExpanded?: boolean;
	isDisabled?: boolean;
	onSelect?: (
		event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem,
		parentItem: TreeViewDataItem
	) => void;
};

function TreeViewSelect(props: TreeViewSelectProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState<TreeViewDataItem | undefined>();

	const onToggle = (): void => {
		setIsOpen(!isOpen);
		if (props.onToggle) {
			props.onToggle();
		}
	};

	const onSelectTreeItem = (
		event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem,
		parentItem: TreeViewDataItem
	): void => {
		setSelectedItem(item);
		if (props.onSelect) {
			props.onSelect(event, item, parentItem);
		}
		onToggle();
	};

	const tree = (
		<TreeView
			data={props.data}
			icon={props.hasFolderIcon && <FolderIcon />}
			expandedIcon={props.hasFolderIcon && <FolderOpenIcon />}
			allExpanded={props.treeItemsExpanded ?? false}
			onSelect={onSelectTreeItem}
		/>
	);

	return (
		<Select
			isOpen={isOpen}
			menuAppendTo="parent"
			onToggle={onToggle}
			placeholderText={selectedItem?.name ?? props.placeHolderText}
			selections={props.selectedItem?.id}
			customContent={tree}
			isDisabled={props.isDisabled}
		/>
	);
}

export default TreeViewSelect;
