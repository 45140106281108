import { InternalAxiosRequestConfig } from 'axios';

export function bannedFieldsInterceptorSuccess(request: InternalAxiosRequestConfig) {
	if (request.method === 'put' || request.method === 'patch') {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const data = request.data;
		const bannedFields = ['created_at', 'updated_at', 'creator', 'updater'];

		if (typeof data !== 'object' || data === null) return request;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		for (const field of Object.keys(data)) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			const value: unknown = data[field];

			if (bannedFields.includes(field)) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				delete data[field];
				continue;
			}

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			data[field] = value;
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		return { ...request, data: data };
	}

	return request;
}

export function bannedFieldsInterceptorError(error: unknown) {
	return Promise.reject(error);
}
