import React, { ReactNode } from 'react';

type Props<T> = {
	data: T;
	children: ReactNode;
	onSetPotentialDropZones?: (data?: T) => void;
	onDragStarted: () => void;
};

const DraggableMenuItem = <T,>(props: Props<T>) => {
	const { data, children, onSetPotentialDropZones, onDragStarted } = props;
	return (
		<div
			className="droppable-menu-item"
			draggable={true}
			unselectable="off"
			onDragStart={(e) => {
				e.dataTransfer.setData('text/plain', '');
				e.dataTransfer.setData('dataBuilderGridItem', JSON.stringify(data));
				onSetPotentialDropZones && onSetPotentialDropZones(data);
				onDragStarted();
			}}
			onDragEnd={(e) => {
				onSetPotentialDropZones && onSetPotentialDropZones();
			}}
		>
			{children}
		</div>
	);
};

export default DraggableMenuItem;
