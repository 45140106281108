import axiosConfig from '../axiosConfig';
import { TDimension } from './Dimension';
import { TUnitType } from './UnitType';
import { TPeriod } from './Period';
import { TKeyMeasureFact } from './KeyMeasureFact';
import { GenericResponse } from '../types';

export enum KeyMeasureStatusEnum {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	LOADING = 'LOADING',
}

export const KeyMeasureStatusOptions = [
	{ value: 'Active', key: 'ACTIVE' },
	{ value: 'Inactive', key: 'INACTIVE' },
	{ value: 'Loading', key: 'LOADING' },
];

export const KeyMeasureStatusOptionsMap: { [key in keyof typeof KeyMeasureStatusEnum]: string } = {
	[KeyMeasureStatusEnum.ACTIVE]: 'Active',
	[KeyMeasureStatusEnum.INACTIVE]: 'Inactive',
	[KeyMeasureStatusEnum.LOADING]: 'Loading',
};

export type TKeyMeasure = {
	alertDefinitions: unknown[];
	code: string;
	control_variable_max: number | null;
	created_at: number;
	creator: unknown;
	description: string | null;
	display_name: string | null;
	groups: unknown[];
	id: number;
	dimensions: TDimension[] | number[];
	keyMeasureFacts: TKeyMeasureFact[];
	last_loaded: number | null;
	name: string;
	partitioned: boolean; // Default false
	period: TPeriod | number;
	recalc_percentage: boolean; // Default false
	status: KeyMeasureStatusEnum; // Default ACTIVE
	unitType: TUnitType | number;
	updated_at: number;
	updater: unknown;
	type?: string;
};

export type TNewKeyMeasure = Omit<
	TKeyMeasure,
	'id' | 'updater' | 'updated_at' | 'creator' | 'created_at'
>;

export class KeyMeasure implements TKeyMeasure {
	alertDefinitions: unknown[];
	code: string;
	control_variable_max: number | null;
	created_at: number;
	creator: unknown;
	description: string | null;
	display_name: string | null;
	groups: unknown[];
	id: number;
	dimensions: TDimension[] | number[];
	keyMeasureFacts: TKeyMeasureFact[];
	last_loaded: number | null;
	name: string;
	partitioned: boolean;
	period: TPeriod | number;
	recalc_percentage: boolean;
	status: KeyMeasureStatusEnum;
	unitType: number | TUnitType;
	updated_at: number;
	updater: unknown;
	type?: string;

	constructor(keyMeasure: TKeyMeasure) {
		this.alertDefinitions = keyMeasure.alertDefinitions;
		this.code = keyMeasure.code;
		this.control_variable_max = keyMeasure.control_variable_max;
		this.created_at = keyMeasure.created_at;
		this.creator = keyMeasure.creator;
		this.description = keyMeasure.description;
		this.display_name = keyMeasure.display_name;
		this.groups = keyMeasure.groups;
		this.id = keyMeasure.id;
		this.dimensions = keyMeasure.dimensions;
		this.keyMeasureFacts = keyMeasure.keyMeasureFacts;
		this.last_loaded = keyMeasure.last_loaded;
		this.name = keyMeasure.name;
		this.partitioned = keyMeasure.partitioned;
		this.period = keyMeasure.period;
		this.recalc_percentage = keyMeasure.recalc_percentage;
		this.status = keyMeasure.status;
		this.unitType = keyMeasure.unitType;
		this.updated_at = keyMeasure.updated_at;
		this.updater = keyMeasure.updater;
		this.type = keyMeasure.type;
	}

	public static Default(): TNewKeyMeasure {
		return {
			last_loaded: 0,
			alertDefinitions: [],
			code: '',
			control_variable_max: null,
			description: null,
			display_name: null,
			groups: [],
			dimensions: [],
			keyMeasureFacts: [],
			name: '',
			partitioned: false,
			period: 0,
			recalc_percentage: false,
			status: KeyMeasureStatusEnum.ACTIVE,
			unitType: 1,
		};
	}
	public static New(
		keyMeasure: TNewKeyMeasure,
		expand: (keyof TKeyMeasure)[] = []
	): Promise<TKeyMeasure> {
		return axiosConfig.instance
			.post('app/key-measure', keyMeasure, { params: { expand: expand } })
			.then((res) => {
				return new KeyMeasure(res.data as TKeyMeasure);
			});
	}

	public static Delete(id: number): Promise<GenericResponse<any>> {
		return axiosConfig.instance
			.delete<GenericResponse<any>>(`app/key-measure/${id}`)
			.then((res) => {
				return res.data;
			});
	}

	public static Update(
		keyMeasure: TKeyMeasure,
		expand: (keyof TKeyMeasure)[] = []
	): Promise<TKeyMeasure> {
		return axiosConfig.instance
			.patch('app/key-measure', keyMeasure, { params: { expand: expand } })
			.then((res) => {
				return new KeyMeasure(res.data as TKeyMeasure);
			});
	}

	public static GetAll(expand: (keyof TKeyMeasure)[] = []): Promise<TKeyMeasure[]> {
		return axiosConfig.instance
			.get('app/key-measures', { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as TKeyMeasure[]).map((keyMeasure: TKeyMeasure) => {
					return new KeyMeasure(keyMeasure);
				});
			});
	}

	public static Get(id: number, expand: (keyof TKeyMeasure)[] = []): Promise<TKeyMeasure> {
		return axiosConfig.instance
			.get(`app/key-measure/${id}`, { params: { expand: expand } })
			.then((res) => {
				return new KeyMeasure(res.data as TKeyMeasure);
			});
	}
}
