import axiosConfig from '../axiosConfig';

export enum DatabaseDriverEnum {
	PDO_MYSQL = 'pdo_mysql',
	PDO_OCI = 'pdo_oci',
	PDO_PGSQL = 'pdo_pgsql',
	PDO_SQLSRV = 'pdo_sqlsrv',
	SQLSRV = 'sqlsrv',
}

export const DatabaseDriverOptions = [
	{ value: 'MySQL', key: 'pdo_mysql' },
	{ value: 'Oracle', key: 'pdo_oci' },
	{ value: 'PostgresSQL', key: 'pdo_pgsql' },
	{ value: 'Microsoft SQL Server', key: 'pdo_sqlsrv' },
	{ value: 'Microsoft SQL Server - PDO', key: 'sqlsrv' },
];

export const DatabaseDriverOptionsMap = {
	[DatabaseDriverEnum.PDO_MYSQL]: 'MySQL',
	[DatabaseDriverEnum.PDO_OCI]: 'Oracle',
	[DatabaseDriverEnum.PDO_PGSQL]: 'PostgresSQL',
	[DatabaseDriverEnum.PDO_SQLSRV]: 'Microsoft SQL Server',
	[DatabaseDriverEnum.SQLSRV]: 'Microsoft SQL Server - PDO',
};

export type TETLSourceDatabase = {
	dbName: string;
	driverType: DatabaseDriverEnum;
	host: string;
	id: number;
	port: number;
	password: string;
	username: string;
};

export type TNewETLSourceDatabase = Omit<TETLSourceDatabase, 'id'>;

export class ETLSourceDatabase implements TETLSourceDatabase {
	dbName: string;
	driverType: DatabaseDriverEnum;
	host: string;
	id: number;
	port: number;
	password: string;
	username: string;

	constructor(etlSource: TETLSourceDatabase) {
		this.dbName = etlSource.dbName;
		this.driverType = etlSource.driverType;
		this.host = etlSource.host;
		this.port = etlSource.port;
		this.id = etlSource.id;
		this.password = etlSource.password;
		this.username = etlSource.username;
	}

	public static Default(): TNewETLSourceDatabase {
		return {
			dbName: '',
			driverType: DatabaseDriverEnum.PDO_MYSQL,
			host: '',
			password: '',
			username: '',
			port: 3306,
		};
	}

	public static New(etlSource: TNewETLSourceDatabase): Promise<TETLSourceDatabase> {
		return axiosConfig.instance.post('app/etl-source-database', etlSource).then((res) => {
			return new ETLSourceDatabase(res.data as TETLSourceDatabase);
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/etl-source-database/${id}`).then(() => {
			return true;
		});
	}

	public static Update(etlSource: TETLSourceDatabase): Promise<TETLSourceDatabase> {
		return axiosConfig.instance.patch('app/etl-source-database', etlSource).then((res) => {
			return new ETLSourceDatabase(res.data as TETLSourceDatabase);
		});
	}

	public static GetAll(): Promise<TETLSourceDatabase[]> {
		return axiosConfig.instance.get('app/etl-source-databases').then((res) => {
			return ((res.data || []) as TETLSourceDatabase[]).map(
				(etlSource: TETLSourceDatabase) => {
					return new ETLSourceDatabase(etlSource);
				}
			);
		});
	}
}
