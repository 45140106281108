import axiosConfig from '../axiosConfig';
import { TFolder } from '../types/folder';
import { GenericResponse } from '../types/generic-response';

export type TNewFolder = {
	name: string;
	parent?: TFolder | number;
	type?: string;
};

export class Folder implements TFolder {
	id: number;
	name: string;
	parent?: TFolder | number;
	type?: string;

	constructor(folder: TFolder) {
		this.id = folder.id;
		this.name = folder.name;
		this.parent = folder.parent;
		this.type = folder.type;
	}

	public static NewFolder(folder: TNewFolder): Promise<TFolder> {
		return axiosConfig.instance.post('app/folder', folder).then((res) => {
			return new Folder(res.data as TFolder);
		});
	}

	public static UpdateFolder(folder: TFolder): Promise<TFolder> {
		return axiosConfig.instance.patch<TFolder>('app/folder', folder).then((res) => {
			return res.data;
		});
	}

	public static DeleteFolder(id: number): Promise<GenericResponse<any>> {
		return axiosConfig.instance.delete<GenericResponse<any>>(`app/folder/${id}`).then((res) => {
			return res.data;
		});
	}

	public static GetAll(expand: (keyof TFolder)[] = []): Promise<TFolder[]> {
		return axiosConfig.instance
			.get<TFolder[]>('app/folders', { params: { expand } })
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}
}
