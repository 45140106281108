import { TUnitType } from '../api/analytics/UnitType';
import { HeatMapSeriesDataTypes } from '../types/charts/multi-series-data-options';
import { IIndexable } from '../types/general';
import { calculatePercentage, ChartDataResponse } from './chart.helper';

type Coordinate = {
	x: number;
	y: number;
	value: number | null; // Assuming value can be a number or null
};

export const formatDefaultMultiSeriesData = (
	chartData: IIndexable[],
	unitType?: TUnitType,
	numberOfDecimals?: number,
	grandTotal?: [{ [key: string]: number }],
	showPercentages?: boolean
) => {
	let response: ChartDataResponse = newChartResponseObject();

	response = populateAxisArrays(chartData, response, showPercentages, grandTotal);

	if (chartData) {
		response.seriesData = response.yAxisArray.map((item) => {
			const groupedItems = chartData.filter((data) => {
				const keys = Object.keys(data);
				if (data[keys[1]] === item) {
					return true;
				}

				return false;
			});

			return {
				name: item,
				data: response.xAxisArray.map((xAxisItem) => {
					const valueItem = groupedItems.find((groupItem) => {
						const keys = Object.keys(groupItem);
						if (groupItem[keys[0]] === xAxisItem) {
							return true;
						}

						return false;
					});

					if (valueItem) {
						const keys = Object.keys(valueItem);
						const percentage = calculatePercentage(
							keys[2]?.replace(/([A-Z])/g, ' $1').trim(),
							grandTotal,
							parseFloat(valueItem[keys[2]] as string)
						);

						let plotValue = !showPercentages
							? parseFloat(valueItem[keys[2]] as string)
							: percentage ?? 0;

						if (!showPercentages && unitType?.code === 'PCT') {
							plotValue = plotValue * 100;
						}
						return parseFloat(plotValue.toFixed(numberOfDecimals));
					} else {
						return 0;
					}
				}),
			};
		});

		// Create a new array to mimic heatmapData structure used for on chart click for multi-series chart
		const lookupData = [];

		// Add xAxisArray as the first row
		lookupData.push(response.xAxisArray);

		// Split valueArray into chunks based on the length of xAxisArray
		const chunkSize = Math.ceil(response.valueArray.length / response.xAxisArray.length);

		for (let i = 0; i < response.valueArray.length; i += chunkSize) {
			const chunk = response.valueArray.slice(i, i + chunkSize);
			lookupData.push(chunk);
		}

		response.lookupData = lookupData;
	}

	return response;
};

export const formatClusteredBarData = (
	chartData: IIndexable[],
	unitType?: TUnitType,
	numberOfDecimals?: number,
	grandTotal?: [{ [key: string]: number }],
	showPercentages?: boolean
): ChartDataResponse => {
	let response: ChartDataResponse = newChartResponseObject();

	response = populateAxisArrays(chartData, response, showPercentages, grandTotal);

	if (chartData) {
		response.xAxisArray.map((xAxis) => {
			const yAxisValues: string[] = [
				...new Set(
					chartData.map((value: IIndexable) => value[Object.keys(value)[1]] as string)
				),
			];
			yAxisValues.map((value: string) => {
				const xyValue: IIndexable | undefined = chartData.find(
					(cd: IIndexable<string>) =>
						cd[Object.keys(cd)[0]] === xAxis && cd[Object.keys(cd)[1]] === value
				);
				let actualValue = null;
				if (xyValue) {
					const percentage = calculatePercentage(
						Object.keys(xyValue)[2]
							?.replace(/([A-Z])/g, ' $1')
							.trim(),
						grandTotal,
						parseFloat(xyValue[Object.keys(xyValue)[2]] as string)
					);

					actualValue = !showPercentages
						? parseFloat(xyValue[Object.keys(xyValue)[2]] as string)
						: percentage ?? 0;

					if (!showPercentages && unitType?.code === 'PCT') {
						actualValue = actualValue * 100;
					}
				}

				const seriesDataIndex = response.seriesData.findIndex((d) => d.name === value);
				actualValue = actualValue
					? parseFloat(actualValue.toFixed(numberOfDecimals))
					: actualValue;
				if (seriesDataIndex >= 0) {
					// Does exist
					(response.seriesData[seriesDataIndex].data as (number | null)[]).push(
						actualValue
					);
				} else {
					// Does not exist
					response.seriesData.push({
						name: value,
						data: [actualValue],
					});
				}
			});
		});
	}
	response.yAxisArray = [];

	return response;
};

export const formatClusteredColumnData = (
	chartData: IIndexable[],
	unitType?: TUnitType,
	numberOfDecimals?: number,
	grandTotal?: [{ [key: string]: number }],
	showPercentages?: boolean
) => {
	let response: ChartDataResponse = newChartResponseObject();

	response = populateAxisArrays(chartData, response, showPercentages, grandTotal);

	if (chartData) {
		response.xAxisArray.map((xAxis) => {
			const yAxisValues: string[] = [
				...new Set(
					chartData.map((value: IIndexable) => value[Object.keys(value)[1]] as string)
				),
			] as string[];
			yAxisValues.map((value) => {
				const xyValue: IIndexable | undefined = chartData.find(
					(cd: IIndexable<string>) =>
						cd[Object.keys(cd)[0]] === xAxis && cd[Object.keys(cd)[1]] === value
				);
				let actualValue = null;
				if (xyValue) {
					const percentage = calculatePercentage(
						Object.keys(xyValue)[2]
							?.replace(/([A-Z])/g, ' $1')
							.trim(),
						grandTotal,
						parseFloat(xyValue[Object.keys(xyValue)[2]] as string)
					);

					actualValue = !showPercentages
						? parseFloat(xyValue[Object.keys(xyValue)[2]] as string)
						: percentage ?? 0;

					if (!showPercentages && unitType?.code === 'PCT') {
						actualValue = actualValue * 100;
					}
				}

				const seriesDataIndex = response.seriesData.findIndex((d) => d.name === value);
				actualValue = actualValue
					? parseFloat(actualValue.toFixed(numberOfDecimals))
					: actualValue;
				if (seriesDataIndex >= 0) {
					// Does exist
					(response.seriesData[seriesDataIndex].data as (number | null)[]).push(
						actualValue
					);
				} else {
					// Does not exist
					response.seriesData.push({
						name: value,
						data: [actualValue],
					});
				}
			});
		});
	}
	response.yAxisArray = [];

	return response;
};

export const formatHeatmapData = (
	chartData: IIndexable[],
	unitType?: TUnitType,
	numberOfDecimals?: number,
	grandTotal?: [{ [key: string]: number }],
	showPercentages?: boolean
) => {
	let response: ChartDataResponse = newChartResponseObject();

	response = populateAxisArrays(chartData, response, showPercentages, grandTotal);

	if (chartData) {
		let index = 0;
		const finalArray: IIndexable[] = [];

		chartData.forEach((value: IIndexable) => {
			const percentage = calculatePercentage(
				Object.keys(value)[2]
					?.replace(/([A-Z])/g, ' $1')
					.trim(),
				grandTotal,
				parseFloat(value[Object.keys(value)[2]] as string)
			);

			let plotValue = !showPercentages
				? parseFloat(value[Object.keys(value)[2]] as string)
				: percentage ?? 0;

			if (!showPercentages && unitType?.code === 'PCT') {
				plotValue = plotValue * 100;
			}

			finalArray.push([
				response.xAxisArray.indexOf(value[Object.keys(value)[0]] as string),
				response.yAxisArray.indexOf(value[Object.keys(value)[1]] as string),
				parseFloat(plotValue.toFixed(numberOfDecimals)),
			]);

			if (index < response.yAxisArray.length - 1) {
				index = index + 1;
			} else {
				index = 0;
			}
		});

		// Create a map to store existing coordinates
		const coordinatesMap = new Map<string, Coordinate>();

		// Populate the map with existing coordinates from the finalArray
		finalArray.forEach((coordinate) => {
			const key = `${coordinate[0] as number},${coordinate[1] as number}`;
			const existingCoordinate = {
				x: coordinate[0] as number,
				y: coordinate[1] as number,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
				value: parseFloat(coordinate[2].toFixed(numberOfDecimals)),
			};

			coordinatesMap.set(key, existingCoordinate);
		});

		// Create an array to store the final heatmap data
		const heatmapData: Coordinate[] = [];

		// Iterate over all possible coordinates and add them to the heatmap data
		response.xAxisArray.forEach((xValue, xIndex) => {
			response.yAxisArray.forEach((yValue, yIndex) => {
				const key = `${xIndex},${yIndex}`;

				const existingCoordinate = coordinatesMap.get(key);

				if (existingCoordinate) {
					// Coordinate exists, add the existing value
					heatmapData.push(existingCoordinate);
				} else {
					// Coordinate doesn't exist, add a blank value
					heatmapData.push({
						x: xIndex,
						y: yIndex,
						value: 0,
					});
				}
			});
		});

		// Assign the heatmap data to response.seriesData
		response.seriesData = [
			{
				name: '',
				data: heatmapData as HeatMapSeriesDataTypes,
			},
		];
	}

	return response;
};

export const formatSankeyOrDepWheelMultiSeriesData = (
	chartData: IIndexable[],
	unitType?: TUnitType,
	numberOfDecimals?: number,
	grandTotal?: [{ [key: string]: number }],
	showPercentages?: boolean
) => {
	let response: ChartDataResponse = newChartResponseObject();

	response = populateAxisArrays(chartData, response, showPercentages, grandTotal);

	if (chartData) {
		const transformedData = chartData.map((item) => {
			const values = Object.values(item);
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
			return values.slice(0, 2).concat(parseFloat(values[2]));
		});

		const responseSeriesData = transformedData.map((item) => {
			return { name: '', data: item };
		});

		const keys = Object.keys(chartData[0]);

		const flattendSeriesData = responseSeriesData.map((item) => {
			const percentage = calculatePercentage(
				keys[2]?.replace(/([A-Z])/g, ' $1').trim(),
				grandTotal,
				parseFloat(item.data[2] as string)
			);

			let plotValue = !showPercentages ? parseFloat(item.data[2] as string) : percentage ?? 0;

			if (!showPercentages && unitType?.code === 'PCT') {
				plotValue = plotValue * 100;
			}

			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return [
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
				item.data[0], // FacilityName
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
				item.data[1], // Region
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
				parseFloat(plotValue.toFixed(numberOfDecimals)), // EmployeeBasePay converted to float
			];
		});

		response.seriesData = flattendSeriesData.map((item) => {
			return { name: '', data: item };
		});

		// Create a new array to mimic heatmapData structure used for on chart click for multi-series chart
		const lookupData = [];
		// Add xAxisArray as the first row
		lookupData.push(response.xAxisArray);
		// Split valueArray into chunks based on the length of xAxisArray
		const chunkSize = Math.ceil(response.valueArray.length / response.xAxisArray.length);
		for (let i = 0; i < response.valueArray.length; i += chunkSize) {
			const chunk = response.valueArray.slice(i, i + chunkSize);
			lookupData.push(chunk);
		}
		response.lookupData = lookupData;
	}

	return response;
};

const newChartResponseObject = (): ChartDataResponse => {
	return {
		xAxisArray: [],
		yAxisArray: [],
		valueArray: [],
		valueArray2: [],
		seriesData: [],
		lookupData: [[], []],
	};
};

const populateAxisArrays = (
	chartData: IIndexable[],
	response: ChartDataResponse,
	showPercentages?: boolean,
	grandTotal?: [{ [key: string]: number }]
) => {
	chartData.map((value: IIndexable) => {
		Object.keys(value).map((key, index) => {
			let stringValue = value[key] as string;
			if (index === 0 && !response.xAxisArray.includes(stringValue)) {
				response.xAxisArray.push(stringValue);
			}

			if (index == 1 && Array.isArray(value[key])) {
				const stringArray = value[key] as string[];
				stringValue = stringArray.join(', ');
			}

			const percentage = showPercentages
				? calculatePercentage(
						key.replace(/([A-Z])/g, ' $1').trim(),
						grandTotal,
						value[key] as number | null
				  )
				: null;

			const total = !showPercentages
				? stringValue
				: !isNaN(+stringValue) && percentage
				? percentage.toString()
				: stringValue;

			if (index === 1 && !response.yAxisArray.includes(total)) {
				response.yAxisArray.push(total);
			}

			if (index === 2) {
				response.valueArray.push(total);
			}
		});
	});

	return response;
};
