import axiosConfig from '../axiosConfig';
import { TPeriod } from './Period';
import { TUser } from '../security/User';
import { TETLSourceDatabase } from './ETLSourceDatabase';
import { TKeyMeasure } from './KeyMeasure';

export enum EOccurrence {
	RECURRING = 'Recurring',
	ONE_TIME = 'OneTime',
	DAILY = 'Daily',
	NEVER = 'Never',
}

export enum EAggregateMethod {
	AGGREGATE_UP_FROM_LOWER_PERIOD = 'AGGREGATE_UP_FROM_LOWER_PERIOD',
	DONT_AUTO_AGGREGATE = 'DONT_AUTO_AGGREGATE',
	FORCE_LAST_LOWER_PERIOD = 'FORCE_LAST_LOWER_PERIOD',
}

export const EAggregateMethodMap: { [key in EAggregateMethod]: string } = {
	AGGREGATE_UP_FROM_LOWER_PERIOD: 'Aggregate Up From Lower Period',
	DONT_AUTO_AGGREGATE: "Don't Auto Aggregate",
	FORCE_LAST_LOWER_PERIOD: 'Force Last Lower Period',
};

export type TKeyMeasureSource = {
	aggregate_method: EAggregateMethod;
	backward_periods: number | null;
	collection_period: TPeriod;
	created_at: number;
	creator: TUser;
	daily_run_interval: number | null;
	description: string | null;
	end_date: number | null;
	ETLSourceDatabase: TETLSourceDatabase;
	forward_periods: number | null;
	id: number;
	keyMeasure: TKeyMeasure;
	name: string;
	occurrence: EOccurrence;
	period: TPeriod;
	run_group: number | null;
	run_sequence: number | null;
	single_period: boolean; // default: false
	sql: string | null;
	sql_period_override: string | null;
	start_date: number;
	next_run_date: number | null;
	updated_at: number;
	updater: TUser;
};

export type TNewKeyMeasureSource = Omit<
	TKeyMeasureSource,
	| 'id'
	| 'updater'
	| 'updated_at'
	| 'creator'
	| 'created_at'
	| 'collection_period'
	| 'ETLSourceDatabase'
	| 'keyMeasure'
	| 'occurrence'
	| 'period'
> & {
	collection_period: number;
	ETLSourceDatabase: number;
	occurrence: null | EOccurrence;
	period: number;
	keyMeasure: number;
};

export class KeyMeasureSource implements TKeyMeasureSource {
	aggregate_method: EAggregateMethod;
	backward_periods: number | null;
	collection_period: TPeriod;
	created_at: number;
	creator: TUser;
	daily_run_interval: number | null;
	description: string | null;
	end_date: number | null;
	ETLSourceDatabase: TETLSourceDatabase;
	forward_periods: number | null;
	id: number;
	keyMeasure: TKeyMeasure;
	name: string;
	occurrence: EOccurrence;
	period: TPeriod;
	run_group: number | null;
	run_sequence: number | null;
	single_period: boolean;
	sql: string | null;
	sql_period_override: string | null;
	start_date: number;
	next_run_date: number | null;
	updated_at: number;
	updater: TUser;

	constructor(keyMeasureSource: TKeyMeasureSource) {
		this.aggregate_method = keyMeasureSource.aggregate_method;
		this.backward_periods = keyMeasureSource.backward_periods;
		this.collection_period = keyMeasureSource.collection_period;
		this.created_at = keyMeasureSource.created_at;
		this.creator = keyMeasureSource.creator;
		this.daily_run_interval = keyMeasureSource.daily_run_interval;
		this.description = keyMeasureSource.description;
		this.end_date = keyMeasureSource.end_date;
		this.ETLSourceDatabase = keyMeasureSource.ETLSourceDatabase;
		this.forward_periods = keyMeasureSource.forward_periods;
		this.id = keyMeasureSource.id;
		this.keyMeasure = keyMeasureSource.keyMeasure;
		this.name = keyMeasureSource.name;
		this.occurrence = keyMeasureSource.occurrence;
		this.period = keyMeasureSource.period;
		this.run_group = keyMeasureSource.run_group;
		this.run_sequence = keyMeasureSource.run_sequence;
		this.single_period = keyMeasureSource.single_period;
		this.sql = keyMeasureSource.sql;
		this.sql_period_override = keyMeasureSource.sql_period_override;
		this.start_date = keyMeasureSource.start_date;
		this.next_run_date = keyMeasureSource.next_run_date;
		this.updated_at = keyMeasureSource.updated_at;
		this.updater = keyMeasureSource.updater;
	}

	public static Default(): TNewKeyMeasureSource {
		return {
			run_sequence: 0,
			aggregate_method: EAggregateMethod.DONT_AUTO_AGGREGATE,
			backward_periods: null,
			collection_period: 0,
			daily_run_interval: null,
			description: null,
			end_date: null,
			ETLSourceDatabase: 0,
			forward_periods: null,
			keyMeasure: 0,
			name: '',
			occurrence: null,
			period: 0,
			run_group: null,
			sql: null,
			sql_period_override: null,
			start_date: 0,
			next_run_date: null,
			single_period: false,
		};
	}
	public static New(
		entity: TNewKeyMeasureSource,
		expand: (keyof TKeyMeasureSource)[] = []
	): Promise<TKeyMeasureSource> {
		return axiosConfig.instance
			.post('app/key-measure-source', entity, { params: { expand: expand } })
			.then((res) => {
				return res.data as TKeyMeasureSource;
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/key-measure-source/${id}`).then(() => {
			return true;
		});
	}

	public static Update(
		entity: TKeyMeasureSource,
		expand: (keyof TKeyMeasureSource)[] = []
	): Promise<TKeyMeasureSource> {
		return axiosConfig.instance
			.patch('app/key-measure-source', entity, { params: { expand: expand } })
			.then((res) => {
				return res.data as TKeyMeasureSource;
			});
	}

	public static GetAll(expand: (keyof TKeyMeasureSource)[] = []): Promise<TKeyMeasureSource[]> {
		return axiosConfig.instance
			.get('app/key-measure-sources', { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as TKeyMeasureSource[]).map(
					(entity: TKeyMeasureSource) => {
						return entity;
					}
				);
			});
	}
}
