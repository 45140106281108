import React, { Dispatch, SetStateAction } from 'react';
import { Button, Flex, Grid, GridItem, Tooltip } from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import './DefaultDashboardOptionsView.scss';
import { DashboardCreationOptions } from './ZiDashboardOptionsModal';

export type DefaultDashboardOptionsViewProps = {
	onViewChange: Dispatch<SetStateAction<DashboardCreationOptions>>;
};

const DefaultDashboardOptionsView = (props: DefaultDashboardOptionsViewProps) => {
	const { onViewChange } = props;

	return (
		<div
			className="component-container"
			data-testid="default-view"
		>
			<Grid className="dashboard-options-list">
				<GridItem span={6}>
					<Flex justifyContent={{ default: 'justifyContentCenter' }}>
						<Button
							className="dashboard-option"
							onClick={() => onViewChange('blank')}
							variant="link"
							data-testid="select-blank"
						>
							<span className="dashboard-option-icon"></span>
							Blank
						</Button>
					</Flex>
				</GridItem>
				{/* <GridItem span={6}>
					<Flex justifyContent={{ default: 'justifyContentCenter' }}>
						<Button
							className="dashboard-option"
							onClick={() => onViewChange('grid')}
							variant="link"
							data-testid="select-frame"
						>
							<span className="dashboard-option-icon">
								<FontAwesomeIcon icon={faObjectsColumn} />
							</span>
							Grid Template
						</Button>
					</Flex>
				</GridItem>
				<GridItem span={6}>
					<Flex justifyContent={{ default: 'justifyContentCenter' }}>
						<Button
							className="dashboard-option"
							onClick={() => onViewChange('flex')}
							variant="link"
							data-testid="select-frame"
						>
							<span className="dashboard-option-icon">
								<FontAwesomeIcon icon={faDatabase} />
							</span>
							Flexible Template
						</Button>
					</Flex>
				</GridItem> */}
				<GridItem span={6}>
					<Flex justifyContent={{ default: 'justifyContentCenter' }}>
						<Tooltip content={<div>Coming Soon</div>}>
							<Button
								className="dashboard-option"
								variant="link"
								data-testid="select-copy"
							>
								<span className="dashboard-option-icon">
									<FontAwesomeIcon icon={faCopy} />
								</span>
								Copy Existing
							</Button>
						</Tooltip>
					</Flex>
				</GridItem>
			</Grid>
		</div>
	);
};

export default DefaultDashboardOptionsView;
