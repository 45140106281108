import axiosConfig from '../axiosConfig';
import { TPeriod } from './Period';

export type TDateRange = {
	begin_date: number;
	end_date: number;
	id: number;
	lookup: number;
	period: TPeriod | number;
	sequence: number;
	title: string | null;
};

export type TNewDateRange =
	| Omit<TDateRange, 'id'>
	| {
			begin_date: number | null;
			end_date: number | null;
	  };

export class DateRange implements TDateRange {
	public static Default(): TNewDateRange {
		return {
			begin_date: null,
			end_date: null,
			lookup: 0,
			period: 0,
			sequence: 0,
			title: null,
		};
	}

	public static New(dateRange: TNewDateRange): Promise<TDateRange> {
		return axiosConfig.instance.post('app/date-range', dateRange).then((res) => {
			return new DateRange(res.data as TDateRange);
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/date-range/${id}`).then(() => {
			return true;
		});
	}

	public static Update(dateRange: TDateRange): Promise<TDateRange> {
		return axiosConfig.instance.patch('app/date-range', dateRange).then((res) => {
			return new DateRange(res.data as TDateRange);
		});
	}

	public static Get(id: number): Promise<TDateRange> {
		return axiosConfig.instance.get(`app/date-range/${id}`).then((res) => {
			return new DateRange(res.data as TDateRange);
		});
	}

	public static GetAll(): Promise<TDateRange[]> {
		return axiosConfig.instance.get('app/date-ranges').then((res) => {
			return ((res.data || []) as TDateRange[]).map((dateRange: TDateRange) => {
				return new DateRange(dateRange);
			});
		});
	}

	begin_date: number;
	end_date: number;
	id: number;
	lookup: number;
	period: TPeriod | number;
	sequence: number;
	title: string | null;

	constructor(DateRange: TDateRange) {
		this.begin_date = DateRange.begin_date;
		this.end_date = DateRange.end_date;
		this.id = DateRange.id;
		this.lookup = DateRange.lookup;
		this.period = DateRange.period;
		this.sequence = DateRange.sequence;
		this.title = DateRange.title;
	}
}
