import React, { Dispatch, SetStateAction, useState } from 'react';
import Loader from '../../../util/Loader';

export type TemplateViewProps = {
	selectedTemplate?: any;
	onSelectTemplate: Dispatch<SetStateAction<any | undefined>>;
};

const AddReportTemplateView = (_props: TemplateViewProps) => {
	const [isLoading, setIsLoading] = useState(false);

	return isLoading ? <Loader /> : <div data-testid="template-view">TODO</div>;
};

export default AddReportTemplateView;
