import { ReactElement, useEffect } from 'react';
import { useIsGranted } from './components/user/ApplicationProvider';
import { Permission } from './enums/permission.enum';
import { useNavigate } from 'react-router';

export type ProtectedRouteProps = {
	route: ReactElement;
	permission: Permission | Permission[];
	component?: React.ComponentType<any>;
	componentProps?: any;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
	route,
	permission,
	component: Component,
	componentProps,
}: ProtectedRouteProps) => {
	const isGranted = useIsGranted();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isGranted(permission)) {
			navigate('/restricted-access');
		}
	}, [permission, isGranted, navigate]);

	if (!isGranted(permission)) {
		return null; // Render nothing while navigating
	}

	return <>{Component ? <Component {...componentProps} /> : route}</>;
};

export default ProtectedRoute;
