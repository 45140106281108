import React from 'react';
import { CardProps } from '../../types/cards/card-props';
import ZiCard from './ZiCard';
import './ListCards.scss';
import { ListCardProps } from '../../types/cards/list-card-props';

function ListCards(props: ListCardProps) {
	const items = props.items.map((item: CardProps, index: number) => {
		return (
			<ZiCard
				key={index}
				hideEllipsis={props.hideEllipsis}
				hidePin={props.hidePin}
				{...item}
			/>
		);
	});
	const additionalClassNames: string = props.additionalClassNames
		? props.additionalClassNames
		: '';

	return <div className={`cardList ${additionalClassNames}`}>{items}</div>;
}

export default ListCards;
