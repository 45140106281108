import React, { Dispatch, SetStateAction } from 'react';
import { Form, FormGroup, Grid, GridItem, TextInput } from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFolder } from '../../../api/foundational-elements/Folder';
import './DefaultDashboardOptionsView.scss';
import { TDashboard } from '../../../api/dashbboards/Dashboards';
import { DashboardCreationOptions } from './ZiDashboardOptionsModal';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import TreeViewSelect from '../../form/Select/TreeViewSelect';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';

export type BlankDashboardOptionsViewProps = {
	onViewChange: Dispatch<SetStateAction<DashboardCreationOptions>>;
	dashboard: TDashboard;
	folders: TFolder[];
	handleNameChange: (value: string, event: React.FormEvent<HTMLInputElement>) => void;
	selectedFolder?: TFolder;
	onSelectFolder: Dispatch<SetStateAction<TFolder | undefined>>;
};

const BlankDashboardOptionsView = (props: BlankDashboardOptionsViewProps) => {
	const { dashboard, folders, handleNameChange, onSelectFolder, selectedFolder } = props;

	return (
		<div
			className="component-container"
			data-testid="default-view"
		>
			<Form>
				<Grid>
					<GridItem span={12}>
						<TextInput
							placeholder="Dashboard Name"
							value={dashboard.name}
							onChange={handleNameChange}
						/>
					</GridItem>
					<FormGroup
						label={<FontAwesomeIcon icon={faFolder} />}
						type="text"
						fieldId="folder"
						className="folder-container"
					>
						<TreeViewSelect
							hasFolderIcon
							onSelect={(e, item) => {
								if (item && item.id && item.id != '-1') {
									onSelectFolder(
										folders.find((f) => f.id.toString() === item.id)
									);
								}
							}}
							data={
								folders
									? folders.map((_: TFolder) => BuildTreeViewItem(_, _.items))
									: []
							}
							placeHolderText={selectedFolder ? selectedFolder.name : 'Dashboard'}
							isDisabled={
								folders &&
								folders.map((f) => f.id).indexOf(-1) > -1 &&
								folders.filter((f) => f.id != -1).length == 0
							}
						/>
					</FormGroup>
				</Grid>
			</Form>
		</div>
	);
};

export default BlankDashboardOptionsView;
