import { faPresentation } from '@fortawesome/pro-light-svg-icons';
import { CardTypesEnum } from '../../enums/card-types.enum';
import { Presentation } from '../../types/present';

export const Presentations: Presentation[] = [
	{
		id: 1,
		folderId: 1,
		title: 'YoY Executive DEI&B Review',
		type: CardTypesEnum.Presentation,
		url: '#',
		icon: faPresentation,
		isRecent: true,
	},
	{
		id: 2,
		folderId: 2,
		title: 'Turnover Mitigation Plan',
		type: CardTypesEnum.Presentation,
		url: '#',
		icon: faPresentation,
		isRecent: true,
	},
	{
		id: 3,
		folderId: 3,
		title: 'Impacting Quality of Care',
		type: CardTypesEnum.Presentation,
		url: '#',
		icon: faPresentation,
		isRecent: true,
	},
	{
		id: 4,
		folderId: 4,
		title: 'Measuring Engagement',
		type: CardTypesEnum.Presentation,
		url: '#',
		icon: faPresentation,
		isRecent: true,
	},
];
