import { Flex, FlexItem, Text } from '@patternfly/react-core';
import React from 'react';
import './SubNavReportSummary.scss';
import { ReportSummary, ReportSummaryItem } from '../../helpers/sub-nav-contexts/sub-nav-context';

type Props = {
	reportSummary: ReportSummary;
};

const SubNavReportSummary = (props: Props) => {
	const {
		reportSummary: { title, reportList },
	} = props;
	return (
		<Flex
			direction={{ default: 'column' }}
			className="report-summary"
			style={{ height: '100%' }}
		>
			<Flex spacer={{ default: 'spacerNone' }}>
				<Text className="right-title font-size-md">{title}</Text>
			</Flex>
			<Flex
				direction={{ default: 'row' }}
				style={{ height: '83%' }}
				// className='action-card-container'
			>
				{(!reportList || reportList.length <= 0) && <Text>No Reports to Show</Text>}
				{reportList.map((item: ReportSummaryItem, index: number) => (
					<Flex
						key={index}
						flex={{ default: 'flex_1' }}
						direction={{ default: 'column' }}
						className="report-summary-item action-card"
						alignSelf={{ default: 'alignSelfCenter' }}
						style={{ height: '100%' }}
					>
						<FlexItem>
							<Text className="report-summary-number">{item.value}</Text>
						</FlexItem>
						<FlexItem>
							<Text className="report-summary-text">{item.description}</Text>
						</FlexItem>
					</Flex>
				))}
			</Flex>
		</Flex>
	);
};

export default SubNavReportSummary;
