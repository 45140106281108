import { Button } from '@patternfly/react-core';
import CenteredPage from '../../layout/CenteredPage';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
	const navigate = useNavigate();

	return (
		<CenteredPage
			description="The page you are looking for may have been removed, had it's name changed, or be temporarily unavailable."
			notice={null}
		>
			<Button
				onClick={() => {
					navigate(-1);
				}}
				variant="primary"
				data-testid="login-submit"
			>
				Return to previous page
			</Button>
		</CenteredPage>
	);
}
