import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Tooltip } from '@patternfly/react-core';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import Authorized from '../../components/security/Authorized';
import { Permission } from '../../enums/permission.enum';

export default function Setup() {
	const navigate = useNavigate();
	return (
		<Authorized by={Permission.CanViewSettingsPage}>
			<Tooltip
				aria="none"
				content={'Setup'}
				flipBehavior={['bottom', 'bottom-end']}
			>
				<Icon
					onClick={() => navigate('/setup')}
					style={{
						width: '2rem',
						height: '1.5rem',
						color: '#000000',
						cursor: 'pointer',
						margin: 8,
					}}
					size="lg"
					alt={'Setup'}
					isInline
				>
					<FontAwesomeIcon icon={faCog} />
				</Icon>
			</Tooltip>
		</Authorized>
	);
}
