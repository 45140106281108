import React from 'react';
import Loader from '../../components/util/Loader';

function ReloadingPage() {
	return (
		<div>
			<Loader />
		</div>
	);
}

export default ReloadingPage;
