import { TDateRange } from '../../api/types';
import { TimeStamps } from '../../types/date-period-selector/timestampes';

export function DatePeriodFormatter(
	timestamp: number | string,
	periodId?: number,
	isFormatPreview = false
): string {
	let date: Date | null = null;
	let formattedDate = '';

	if (typeof timestamp !== 'number' && Number.isNaN(parseInt(timestamp))) {
		return timestamp;
	}

	//if you are formatting the dates displayed in preview table first convert the string date to a unix timestamp
	//then convert the unix date to a js date object
	if (isFormatPreview && typeof timestamp === 'string') {
		const year = parseInt(timestamp.toString().substring(0, 4));
		const month = parseInt(timestamp.toString().substring(4, 6)) - 1;
		const day = getDaysInMonth(year, month);
		date = new Date(year, month, day);
	} else if (typeof timestamp === 'number') {
		date = new Date(timestamp * 1000);
	}

	if (date) {
		switch (periodId) {
			case 1: {
				//Year formatting
				formattedDate = new Intl.DateTimeFormat('en-US', {
					timeZone: 'UTC',
					year: 'numeric',
				}).format(date);
				break;
			}
			case 2: {
				//Quarter formatting
				const quarter = Math.floor((date.getMonth() + 3) / 3);
				formattedDate = new Intl.DateTimeFormat('en-US', {
					timeZone: 'UTC',
					year: 'numeric',
				}).format(date);
				formattedDate = `Q${quarter} ${formattedDate}`;
				break;
			}
			case 3: {
				//Month Formatting
				formattedDate = new Intl.DateTimeFormat('en-US', {
					timeZone: 'UTC',
					year: 'numeric',
					month: 'short',
				}).format(date);
				break;
			}
			case 4: {
				//Week Formatting
				formattedDate = new Intl.DateTimeFormat('en-US', {
					timeZone: 'UTC',
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				}).format(date);
				break;
			}
			default: {
				formattedDate = new Intl.DateTimeFormat('en-US', {
					timeZone: 'UTC',
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				}).format(date);
				break;
			}
		}
	}

	return formattedDate;
}

export function DatePeriodReformatter(date: string, periodId: number): TDateRange | undefined {
	let timeStamps: TimeStamps | null = null;

	switch (periodId) {
		case 1: {
			//Year formatting
			timeStamps = convertYYYYToYearTimestamps(date);
			break;
		}
		case 2: {
			//Quarter formatting
			timeStamps = convertQuarterToTimestamps(date);
			break;
		}
		case 3: {
			//Month Formatting
			timeStamps = convertMMMYYYYToTimestamps(date.replace(' ', ''));
			break;
		}
		case 4: {
			//Week Formatting
			timeStamps = convertToWeeklyTimestamps(date);
			break;
		}
	}

	if (timeStamps) {
		return {
			begin_date: timeStamps.startTimestamp ?? 0,
			end_date: timeStamps.endTimestamp ?? 0,
			period: periodId,
			sequence: 0,
			id: 0,
		};
	}

	return;
}

function getDaysInMonth(year: number, month: number): number {
	// Create a date for the first day of the next month, then subtract one day
	// Month is zero-based, so January is 0 and December is 11
	return new Date(year, month + 1, 0).getDate();
}

function convertToUnixTimestamps(start: number, end: number): TimeStamps {
	return {
		startTimestamp: Math.floor(start / 1000),
		endTimestamp: Math.floor(end / 1000),
	};
}

function convertMMMYYYYToTimestamps(dateString: string): TimeStamps {
	// Parse the input string (MMMYYYY)
	const monthAbbr = dateString.slice(0, 3); // Extract month abbreviation (e.g., "Mar")
	const year = parseInt(dateString.slice(3), 10); // Extract year (e.g., "2023")

	// Define a map of month abbreviations to month numbers (0-based for Date)
	const monthMap: { [key: string]: number } = {
		Jan: 0,
		Feb: 1,
		Mar: 2,
		Apr: 3,
		May: 4,
		Jun: 5,
		Jul: 6,
		Aug: 7,
		Sep: 8,
		Oct: 9,
		Nov: 10,
		Dec: 11,
	};

	// Get the month number from the map
	const month = monthMap[monthAbbr];

	// Create a Date object for the start of the month in UTC
	const startOfMonth = new Date(Date.UTC(year, month, 1, 0, 0, 0, 0)); // UTC time at the start of the month

	// Create a Date object for the end of the month in UTC
	const endOfMonth = new Date(Date.UTC(year, month + 1, 0, 23, 59, 59, 999)); // UTC time at the end of the month

	return convertToUnixTimestamps(startOfMonth.getTime(), endOfMonth.getTime());
}

function convertYYYYToYearTimestamps(yearString: string): TimeStamps {
	// Parse the input string to get the year (YYYY)
	const year = parseInt(yearString, 10);

	// Create a Date object for the start of the year in UTC
	const startOfYear = new Date(Date.UTC(year, 0, 1, 0, 0, 0, 0)); // January 1st, 00:00:00 UTC

	// Create a Date object for the end of the year in UTC
	const endOfYear = new Date(Date.UTC(year, 11, 31, 23, 59, 59, 999)); // December 31st, 23:59:59 UTC

	return convertToUnixTimestamps(startOfYear.getTime(), endOfYear.getTime());
}

function convertQuarterToTimestamps(quarterString: string): TimeStamps {
	// Extract quarter and year from the input string
	const [quarter, yearString] = quarterString.split(' ');
	const year = parseInt(yearString, 10);

	// Determine the start month and end month of the quarter
	let startMonth = 0;
	let endMonth = 0;

	switch (quarter) {
		case 'Q1':
			startMonth = 0; // January
			endMonth = 2; // March
			break;
		case 'Q2':
			startMonth = 3; // April
			endMonth = 5; // June
			break;
		case 'Q3':
			startMonth = 6; // July
			endMonth = 8; // September
			break;
		case 'Q4':
			startMonth = 9; // October
			endMonth = 11; // December
			break;
	}

	// Create Date objects for the start and end of the quarter in UTC
	const startOfQuarter = new Date(Date.UTC(year, startMonth, 1, 0, 0, 0, 0));
	const endOfQuarter = new Date(Date.UTC(year, endMonth + 1, 0, 23, 59, 59, 999));

	return convertToUnixTimestamps(startOfQuarter.getTime(), endOfQuarter.getTime());
}

function convertToWeeklyTimestamps(dateString: string): TimeStamps {
	// Parse the input string (MM/DD/YYYY)
	const [month, day, yearString] = dateString.split('/');
	const monthNumber = parseInt(month, 10) - 1; // Months are 0-based in Date (0 = January, 1 = February, etc.)
	const dayNumber = parseInt(day, 10);
	const year = parseInt(yearString, 10);

	// Create a Date object for the input date
	const inputDate = new Date(Date.UTC(year, monthNumber, dayNumber));

	// Determine the start of the week (Monday) and end of the week (Sunday)
	const dayOfWeek = inputDate.getUTCDay();
	const startOfWeek = new Date(inputDate);
	const endOfWeek = new Date(inputDate);

	// Adjust for the start of the week (Monday)
	const daysToStartOfWeek = (dayOfWeek + 6) % 7; // Convert to days since Monday
	startOfWeek.setUTCDate(inputDate.getUTCDate() - daysToStartOfWeek);
	startOfWeek.setUTCHours(0, 0, 0, 0); // Start of the day in UTC

	// Adjust for the end of the week (Sunday)
	const daysToEndOfWeek = (7 - dayOfWeek) % 7; // Days until Sunday
	endOfWeek.setUTCDate(inputDate.getUTCDate() + daysToEndOfWeek);
	endOfWeek.setUTCHours(23, 59, 59, 999); // End of the day in UTC

	return convertToUnixTimestamps(startOfWeek.getTime(), endOfWeek.getTime());
}
