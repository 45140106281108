import axiosConfig from '../axiosConfig';
import { TPermission } from './Permission';

export type TRole = {
	description: string | null;
	id: number;
	name: string;
	permissions: TPermission[] | number[];
};

export type TNewRole = Omit<TRole, 'id'>;

export class Role implements TRole {
	description: string | null;
	id: number;
	name: string;
	permissions: TPermission[] | number[];

	constructor(role: TRole) {
		this.description = role.description;
		this.id = role.id;
		this.name = role.name;
		this.permissions = role.permissions;
	}

	public static Default(): TNewRole {
		return {
			description: null,
			permissions: [],
			name: '',
		};
	}

	public static Get(id: number, _expand: (keyof TRole)[] = []): Promise<TRole> {
		return axiosConfig.instance.get(`app/role/${id}`).then((response) => {
			return response.data as TRole;
		});
	}

	public static New(role: TNewRole, expand: (keyof TRole)[] = []): Promise<TRole> {
		return axiosConfig.instance
			.post('app/role', role, { params: { expand: expand } })
			.then((res) => {
				return new Role(res.data as TRole);
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/role/${id}`).then(() => {
			return true;
		});
	}

	public static Update(role: TRole, expand: (keyof TRole)[] = []): Promise<TRole> {
		return axiosConfig.instance
			.patch('app/role', role, { params: { expand: expand } })
			.then((res) => {
				return new Role(res.data as TRole);
			});
	}

	public static GetAll(expand: (keyof TRole)[] = []): Promise<TRole[]> {
		return axiosConfig.instance.get('app/roles', { params: { expand: expand } }).then((res) => {
			return ((res.data || []) as TRole[]).map((role: TRole) => {
				return new Role(role);
			});
		});
	}
}
