import React, { ReactElement, useEffect, useState } from 'react';
import './LambdaInsights.scss';
import { LambdaInsightsResponse } from '../../api/types';
import CopyButton from '../button/CopyButton';
import { List, ListItem } from '@patternfly/react-core';

export type props = {
	insights: LambdaInsightsResponse;
	hasBackground: boolean;
};

const LambdaInsights = (props: props): ReactElement => {
	const { insights, hasBackground } = props;
	const [copyText, setCopyText] = useState('');

	useEffect(() => {
		let finalText = '';
		insights.insights.forEach((insight, index) => {
			if (index === 0) {
				finalText = `Insights\n`;
			}

			finalText = `${finalText}${insight}\n`;
		});

		insights.actions.forEach((action, index) => {
			if (index === 0) {
				finalText = `${finalText}Actions\n`;
			}

			finalText = `${finalText}${action}\n`;
		});

		setCopyText(finalText);
	}, [insights]);

	const boldText = (text: string) => {
		return `<div>${text.replace(/\*{2}(.*?)\*{2}/g, '<b>$1</b>')}</div>`;
	};

	return (
		<div className={`lambda-insights-container ${hasBackground ? 'insights-background' : ''}`}>
			<div className={'insights'}>
				<div className={'header'}>
					<b>Insights</b>
					<CopyButton
						hasIcon={true}
						textToCopy={copyText}
					/>
				</div>
				<List>
					{insights.insights.map((insight) => {
						return (
							<ListItem className={'insight-item'}>
								<div dangerouslySetInnerHTML={{ __html: boldText(insight) }}></div>
							</ListItem>
						);
					})}
				</List>
			</div>
			<div className={'actions'}>
				<div className={'header'}>
					<b>Actions</b>
				</div>
				<List>
					{insights.actions.map((action) => {
						return (
							<ListItem className={'insight-item'}>
								<div dangerouslySetInnerHTML={{ __html: boldText(action) }}></div>
							</ListItem>
						);
					})}
				</List>
			</div>
		</div>
	);
};

export default LambdaInsights;
