import { faBell, faBookMedical } from '@fortawesome/pro-regular-svg-icons';
import { SubNavContext, TipAndTrickType } from './sub-nav-context';

const ReportSubNavContext: SubNavContext = {
	actions: {
		title: 'Common Actoins',
		actionsList: [
			{
				title: 'Add Report',
				url: '#',
				icon: faBell,
			},
			{
				title: 'Schedule Report',
				url: '#',
				icon: faBookMedical,
			},
		],
	},
	tipsAndTricks: {
		title: 'Tips & Tricks',
		allTutorialsLink: '#',
		tipsAndTricksList: [
			{
				title: 'How to Add A Report',
				url: '#',
				type: TipAndTrickType.VIDEO,
			},
			{
				title: 'How to Make a Schedule',
				url: '#',
				type: TipAndTrickType.PDF,
			},
		],
	},
};

export default ReportSubNavContext;
