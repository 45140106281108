export type TSharedEntityUser = {
	user?: number;
	sharedEntity?: number;
	can_edit: boolean;
	can_share: boolean;
};

export class SharedEntityUser implements TSharedEntityUser {
	user?: number;
	sharedEntity?: number;
	can_edit: boolean;
	can_share: boolean;

	constructor(sharedEntityUser: TSharedEntityUser) {
		this.user = sharedEntityUser.user;
		this.can_edit = sharedEntityUser.can_edit;
		this.can_share = sharedEntityUser.can_share;
		this.sharedEntity = sharedEntityUser.sharedEntity;
	}

	static Default(): TSharedEntityUser {
		return {
			user: 0,
			can_edit: false,
			can_share: false,
		};
	}
}
