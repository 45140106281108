import React, { useState } from 'react';
import ZiTable, { Column } from '../../../components/table/ZiTable';
import FilterTableLayout from '../../../layout/FilterTableLayout';
import FilterIcon from '@fortawesome/fontawesome-pro/svgs/regular/filter.svg';
import { Schedule, TSchedule } from '../../../api/schedules/Schedules';
import { Grid, GridItem, Title } from '@patternfly/react-core';

function ListSchedules() {
	const [schedules, setSchedules] = useState<Schedule[]>([]);

	const selectedColumns: Column<TSchedule>[] = [
		{
			title: 'Report',
			columnName: 'name',
			sortable: true,
		},
		{
			title: 'Interval',
			columnName: 'interval',
			sortable: true,
		},
		{
			title: 'Last Run Date',
			columnName: 'last_run_date',
			sortable: true,
		},
		{
			title: 'Next Run Date',
			columnName: 'next_run_date',
			sortable: true,
		},
	];

	const schedulesTable = (
		<ZiTable<Schedule>
			ariaLabel={'Schedules table'}
			columns={selectedColumns}
			data={schedules}
			caption="Schedules"
			loading={false}
		/>
	);

	return (
		<React.Fragment>
			<Grid hasGutter>
				<span className="spacer-sm"></span>
				<Title headingLevel={'h1'}>Schedules</Title>
				<GridItem span={12}>
					<FilterTableLayout
						table={schedulesTable}
						icon={FilterIcon}
					/>
				</GridItem>
			</Grid>
		</React.Fragment>
	);
}
export default ListSchedules;
