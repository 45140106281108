export enum EntityTypesEnum {
	Dashboard = 'Dashboard',
	Widget = 'widget',
	Dataframe = 'Dataframe',
	Chart = 'Chart',
	Report = 'Report',
	Presentation = 'Presentation',
	Question = 'Question',
	Folder = 'Folder',
	Alert = 'Alert',
}

export const analyzeRoutes = [
	'recents',
	'favorites',
	'charts',
	'dataframes',
	'dashboards',
	'folder',
];
