import axiosConfig from '../axiosConfig';

export type TChartSeries = {
	id?: number;
	chart?: number;
	chart_id: number;
	entity_id: number;
	entity_type: string;
	sequence: number;
};

export class ChartSeries implements TChartSeries {
	id?: number;
	chart?: number;
	chart_id: number;
	entity_id: number;
	entity_type: string;
	sequence: number;

	constructor(chartSeries: TChartSeries) {
		this.id = chartSeries.id;
		this.chart = chartSeries.chart;
		this.chart_id = chartSeries.chart_id;
		this.entity_id = chartSeries.entity_id;
		this.entity_type = chartSeries.entity_type;
		this.sequence = chartSeries.sequence;
	}

	public static Default(): TChartSeries {
		return {
			id: 0,
			chart_id: 0,
			entity_id: 0,
			entity_type: '',
			sequence: 0,
		};
	}

	public static New(chartSeries: TChartSeries): Promise<TChartSeries> {
		return axiosConfig.instance
			.post<TChartSeries>(`app/chart/${chartSeries.chart_id}/series`, chartSeries)
			.then((res) => {
				return res.data;
			});
	}

	public static Delete(chart_id: number, id: number): Promise<boolean> {
		return axiosConfig.instance
			.delete<boolean>(`app/chart/${chart_id}/series/${id}`)
			.then((res) => {
				return res.data;
			});
	}

	public static Update(chartSeries: TChartSeries): Promise<TChartSeries> {
		return axiosConfig.instance
			.patch<TChartSeries>('app/charts/series', chartSeries)
			.then((res) => {
				return res.data;
			});
	}

	public static GetAllForChart(chart_id: number): Promise<TChartSeries[]> {
		return axiosConfig.instance
			.get<TChartSeries[]>(`app/chart/${chart_id}/series`)
			.then((res) => {
				return res.data ?? [];
			});
	}
}
