import React from 'react';
import { Spinner } from '@patternfly/react-core';
import './Loader.scss';

type Props = {
	size?: 'lg' | 'md' | 'xl' | 'sm' | undefined;
};

function Loader(props: Props) {
	const { size } = props;

	return (
		<div
			className="loader"
			data-testid="loader"
		>
			<Spinner size={size ?? 'xl'} />
		</div>
	);
}

export default Loader;
