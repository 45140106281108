import React, { Dispatch, SetStateAction, useState } from 'react';
import { TableComposable, Tbody, Td, Tr } from '@patternfly/react-table';
import { Grid, GridItem, InputGroup } from '@patternfly/react-core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMount } from 'react-use';

export type DualListItem = {
	id: number | string;
	icon: IconDefinition;
	label: string;
	selected: boolean;
	can_edit?: boolean;
	can_share?: boolean;
};

type Props = {
	leftItems: DualListItem[];
	setLeftItems?: Dispatch<SetStateAction<DualListItem[]>>;
	rightItems: DualListItem[];
	setRightItems?: Dispatch<SetStateAction<DualListItem[]>>;
	onComponentMount?: () => void;
};

const DualListSelectorV2 = (props: Props) => {
	const [leftSearchQuery, setLeftSearchQuery] = useState('');
	const [rightSearchQuery, setRightSearchQuery] = useState('');

	useMount(() => {
		clearAllSelectedRows();
		if (props.onComponentMount) {
			props.onComponentMount();
		}
	});

	const handleLeftSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLeftSearchQuery(event.target.value);
	};

	const handleRightSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRightSearchQuery(event.target.value);
	};

	const handleAdd = () => {
		clearAllSelectedRows();
		const selectedItems = props.leftItems.filter((item) => item.selected);
		if (selectedItems.length > 0) {
			props.setLeftItems &&
				props.setLeftItems(props.leftItems.filter((item) => !item.selected));
			selectedItems.map((item) => (item.selected = false));
			props.setRightItems && props.setRightItems(props.rightItems.concat(selectedItems));
		}
	};

	const handleRemove = () => {
		clearAllSelectedRows();
		const selectedItems = props.rightItems.filter((item) => item.selected);
		if (selectedItems.length > 0) {
			props.setRightItems &&
				props.setRightItems(props.rightItems.filter((item) => !item.selected));
			selectedItems.map((item) => (item.selected = false));
			props.setLeftItems && props.setLeftItems([...props.leftItems, ...selectedItems]);
		}
	};

	const handleSelect = (
		item: DualListItem,
		list: string,
		_event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>
	) => {
		const updateSelection = (items: DualListItem[]) =>
			items.map((i) => ({
				...i,
				selected: i.id === item.id ? !i.selected : i.selected,
			}));

		if (list === 'left') {
			props.setLeftItems && props.setLeftItems((prevItems) => updateSelection(prevItems));
		} else {
			props.setRightItems && props.setRightItems((prevItems) => updateSelection(prevItems));
		}
	};

	const clearAllSelectedRows = () => {
		setTimeout(() => {
			const rows = document.getElementsByClassName('dual-list-row');
			const elementsArray = Array.from(rows);
			elementsArray.map((row) => {
				row.classList.remove('dual-list-row-selected');
				row.classList.remove('selected');
			});
		}, 80);
	};

	const handleEditShareToggle = (
		event: React.MouseEvent<HTMLInputElement, MouseEvent>,
		rowItem: DualListItem,
		isShare: boolean
	) => {
		const target = event.target as HTMLInputElement;
		const isChecked: boolean = target.checked;

		const index = props.rightItems.findIndex((item) => item === rowItem);
		const updatedRightItems = [...props.rightItems];

		if (isShare) {
			updatedRightItems[index] = {
				...updatedRightItems[index],
				can_share: isChecked,
			};
		} else {
			updatedRightItems[index] = {
				...updatedRightItems[index],
				can_edit: isChecked,
			};
		}

		props.setRightItems && props.setRightItems(updatedRightItems);
	};

	const renderTable = (
		items: DualListItem[],
		searchQuery: string,
		handleSearch: React.ChangeEventHandler<HTMLInputElement> | undefined,
		handleSelect: (
			item: DualListItem,
			list: string,
			event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>
		) => void,
		list: string
	) => (
		<div>
			<label>{list == 'left' ? 'Share with additional Users:' : 'Existing Shares:'}</label>
			<span className="spacer-xs"></span>
			<InputGroup className="grp-search">
				<span className="pf-c-input-group__text pf-m-icon ">
					<FontAwesomeIcon icon={faMagnifyingGlass} />
				</span>
				<input
					type="text"
					placeholder="Search..."
					className="pf-c-form-control tb-dual-search"
					value={searchQuery}
					onChange={handleSearch}
				/>
			</InputGroup>
			<span className="spacer-xs"></span>
			<div className="tbl-dual-list-container">
				<TableComposable className="pf-m-sticky-header tbl-dual-list">
					<Tbody>
						{list == 'right' && props.rightItems.length > 0 && (
							<Tr>
								<Td></Td>
								<Td>Edit?</Td>
								<Td>Share?</Td>
							</Tr>
						)}
						{list == 'right' && props.rightItems.length == 0 && (
							<>
								<Tr className="empty-row">
									<Td className="first-empty-td"></Td>
									<Td>Edit?</Td>
									<Td>Share?</Td>
								</Tr>
								<Tr className="empty-row">
									<Td> </Td>
									<Td> </Td>
									<Td> </Td>
								</Tr>
								<Tr className="empty-row">
									<Td> </Td>
									<Td> </Td>
									<Td> </Td>
								</Tr>
								<Tr className="empty-row">
									<Td> </Td>
									<Td> </Td>
									<Td> </Td>
								</Tr>
								<Tr className="empty-row">
									<Td> </Td>
									<Td> </Td>
									<Td> </Td>
								</Tr>
							</>
						)}
						{list == 'left' && props.leftItems.length == 0 && (
							<>
								<Tr className="empty-row">
									<Td> </Td>
									<Td> </Td>
									<Td> </Td>
								</Tr>
								<Tr className="empty-row">
									<Td> </Td>
									<Td> </Td>
									<Td> </Td>
								</Tr>
								<Tr className="empty-row">
									<Td> </Td>
									<Td> </Td>
									<Td> </Td>
								</Tr>
								<Tr className="empty-row">
									<Td> </Td>
									<Td> </Td>
									<Td> </Td>
								</Tr>
								<Tr className="empty-row">
									<Td> </Td>
									<Td> </Td>
									<Td> </Td>
								</Tr>
							</>
						)}
					</Tbody>
					<Tbody>
						{items.map((item) => (
							<tr
								key={item.id}
								className={
									item.selected
										? 'selected dual-list-row-selected dual-list-row'
										: 'dual-list-row'
								}
							>
								<td onClick={(e) => handleSelect(item, list, e)}>
									<FontAwesomeIcon icon={item.icon} /> {item.label}
								</td>
								{list == 'right' && props.rightItems.length > 0 && (
									<>
										<td>
											<span className="dual-list-chk-item">
												<input
													onClick={(e) => {
														handleEditShareToggle(e, item, false);
													}}
													className="ml-10"
													type="checkbox"
													checked={item.can_edit}
												></input>
											</span>
										</td>
										<td>
											<input
												onClick={(e) => {
													handleEditShareToggle(e, item, true);
												}}
												className="ml-10"
												type="checkbox"
												checked={item.can_share}
											></input>
										</td>
									</>
								)}
							</tr>
						))}
					</Tbody>
				</TableComposable>
			</div>
		</div>
	);

	return (
		<Grid
			span={12}
			className="dual-list-v2-container"
		>
			<GridItem span={4}>
				<div className="left-table">
					{renderTable(
						props.leftItems.filter((item) =>
							item.label.toLowerCase().includes(leftSearchQuery.toLowerCase())
						),
						leftSearchQuery,
						handleLeftSearch,
						handleSelect,
						'left'
					)}
				</div>
			</GridItem>
			<GridItem span={2}>
				<div className="dv-dual-list-actions">
					<button onClick={handleAdd}>
						<FontAwesomeIcon icon={faChevronRight} />
					</button>
					<br />
					<span className="spacer-xs"></span>
					<button onClick={handleRemove}>
						<FontAwesomeIcon icon={faChevronLeft} />
					</button>
				</div>
			</GridItem>
			<GridItem span={6}>
				<div className="right-table">
					{renderTable(
						props.rightItems.filter((item) =>
							item.label.toLowerCase().includes(rightSearchQuery.toLowerCase())
						),
						rightSearchQuery,
						handleRightSearch,
						handleSelect,
						'right'
					)}
				</div>
			</GridItem>
		</Grid>
	);
};

export default DualListSelectorV2;
