import React, { Dispatch, useState } from 'react';
import {
	NotificationDrawerBody,
	NotificationDrawerList,
	ExpandableSection,
} from '@patternfly/react-core';
import './Notifications.scss';
import { TAppNotification } from '../../api/types/AppNotification';
import NotificationJobGroup from './NotificationJobGroup';

type Props = {
	jobGroup: { jobType: string; notifications: TAppNotification[] };
	notifications: TAppNotification[];
	setNotifications: Dispatch<React.SetStateAction<TAppNotification[]>>;
	groupBy: <T, K extends keyof any>(array: T[], keyGetter: (item: T) => K) => Record<K, T[]>;
};

const NotificationsGroups = ({ jobGroup, notifications, setNotifications, groupBy }: Props) => {
	const [isGroupExpanded, setIsGroupExpanded] = useState<boolean>(false);

	const onOpen = () => {
		setIsGroupExpanded(!isGroupExpanded);
	};

	const groupedNotifications: Record<string, TAppNotification[]> = groupBy(
		jobGroup.notifications,
		(notification) => notification.job_queue_id ?? 'null'
	);

	let jobGroupHeading = '';

	switch (jobGroup.jobType?.toLowerCase()) {
		case 'dimension':
			jobGroupHeading = 'Dimension Import Queue';
			break;
		case 'keymeasure':
			jobGroupHeading = 'Key Measure Import Queue';
			break;
		case 'schemaupdate':
			jobGroupHeading = 'Schema Update Queue';
			break;
		default:
			jobGroupHeading = 'Ungrouped Queue';
	}

	return (
		<>
			<ExpandableSection
				toggleText={jobGroupHeading}
				onToggle={onOpen}
				isExpanded={isGroupExpanded}
				isIndented
				data-testid={`Job_type-${jobGroupHeading.toLowerCase().replace(' ', '_')}`}
			>
				<NotificationDrawerBody>
					<NotificationDrawerList>
						{Object.entries(groupedNotifications).map(
							([jobQueueId, groupedNotifications]) => {
								return (
									<NotificationJobGroup
										jobGroup={{
											jobQueueId: jobQueueId,
											notifications: groupedNotifications,
										}}
										notifications={notifications}
										setNotifications={setNotifications}
									/>
								);
							}
						)}
					</NotificationDrawerList>
				</NotificationDrawerBody>
			</ExpandableSection>
		</>
	);
};

export default NotificationsGroups;
