import { TDateRange } from '../../api/types';
import React, { useState } from 'react';
import { Tab, Tabs } from '@patternfly/react-core';
import MyPeriodButtons from './MyPeriodButtons';
import { useApplication } from '../user/ApplicationProvider';

type DatePeriodHtmlProps = {
	updateSelectedDates: (selectedDateRange?: TDateRange) => (TDateRange | undefined)[];
	selectedRange: [TDateRange | undefined, TDateRange | undefined];
};
const DatePeriodHtml = (props: DatePeriodHtmlProps) => {
	const { updateSelectedDates, selectedRange } = props;
	const { datePeriods } = useApplication();
	const [activeTabKey, setActiveTabKey] = useState(selectedRange[0]?.period ?? 3);
	// console.table(currentDatePeriods);
	const getDateRangesByPeriodId = (periodId: number) => {
		return datePeriods.filter((dp) => dp.period === periodId);
	};

	const handleTabSelect = (
		_event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setActiveTabKey(Number(tabIndex));
		updateSelectedDates();
	};

	return (
		<div>
			<Tabs
				activeKey={activeTabKey}
				onSelect={handleTabSelect}
			>
				<Tab
					eventKey={0}
					title={'Period:'}
					isDisabled
				></Tab>
				<Tab
					eventKey={4}
					title={'Week'}
				>
					{activeTabKey === 4 && (
						<MyPeriodButtons
							datePeriods={getDateRangesByPeriodId(4)}
							selectedRange={selectedRange}
							updateSelectedDates={updateSelectedDates}
						/>
					)}
				</Tab>
				<Tab
					eventKey={3}
					title={'Month'}
				>
					{activeTabKey === 3 && (
						<MyPeriodButtons
							datePeriods={getDateRangesByPeriodId(3)}
							selectedRange={selectedRange}
							updateSelectedDates={updateSelectedDates}
						/>
					)}
				</Tab>
				<Tab
					eventKey={2}
					title={'Quarter'}
				>
					{activeTabKey === 2 && (
						<MyPeriodButtons
							datePeriods={getDateRangesByPeriodId(2)}
							selectedRange={selectedRange}
							updateSelectedDates={updateSelectedDates}
						/>
					)}
				</Tab>
				<Tab
					eventKey={1}
					title={'Year'}
				>
					{activeTabKey === 1 && (
						<MyPeriodButtons
							datePeriods={getDateRangesByPeriodId(1)}
							selectedRange={selectedRange}
							updateSelectedDates={updateSelectedDates}
						/>
					)}
				</Tab>
			</Tabs>
			<br />
		</div>
	);
};
export default DatePeriodHtml;
