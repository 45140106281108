import React, { ReactElement, useEffect, useState } from 'react';
import {
	Button,
	Grid,
	GridItem,
	InputGroup,
	Modal,
	ModalVariant,
	TextVariants,
	Text,
	Select,
	SelectOption,
	SelectVariant,
	SelectDirection,
	SelectOptionObject,
} from '@patternfly/react-core';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; // Import the styles
import draftToHtml from 'draftjs-to-html';
import { GenericStringKeyValueResponse } from '../../../api/types';
import { ReportConditionalFormattingRuleEnum } from '../../../enums/report-condition-formatting-rule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
	ConditionalFormatRow,
	IConditionalFormattingModalProps,
} from '../../../types/conditional-rules/conditional-rules';
import {
	defaultPlaceholderHtml,
	getDefaultEditorState,
} from '../../../helpers/rte-edtior-states-helper';
import { extractRgbColor, rgbToHex } from '../../../helpers/color-hex-rgb-helper';
import Loader from '../../util/Loader';

const ConditionalFormattingModal = (props: IConditionalFormattingModalProps): ReactElement => {
	const [editorStates, setEditorStates] = useState<EditorState[]>([]);
	const [isConditionsDropdownOpen, setIsConditionsDropdownOpen] = React.useState<boolean[]>([
		false,
	]);
	const [selectedConditions, setSelectedConditions] = React.useState<
		(string | SelectOptionObject)[]
	>(['0']);
	const [conditionValues, setConditionValues] = useState<number[]>([]);
	const [selectedConditionsChosenOption, setSelectedConditionsChosenOption] = React.useState<
		boolean[]
	>([false]);
	const [conditionValues_AndValue1, setConditionValues_AndValue1] = useState<number[]>([]);
	const [conditionValues_AndValue2, setConditionValues_AndValue2] = useState<number[]>([]);
	const [existingConditionalRuleIds, setExistingConditionalRuleIds] = useState<
		GenericStringKeyValueResponse<number>[]
	>([]);

	useEffect(() => {
		CheckExistingConditionalRules();
	}, [props.rules]);

	useEffect(() => {
		selectedConditions.map((condtion, idx) => {
			if (condtion == ReportConditionalFormattingRuleEnum.BETWEEN) {
				handleBetweenDropdownOptionToggle(idx, true);
			} else {
				handleBetweenDropdownOptionToggle(idx, false);
			}
		});
	}, [selectedConditions]);

	const initDefaults = () => {
		setEditorStates([getDefaultEditorState()]);
		setInitialState(0);
		setSelectedConditions([]);
		setConditionValues([0]);
		setConditionValues_AndValue1([]);
		setConditionValues_AndValue2([]);
	};

	const CheckExistingConditionalRules = () => {
		initDefaults();

		if (props.rules.length) {
			const existingSelectedConditions: string[] = [];
			const existingSelectedConditionValues: number[] = [];

			let anyMatchingRules = false;
			const rulesForCurrentFact = props.rules.filter(
				(x) => x.key_measure_fact_id == props.fact?.data?.id
			);

			rulesForCurrentFact.map((rule, idx) => {
				setExistingConditionalRuleIds((prevState) => [
					...prevState,
					{ key: `${rule.key_measure_fact_id}_${idx}`, value: rule.id! },
				]);

				setInitialStateForExisting(idx, rule.editorHTML);
				existingSelectedConditions.push(rule.rule);
				existingSelectedConditionValues.push(rule.value);

				if (rule.rule == ReportConditionalFormattingRuleEnum.BETWEEN) {
					handleBetweenInputValuesInput1(idx, rule.value);
					handleBetweenInputValuesInput2(idx, rule.value2!);
				}
				anyMatchingRules = true;
			});

			if (anyMatchingRules) {
				setSelectedConditions(existingSelectedConditions);
				setConditionValues(existingSelectedConditionValues);
			} else {
				initDefaults();
			}
		}
	};

	// Helper to initialize editor state for a specific index
	const setInitialState = (index: number) => {
		const html = defaultPlaceholderHtml;
		const contentBlock = htmlToDraft(html);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const newEditorState = EditorState.createWithContent(contentState);

			setEditorStates((prev) => {
				const updatedStates = [...prev];
				// Ensure the array has enough space for the current index
				if (!updatedStates[index]) {
					updatedStates[index] = EditorState.createEmpty();
				}
				updatedStates[index] = newEditorState;
				return updatedStates;
			});
		}
	};

	const setInitialStateForExisting = (index: number, html: string) => {
		const templateFormattedHtml = html
			.replace('{0}', 'AaBbCcYyZz')
			.replace('<p>', '<p style="text-align:center;">');
		const contentBlock = htmlToDraft(templateFormattedHtml);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const newEditorState = EditorState.createWithContent(contentState);

			setEditorStates((prev) => {
				const updatedStates = [...prev];
				updatedStates[index] = newEditorState;
				return updatedStates;
			});
		}
	};

	// Handler for changing the editor state
	const onEditorStateChange = (index: number, editorState: EditorState) => {
		setEditorStates((prev) => {
			const updatedStates = [...prev];
			updatedStates[index] = editorState;
			return updatedStates;
		});
	};

	// Handle editor state change and apply bold formatting if necessary
	const handleEditorStateChange = (index: number, editorState: EditorState) => {
		onEditorStateChange(index, editorState);
	};

	// Toggle dropdown open/close state for a specific index
	const handleDropdownToggle = (index: number, isOpen: boolean) => {
		setIsConditionsDropdownOpen((prev) => {
			const updatedOpenStates = [...prev];
			updatedOpenStates[index] = isOpen;
			return updatedOpenStates;
		});
	};

	const handleBetweenDropdownOptionToggle = (index: number, isBetweenOption: boolean) => {
		setSelectedConditionsChosenOption((prev) => {
			const updatedOpenStates = [...prev];
			updatedOpenStates[index] = isBetweenOption;
			return updatedOpenStates;
		});
	};

	const handleBetweenInputValuesInput1 = (index: number, value: number) => {
		setConditionValues_AndValue1((prev) => {
			const updatedOpenStates = [...prev];
			updatedOpenStates[index] = value;
			return updatedOpenStates;
		});
	};

	const handleBetweenInputValuesInput2 = (index: number, value: number) => {
		setConditionValues_AndValue2((prev) => {
			const updatedOpenStates = [...prev];
			updatedOpenStates[index] = value;
			return updatedOpenStates;
		});
	};

	// Handle dropdown selection for a specific index
	const handleDropdownSelect = (
		index: number,
		event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
		selection: string | SelectOptionObject
	) => {
		setSelectedConditions((prev) => {
			const updatedSelections = [...prev];
			updatedSelections[index] = selection;
			return updatedSelections;
		});
		handleDropdownToggle(index, false);
	};

	// Handle input value change for a specific index
	const handleInputChange = (index: number, value: number) => {
		setConditionValues((prev) => {
			const updatedValues = [...prev];
			updatedValues[index] = value;
			return updatedValues;
		});
	};

	const onSave = () => {
		const rules: ConditionalFormatRow[] = [];

		editorStates.forEach((editorState, idx) => {
			const rawContentState = convertToRaw(editorState.getCurrentContent());
			const htmlContent = draftToHtml(rawContentState);
			const selectedCondition = selectedConditions[idx];
			const betweenOptionSelected = selectedConditionsChosenOption[idx];
			const actualConditionValue = conditionValues[idx];
			const betweenConditionVal1 = conditionValues_AndValue1[idx];
			const betweenConditionVal2 = conditionValues_AndValue2[idx];
			const isBold = htmlContent.includes('<strong>');
			const isItalic = htmlContent.includes('<em>');
			const isUnderline = htmlContent.includes('<ins>');
			const isStrikethrough = htmlContent.includes('<del>');
			const textColor = htmlContent.includes('color: rgb(')
				? rgbToHex(extractRgbColor(htmlContent, 'color')!)
				: undefined;
			const backgroundColor = htmlContent.includes('background-color: rgb(')
				? rgbToHex(extractRgbColor(htmlContent, 'background-color')!)
				: undefined;
			const formattedHtmlContent = htmlContent
				.replace(/style="text-align:center;"\s*/, '')
				.replace('<p >', '<p>')
				.replace('AaBbCcYyZz', '{0}');

			const existingRuleId = existingConditionalRuleIds.find(
				(x) => x.key == `${props.fact?.data?.id ?? 0}_${idx}`
			);

			rules.push({
				...(existingRuleId ? { id: existingRuleId?.value } : {}),
				editorHTML: formattedHtmlContent,
				isBold: isBold,
				isItalic: isItalic,
				isStrikethrough: isStrikethrough,
				isUnderline: isUnderline,
				backgroundColor: backgroundColor,
				textColor: textColor,
				selectedConditionValue:
					selectedCondition && selectedCondition.toString() != ''
						? (selectedCondition as ReportConditionalFormattingRuleEnum)
						: ReportConditionalFormattingRuleEnum.GREATER_THAN,
				normalConditionValue: !betweenOptionSelected ? actualConditionValue : undefined,
				betweenConditionValue1: betweenOptionSelected ? betweenConditionVal1 : undefined,
				betweenConditionValue2: betweenOptionSelected ? betweenConditionVal2 : undefined,
			});
		});

		props.handleSave(
			rules.map((rule) => {
				return {
					...(rule.id ? { id: rule?.id } : {}),
					report: props.reportId,
					background_color: rule.backgroundColor ?? '',
					color: rule.textColor,
					value:
						rule.selectedConditionValue == ReportConditionalFormattingRuleEnum.BETWEEN
							? rule.betweenConditionValue1 ?? 0
							: rule.normalConditionValue ?? 0,
					value2:
						rule.selectedConditionValue == ReportConditionalFormattingRuleEnum.BETWEEN
							? rule.betweenConditionValue2
							: undefined,
					rule: rule.selectedConditionValue,
					editorHTML: rule.editorHTML,
					key_measure_fact_id: props.fact?.data?.id ?? 0,
					start_range: 0,
					end_range: 0,
				};
			})
		);
	};

	const preventKeyDownOnRTE = (event: React.KeyboardEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	const handleKeyCommand = (command: string): 'handled' | 'not-handled' => {
		const blockedKeys = ['backspace', 'delete', 'enter', 'split-block'];
		if (blockedKeys.includes(command)) {
			return 'handled'; // This prevents the default action for blocked keys
		}
		return 'not-handled'; // Allow other commands to be handled normally
	};

	// Dynamically generate a new template item for each condition
	const templateItem = (index: number) => (
		<React.Fragment key={index}>
			<br />
			<label>
				<u>Condition {index + 1}</u>
			</label>
			<Button
				key={`del-condition-${index}`}
				variant="primary"
				className="del-row-rule"
				onClick={() => deleteConditionRow(index)}
			>
				<FontAwesomeIcon icon={faTimes} />
			</Button>
			<br />
			<Grid span={12}>
				<GridItem span={3}>
					<Text component={TextVariants.h2}>{props.fact?.data?.title}</Text>{' '}
				</GridItem>
				<GridItem span={4}>
					<Select
						variant={SelectVariant.single}
						onToggle={(isOpen) => handleDropdownToggle(index, isOpen)}
						onSelect={(event, selection) =>
							handleDropdownSelect(index, event, selection)
						}
						selections={selectedConditions[index]}
						isOpen={isConditionsDropdownOpen[index]}
						isDisabled={false}
						direction={SelectDirection.down}
						menuAppendTo={() => document.body}
					>
						{getConditionOptions()}
					</Select>
				</GridItem>
				{!selectedConditionsChosenOption[index] && (
					<GridItem span={5}>
						<input
							type="number"
							className="pf-c-form-control"
							value={conditionValues[index]} // Set the input value
							onChange={(e) => handleInputChange(index, +e.target.value)}
						/>
					</GridItem>
				)}
				{selectedConditionsChosenOption[index] && (
					<>
						<GridItem span={2}>
							<input
								type="number"
								className="pf-c-form-control"
								value={conditionValues_AndValue1[index]} // Set the input value
								onChange={(e) =>
									handleBetweenInputValuesInput1(index, +e.target.value)
								}
							/>
						</GridItem>
						<GridItem span={1}>
							<Text
								className="lbl-and"
								component={TextVariants.h2}
							>
								AND
							</Text>
						</GridItem>
						<GridItem span={2}>
							<input
								type="number"
								className="pf-c-form-control"
								value={conditionValues_AndValue2[index]} // Set the input value
								onChange={(e) =>
									handleBetweenInputValuesInput2(index, +e.target.value)
								}
							/>
						</GridItem>
					</>
				)}
			</Grid>
			<InputGroup onKeyDown={(e) => preventKeyDownOnRTE(e)}>
				<Editor
					editorState={editorStates[index]}
					toolbar={{
						options: ['inline', 'colorPicker'],
						inline: {
							options: ['bold', 'italic', 'underline', 'strikethrough'],
						},
					}}
					onEditorStateChange={(editorState) =>
						handleEditorStateChange(index, editorState)
					}
					handleKeyCommand={(command) => handleKeyCommand(command)}
				/>
			</InputGroup>
		</React.Fragment>
	);

	// Add more conditions logic
	const addNewConditionRow = () => {
		// Set the default editor state for the new condition
		setEditorStates([...editorStates, getDefaultEditorState()]);
	};

	const deleteConditionRow = (idx: number) => {
		setEditorStates((prevEditorStates) => {
			// Check if the index is within the bounds of the array
			if (idx >= 0 && idx < prevEditorStates.length) {
				// Create a new array excluding the editor state at the provided index
				return [...prevEditorStates.slice(0, idx), ...prevEditorStates.slice(idx + 1)];
			}
			// Return the same array if the index is out of bounds
			return prevEditorStates;
		});
	};

	const onModalClose = () => {
		initDefaults();
		props.handleClose();
	};

	// Get condition options for the dropdown
	const getConditionOptions = (): JSX.Element[] => {
		return [
			<SelectOption
				value={ReportConditionalFormattingRuleEnum.GREATER_THAN}
				key={'condition_0'}
			>
				Greater Than
			</SelectOption>,
			<SelectOption
				value={ReportConditionalFormattingRuleEnum.GREATER_THAN_OR_EQUAL_TO}
				key={'condition_1'}
			>
				Greater Than Or Equal
			</SelectOption>,
			<SelectOption
				value={ReportConditionalFormattingRuleEnum.LESS_THAN}
				key={'condition_2'}
			>
				Less Than
			</SelectOption>,
			<SelectOption
				value={ReportConditionalFormattingRuleEnum.LESS_THAN_OR_EQUAL_TO}
				key={'condition_3'}
			>
				Less Than Or Equal
			</SelectOption>,
			<SelectOption
				value={ReportConditionalFormattingRuleEnum.EQUAL_TO}
				key={'condition_4'}
			>
				Equals
			</SelectOption>,
			<SelectOption
				value={ReportConditionalFormattingRuleEnum.NOT_EQUAL_TO}
				key={'condition_5'}
			>
				Not Equal To
			</SelectOption>,
			<SelectOption
				value={ReportConditionalFormattingRuleEnum.BETWEEN}
				key={'condition_6'}
			>
				Between
			</SelectOption>,
		];
	};

	return (
		<Modal
			title="Conditional Formatting"
			className="cond-format-modal-body"
			variant={ModalVariant.medium}
			isOpen={true}
			onClose={onModalClose}
			actions={[
				<Button
					key="confirm"
					variant="primary"
					onClick={onSave}
				>
					Apply Rules
				</Button>,
				<Button
					key="cancel"
					variant="secondary"
					onClick={onModalClose}
				>
					Cancel
				</Button>,
			]}
		>
			{editorStates.length > 0 ? (
				editorStates.map((_, index) => (
					<React.Fragment key={index}>{templateItem(index)}</React.Fragment>
				))
			) : (
				<Loader />
			)}
			<br />
			<Button
				key="new-condition"
				variant="primary"
				className="pull-right"
				onClick={addNewConditionRow}
			>
				<FontAwesomeIcon
					className="modal-icon"
					icon={faPlus}
				/>
				&nbsp; New Condition
			</Button>
		</Modal>
	);
};

export default ConditionalFormattingModal;
