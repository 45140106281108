import axiosConfig from '../axiosConfig';
import { TFolder } from '../foundational-elements/Folder';

export type TPresent = {
	id?: number;
	description: string;
	name: string;
	owner: number;
	folder?: TFolder | number;
};

export class Present implements TPresent {
	id?: number;
	description: string;
	name: string;
	owner: number;
	folder?: TFolder | number;

	constructor(dashboard: TPresent) {
		this.id = dashboard.id;
		this.description = dashboard.description;
		this.name = dashboard.name;
		this.owner = dashboard.owner;
		this.folder = dashboard.folder;
	}

	public static Default(): TPresent {
		return {
			description: '',
			name: '',
			owner: 0,
		};
	}

	public static New(dashboard: TPresent): Promise<TPresent> {
		return axiosConfig.instance.post('app/present', dashboard).then((res) => {
			return new Present(res.data as TPresent);
		});
	}

	public static Edit(dashboard: TPresent): Promise<TPresent> {
		return axiosConfig.instance.patch(`app/present`, dashboard).then((res) => {
			return new Present(res.data as TPresent);
		});
	}

	public static Get(presentId: number): Promise<TPresent> {
		return axiosConfig.instance.get(`app/present/${presentId}`).then((res) => {
			return new Present(res.data as TPresent);
		});
	}

	public static GetAll(expand: (keyof TPresent)[] = []): Promise<TPresent[]> {
		return axiosConfig.instance
			.get<TPresent[]>('app/present', { params: { expand } })
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}

	public static Delete(dashboardId: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/dashboard/${dashboardId}`).then((res) => {
			return res.data as boolean;
		});
	}
}
