import React, { useEffect } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { OutletContext } from '../../layout/Layout';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { PresentContext } from '../../types/present';
import { Card, CardBody } from '@patternfly/react-core';
import { FolderTypesEnum } from '../../enums/folder-types-enum';
import { useMount } from 'react-use';

export enum ListType {
	All,
	Recents,
	Favorites,
}

type Props = {
	pageContext: PresentContext;
	listType?: ListType;
};

const Present = (props: Props) => {
	const { pageContext } = props;
	const navigate = useNavigate();
	const { folderId } = useParams();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();

	useMount(() => {
		const prevPresentPage = localStorage.getItem('prevPresentPage');
		if (prevPresentPage) {
			navigate(`/present/${prevPresentPage}`);
		}
	});

	useEffect(() => {
		const headerContext = {
			header: '',
			subheader: '',
		};

		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Present"
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
					subNavContext={pageContext.subNavContext}
				/>
			),
			sidebarContext: pageContext.sideBarContext,
			sidebarHeaderContext: headerContext,
			sidebarSearch: {
				title: 'Search Presentaions',
				placeholder: 'Search Presentations',
				submit: (term: string | undefined) => {
					let url = `/present`;
					if (folderId) {
						url = `${url}/${folderId}`;
					}
					if (term) {
						url = `${url}/search?term=${term}`;
					}
					navigate(url);
				},
			},
			folderHeadingTitle: 'Presentations',
			folderType: FolderTypesEnum.Presentation,
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	return (
		<Card>
			<CardBody>
				<Outlet />
			</CardBody>
		</Card>
	);
};

export default Present;
