import axiosConfig from '../axiosConfig';
import { LambdaInsightsRequest, LambdaInsightsResponse, GenericResponse } from '../types';

export class Lambda {
	public static GetInsights(
		request: LambdaInsightsRequest
	): Promise<GenericResponse<LambdaInsightsResponse>> {
		return axiosConfig.instance.post('app/lambda', request).then((res) => {
			return res.data as GenericResponse<LambdaInsightsResponse>;
		});
	}
}
