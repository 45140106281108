import { TRecent, Recent } from '../../api/recents/recents';
import { RecentTypesEnum } from '../../enums/recent-types.enum';
import { CardProps } from '../../types/cards/card-props';

export const addNewRecentDataframe = (id: string) => {
	const recentRequest: TRecent = {
		entity_id: +id,
		entity_type: RecentTypesEnum.Dataframe,
	};
	void Recent.New(recentRequest);
};

export const addNewRecentChart = (id: string) => {
	const recentRequest: TRecent = {
		entity_id: +id,
		entity_type: RecentTypesEnum.Charts,
	};
	void Recent.New(recentRequest);
};

export const addNewRecentWidget = (id: number) => {
	const recentRequest: TRecent = {
		entity_id: id,
		entity_type: RecentTypesEnum.Widget,
	};
	void Recent.New(recentRequest);
};

export const addNewRecentReport = (id: string) => {
	const recentRequest: TRecent = {
		entity_id: +id,
		entity_type: RecentTypesEnum.Report,
	};
	void Recent.New(recentRequest);
};

export const addNewRecentTable = (id: string) => {
	const recentRequest: TRecent = {
		entity_id: +id,
		entity_type: RecentTypesEnum.Table,
	};
	void Recent.New(recentRequest);
};

export const addNewRecentDashboard = (id: string) => {
	const recentRequest: TRecent = {
		entity_id: +id,
		entity_type: RecentTypesEnum.Dashboard,
	};
	void Recent.New(recentRequest);
};

export const addNewRecentPresentation = (id: string) => {
	const recentRequest: TRecent = {
		entity_id: +id,
		entity_type: RecentTypesEnum.Presentation,
	};
	void Recent.New(recentRequest);
};

export const addNewRecentQuestion = (id: string) => {
	const recentRequest: TRecent = {
		entity_id: +id,
		entity_type: RecentTypesEnum.Question,
	};
	void Recent.New(recentRequest);
};

export const sortByTimestamp = (cards: CardProps[]): CardProps[] => {
	return cards.sort((a, b) => {
		const dateA = a.timestamp;
		const dateB = b.timestamp;

		if (dateA !== undefined && dateB !== undefined) {
			return dateB - dateA;
		} else {
			return 0;
		}
	});
};
