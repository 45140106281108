import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, Grid, GridItem, Text, Tooltip } from '@patternfly/react-core';
import React from 'react';
import './ActionCard.scss';
import { useNavigate } from 'react-router-dom';
import { SubNavAction } from '../../helpers/sub-nav-contexts/sub-nav-context';

type Props = {
	key: number;
	actionItem: SubNavAction;
};

const ActionCard = (props: Props) => {
	const {
		actionItem: { url, title, icon, tooltip, prevUrlRefObject },
	} = props;

	const navigate = useNavigate();

	const innerTemplate = (
		<Grid
			onClick={() => {
				if (prevUrlRefObject) {
					localStorage.removeItem(prevUrlRefObject);
				}
				navigate(url);
			}}
			className="action-card-container"
		>
			<GridItem
				span={12}
				className="action-card"
			>
				<Grid>
					<GridItem
						span={10}
						offset={1}
					>
						<Flex alignItems={{ default: 'alignItemsCenter' }}>
							<Flex
								alignItems={{ default: 'alignItemsCenter' }}
								className="action-card-icon"
							>
								<FontAwesomeIcon icon={icon} />
							</Flex>
							<Flex
								className="action-card-title"
								flex={{ default: 'flex_4' }}
							>
								<Text>{title}</Text>
							</Flex>
						</Flex>
					</GridItem>
				</Grid>
			</GridItem>
		</Grid>
	);

	return (
		<GridItem span={4}>
			{tooltip ? <Tooltip content={tooltip}>{innerTemplate}</Tooltip> : innerTemplate}
		</GridItem>
	);
};

export default ActionCard;
