import React, { ReactElement } from 'react';
import { TUnitType } from '../../../api/analytics/UnitType';
import {
	Button,
	Form,
	FormGroup,
	Modal,
	ModalVariant,
	Select,
	SelectDirection,
	SelectOption,
	SelectOptionObject,
	SelectVariant,
} from '@patternfly/react-core';
import { DraggableMenuItemData } from '../../../types/databuilder/databuilder';

export type IUnitTypeModalProps = {
	unitTypes: TUnitType[];
	fact: DraggableMenuItemData | null;
	handleClose: () => void;
	handleSave: (fact: DraggableMenuItemData) => void;
};

const ChangeUnitTypeModal = (props: IUnitTypeModalProps): ReactElement => {
	const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
	const [selected, setSelected] = React.useState<string | SelectOptionObject>(
		props.fact && props.fact.data?.unitType ? props.fact.data?.unitType.toString() : ''
	);

	const onCloseModal = () => {
		props.handleClose();
	};

	const onSaveModal = () => {
		const fact = Object.assign({}, props.fact);

		if (selected && fact.data) {
			fact.data.unitType = parseInt(selected.toString());
		}

		props.handleSave(fact);
	};

	const onToggleDropdown = (isOpen: boolean) => {
		setIsDropdownOpen(isOpen);
	};

	const onSelectDropdown = (
		event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
		selection: string | SelectOptionObject,
		isPlaceholder?: boolean | undefined
	) => {
		if (!isPlaceholder) {
			setSelected(selection);
			setIsDropdownOpen(false);
		}
	};

	const getOptions = (): JSX.Element[] => {
		return props.unitTypes.map((type): ReactElement => {
			return (
				<SelectOption
					value={type.id.toString()}
					key={type.code}
				>
					{type.name}
				</SelectOption>
			);
		});
	};

	return (
		<React.Fragment>
			<Modal
				title="Change Unit Type"
				variant={ModalVariant.small}
				isOpen={true}
				onClose={onCloseModal}
				data-testid="change-unit-type-modal"
				actions={[
					<Button
						key="confirm"
						variant="primary"
						onClick={onSaveModal}
					>
						Save
					</Button>,
					<Button
						key="cancel"
						variant="secondary"
						onClick={onCloseModal}
					>
						Cancel
					</Button>,
				]}
			>
				<Form>
					<FormGroup
						label="Unit Type"
						fieldId="unit_type"
						data-testid="change-unit-type-select-form-group"
					>
						<Select
							variant={SelectVariant.single}
							onToggle={onToggleDropdown}
							onSelect={onSelectDropdown}
							selections={selected}
							isOpen={isDropdownOpen}
							isDisabled={false}
							direction={SelectDirection.down}
							menuAppendTo={() => document.body}
							data-testid="change-unit-type-select"
							className="selectUnitType"
						>
							{getOptions()}
						</Select>
					</FormGroup>
				</Form>
			</Modal>
		</React.Fragment>
	);
};

export default ChangeUnitTypeModal;
