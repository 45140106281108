import React, { useRef, useState } from 'react';
import { Button, Form, FormGroup, Modal, Text, TextInput } from '@patternfly/react-core';
import './ChangePasswordModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { TUser, User } from '../../api/security/User';
import ChangePasswordSuccessModal from './ChangePasswordSuccessModal';
import { TPassword } from '../../api/types/password/password';

type Props = {
	isOpen: boolean;
	user: TUser | undefined;
	onClose: () => void;
};

function ChangePasswordModal(props: Props) {
	const currentPasswordRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);
	const confirmPasswordRef = useRef<HTMLInputElement>(null);
	const [isChangePasswordSuccessModalOpen, setIsChangePasswordSuccessModalOpen] =
		useState<boolean>(false);
	const [meter, setMeter] = useState(false);
	const [icon, setIcon] = useState({
		currentPassword: faEyeSlash,
		password: faEyeSlash,
		confirmPassword: faEyeSlash,
	});
	const [input, setInput] = useState({
		currentPassword: '',
		password: '',
		confirmPassword: '',
	});
	const [error, setError] = useState({
		currentPassword: 'Please Enter Current Password',
		password: 'Please Enter New Password',
		confirmPassword: 'Please Enter Confirm Password',
	});
	const [type, setType] = useState({
		currentPassword: 'password',
		password: 'password',
		confirmPassword: 'password',
	});

	const atLeastOneUppercase = /[A-Z]/g;
	const atLeastOneLowercase = /[a-z]/g;
	const atLeastOneNumeric = /[0-9]/g;
	const atLeastOneSpecialChar = /[#?!@$%^&*-]/g;
	const betweenSixAndSixteenChars = /.{6,16}/g;

	const resetPassword = () => {
		const password = passwordRef.current?.value;
		const currentPassword = currentPasswordRef.current?.value ?? '';

		if (password) {
			const user_id = props.user?.id;
			if (user_id === null) {
				return;
			}

			User.ResetPassword(user_id || -1, password, '', true, currentPassword)
				.then((response: void | TPassword) => {
					const passwordResponse = response as TPassword;

					if (passwordResponse.data && passwordResponse.data.success) {
						props.onClose();
						setIsChangePasswordSuccessModalOpen(true);
					}
				})
				.catch(() => {
					return false;
				});
		}
	};

	const onInputChange = (value: string, event: React.FormEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const { name } = target;

		setInput((prev) => ({
			...prev,
			[name]: value,
		}));

		validateInput(value, target);
	};

	const validateInput = (value: string, target: HTMLInputElement) => {
		const { name } = target;

		setError((prev) => {
			const stateObj = { ...prev, [name]: '' };

			switch (name) {
				case 'currentPassword':
					if (!value) {
						stateObj[name] = 'Please Enter Current Password';
					}
					break;

				case 'password':
					if (!value) {
						stateObj[name] = 'Please Enter New Password';
					} else if (input.confirmPassword && value !== input.confirmPassword) {
						stateObj['confirmPassword'] =
							'Password and Confirm Password does not match';
					} else {
						stateObj['confirmPassword'] = input.confirmPassword
							? ''
							: error.confirmPassword;
					}
					break;

				case 'confirmPassword':
					if (!value) {
						stateObj[name] = 'Please Enter Confirm Password';
					} else if (input.password && value !== input.password) {
						stateObj[name] = 'Password and Confirm Password does not match';
					}
					break;

				default:
					break;
			}

			return stateObj;
		});
	};

	const toggleType = (event: React.MouseEvent<HTMLSpanElement>) => {
		const target = event.currentTarget;
		const { id } = target;

		setType((prev) => {
			const stateObj = { ...prev, [id]: '' };

			switch (id) {
				case 'currentPassword':
					if (prev.currentPassword === 'password') {
						stateObj[id] = 'text';
					} else {
						stateObj[id] = 'password';
					}
					break;

				case 'password':
					if (prev.password === 'password') {
						stateObj[id] = 'text';
					} else {
						stateObj[id] = 'password';
					}
					break;

				case 'confirmPassword':
					if (prev.confirmPassword === 'password') {
						stateObj[id] = 'text';
					} else {
						stateObj[id] = 'password';
					}
					break;

				default:
					break;
			}

			return stateObj;
		});

		setIcon((prev) => {
			const stateObj = { ...prev, [id]: '' };

			switch (id) {
				case 'currentPassword':
					if (prev.currentPassword === faEyeSlash) {
						stateObj[id] = faEye;
					} else {
						stateObj[id] = faEyeSlash;
					}
					break;

				case 'password':
					if (prev.password === faEyeSlash) {
						stateObj[id] = faEye;
					} else {
						stateObj[id] = faEyeSlash;
					}
					break;

				case 'confirmPassword':
					if (prev.confirmPassword === faEyeSlash) {
						stateObj[id] = faEye;
					} else {
						stateObj[id] = faEyeSlash;
					}
					break;

				default:
					break;
			}

			return stateObj;
		});
	};

	const matchPasswordLength = (r: RegExp, password: string) => {
		const match = password.match(r);
		return match && match.length == 1;
	};

	const closeChangePasswordSuccessModal = () => {
		setIsChangePasswordSuccessModalOpen(false);
	};

	const passwordTracker = {
		uppercase: input.password.match(atLeastOneUppercase),
		lowercase: input.password.match(atLeastOneLowercase),
		number: input.password.match(atLeastOneNumeric),
		specialChar: input.password.match(atLeastOneSpecialChar),
		betweenSixAndSixteenChars: matchPasswordLength(betweenSixAndSixteenChars, input.password),
		passwordsMatch: input.password === input.confirmPassword,
	};

	const passwordStrength = Object.values(passwordTracker).filter((value) => value).length;

	const getStrengthColor = (score: number) => {
		switch (score) {
			case 1:
				return '#ff4d4d';
			case 2:
				return '#ff8533';
			case 3:
				return '#ffcc00';
			case 4:
				return '#99cc00';
			case 5:
				return 'green';
			case 6:
				return 'green';
			default:
				return '#f5f5f5';
		}
	};

	const getStrengthText = (score: number) => {
		switch (score) {
			case 1:
				return 'Weak';
			case 2:
				return 'Fair';
			case 3:
				return 'OK';
			case 4:
				return 'Good';
			case 5:
				return 'Strong';
			case 6:
				return 'Strong';
		}
	};

	return (
		<>
			<Modal
				title="Reset Password"
				variant="small"
				isOpen={props.isOpen}
				onClose={props.onClose}
				actions={[
					<Button
						key="confirm"
						variant="primary"
						onClick={resetPassword}
						data-testid="passwordreset-submit"
						isDisabled={passwordStrength < 6}
					>
						Save
					</Button>,
					<Button
						key="cancel"
						variant="secondary"
						onClick={props.onClose}
						data-testid="passwordreset-cancel"
					>
						Cancel
					</Button>,
				]}
			>
				<Form className="password-form">
					<FormGroup
						label="Enter Current Password"
						isRequired
						fieldId="currentPassword"
						helperTextInvalid={error.currentPassword}
						helperTextInvalidIcon={<>*</>}
						validated={error.currentPassword ? 'error' : 'default'}
					>
						<TextInput
							isRequired
							onChange={onInputChange}
							type={
								type.currentPassword === 'password' ? type.currentPassword : 'text'
							}
							name="currentPassword"
							id="current-password"
							aria-label="Current Password"
							data-testid="currentpassword-password"
							autoComplete="current-password"
							ref={currentPasswordRef}
						/>
						<span className="p-validate">
							{error.currentPassword === '' ? (
								<FontAwesomeIcon
									icon={faCheck}
									className="fa-check"
								/>
							) : (
								<FontAwesomeIcon
									icon={faXmark}
									className="fa-x"
								/>
							)}
						</span>
						<span
							id="currentPassword"
							className="p-viewer"
							onClick={toggleType}
						>
							<FontAwesomeIcon icon={icon.currentPassword} />
						</span>
					</FormGroup>
					<FormGroup
						label="Enter New Password"
						isRequired
						fieldId="newPassword"
						helperTextInvalid={error.password}
						helperTextInvalidIcon={<>*</>}
						validated={error.password ? 'error' : 'default'}
					>
						<TextInput
							isRequired
							onFocus={() => setMeter(true)}
							onChange={onInputChange}
							type={type.password === 'password' ? type.password : 'text'}
							name="password"
							id="password"
							aria-label="New Password"
							data-testid="passwordreset-password"
							autoComplete="new-password"
							ref={passwordRef}
						/>
						<span className="p-validate">
							{error.password === '' ? (
								<FontAwesomeIcon
									icon={faCheck}
									className="fa-check"
								/>
							) : (
								<FontAwesomeIcon
									icon={faXmark}
									className="fa-x"
								/>
							)}
						</span>
						<span
							id="password"
							className="p-viewer"
							onClick={toggleType}
						>
							<FontAwesomeIcon icon={icon.password} />
						</span>
					</FormGroup>
					<FormGroup
						label="Please Confirm New Password"
						isRequired
						fieldId="confirmPassword"
						helperTextInvalid={error.confirmPassword}
						helperTextInvalidIcon={<>*</>}
						validated={error.confirmPassword ? 'error' : 'default'}
					>
						<TextInput
							isRequired
							onChange={onInputChange}
							type={
								type.confirmPassword === 'password' ? type.confirmPassword : 'text'
							}
							name="confirmPassword"
							id="confirm-password"
							aria-label={'Confirm New Password'}
							data-testid="passwordreset-confirmpassword"
							autoComplete="new-password"
							ref={confirmPasswordRef}
						/>
						<span className="p-validate">
							{error.confirmPassword === '' ? (
								<FontAwesomeIcon
									icon={faCheck}
									className="fa-check"
								/>
							) : (
								<FontAwesomeIcon
									icon={faXmark}
									className="fa-x"
								/>
							)}
						</span>
						<span
							id="confirmPassword"
							className="p-viewer"
							onClick={toggleType}
						>
							<FontAwesomeIcon icon={icon.confirmPassword} />
						</span>
					</FormGroup>

					{meter && (
						<>
							<Text>Password Strength Indicator</Text>
							<div
								className="password-strength"
								style={{
									borderColor: getStrengthColor(passwordStrength),
								}}
							>
								<div
									className="password-strength-meter"
									style={{
										width: `${(passwordStrength / 5) * 100}%`,
										backgroundColor: getStrengthColor(passwordStrength),
									}}
								>
									<p>{getStrengthText(passwordStrength)}</p>
								</div>
							</div>
						</>
					)}

					<div>
						<Text>
							{passwordTracker.uppercase ? (
								<FontAwesomeIcon
									icon={faCheck}
									color="green"
									className="fontAwesomePadding fa-check"
								/>
							) : (
								<FontAwesomeIcon
									icon={faXmark}
									color="red"
									className="fontAwesomePadding fa-x"
								/>
							)}
							MUST contain at least one uppercase letter
						</Text>
						<Text>
							{passwordTracker.lowercase ? (
								<FontAwesomeIcon
									icon={faCheck}
									color="green"
									className="fontAwesomePadding fa-check"
								/>
							) : (
								<FontAwesomeIcon
									icon={faXmark}
									color="red"
									className="fontAwesomePadding fa-x"
								/>
							)}
							MUST contain at least one lowercase letter
						</Text>
						<Text>
							{passwordTracker.number ? (
								<FontAwesomeIcon
									icon={faCheck}
									color="green"
									className="fontAwesomePadding fa-check"
								/>
							) : (
								<FontAwesomeIcon
									icon={faXmark}
									color="red"
									className="fontAwesomePadding fa-x"
								/>
							)}
							MUST contain at least one number
						</Text>
						<Text>
							{passwordTracker.specialChar ? (
								<FontAwesomeIcon
									icon={faCheck}
									color="green"
									className="fontAwesomePadding fa-check"
								/>
							) : (
								<FontAwesomeIcon
									icon={faXmark}
									color="red"
									className="fontAwesomePadding fa-x"
								/>
							)}
							MUST contain at least one special character (!@#$%^&+()_*,etc.)
						</Text>
						<Text>
							{passwordTracker.betweenSixAndSixteenChars ? (
								<FontAwesomeIcon
									icon={faCheck}
									color="green"
									className="fontAwesomePadding fa-check"
								/>
							) : (
								<FontAwesomeIcon
									icon={faXmark}
									color="red"
									className="fontAwesomePadding fa-x"
								/>
							)}
							MUST contain between 6 and 16 characters
						</Text>
					</div>
				</Form>
			</Modal>
			<ChangePasswordSuccessModal
				isOpen={isChangePasswordSuccessModalOpen}
				onClose={closeChangePasswordSuccessModal}
			/>
		</>
	);
}

export default ChangePasswordModal;
