import {
	Spinner,
	Alert,
	Button,
	Form,
	FormGroup,
	TextInput,
	ActionGroup,
} from '@patternfly/react-core';
import React, { ReactElement, useEffect, useState } from 'react';
import './LoginPage.scss';
import { Login } from '../../../api/login/Login';
import CenteredPage from '../../../layout/CenteredPage';
import axios, { AxiosError } from 'axios';
import axiosConfig, { IZIApiError } from '../../../api/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { getCSRFToken } from '../../../api/security/CSRFToken';

export default function LoginPage(): ReactElement {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<React.ReactElement | null>();
	const navigate = useNavigate();

	useEffect(() => {
		getCSRFToken()
			.then((token) => {
				axiosConfig.instance.defaults.headers['CSRF-TOKEN'] = token;
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Enter') {
				login();
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			login();
		}
	};

	const login = () => {
		if (loading) {
			return;
		}

		let login_username = '';
		let login_password = '';

		if (username === '' || password === '') {
			const usernameInput = document.getElementById('username') as HTMLInputElement;
			login_username = usernameInput.value;

			const passwordInput = document.getElementById('password') as HTMLInputElement;
			login_password = passwordInput.value;
		} else {
			login_username = username;
			login_password = password;
		}

		if (login_username === '' || login_password === '') {
			setMessage(
				<Alert
					variant="danger"
					isInline
					isPlain
					title="Username and password must be set."
				/>
			);

			return;
		}

		setMessage(null);
		setLoading(true);

		Login(login_username, login_password)
			.then(() => {
				navigate('/getting-started');
			})
			.catch((err: unknown) => {
				if (axios.isAxiosError(err)) {
					const axiosErr: AxiosError = err as AxiosError;

					const data = axiosErr.response?.data as IZIApiError;

					if (data === undefined) {
						setMessage(
							<Alert
								variant="danger"
								isInline
								isPlain
								title="An unknown error occurred. Please try again."
								data-testid="login-error"
							/>
						);
					} else {
						setMessage(
							<Alert
								variant="danger"
								isInline
								isPlain
								title={(axiosErr.response?.data as IZIApiError).detail}
								data-testid="login-error"
							/>
						);
					}

					setLoading(false);
				}
			});
	};

	const button = loading ? (
		<div className="spinner-container">
			<Spinner size={'lg'} />
		</div>
	) : (
		<Button
			onClick={login}
			variant="primary"
			data-testid="login-submit"
			type="button"
			className="btn-login"
		>
			Login
		</Button>
	);

	return (
		<CenteredPage
			description="Please enter your login credentials:"
			notice={message}
		>
			<Form autoComplete="on">
				<FormGroup
					label="User Account:"
					isRequired
				>
					<TextInput
						isRequired
						type="text"
						id={'username'}
						name="username"
						aria-label={'User Account'}
						onChange={(e) => setUsername(e)}
						data-testid="login-username"
						onKeyDown={handleKeyDown}
					></TextInput>
				</FormGroup>
				<FormGroup
					label="User Password:"
					isRequired
				>
					<TextInput
						isRequired
						type="password"
						id={'password'}
						name="password"
						aria-label={'User Password'}
						onChange={(e) => setPassword(e)}
						data-testid="login-password"
						onKeyDown={handleKeyDown}
					></TextInput>
				</FormGroup>
				<ActionGroup className="actiongroup-center">{button}</ActionGroup>
			</Form>
		</CenteredPage>
	);
}
