import axiosConfig from '../axiosConfig';
import { TAppNotification } from '../types/AppNotification';

export async function markNotificationRead(notification: TAppNotification): Promise<boolean> {
	return await axiosConfig.instance
		.post<TAppNotification>(`app/notification/mark-read/${notification.id}`)
		.then(() => {
			return true;
		})
		.catch(() => {
			return false;
		});
}

export async function markAllNotificationsAsRead(): Promise<boolean> {
	return await axiosConfig.instance
		.post<TAppNotification>(`/app/notification/mark-all-as-read`)
		.then(() => {
			return true;
		})
		.catch(() => {
			return false;
		});
}

export async function dismissNotification(notification: TAppNotification): Promise<boolean> {
	return await axiosConfig.instance
		.delete<TAppNotification>(`app/notification/dismiss/${notification.id}`)
		.then(() => {
			return true;
		})
		.catch(() => {
			return false;
		});
}

export async function dismissAllNotifications(): Promise<boolean> {
	return await axiosConfig.instance
		.delete<TAppNotification>(`/app/notification/dismiss-all`)
		.then(() => {
			return true;
		})
		.catch(() => {
			return false;
		});
}

export async function getUnreadCount(): Promise<number> {
	return await axiosConfig.instance
		.get<{ count: number }>(`/app/notification/get-unread-count`, { cache: false })
		.then((res) => {
			return res.data.count;
		})
		.catch(() => {
			return -1;
		});
}
