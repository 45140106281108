import axiosConfig from '../axiosConfig';

export type TPeriod = {
	alertDefinitions: unknown[];
	code: string;
	created_at: number | null;
	creator: unknown;
	id: number;
	interval_quantity: number;
	interval_units: number;
	name: string;
	num_visible_outside_range: number; // Default 0
	period: TPeriod | null | number;
	updated_at: number;
	updater: unknown;
	visible: boolean; // Default true
};

export type TNewPeriod = Omit<TPeriod, 'created_at' | 'creator' | 'updater' | 'updated_at' | 'id'>;

export class Period implements TPeriod {
	alertDefinitions: unknown[];
	code: string;
	created_at: number | null;
	creator: unknown;
	id: number;
	interval_quantity: number;
	interval_units: number;
	name: string;
	num_visible_outside_range: number;
	period: TPeriod | number | null;
	updated_at: number;
	updater: unknown;
	visible: boolean;

	constructor(period: TPeriod) {
		this.alertDefinitions = period.alertDefinitions;
		this.code = period.code;
		this.created_at = period.created_at;
		this.creator = period.creator;
		this.id = period.id;
		this.interval_quantity = period.interval_quantity;
		this.interval_units = period.interval_units;
		this.name = period.name;
		this.num_visible_outside_range = period.num_visible_outside_range;
		this.period = period.period;
		this.updated_at = period.updated_at;
		this.updater = period.updater;
		this.visible = period.visible;
	}

	public static Default(): TNewPeriod {
		return {
			alertDefinitions: [],
			code: '',
			interval_quantity: 1,
			interval_units: 1,
			name: '',
			num_visible_outside_range: 0,
			period: null,
			visible: true,
		};
	}

	public static New(period: TNewPeriod): Promise<TPeriod> {
		return axiosConfig.instance.post('app/period', period).then((res) => {
			return new Period(res.data as TPeriod);
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/period/${id}`).then(() => {
			return true;
		});
	}

	public static Update(period: TPeriod): Promise<TPeriod> {
		return axiosConfig.instance.patch('app/period', period).then((res) => {
			return new Period(res.data as TPeriod);
		});
	}

	public static Get(id: number): Promise<TPeriod> {
		return axiosConfig.instance.get(`app/period/${id}`).then((res) => {
			return new Period(res.data as TPeriod);
		});
	}

	public static GetAll(): Promise<TPeriod[]> {
		return axiosConfig.instance.get('app/periods').then((res) => {
			return ((res.data || []) as TPeriod[]).map((period: TPeriod) => {
				return new Period(period);
			});
		});
	}
}
