import React, { ReactElement, useEffect } from 'react';
import {
	AlertVariant,
	Button,
	DualListSelector,
	Form,
	FormGroup,
	Grid,
	GridItem,
	Modal,
	ModalVariant,
	Radio,
	Select,
	SelectDirection,
	SelectOption,
	SelectOptionObject,
	SelectVariant,
	Tab,
	TabTitleText,
	Tabs,
	Text,
	TextContent,
	TextInput,
	TextVariants,
} from '@patternfly/react-core';
import { DragItem } from '../../../types/dataframes/dataframe-drag-item';
import './BuildDataframe.scss';
import { DimensionAttribute } from '../../../api/analytics/DimensionAttribute';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { GenericKeyValueResponse } from '../../../api/types';
import Loader from '../../../components/util/Loader';
import { FilterOperatorEnum } from '../../../enums/operators.enum';
import { OptionsBuilderItemTypes } from '../../../types/dataframes/options-builder-item-types';
import { Dimension } from '../../../api/analytics/Dimension';

export type IFilterModalProps = {
	filter: DragItem | null;
	handleClose: () => void;
	handleSave: (fact: DragItem) => void;
	readonly?: boolean;
};

const FilterModal = (props: IFilterModalProps): ReactElement => {
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isOperatorDropdownOpen, setIsOperatorDropdownOpen] = React.useState(false);
	const [activeTabKey, setActiveTabKey] = React.useState<string | number>(0);
	const [isInclude, setIsInclude] = React.useState(true);
	const [isExclude, setIsExclude] = React.useState(false);
	const [selectedOperator, setSelectedOperator] = React.useState<string | SelectOptionObject>(
		FilterOperatorEnum.EQUALS
	);
	const [rawOptions, setRawOptions] = React.useState<GenericKeyValueResponse<string>[]>([]);
	const [availableOptions, setAvailableOptions] = React.useState<React.ReactNode[]>([]);
	const [chosenOptions, setChosenOptions] = React.useState<React.ReactNode[]>([]);
	const [firstValue, setFirstValue] = React.useState<string>();
	const [secondValue, setSecondValue] = React.useState<string>();
	const { addToast } = useToast();

	useEffect(() => {
		if (props.filter?.id) {
			setIsLoading(true);
			if (props.filter.type === OptionsBuilderItemTypes.DimensionAttribute) {
				DimensionAttribute.Retrieval(props.filter.id)
					.then((response) => {
						response.sort((a, b) => {
							if (a.value.toLowerCase() < b.value.toLowerCase()) {
								return -1;
							}

							if (a.value.toLowerCase() > b.value.toLowerCase()) {
								return 1;
							}

							return 0;
						});

						setRawOptions(response);
						setAvailableOptions(
							response
								.filter(
									(option: GenericKeyValueResponse<string>): boolean =>
										!props.filter?.value?.includes(option.id.toString())
								)
								.map((option: GenericKeyValueResponse<string>): string => {
									return option.value;
								})
						);
						setIsLoading(false);
					})
					.catch((): void => {
						addToast('Error fetching filter data.', AlertVariant.danger);
						setIsLoading(false);
						onCloseModal();
					});
			} else {
				Dimension.Retrieval(props.filter.id)
					.then((response) => {
						setRawOptions(response);
						setAvailableOptions(
							response
								.filter(
									(option: GenericKeyValueResponse<string>): boolean =>
										!props.filter?.value?.includes(option.id.toString())
								)
								.map((option: GenericKeyValueResponse<string>): string => {
									return option.value;
								})
						);
						setIsLoading(false);
					})
					.catch((): void => {
						addToast('Error fetching filter data.', AlertVariant.danger);
						setIsLoading(false);
						onCloseModal();
					});
			}
		}
	}, []);

	useEffect(() => {
		if (props.filter?.excluded) {
			handleIsExcludeChange();
		} else {
			handleIsIncludeChange();
		}
		if (props.filter?.operator || props.filter?.isExistingValue) {
			if (props.filter?.isExistingValue) {
				setSelectedOperator(FilterOperatorEnum.EQUALS);
			} else {
				props.filter?.operator && setSelectedOperator(props.filter?.operator);
			}

			if (props.filter.value) {
				const values = props.filter.value.split(',');

				if (props.filter.isExistingValue) {
					const optionList: string[] = [];

					values.map((value: string) => {
						const rawOption = rawOptions.find((option) => option.id == parseInt(value));

						if (rawOption) {
							optionList.push(rawOption.value);
						}
					});

					setChosenOptions(optionList);
				} else {
					setFirstValue(values[0]);
					setActiveTabKey(1);
					if (props.filter.operator === FilterOperatorEnum.BETWEEN) {
						setSecondValue(values[1]);
					}
				}
			}
		}
	}, [rawOptions]);

	const onCloseModal = () => {
		props.handleClose();
	};

	const onSaveModal = () => {
		const filter = Object.assign({}, props.filter);
		filter.operator = selectedOperator?.toString();
		filter.excluded = isExclude;
		filter.isInvalid = false;

		if (chosenOptions.length > 0) {
			filter.isExistingValue = true;
			filter.value = chosenOptions
				.map((option: React.ReactNode): number | undefined => {
					const rawOption: GenericKeyValueResponse<string> | undefined = rawOptions.find(
						(rOption: GenericKeyValueResponse<string>) => rOption.value === option
					);
					if (rawOption) {
						return rawOption.id;
					}
				})
				.toString();
		} else {
			filter.isExistingValue = false;
			filter.value = [...[firstValue], ...(secondValue ? [secondValue] : [])].toString();
		}

		props.handleSave(filter);
	};

	const onListChange = (
		newAvailableOptions: React.ReactNode[],
		newChosenOptions: React.ReactNode[]
	) => {
		setAvailableOptions(newAvailableOptions.sort());
		setChosenOptions(newChosenOptions.sort());
	};

	const onFirstValueChange = (value: string) => {
		setFirstValue(value);
	};

	const onSecondValueChange = (value: string) => {
		setSecondValue(value);
	};

	const handleTabClick = (
		event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent | MouseEvent,
		tabIndex: string | number
	) => {
		setActiveTabKey(tabIndex);
		if (tabIndex === 0) {
			setSelectedOperator(FilterOperatorEnum.EQUALS);
			setFirstValue(undefined);
			setSecondValue(undefined);
		} else {
			setChosenOptions([]);
		}
	};

	const onToggleOperatorDropdown = (isOpen: boolean) => {
		setIsOperatorDropdownOpen(isOpen);
	};

	const onSelectOperatorDropdown = (
		event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
		selection: string | SelectOptionObject,
		isPlaceholder?: boolean | undefined
	) => {
		if (!isPlaceholder) {
			setSelectedOperator(selection);
			setIsOperatorDropdownOpen(false);
		}
	};

	const handleIsIncludeChange = () => {
		setIsInclude(true);
		setIsExclude(false);
	};

	const handleIsExcludeChange = () => {
		setIsInclude(false);
		setIsExclude(true);
	};

	const getOperatorOptions = (): JSX.Element[] => {
		return [
			<SelectOption
				value={FilterOperatorEnum.BEGINS_WITH}
				key={'begins_with'}
			>
				Begins With
			</SelectOption>,
			<SelectOption
				value={FilterOperatorEnum.EQUALS}
				key={'equals'}
			>
				Equals
			</SelectOption>,
			<SelectOption
				value={FilterOperatorEnum.END_WITH}
				key={'ends_with'}
			>
				Ends With
			</SelectOption>,
			<SelectOption
				value={FilterOperatorEnum.CONTAINS}
				key={'contains'}
			>
				Contains
			</SelectOption>,
			<SelectOption
				value={FilterOperatorEnum.BETWEEN}
				key={'between'}
			>
				Between
			</SelectOption>,
		];
	};
	const getModalButtons = () => {
		const buttons: React.ReactNode[] = [];

		if (!props.readonly) {
			buttons.push(
				<Button
					key="confirm"
					variant="primary"
					onClick={onSaveModal}
					disabled={isLoading}
				>
					Save
				</Button>
			);
		}
		buttons.push(
			<Button
				key="cancel"
				variant="secondary"
				onClick={onCloseModal}
				disabled={isLoading}
			>
				Cancel
			</Button>
		);

		return buttons;
	};
	return (
		<React.Fragment>
			<Modal
				title={props.filter?.title}
				variant={ModalVariant.medium}
				isOpen={true}
				onClose={onCloseModal}
				actions={getModalButtons()}
			>
				{isLoading ? (
					<Loader />
				) : (
					<Form readOnly={props.readonly}>
						<Grid
							hasGutter
							md={6}
						>
							<FormGroup
								label="Operator"
								fieldId="operator"
							>
								<Select
									variant={SelectVariant.single}
									onToggle={onToggleOperatorDropdown}
									onSelect={onSelectOperatorDropdown}
									selections={selectedOperator}
									isOpen={isOperatorDropdownOpen}
									isDisabled={activeTabKey === 0 || props.readonly}
									direction={SelectDirection.down}
									menuAppendTo={() => document.body}
								>
									{getOperatorOptions()}
								</Select>
							</FormGroup>
							<FormGroup
								role="radiogroup"
								isInline
								fieldId="value-radio-group"
								label="Value"
							>
								<Radio
									isChecked={isInclude}
									onChange={handleIsIncludeChange}
									name="value-radio"
									label="Include"
									id="value-radio-01"
									isDisabled={props.readonly}
								/>
								<Radio
									isChecked={isExclude}
									onChange={handleIsExcludeChange}
									name="value-radio"
									label="Exclude"
									id="value-radio-02"
									isDisabled={props.readonly}
								/>
							</FormGroup>
							<GridItem span={12}>
								<Tabs
									activeKey={activeTabKey}
									isFilled
									onSelect={handleTabClick}
									role="region"
								>
									<Tab
										eventKey={0}
										title={<TabTitleText>Select Values</TabTitleText>}
										isDisabled={props.readonly}
									>
										<DualListSelector
											isSearchable
											availableOptions={availableOptions}
											chosenOptions={chosenOptions}
											onListChange={onListChange}
											id="dual-list-selector-basic-search"
											isDisabled={props.readonly}
										/>
									</Tab>
									<Tab
										eventKey={1}
										title={<TabTitleText>Enter Value</TabTitleText>}
										isDisabled={props.readonly}
									>
										<FormGroup
											label="Enter Value"
											className="value-section"
										>
											<TextInput
												type="text"
												id="first-value"
												name="First Value"
												value={firstValue}
												onChange={onFirstValueChange}
											/>
										</FormGroup>
										{selectedOperator === FilterOperatorEnum.BETWEEN && (
											<React.Fragment>
												<TextContent className="and-header">
													<Text component={TextVariants.h3}>AND</Text>
												</TextContent>
												<FormGroup label="Enter Value">
													<TextInput
														type="text"
														id="second-value"
														name="Second Value"
														value={secondValue}
														onChange={onSecondValueChange}
													/>
												</FormGroup>
											</React.Fragment>
										)}
									</Tab>
								</Tabs>
							</GridItem>
						</Grid>
					</Form>
				)}
			</Modal>
		</React.Fragment>
	);
};

export default FilterModal;
