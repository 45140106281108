import { Text, TextContent, TextVariants } from '@patternfly/react-core';
import { SideBarHeaderContext } from './Layout';

interface Props {
	sidebarHeaders: SideBarHeaderContext;
}

export default function SidebarHeaders({ sidebarHeaders }: Props) {
	return (
		<TextContent className="zi-sidebar-headers">
			<Text component={TextVariants.h1}>{sidebarHeaders.header}</Text>
			<Text component={TextVariants.small}>{sidebarHeaders.subheader}</Text>
		</TextContent>
	);
}
