import React, { ReactElement, useEffect } from 'react';
import {
	Button,
	Form,
	FormGroup,
	Modal,
	ModalVariant,
	Select,
	SelectDirection,
	SelectOption,
	SelectOptionObject,
	SelectVariant,
	TextInput,
} from '@patternfly/react-core';
import { FilterOperatorEnum } from '../../../enums/operators.enum';
import { DraggableMenuItemData } from '../../../types/databuilder/databuilder';

export type IFactFilterModalProps = {
	fact: DraggableMenuItemData | null;
	handleClose: () => void;
	handleSave: (fact: DraggableMenuItemData) => void;
};

const FactFilterOptionsModal = (props: IFactFilterModalProps): ReactElement => {
	const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
	const [filterValue, setFilterValue] = React.useState<string>('');
	const [selectedFilter, setSelectedFilter] = React.useState<string | SelectOptionObject>('');

	useEffect(() => {
		setFilterValue(props.fact && props.fact.data?.value ? props.fact.data?.value : '');
		setSelectedFilter(
			props.fact && props.fact.data?.operator
				? props.fact.data?.operator
				: FilterOperatorEnum.LESS_THAN
		);
	}, []);

	const onCloseModal = () => {
		props.handleClose();
	};

	const onSaveModal = () => {
		const fact = Object.assign({}, props.fact);

		if (filterValue && fact.data) {
			fact.data.value = filterValue;
		}

		if (selectedFilter && fact.data) {
			fact.data.operator = selectedFilter.toString();
		}

		props.handleSave(fact);
	};

	const onToggleDropdown = (isOpen: boolean) => {
		setIsDropdownOpen(isOpen);
	};

	const onSelectDropdown = (
		event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
		selection: string | SelectOptionObject,
		isPlaceholder?: boolean | undefined
	) => {
		if (!isPlaceholder) {
			setSelectedFilter(selection);
			setIsDropdownOpen(false);
		}
	};

	const onFilterValueChange = (value: string) => {
		setFilterValue(value);
	};

	const getOptions = (): JSX.Element[] => {
		return [
			<SelectOption
				value={FilterOperatorEnum.LESS_THAN}
				key={'less_than'}
			>
				{'<'}
			</SelectOption>,
			<SelectOption
				value={FilterOperatorEnum.LESS_THAN_OR_EQUAL}
				key={'less_than_equal_to'}
			>
				{'<='}
			</SelectOption>,
			<SelectOption
				value={FilterOperatorEnum.NOT}
				key={'not'}
			>
				{'<>'}
			</SelectOption>,
			<SelectOption
				value={FilterOperatorEnum.EQUALS}
				key={'equal'}
			>
				{'='}
			</SelectOption>,
			<SelectOption
				value={FilterOperatorEnum.GREATER_THAN}
				key={'greater_than'}
			>
				{'>'}
			</SelectOption>,
			<SelectOption
				value={FilterOperatorEnum.GREATER_THAN_OR_EQUAL}
				key={'greater_than_equal_to'}
			>
				{'>='}
			</SelectOption>,
		];
	};

	return (
		<React.Fragment>
			<Modal
				title="Key Measure Filters"
				variant={ModalVariant.small}
				isOpen={true}
				onClose={onCloseModal}
				actions={[
					<Button
						key="confirm"
						variant="primary"
						onClick={onSaveModal}
					>
						Apply
					</Button>,
					<Button
						key="cancel"
						variant="secondary"
						onClick={onCloseModal}
					>
						Cancel
					</Button>,
				]}
			>
				<Form>
					<FormGroup label="Filter Type">
						<Select
							variant={SelectVariant.single}
							onToggle={onToggleDropdown}
							onSelect={onSelectDropdown}
							selections={selectedFilter}
							isOpen={isDropdownOpen}
							isDisabled={false}
							direction={SelectDirection.down}
							menuAppendTo={() => document.body}
						>
							{getOptions()}
						</Select>
					</FormGroup>
					<FormGroup label="Filter Value">
						<TextInput
							type="text"
							id="filter-value"
							name="Filter Value"
							value={filterValue}
							onChange={onFilterValueChange}
						/>
					</FormGroup>
				</Form>
			</Modal>
		</React.Fragment>
	);
};

export default FactFilterOptionsModal;
