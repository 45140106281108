import axiosConfig from '../axiosConfig';

export type TPermission = {
	id: number;
	name: string;
};

export type TNewPermission = Omit<Permission, 'id'>;

export class Permission implements TPermission {
	id: number;
	name: string;

	constructor(permission: TPermission) {
		this.id = permission.id;
		this.name = permission.name;
	}

	public static Default(): TNewPermission {
		return {
			name: '',
		};
	}

	public static GetAll(expand: (keyof TPermission)[] = []): Promise<TPermission[]> {
		return axiosConfig.instance
			.get('app/permissions', { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as Permission[]).map((permission: TPermission) => {
					return new Permission(permission);
				});
			});
	}

	public static New(
		permission: TNewPermission,
		expand: (keyof TPermission)[] = []
	): Promise<TPermission> {
		return axiosConfig.instance
			.post('app/permission', permission, { params: { expand: expand } })
			.then((res) => {
				return new Permission(res.data as TPermission);
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/permission/${id}`).then(() => {
			return true;
		});
	}

	public static Update(
		permission: TPermission,
		expand: (keyof TPermission)[] = []
	): Promise<TPermission> {
		return axiosConfig.instance
			.patch('app/permission', permission, { params: { expand: expand } })
			.then((res) => {
				return new Permission(res.data as TPermission);
			});
	}
}
