import React, { Dispatch, useState } from 'react';
import {
	Dropdown,
	DropdownItem,
	DropdownPosition,
	KebabToggle,
	NotificationDrawerBody,
	NotificationDrawerList,
	NotificationDrawerListItem,
	NotificationDrawerListItemBody,
	NotificationDrawerListItemHeader,
	ExpandableSection,
} from '@patternfly/react-core';
import './Notifications.scss';
import { TAppNotification } from '../../api/types/AppNotification';
import { timeAgo } from '../../utilities';
import { dismissNotification, markNotificationRead } from '../../api/notification/Notification';
import { convertUnixToDate } from '../../helpers/multipart-response.helper';

type Props = {
	jobGroup: { jobQueueId: string; notifications: TAppNotification[] };
	notifications: TAppNotification[];
	setNotifications: Dispatch<React.SetStateAction<TAppNotification[]>>;
};

const NotificationsJobGroups = ({ jobGroup, notifications, setNotifications }: Props) => {
	const [isGroupExpanded, setIsGroupExpanded] = useState<boolean>(false);
	const [isOpenMap, setIsOpenMap] = React.useState<boolean[]>([]);

	const onOpen = () => {
		setIsGroupExpanded(!isGroupExpanded);
	};

	const onToggle = (index: number) => (isOpen: boolean) => {
		const newState = [...isOpenMap.slice(0, index), isOpen, ...isOpenMap.slice(index + 1)];
		setIsOpenMap(newState);
	};

	const onSelect = () => {
		setIsOpenMap(new Array(jobGroup.notifications.length).fill(false));
	};

	const markAsRead = (id: number) => {
		const newNotification = jobGroup.notifications.find((n) => n.id === id);
		const index = notifications.findIndex((n) => n.id === id);

		if (newNotification) {
			newNotification.read = true;
			const newState = [
				...notifications.slice(0, index),
				newNotification,
				...notifications.slice(index + 1),
			];

			setNotifications(newState);

			void markNotificationRead(newNotification);
		}
	};

	const dismissSingleNotificationOnClick = (id: number) => {
		const notification = jobGroup.notifications.find((n) => n.id === id);
		const index = notifications.findIndex((n) => n.id === id);

		if (notification) {
			const newState = [...notifications.slice(0, index), ...notifications.slice(index + 1)];

			setNotifications(newState);
			setIsOpenMap(new Array(newState.length).fill(false));

			void dismissNotification(notification);
		}
	};

	let unreadCount = 0;

	const notification = jobGroup.notifications[0];

	if (notification) {
		const jobDetails = notification.job_details;
		const recipientName =
			notification.job_details && notification.job_details.recipientName
				? `- ${notification.job_details.recipientName}`
				: '';
		const createdDate = convertUnixToDate(notification.created_at.toString());
		const groupName = jobDetails
			? jobDetails.name
				? `${jobDetails.name} - ${createdDate} ${recipientName}`
				: `${createdDate} ${recipientName}`
			: jobGroup.jobQueueId
			? `Job Id: ${jobGroup.jobQueueId} - ${createdDate} ${recipientName}`
			: `Ungrouped Notifications ${recipientName}`;

		return (
			<>
				<ExpandableSection
					toggleText={groupName}
					onToggle={onOpen}
					isExpanded={isGroupExpanded}
					isIndented
					data-testid={`Job_Id-${notification.job_queue_id ?? 'ungrouped'}`}
				>
					<NotificationDrawerBody>
						<NotificationDrawerList>
							{jobGroup.notifications.map((notification, index) => {
								if (!notification.read) {
									unreadCount++;
								}

								return (
									<NotificationDrawerListItem isRead={notification.read}>
										<NotificationDrawerListItemHeader
											variant="default"
											title={notification.title}
											srTitle={notification.title}
										>
											<Dropdown
												position={DropdownPosition.right}
												onSelect={onSelect}
												toggle={
													<KebabToggle
														onToggle={onToggle(index)}
														id={`notification-toggle-${
															notification.job_queue_id ?? 'ungrouped'
														}-${index}`}
													/>
												}
												isOpen={isOpenMap[index]}
												isPlain
												dropdownItems={[
													<DropdownItem
														key="mark-read"
														disabled={notification.read}
														onClick={() => markAsRead(notification.id)}
													>
														Mark Read
													</DropdownItem>,
													<DropdownItem
														key="dismiss"
														onClick={() =>
															dismissSingleNotificationOnClick(
																notification.id
															)
														}
													>
														Dismiss
													</DropdownItem>,
												]}
												id={`notification-${
													notification.job_queue_id ?? 'ungrouped'
												}-${index}`}
											/>
										</NotificationDrawerListItemHeader>
										<NotificationDrawerListItemBody
											timestamp={timeAgo(notification.created_at)}
										>
											{notification.message}
											<br />
											Created at:
											{` ${convertUnixToDate(
												notification.created_at.toString()
											)}`}
										</NotificationDrawerListItemBody>
									</NotificationDrawerListItem>
								);
							})}
						</NotificationDrawerList>
					</NotificationDrawerBody>
				</ExpandableSection>
			</>
		);
	} else {
		return <></>;
	}
};

export default NotificationsJobGroups;
