import axiosConfig from '../axiosConfig';
import { TDashboard } from './Dashboards';

export type TDashboardFilter = {
	id?: number;
	dashboard: TDashboard | number;
	entity_id: number;
	entity_type: string;
	excluded: boolean;
	isExistingValue: boolean;
	operator?: string;
	value?: string;
	sequence: number;
	isNew?: boolean;
};

export class DashboardFilter implements TDashboardFilter {
	id?: number;
	dashboard: TDashboard | number;
	entity_id: number;
	entity_type: string;
	excluded: boolean;
	isExistingValue: boolean;
	operator?: string;
	value?: string;
	sequence: number;
	isNew?: boolean;

	constructor(dashboardFilter: TDashboardFilter) {
		this.id = dashboardFilter.id;
		this.dashboard = dashboardFilter.dashboard;
		this.entity_id = dashboardFilter.entity_id;
		this.entity_type = dashboardFilter.entity_type;
		this.excluded = dashboardFilter.excluded;
		this.isExistingValue = dashboardFilter.isExistingValue;
		this.operator = dashboardFilter.operator;
		this.value = dashboardFilter.value;
		this.sequence = dashboardFilter.sequence;
		this.isNew = dashboardFilter.isNew;
	}

	public static Default(): TDashboardFilter {
		return {
			dashboard: 0,
			entity_id: 0,
			entity_type: '',
			excluded: false,
			isExistingValue: false,
			sequence: 0,
		};
	}

	public static New(dashboardFilter: TDashboardFilter): Promise<TDashboardFilter> {
		return axiosConfig.instance.post('app/dashboard-filter', dashboardFilter).then((res) => {
			return new DashboardFilter(res.data as TDashboardFilter);
		});
	}

	public static Edit(dashboardFilter: TDashboardFilter): Promise<TDashboardFilter> {
		return axiosConfig.instance.patch(`app/dashboard-filter`, dashboardFilter).then((res) => {
			return new DashboardFilter(res.data as TDashboardFilter);
		});
	}

	public static Get(dashboardFilterId: number): Promise<TDashboardFilter> {
		return axiosConfig.instance.get(`app/dashboard-filter/${dashboardFilterId}`).then((res) => {
			return new DashboardFilter(res.data as TDashboardFilter);
		});
	}

	public static GetAll(
		filter: Record<string, string>,
		expand: (keyof TDashboardFilter)[] = []
	): Promise<TDashboardFilter[]> {
		let url = 'app/dashboard-filters';
		if (filter) {
			let joinedFilterString = '';
			for (const property in filter) {
				if (joinedFilterString !== '') {
					joinedFilterString = `${joinedFilterString}&`;
				}
				joinedFilterString = `${joinedFilterString}filter[${property}]=${filter[property]}`;
			}
			url = `${url}?${joinedFilterString}`;
		}
		return axiosConfig.instance
			.get<TDashboardFilter[]>(url, { params: { expand } })
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}

	public static Delete(dashboardFilterId: number): Promise<boolean> {
		return axiosConfig.instance
			.delete(`app/dashboard-filter/${dashboardFilterId}`)
			.then((res) => {
				return res.data as boolean;
			});
	}
}
