import {
	MultipartResponse,
	MultipartResponseBoundaryTypes,
	MultipartResponseContentTypes,
	parseMultipartResponse,
} from '../../helpers/multipart-response.helper';
import axiosConfig from '../axiosConfig';
import { TUser } from '../security/User';
import { TSharedEntity } from '../shared-entity/SharedEntity';
import {
	DataframeDataRetrievalRequest,
	DataframeDataRetrievalResponse,
	GenericResponse,
} from '../types';
import { TDataframe } from '../types/dataframe';
import { TDataframeData } from '../types/dataframe-data';
import { TDataframeEntry } from '../types/dataframe-entry';
import { TDataframeFilter } from '../types/dataframe-filter';
import { TDataframeOrder } from '../types/dataframe-order';
import { TFolder } from '../types/folder';

export type TNewDataframe = {
	columnEntry: TNewDataframeEntry[];
	datasets: TNewDataframeData[];
	filters: TNewDataframeFilter[];
	folder?: number;
	name: string;
	order?: TNewDataframeOrder[];
	rowEntry: TNewDataframeEntry[];
	managed?: boolean;
	parent?: number;
	id?: number;
};

export type TNewDataframeData = Omit<TDataframeData, 'id' | 'dataframe'>;

export type TNewDataframeEntry = Omit<TDataframeEntry, 'id' | 'dataframe'>;

export type TNewDataframeFilter = Omit<TDataframeFilter, 'id' | 'dataframe'>;

export type TNewDataframeOrder = Omit<TDataframeOrder, 'id' | 'dataframe'>;

export class Dataframe implements TDataframe {
	columnEntry: TDataframeEntry[];
	created_at: string;
	creator: TUser | number;
	datasets: TDataframeData[];
	filters: TDataframeFilter[];
	folder?: number | TFolder;
	id: number;
	parent?: number;
	name: string;
	order: TDataframeOrder[];
	rowEntry: TDataframeEntry[];
	updated_at: string;
	updater: number;
	managed?: boolean;
	owner: TUser | number;
	sharedDataframe: TSharedEntity[] | number[];

	constructor(dataframe: TDataframe) {
		this.columnEntry = dataframe.columnEntry;
		this.created_at = dataframe.created_at;
		this.creator = dataframe.creator;
		this.datasets = dataframe.datasets;
		this.filters = dataframe.filters;
		this.folder = dataframe.folder ? dataframe.folder : undefined;
		this.id = dataframe.id;
		this.parent = dataframe.parent;
		this.name = dataframe.name;
		this.order = dataframe.order;
		this.rowEntry = dataframe.rowEntry;
		this.updated_at = dataframe.updated_at;
		this.updater = dataframe.updater;
		this.managed = dataframe.managed;
		this.owner = dataframe.owner;
		this.sharedDataframe = dataframe.sharedDataframe;
	}

	public static NewDataframe(dataframe: TNewDataframe): Promise<TDataframe> {
		return axiosConfig.instance.post('app/dataframe', dataframe).then((res) => {
			return new Dataframe(res.data as TDataframe);
		});
	}

	public static DeleteDataframe(id: number): Promise<GenericResponse<any>> {
		return axiosConfig.instance
			.delete<GenericResponse<any>>(`app/dataframe/${id}`)
			.then((res) => {
				return res.data;
			});
	}

	public static PatchDataframe(dataframe: TNewDataframe): Promise<TDataframe> {
		return axiosConfig.instance.patch('app/dataframe', dataframe).then((res) => {
			return new Dataframe(res.data as TDataframe);
		});
	}

	public static Get(id: number, expand: (keyof TDataframe)[] = []): Promise<TDataframe> {
		return axiosConfig.instance
			.get<TDataframe>(`app/dataframe/${id}`, { params: { expand } })
			.then((res) => {
				return res.data;
			});
	}

	public static GetAll(expand: (keyof TDataframe)[] = []): Promise<TDataframe[]> {
		return axiosConfig.instance
			.get<TDataframe[]>('app/dataframes', { params: { expand } })
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}

	public static Retrieve(
		request: DataframeDataRetrievalRequest
	): Promise<MultipartResponse<DataframeDataRetrievalResponse>> {
		return axiosConfig.instance.post<string>('app/data-retrieval', request).then((res) => {
			const contentTypesNeeded = [
				MultipartResponseContentTypes.json,
				MultipartResponseContentTypes.csv,
			];
			return parseMultipartResponse<DataframeDataRetrievalResponse>(
				res.data,
				MultipartResponseBoundaryTypes.boundary,
				contentTypesNeeded
			);
		});
	}
}
