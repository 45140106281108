import { useCallback, useState } from 'react';
import { DragOverEvent, Layout } from 'react-grid-layout';
import RGL, { WidthProvider } from 'react-grid-layout';
const ReactGridLayout = WidthProvider(RGL);
import './DropGrid.scss';
import { DashboardWidget } from '../../api/dashbboards/DashboardWidgets';
import AutoWidget from './AutoWidget';
import { Flex, FlexItem, Text } from '@patternfly/react-core';
import _ from 'lodash';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { DashboardFilter } from '../../api/dashbboards/DashboardFilter';

type Props = {
	widgets: DashboardWidget[];
	selectedDate?: TNewDateRange;
	handleWidgetChange?: (widget: DashboardWidget) => void;
	removeWidget?: (widget: DashboardWidget) => void;
	handleEditClick?: (widget: DashboardWidget) => void;
	displayOnly?: boolean;
	isDashboard?: boolean;
	gridLayout?: boolean;
	gridBorder?: boolean;
	filters?: DashboardFilter[];
	isEdit?: boolean;
};

const DropGrid = (props: Props) => {
	const {
		widgets,
		selectedDate,
		handleWidgetChange,
		removeWidget,
		handleEditClick,
		displayOnly,
		isDashboard,
		gridLayout,
		gridBorder,
		filters,
		isEdit,
	} = props;
	const [currentStaticId, setCurrentStaticId] = useState<number>();
	const [layouts, setLayouts] = useState<ReactGridLayout.Layout[]>();

	const setEditingId = useCallback(
		(id?: number) => {
			setCurrentStaticId(id);
		},
		[setCurrentStaticId]
	);

	const onDrop = (
		_layout: ReactGridLayout.Layout[],
		item: ReactGridLayout.Layout,
		_e: DragEvent
	) => {
		let widgetData: DashboardWidget;
		if (_e.dataTransfer) {
			const widgetId = widgets.find((w) => w.id === widgets.length + 1)?.id;
			widgetData = JSON.parse(_e.dataTransfer.getData('widgetData')) as DashboardWidget;
			widgetData.id = widgetId ? widgetId + 1 : widgets.length + 1; //A temp id assigned to a widget that will not be saved to the DB
			widgetData.row = item.y;
			widgetData.col = item.x;
			widgetData.isNew = true;

			handleWidgetChange && handleWidgetChange(widgetData);
		}
	};

	const onResizeStop = (
		_layout: ReactGridLayout.Layout[],
		_oldItem: ReactGridLayout.Layout,
		newItem: ReactGridLayout.Layout,
		_placeholder: ReactGridLayout.Layout,
		_e: MouseEvent,
		_element: HTMLElement
	) => {
		const widget = widgets.find((w) => w.id?.toString() === newItem.i);
		if (widget) {
			widget.sizey = newItem.h;
			widget.sizex = newItem.w;
			widget.widgetChanged = true;

			handleWidgetChange && handleWidgetChange(widget);
		}
	};

	const onDropDragOver = (_e: DragOverEvent) => {
		let widgetData: DashboardWidget;
		const draggingItem = global.localStorage.getItem('zeroedIn-dragging-item');
		if (draggingItem) {
			widgetData = JSON.parse(draggingItem) as DashboardWidget;
			return { h: widgetData.sizey, w: widgetData.sizex };
		}

		return undefined;
	};

	const onLayoutChange = (currentLayout: Layout[]) => {
		if (isEdit) {
			currentLayout.map((layout) => {
				const widget = widgets.find((w) => w.id?.toString() === layout.i);
				if (widget && hasWidgetChanged(widget, layout)) {
					widget.col = layout.x;
					widget.row = layout.y;
					widget.sizey = layout.h;
					widget.sizex = layout.w;
					widget.widgetChanged = true;
					widget.isEdit = false;

					handleWidgetChange && handleWidgetChange(widget);
				}
			});
		}

		setLayouts(currentLayout);
	};

	const hasWidgetChanged = (currentWidget: DashboardWidget, newItem: Layout) => {
		let response = false;
		if (currentWidget.id?.toString() === newItem.i) {
			response = !_.isEqual(
				{
					col: currentWidget.col,
					row: currentWidget.row,
					sizey: currentWidget.sizey,
					sizex: currentWidget.sizex,
				},
				{
					col: newItem.x,
					row: newItem.y,
					sizey: newItem.h,
					sizex: newItem.w,
				}
			);
		}
		return response;
	};

	const getWidgetLayout = (widget: DashboardWidget, index: number) => {
		return {
			i: widget.id?.toString() ?? index,
			x: widget.col,
			y: widget.row,
			h: widget.sizey,
			w: widget.sizex,
			minW: widget.chart ? 4 : 0,
			minH: widget.chart ? 4 : 0,
			static: currentStaticId && widget.id === currentStaticId ? true : false,
		};
	};

	return (
		<ReactGridLayout
			className={displayOnly ? 'layout display-only' : 'layout'}
			rowHeight={34}
			layout={layouts}
			onDrop={onDrop}
			onResizeStop={onResizeStop}
			onDropDragOver={onDropDragOver}
			onLayoutChange={onLayoutChange}
			isDroppable={true}
			isBounded={!isDashboard}
			compactType={gridLayout ? 'vertical' : null}
			isDraggable={!displayOnly}
			isResizable={!displayOnly}
			allowOverlap={!gridLayout}
			measureBeforeMount={false}
			preventCollision={widgets.length === 0}
		>
			{widgets.length > 0 ? (
				widgets.map((widget, index) => {
					return (
						<div
							className={
								widget.widget_type != 'text' || !displayOnly
									? gridBorder
										? 'grid-layout-widget grid-border'
										: 'grid-layout-widget'
									: gridBorder
									? 'grid-layout-widget-text grid-border'
									: 'grid-layout-widget-text'
							}
							key={widget.id ?? index}
							data-grid={getWidgetLayout(widget, index)}
						>
							<AutoWidget
								widget={widget}
								selectedDate={selectedDate}
								removeWidget={removeWidget}
								updateWidget={handleWidgetChange}
								handleEditClick={handleEditClick}
								setEditingId={setEditingId}
								filters={filters}
								isEdit={isEdit}
							/>
						</div>
					);
				})
			) : (
				<div
					className="grid-layout-empty-widget"
					key={'0'}
					style={{ height: '100%', width: '100%' }}
				>
					<Flex
						direction={{ default: 'column' }}
						alignItems={{ default: 'alignItemsCenter' }}
						justifyContent={{ default: 'justifyContentCenter' }}
						alignSelf={{ default: 'alignSelfStretch' }}
						style={{ height: '100%' }}
					>
						<FlexItem>
							<Text component="h2">No Widgets yet</Text>
						</FlexItem>
						<FlexItem>
							<Text component="h3">Drag widgets from your assets library</Text>
						</FlexItem>
					</Flex>
				</div>
			)}
		</ReactGridLayout>
	);
};

export default DropGrid;
