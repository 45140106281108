import React, { Dispatch, SetStateAction } from 'react';
import { ViewTypesEnum, viewOptions } from './ZiCreateSelectionModal';
import { Button, Tooltip } from '@patternfly/react-core';
import './DefaultView.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type DefaultViewProps = {
	onViewChange: Dispatch<SetStateAction<ViewTypesEnum>>;
	viewOptions: viewOptions[];
	label: string;
};

const DefaultView = (props: DefaultViewProps) => {
	const changeView = (type: ViewTypesEnum) => {
		if (type != ViewTypesEnum.Copy) {
			props.onViewChange(type);
		}
	};

	const buildOptions = (): JSX.Element[] => {
		const options: JSX.Element[] = [];
		props.viewOptions.forEach((option): void => {
			if (option.tooltip) {
				options.push(
					<Tooltip content={<div>{option.tooltip}</div>}>
						<Button
							className="create-selection-option"
							onClick={() => changeView(option.type)}
							variant="link"
							data-testid="select-measure"
						>
							<span className="create-selection-option-icon">
								<FontAwesomeIcon icon={option.icon} />
							</span>
							{option.label}
						</Button>
					</Tooltip>
				);
			} else {
				options.push(
					<Button
						className="create-selection-option"
						onClick={() => changeView(option.type)}
						variant="link"
						data-testid="select-measure"
					>
						<span className="create-selection-option-icon">
							<FontAwesomeIcon icon={option.icon} />
						</span>
						{option.label}
					</Button>
				);
			}
		});

		return options;
	};

	return (
		<div
			className="component-container"
			data-testid="default-view"
		>
			<span>Create {props.label} based on</span>
			<div className="create-selection-options-list">{buildOptions()}</div>
		</div>
	);
};

export default DefaultView;
