import React, { useState } from 'react';
import { Select, SelectOption, SelectVariant, SelectOptionObject } from '@patternfly/react-core';
import { TTag, Tag } from '../../api/tags/Tags';
import { useMount } from 'react-use';

type Props = {
	selected: number[];
	setSelected: React.Dispatch<React.SetStateAction<number[]>>;
};

const MultiSelectTagDropdown = (props: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [tagOptions, setTagOptions] = useState<Tag[]>([]);

	const onToggle = (isOpen: boolean) => {
		setIsOpen(isOpen);
	};

	useMount(() => {
		GetAllTags();
	});

	const onSelect = (
		event: React.MouseEvent | React.ChangeEvent,
		value: string | SelectOptionObject
	) => {
		const isRemove = event.currentTarget.id.toString().includes('remove');

		if (isRemove) {
			// Handle remove action here
			const updatedTags = props.selected.filter((x) => x != value);
			props.setSelected(updatedTags);
		} else {
			// Handle select action here
			const alreadyExists = props.selected.find((x) => x == value) !== undefined;
			if (typeof value === 'number' && !alreadyExists) {
				props.setSelected([...props.selected, parseInt(value)]);
			}
		}
	};

	const handleCreateOption = (value: string) => {
		const tagRequest: TTag = {
			name: value,
		};

		void Tag.New(tagRequest).then(() => {
			GetAllTags();
		});
	};

	const GetAllTags = () => {
		void Tag.GetAll().then((response) => {
			setTagOptions(response);
		});
	};

	const onClearSelection = () => {
		props.setSelected([]);
	};

	return (
		<div>
			<Select
				variant={SelectVariant.typeaheadMulti}
				aria-label={`Multi-select-${Math.random()}`}
				onToggle={onToggle}
				onSelect={onSelect}
				onCreateOption={handleCreateOption}
				selections={props.selected}
				isOpen={isOpen}
				placeholderText="Select options"
				maxHeight={300}
				isCreatable={true}
				onClear={onClearSelection}
			>
				{tagOptions.map((option, index) => (
					<SelectOption
						key={index}
						value={option.id}
					>
						{option.name}
					</SelectOption>
				))}
			</Select>
		</div>
	);
};

export default MultiSelectTagDropdown;
