import React from 'react';
import { HeaderMenuItem } from '../../helpers/headerHelper';
import {
	Dropdown,
	DropdownItem,
	DropdownToggle,
	Flex,
	FlexItem,
	Text,
	Tile,
} from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useIsGranted } from '../../components/user/ApplicationProvider';

type Props = {
	headerMenuItems: HeaderMenuItem[];
};

const HeaderMenuItems = (props: Props) => {
	const navigate = useNavigate();
	const { headerMenuItems } = props;
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const isGranted = useIsGranted();

	const onToggle = (isOpen: boolean) => {
		setIsOpen(isOpen);
	};

	const onFocus = () => {
		const element = document.getElementById('toggle-initial-selection');
		element?.focus();
	};

	const onSelect = () => {
		setIsOpen(false);
		onFocus();
	};
	const GetDropdown = (dropdownItem: HeaderMenuItem) => {
		return (
			<Dropdown
				menuAppendTo={() =>
					document.getElementById(`menu-item-${dropdownItem.key}`) as HTMLElement
				}
				data-testid={`menu-item-${dropdownItem.key}`}
				onSelect={onSelect}
				isPlain
				toggle={
					<DropdownToggle
						toggleIndicator={null}
						onToggle={onToggle}
						isPlain
						style={{ padding: '0px' }}
						icon={
							<Tile
								title=""
								isSelected={isMenuItemActive(dropdownItem)}
								data-testid={`menu-item-dropdown-toggle-${dropdownItem.key}`}
							>
								<Flex>
									<Text>{dropdownItem.displayText}</Text>
									<FontAwesomeIcon
										size="sm"
										icon={faCaretDown}
									/>
								</Flex>
							</Tile>
						}
					></DropdownToggle>
				}
				isOpen={isOpen}
				dropdownItems={dropdownItem.subMenuItems?.map(
					(menuItem: HeaderMenuItem, index: number) => (
						<DropdownItem
							key={index}
							data-testid={`menu-item-dropdown-item-${menuItem.key}`}
							onClick={() => navigate(menuItem.url ?? '')}
							className={`${isMenuItemActive(menuItem) ? 'active' : ''}`}
						>
							{menuItem.displayText}
						</DropdownItem>
					)
				)}
			></Dropdown>
		);
	};

	const isMenuItemActive = (menuItem: HeaderMenuItem) => {
		let isActive =
			menuItem.url &&
			window.location.pathname &&
			window.location.pathname.indexOf(`/${menuItem.url ?? ''}`) >= 0;
		if (menuItem.subMenuItems) {
			const found = menuItem.subMenuItems.findIndex(
				(item) =>
					item.url &&
					window.location.pathname &&
					window.location.pathname.indexOf(`/${item.url ?? ''}`) >= 0
			);
			isActive = found >= 0;
		}
		return isActive ? true : false;
	};

	return (
		<Flex spaceItems={{ default: 'spaceItemsXl' }}>
			{headerMenuItems.map((menuItem: HeaderMenuItem, index: number) => {
				if (menuItem.permission && !isGranted(menuItem.permission)) {
					return <></>;
				}
				return (
					<FlexItem
						key={index}
						id={`menu-item-${menuItem.key}`}
						className="menu-item"
					>
						{!menuItem.subMenuItems ? (
							<Tile
								title=""
								isSelected={isMenuItemActive(menuItem)}
								data-testid={`menu-item-${menuItem.key}`}
								onClick={() => navigate(menuItem.url ?? '')}
							>
								<Flex>
									<Text>{menuItem.displayText}</Text>
								</Flex>
							</Tile>
						) : (
							GetDropdown(menuItem)
						)}
					</FlexItem>
				);
			})}
		</Flex>
	);
};

export default HeaderMenuItems;
