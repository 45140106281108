import axiosConfig from '../axiosConfig';
import { TKeyMeasure } from './KeyMeasure';
import { TUnitType } from './UnitType';

export enum DirectionEnum {
	DOWNWARD = 'DOWNWARD',
	UPWARD = 'UPWARD',
}

export const KeyMeasureFactDirectionOptions = [
	{ value: 'Downward', key: 'DOWNWARD' },
	{ value: 'Upward', key: 'UPWARD' },
];

export const KeyMeasureFactDirectionOptionsMap: { [key in keyof typeof DirectionEnum]: string } = {
	[DirectionEnum.DOWNWARD]: 'Downward',
	[DirectionEnum.UPWARD]: 'Upward',
};

export type TKeyMeasureFact = {
	created_at: number;
	creator: unknown;
	keyMeasure: TKeyMeasure | number;
	unitType: TUnitType | number;
	id: number;
	name: string;
	sequence: number;
	updated_at: number;
	updater: unknown;
	alias?: string;
	direction: DirectionEnum; // Default UPWARD
	maximum_value?: number | null;
	minimum_value?: number | null;
};

export type TNewKeyMeasureFact = Omit<
	TKeyMeasureFact,
	'id' | 'updater' | 'updated_at' | 'creator' | 'created_at'
>;

export class KeyMeasureFact implements TKeyMeasureFact {
	created_at: number;
	creator: unknown;
	keyMeasure: TKeyMeasure | number;
	unitType: TUnitType | number;
	id: number;
	name: string;
	sequence: number;
	updated_at: number;
	updater: unknown;
	direction: DirectionEnum; // Default UPWARD
	maximum_value?: number | null;
	minimum_value?: number | null;
	alias?: string;

	constructor(keyMeasureFact: TKeyMeasureFact) {
		this.created_at = keyMeasureFact.created_at;
		this.creator = keyMeasureFact.creator;
		this.keyMeasure = keyMeasureFact.keyMeasure;
		this.unitType = keyMeasureFact.unitType;
		this.id = keyMeasureFact.id;
		this.name = keyMeasureFact.name;
		this.sequence = keyMeasureFact.sequence;
		this.updated_at = keyMeasureFact.updated_at;
		this.updater = keyMeasureFact.updater;
		this.direction = keyMeasureFact.direction;
		this.maximum_value = keyMeasureFact.maximum_value;
		this.minimum_value = keyMeasureFact.minimum_value;
		this.alias = keyMeasureFact.alias;
	}

	public static Default(): TNewKeyMeasureFact {
		return {
			keyMeasure: 0,
			unitType: 1,
			name: '',
			sequence: 0,
			alias: '',
			direction: DirectionEnum.UPWARD,
		};
	}

	public static New(keyMeasureFact: TNewKeyMeasureFact): Promise<TKeyMeasureFact> {
		return axiosConfig.instance.post('app/key-measure-fact', keyMeasureFact).then((res) => {
			return new KeyMeasureFact(res.data as TKeyMeasureFact);
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/key-measure-fact/${id}`).then(() => {
			return true;
		});
	}

	public static Update(keyMeasureFact: TKeyMeasureFact): Promise<TKeyMeasureFact> {
		return axiosConfig.instance.patch('app/key-measure-fact', keyMeasureFact).then((res) => {
			return new KeyMeasureFact(res.data as TKeyMeasureFact);
		});
	}

	public static GetAll(): Promise<TKeyMeasureFact[]> {
		return axiosConfig.instance.get('app/key-measure-facts').then((res) => {
			return ((res.data || []) as TKeyMeasureFact[]).map(
				(keyMeasureFact: TKeyMeasureFact) => {
					return new KeyMeasureFact(keyMeasureFact);
				}
			);
		});
	}

	public static Get(
		id: number,
		expand: (keyof TKeyMeasureFact)[] = []
	): Promise<TKeyMeasureFact> {
		return axiosConfig.instance
			.get(`app/key-measure-fact/${id}`, { params: { expand: expand } })
			.then((res) => {
				return res.data as TKeyMeasureFact;
			});
	}
}
