import { TDimension } from '../api/analytics/Dimension';
import { OptionsBuilderItemTypes } from '../types/dataframes/options-builder-item-types';

export const defaultDateRangeDim: TDimension = {
	id: 0,
	allow_filtering: true,
	code: 'DateSeries',
	creator: '1',
	created_at: 1,
	display_name: 'Date Range',
	type: OptionsBuilderItemTypes.DateSeries,
	description: '',
	dimensionAttributes: [],
	groups: [],
	is_unique: true,
	keyMeasures: [],
	name: 'Date Range',
	show_on_summary: false,
	updated_at: 1,
	updater: undefined,
	userDimensions: [],
};
