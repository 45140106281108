import { Flex, FlexItem } from '@patternfly/react-core';
import Setup from './Setup';
import Avatar from './Avatar';
import Notifications from './Notifications';
import React from 'react';
import HeaderMenuItems from './HeaderMenuItems';
import { HeaderMenuItem } from '../../helpers/headerHelper';
import { useIsGranted } from '../../components/user/ApplicationProvider';
import { Permission } from '../../enums/permission.enum';

type Props = {
	menuItems: HeaderMenuItem[];
};

const HeaderContent = (props: Props) => {
	const { menuItems } = props;
	const isGranted = useIsGranted();
	const hasViewNotificationsPerm = isGranted(Permission.ViewZoneNotification);
	const hasViewSetupPerm = isGranted(Permission.ViewZoneSettings);

	return (
		<Flex
			justifyContent={{ default: 'justifyContentSpaceBetween' }}
			alignItems={{ default: 'alignItemsCenter' }}
		>
			<HeaderMenuItems headerMenuItems={menuItems} />
			<FlexItem>
				<Flex>
					{hasViewSetupPerm && (
						<FlexItem style={{ margin: 0 }}>
							<Setup />
						</FlexItem>
					)}
					{hasViewNotificationsPerm && (
						<FlexItem style={{ margin: 0 }}>
							<Notifications />
						</FlexItem>
					)}
					<FlexItem>
						<Avatar />
					</FlexItem>
				</Flex>
			</FlexItem>
		</Flex>
	);
};

export default HeaderContent;
