import axiosConfig from '../axiosConfig';

export type TQuestionTag = {
	question: number;
	tag: number;
};

export class QuestionTag implements TQuestionTag {
	question: number;
	tag: number;

	constructor(questionTag: TQuestionTag) {
		this.question = questionTag.question;
		this.tag = questionTag.tag;
	}

	public static Default(): TQuestionTag {
		return {
			question: 0,
			tag: 0,
		};
	}

	public static New(tag: TQuestionTag): Promise<TQuestionTag> {
		return axiosConfig.instance
			.post<TQuestionTag>(`app/question/${tag.question}/tag/${tag.tag}`)
			.then((res) => {
				return res.data;
			});
	}

	public static Delete(questionId: number, tagId: number): Promise<boolean> {
		return axiosConfig.instance
			.delete<boolean>(`app/question/${questionId}/tag/${tagId}`)
			.then((res) => {
				return res.data;
			});
	}

	public static Update(tag: TQuestionTag): Promise<TQuestionTag> {
		return axiosConfig.instance.patch<TQuestionTag>('app/question/tag', tag).then((res) => {
			return res.data;
		});
	}

	public static GetAll(): Promise<TQuestionTag[]> {
		return axiosConfig.instance.get<TQuestionTag[]>('app/question/tags/').then((res) => {
			return res.data ?? [];
		});
	}

	public static GetOne(id: number): Promise<TQuestionTag> {
		return axiosConfig.instance.get<TQuestionTag>(`app/question/tag/${id}/`).then((res) => {
			return res.data ?? [];
		});
	}
}
