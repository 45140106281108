import { AxiosInstance } from 'axios';
import axios from 'axios';
import { AxiosCacheInstance, setupCache } from 'axios-cache-interceptor';

const url: string = process.env.REACT_APP_API_URL ?? 'https://dev.zeroedin.com';
const port: string = process.env.REACT_APP_API_PORT ?? '9999';

export interface IZIApiError {
	'detail': string;
	'error-id': string;
	'extra': string;
}

function axiosConfig(): {
	baseURL: string;
	timeout: number;
	instance: AxiosCacheInstance;
} {
	const baseURL = `${url}:${port}/`;
	const timeout = 100000;

	const axiosInstance: AxiosInstance = axios.create({
		baseURL,
		timeout,
		withCredentials: true,
		headers: {
			'CSRF-TOKEN': 'CSRF-Token',
		},
	});

	const instance = setupCache(axiosInstance);

	return {
		baseURL,
		timeout,
		instance,
	};
}

export default axiosConfig();
