import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, FlexItem, Title, Text, Grid, GridItem } from '@patternfly/react-core';
import { Dispatch, useEffect } from 'react';
import BreadCrumbs from '../BreadCrumbs';
import { SubNavContext } from '../../helpers/sub-nav-contexts/sub-nav-context';
import SubNavCommonActions from '../../components/subnav/SubNavCommonActions';
import SubNavReportSummary from '../../components/report/SubNavReportSummary';
import SubNavTipsAndTricks from '../../components/subnav/SubNavTipsAndTricks';
import SubNavReportSnapshot from '../../components/report/SubNavReportSnapshot';
import _ from 'lodash';
import { BoolStringEnum } from '../../enums/bool-string.enum';

type props = {
	pageTitle: string;
	pageDescription?: string | undefined;
	expanded?: boolean | undefined;
	setExpanded?: Dispatch<boolean> | undefined;
	isCollapsable?: boolean | undefined;
	subNavContext?: SubNavContext;
	collapseByDefault?: boolean;
};

function PageTitleSubheader({
	pageTitle,
	pageDescription,
	isCollapsable,
	expanded,
	setExpanded,
	subNavContext,
	collapseByDefault,
}: props) {
	const onToggle = () => {
		setExpandedStorageValue(!expanded);
		setExpanded && setExpanded(!expanded);
	};

	useEffect(() => {
		if (collapseByDefault) {
			setExpandedStorageValue(false);
			setExpanded && setExpanded(false);
		} else {
			const storedExpanded = getExpandedStorageValue();

			if (_.isNull(storedExpanded)) {
				setExpanded && setExpanded(true);
			} else {
				setExpanded && setExpanded(storedExpanded);
			}
		}
	}, [isCollapsable, subNavContext, collapseByDefault]);

	const getExpandedStorageValue = (): boolean | null => {
		const storedValue = localStorage.getItem('header-expanded');
		return _.isNull(storedValue) ? null : storedValue === BoolStringEnum.True;
	};

	const setExpandedStorageValue = (collapsed: boolean) => {
		localStorage.setItem('header-expanded', collapsed.toString());
	};

	return (
		<Grid>
			<Grid span={12}>
				<Flex direction={{ default: 'row' }}>
					<FlexItem>
						<BreadCrumbs />
					</FlexItem>
					{!pageTitle && isCollapsable && (
						<FlexItem
							onClick={() => {
								onToggle();
							}}
							alignSelf={{ default: 'alignSelfFlexEnd' }}
							align={{ default: 'alignRight' }}
						>
							{isCollapsable && (
								<FontAwesomeIcon
									className="icon"
									icon={expanded ? faAngleUp : faAngleDown}
									size="xl"
								/>
							)}
						</FlexItem>
					)}
				</Flex>
			</Grid>
			<GridItem span={12}>
				{pageTitle && isCollapsable && (
					<Flex direction={{ default: 'row' }}>
						<FlexItem>
							<Title
								headingLevel={'h1'}
								className={'text-secondary sub-heading'}
							>
								{pageTitle}
							</Title>
						</FlexItem>
						<FlexItem
							onClick={() => {
								onToggle();
							}}
							alignSelf={{ default: 'alignSelfFlexEnd' }}
							align={{ default: 'alignRight' }}
						>
							{isCollapsable && (
								<FontAwesomeIcon
									className="icon"
									icon={expanded ? faAngleUp : faAngleDown}
									size="xl"
								/>
							)}
						</FlexItem>
					</Flex>
				)}
			</GridItem>
			<Grid
				span={12}
				className="hidden-when-collapsed"
			>
				{subNavContext?.actions && pageDescription ? (
					<GridItem span={12}>
						<FlexItem className="sub-heading-description">
							<Text>{pageDescription}</Text>
						</FlexItem>
					</GridItem>
				) : null}
				<GridItem span={6}>
					{!subNavContext?.actions && pageDescription ? (
						<FlexItem className="sub-heading-description">
							<Text>{pageDescription}</Text>
						</FlexItem>
					) : null}
					{subNavContext?.actions && (
						<SubNavCommonActions actionsSummary={subNavContext.actions} />
					)}
				</GridItem>
				<GridItem span={1} />
				<GridItem span={5}>
					{subNavContext?.report && (
						<SubNavReportSummary reportSummary={subNavContext.report} />
					)}
					{subNavContext?.tipsAndTricks && (
						<SubNavTipsAndTricks tipsAndTricks={subNavContext.tipsAndTricks} />
					)}
					{subNavContext?.reportSnapshot && (
						<SubNavReportSnapshot reportSnapshot={subNavContext.reportSnapshot} />
					)}
				</GridItem>
			</Grid>
		</Grid>
	);
}

export default PageTitleSubheader;
