import React, { Dispatch, SetStateAction, useState } from 'react';
import { useMount } from 'react-use';
import { TFolder } from '../../../api/foundational-elements/Folder';
import { FormGroup, TextInput, TreeView, TreeViewDataItem } from '@patternfly/react-core';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { TChart } from '../../../api/charts/Chart';
import Loader from '../../util/Loader';

export type CopyChartOptionsViewProps = {
	selectedChart?: TChart;
	onSelectChart: Dispatch<SetStateAction<TChart | undefined>>;
};

const CopyChartOptionsView = (_props: CopyChartOptionsViewProps) => {
	const [folders, setFolders] = useState<TreeViewDataItem[]>([]);
	const [filteredItems, setFilteredItems] = useState<TreeViewDataItem[]>([]);
	const [isFiltered, setIsFiltered] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	useMount(() => {
		setIsLoading(true);

		const apiFolders: TFolder[] = [
			{
				id: 1,
				name: 'Job Category',
				type: 'Chart',
				items: [
					{ id: 1, name: `some chart-1` },
					{ id: 2, name: `some chart-2` },
				],
			},
			{
				id: 2,
				name: 'Employee',
				type: 'Chart',
				items: [
					{ id: 3, name: `some chart-3` },
					{ id: 4, name: `some chart-4` },
				],
			},
		];

		setFolders(apiFolders.map((_) => BuildTreeViewItem(_, _.items)));
		setFilteredItems(apiFolders.map((_) => BuildTreeViewItem(_, _.items)));

		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	});

	const selectChart = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	): void => {
		if (!item.id?.includes('__') || !item) {
			return;
		}

		const [folderId, chartId] = item.id.split('__');

		console.log(folderId, chartId);

		//update with required props
		//props.onSelectChart({ id: +chartId, name: itemName, chart_type: 'bubble' });
	};

	const onSearch = (input: string): void => {
		setSearchValue(input);
		if (input === '') {
			setFilteredItems(folders);
			setIsFiltered(false);
		} else {
			const filtered = folders
				.map((opt) => Object.assign({}, opt))
				.filter((item) => filterItems(item, input));
			setFilteredItems(filtered);
			setIsFiltered(true);
		}
	};

	const filterItems = (item: TreeViewDataItem, input: string): boolean => {
		const itemName = item.name as string;
		input = input.toLowerCase();
		if (itemName.toLowerCase().includes(input)) {
			return true;
		}

		if (item.children) {
			return (
				(item.children = item.children
					.map((opt) => Object.assign({}, opt))
					.filter((child) => filterItems(child, input))).length > 0
			);
		}

		return false;
	};

	return isLoading ? (
		<Loader />
	) : (
		<div data-testid="copy-view">
			<FormGroup>
				<TextInput
					type="text"
					value={searchValue}
					onChange={onSearch}
					id="input-search"
					name="search-input"
					aria-label="Search for a Chart"
					placeholder="Search for a Chart"
				/>
			</FormGroup>

			<TreeView
				onSelect={selectChart}
				data={filteredItems}
				allExpanded={isFiltered}
			/>
		</div>
	);
};

export default CopyChartOptionsView;
