import axiosConfig from '../axiosConfig';

export type TUserDimensionAttributeFilter = {
	id: number;
	user: number;
	username: string;
	dimension_attribute: number;
	value: string;
	logic_group: number;
};

export type TNewUserDimensionAttributeFilter = Omit<TUserDimensionAttributeFilter, 'id'>;

export class UserDimensionAttributeFilter implements TUserDimensionAttributeFilter {
	id: number;
	user: number;
	username: string;
	dimension_attribute: number;
	value: string;
	logic_group: number;

	constructor(udf: TUserDimensionAttributeFilter) {
		this.id = udf.id;
		this.user = udf.user;
		this.username = udf.username;
		this.dimension_attribute = udf.dimension_attribute;
		this.value = udf.value;
		this.logic_group = udf.logic_group;
	}

	public static Default(): TNewUserDimensionAttributeFilter {
		return {
			dimension_attribute: 0,
			value: '',
			logic_group: 0,
			user: 0,
			username: '',
		};
	}

	public static GetAll(
		id: number,
		expand: (keyof TUserDimensionAttributeFilter)[] = []
	): Promise<TUserDimensionAttributeFilter[]> {
		// This requires multiple return endpoints to end in 's' in the API
		return axiosConfig.instance
			.get(`app/userdimensionattributes/${id}`, { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as TUserDimensionAttributeFilter[]).map((entity) => {
					return entity;
				});
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/userdimensionattribute/` + String(id)).then(() => {
			return true;
		});
	}

	public static New(
		entity: TNewUserDimensionAttributeFilter
	): Promise<TUserDimensionAttributeFilter> {
		return axiosConfig.instance.post(`app/userdimensionattribute`, entity).then((res) => {
			return res.data as TUserDimensionAttributeFilter;
		});
	}

	public static Update(
		entity: TUserDimensionAttributeFilter
	): Promise<TUserDimensionAttributeFilter> {
		return axiosConfig.instance.patch('app/userdimensionattribute', entity).then((res) => {
			return res.data as TUserDimensionAttributeFilter;
		});
	}
}
