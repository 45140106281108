import React, { useState } from 'react';
import { Flex, FlexItem, Grid, GridItem, TextInput } from '@patternfly/react-core';
import MagnifyingGlassIcon from '@fortawesome/fontawesome-pro/svgs/regular/magnifying-glass.svg';

export type Props = {
	layoutActions?: React.ReactElement | (React.ReactElement | null)[];
	table: React.ReactElement;
	icon?: string;
};
export default function FilterTableLayout(props: Props) {
	const [filter, setFilter] = useState<string>('');

	const tableWithFilter = React.cloneElement(props.table, {
		filter: filter,
	});

	const renderLayoutActions = () => {
		if (props.layoutActions) {
			return (
				<Flex>
					{React.Children.map(props.layoutActions, (child, index) => (
						<FlexItem key={index}>{child}</FlexItem>
					))}
				</Flex>
			);
		}
		return null;
	};

	return (
		<Grid hasGutter>
			<GridItem span={3}>
				<TextInput
					value={filter}
					type="text"
					className="filter"
					customIconUrl={props.icon ? props.icon : MagnifyingGlassIcon}
					placeholder="Filter"
					onChange={(value) => setFilter(value)}
					aria-label="Filter table"
				/>
			</GridItem>

			<GridItem
				span={9}
				style={{ justifySelf: 'end', gap: '1rem' }}
			>
				{renderLayoutActions()}
			</GridItem>
			<GridItem span={12}>{tableWithFilter}</GridItem>
		</Grid>
	);
}
