import {
	AlertVariant,
	Button,
	Modal,
	ModalBoxFooter,
	ModalVariant,
	Spinner,
} from '@patternfly/react-core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import DualListSelectorV2, { DualListItem } from './DualListSelectorV2';
import { SharedEntity, TSharedEntity } from '../../api/shared-entity/SharedEntity';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { EntityTypesEnum } from '../../enums/entity-types.enum';
import { faUsers, faUser } from '@fortawesome/pro-solid-svg-icons';
import { TGroup } from '../../api/security/Group';
import { TUser, User } from '../../api/security/User';
import { useUser } from '../../../src/components/user/ApplicationProvider';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	entityType: string;
	entityName: string;
};

function ShareEntityModal(props: Props) {
	const [isLoading, _setIsLoading] = useState<boolean>(false);
	const {
		frameId,
		measureFactId,
		chartId,
		dashboardId,
		presentationId,
		folderId,
		parentFolderId,
		reportId,
	} = useParams();
	const pathname = useLocation().pathname;
	const segments = pathname.split('/');
	const lastSegment = segments[segments.length - 1];
	const { addToast } = useToast();
	const [sharedEntity, setSharedEntity] = useState<TSharedEntity>(SharedEntity.Default());
	const [pageLoadLeftItems, setPageLoadLeftItems] = useState<DualListItem[]>([]);
	const [leftItems, setLeftItems] = useState<DualListItem[]>([]);
	const [rightItems, setRightItems] = useState<DualListItem[]>([]);
	const [hasLoadedFetchedEntities, _setHasLoadedFetchedEntities] = useState<boolean>(false);
	const [_hasLoadedUserAndGroups, setHasLoadedUserAndGroups] = useState<boolean>(false);
	const currentUser = useUser();

	useEffect(() => {
		const [entityType, entityId] = setEntityTypeAndId();
		const entTypeLowerCased = entityType.toLowerCase();
		if (entityId && entityType) {
			Promise.all([
				User.GetAll(['groups']),
				SharedEntity.GetAll({
					creator: currentUser.id.toString(),
					entity_type: entTypeLowerCased,
					[entTypeLowerCased]: entityId.toString(),
				}),
			])
				.then(([usersResponse, sharedEntitiesResponse]): void => {
					handleAllUsersAndGroupResponse(usersResponse);
					handleSharedEntityAllResponse(sharedEntitiesResponse);
				})
				.catch(() => {
					console.log('Error in SharedEntity.GetAll');
				});
		}
	}, []);

	const setEntityTypeAndId = (): [string, number] => {
		let entityType = '';
		let entityId = 0;
		const newSharedEntity = SharedEntity.Default();

		if (chartId || (measureFactId && chartId)) {
			entityType = EntityTypesEnum.Chart;
			newSharedEntity.chart = parseInt(chartId);
			newSharedEntity.entity_type = entityType;
			entityId = parseInt(chartId);
		}
		if (frameId || (measureFactId && frameId)) {
			entityType = EntityTypesEnum.Dataframe;
			newSharedEntity.dataframe = parseInt(frameId);
			newSharedEntity.entity_type = entityType;
			entityId = parseInt(frameId);
		}
		if (dashboardId) {
			entityType = EntityTypesEnum.Dashboard;
			newSharedEntity.dashboard = parseInt(dashboardId);
			newSharedEntity.entity_type = entityType;
			entityId = parseInt(dashboardId);
		}
		if (presentationId) {
			entityType = EntityTypesEnum.Presentation;
			newSharedEntity.presentation = parseInt(presentationId);
			newSharedEntity.entity_type = entityType;
			entityId = parseInt(presentationId);
		}
		if (reportId) {
			entityType = EntityTypesEnum.Report;
			newSharedEntity.report = parseInt(reportId);
			newSharedEntity.entity_type = entityType;
			entityId = parseInt(reportId);
		}

		if (!entityType && (folderId || parentFolderId || lastSegment)) {
			const actualId =
				!parentFolderId && !folderId ? lastSegment : parentFolderId ?? folderId!;
			entityType = EntityTypesEnum.Folder;
			newSharedEntity.folder = parseInt(actualId);
			newSharedEntity.entity_type = entityType;
			entityId = parseInt(actualId);
		}

		setSharedEntity(newSharedEntity);

		return [newSharedEntity.entity_type, entityId];
	};

	const handleSharedEntityAllResponse = (sharedEntities: TSharedEntity[]): void => {
		if (sharedEntities && sharedEntities.length > 0) {
			setSharedEntity(sharedEntities[0]);
		}
	};

	const handleAllUsersAndGroupResponse = (users: TUser[]): void => {
		let items: DualListItem[] = [];
		users.map((user) => {
			const fetchedGroups = user.groups as TGroup[];
			fetchedGroups.map((group: TGroup) => {
				items.push({
					id: `ug_${group.id}`,
					icon: faUsers,
					label: `${group.name} (${group.tag})`,
					selected: false,
				});
			});

			if (user.id !== currentUser.id) {
				items.push({
					id: user.id,
					icon: faUser,
					label: `${user.name} (${user.account})`,
					selected: false,
				});
			}
		});
		items = uniqueByKey(items);
		setLeftItems(items);
		setPageLoadLeftItems(items);
		setHasLoadedUserAndGroups(true);
	};

	const uniqueByKey = (items: DualListItem[]): DualListItem[] => {
		const mapObj = new Map();

		items.forEach((v: DualListItem) => {
			const prevValue: number = mapObj.get(v.id) as number;
			if (!prevValue) {
				mapObj.set(v.id, v);
			}
		});
		return [...mapObj.values()] as DualListItem[];
	};

	useEffect(() => {
		if (!hasLoadedFetchedEntities) {
			getAllUsersAndGroups();
		}
	}, [hasLoadedFetchedEntities]);

	useEffect(() => {
		const leftSelect = pageLoadLeftItems.filter(
			(l) =>
				!sharedEntity.shared_entity_groups?.find(
					(g) => l.id.toString().startsWith('ug_') && l.id === `ug_${g.group ?? 0}`
				) &&
				!sharedEntity.shared_entity_users?.find(
					(u) => (!l.id.toString().startsWith('ug_') && l.id === u.user) ?? 0
				)
		);
		const rightSelect = pageLoadLeftItems.filter((l) => {
			const foundInGroups = sharedEntity.shared_entity_groups?.find(
				(g) => l.id.toString().startsWith('ug_') && l.id === `ug_${g.group ?? 0}`
			);
			if (foundInGroups) {
				l.can_edit = foundInGroups.can_edit ?? false;
				l.can_share = foundInGroups.can_share ?? false;
			}

			const foundInUsers = sharedEntity.shared_entity_users?.find(
				(u) => !l.id.toString().startsWith('ug_') && l.id === u.user
			);
			if (foundInUsers) {
				l.can_edit = foundInUsers.can_edit ?? false;
				l.can_share = foundInUsers.can_share ?? false;
			}

			return foundInGroups || foundInUsers;
		});
		setLeftItems([...leftSelect]);
		setRightItems([...rightSelect]);
	}, [sharedEntity.shared_entity_groups, sharedEntity.shared_entity_users]);

	const getAllUsersAndGroups = () => {
		const initialItems: DualListItem[] = [];
		const userGroups: TGroup[] = [];

		User.GetAll(['groups'])
			.then((response) => {
				const userGroupLastIdx = response.length - 1;
				response.map((user) => {
					const newGroups: TGroup[] = [];
					const fetchedGroups = user.groups as TGroup[];

					fetchedGroups.map((group: TGroup) => {
						if (!userGroups.includes(group)) {
							userGroups.push(group);
							newGroups.push(group);
						}
					});

					newGroups.map((ug) => {
						if (!initialItems.find((x) => x.id == `ug_${ug.id}`)) {
							initialItems.push({
								id: `ug_${ug.id}`,
								icon: faUsers,
								label: `${ug.name} (${ug.tag})`,
								selected: false,
							});
						}
					});

					if (!initialItems.find((x) => x.id == user.id)) {
						initialItems.push({
							id: user.id,
							icon: faUser,
							label: `${user.name} (${user.account})`,
							selected: false,
						});
					}

					setPageLoadLeftItems([...pageLoadLeftItems, ...initialItems]);

					if (response.indexOf(user) == userGroupLastIdx) {
						setHasLoadedUserAndGroups(true);
					}
				});
			})
			.catch(() => console.log('Error in User.GetAll'));
	};

	const onSave = () => {
		const request = { ...sharedEntity };
		// Remove Groups
		request.shared_entity_groups = request.shared_entity_groups?.filter((g) => {
			return rightItems.find((r) => r.id === `ug_${g.group ?? 0}`);
		});
		// Remove Users
		request.shared_entity_users = request.shared_entity_users?.filter((u) => {
			return rightItems.find((r) => r.id === u.user ?? 0);
		});
		rightItems.map((item) => {
			if (item.id.toString().includes('ug_')) {
				// Groups
				const existingGroup = request.shared_entity_groups?.find(
					(val) => `ug_${val.group ?? 0}` === item.id
				);
				if (existingGroup) {
					existingGroup.can_edit = item.can_edit ?? false;
					existingGroup.can_share = item.can_share ?? false;
				} else {
					if (!request.shared_entity_groups) {
						request.shared_entity_groups = [];
					}
					request.shared_entity_groups?.push({
						group: parseInt(item.id.toString().replace('ug_', '')),
						can_edit: item.can_edit ?? false,
						can_share: item.can_share ?? false,
					});
				}
			} else {
				// Users
				const existingUser = request.shared_entity_users?.find(
					(val) => val.user === item.id
				);
				if (existingUser) {
					existingUser.can_edit = item.can_edit ?? false;
					existingUser.can_share = item.can_share ?? false;
				} else {
					if (!request.shared_entity_users) {
						request.shared_entity_users = [];
					}
					request.shared_entity_users?.push({
						user: parseInt(item.id.toString()),
						can_edit: item.can_edit ?? false,
						can_share: item.can_share ?? false,
					});
				}
			}
		});
		if (!sharedEntity.id) {
			SharedEntity.New(request)
				.then((response) => {
					addToast('Sharing configuration saved successfully', AlertVariant.success);
					setSharedEntity(response);
					props.onClose();
				})
				.catch(() =>
					addToast(
						`An error occured during sharing configuration save`,
						AlertVariant.danger
					)
				);
		} else {
			SharedEntity.Update(request)
				.then((response) => {
					addToast('Sharing configuration saved successfully', AlertVariant.success);
					if (response.id) {
						setSharedEntity(response);
					} else {
						setSharedEntity(SharedEntity.Default());
						setEntityTypeAndId();
					}
					props.onClose();
				})
				.catch(() =>
					addToast(
						`An error occured during sharing configuration save`,
						AlertVariant.danger
					)
				);
		}
	};

	return (
		<div>
			<Modal
				title={`Sharing for ${props.entityType} - ${props.entityName}`}
				variant={ModalVariant.large}
				isOpen={props.isOpen}
				onClose={props.onClose}
			>
				<div>
					{isLoading && (
						<div className="spinner-container">
							<Spinner size={'lg'} />
						</div>
					)}
					{!isLoading && (
						<div>
							<DualListSelectorV2
								leftItems={leftItems}
								setLeftItems={setLeftItems}
								rightItems={rightItems}
								setRightItems={setRightItems}
							/>
							<br />
						</div>
					)}
					<ModalBoxFooter className="pull-right modal-share-footer">
						<Button
							variant="primary"
							onClick={onSave}
						>
							Save
						</Button>
					</ModalBoxFooter>
				</div>
			</Modal>
		</div>
	);
}

export default ShareEntityModal;
