export enum ReportConditionalFormattingRuleEnum {
	BETWEEN = 'BETWEEN',
	BOTTOM_10_PERCENT = 'BOTTOM_10',
	DUPLICATE = 'DUPLICATE',
	EQUAL_TO = 'EQUAL_TO',
	GREATER_THAN = 'GREATER_THAN',
	GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
	LESS_THAN = 'LESS_THAN',
	LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
	NOT_EQUAL_TO = 'NOT_EQUAL_TO',
	TOP_10_PERCENT = 'TOP_10',
	TOP_N = 'TOP_N',
	UNIQUE = 'UNIQUE',
}
