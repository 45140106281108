import axiosConfig from '../axiosConfig';
import { TTag } from '../tags/Tags';

export type TQuestion = {
	id?: number;
	category?: number;
	creator_id?: number;
	name: string;
	description: string;
	sequence: number;
	updated_at?: number;
	created_at?: number;
	chart: number;
	tags?: TTag[];
};

export class Question implements TQuestion {
	id?: number;
	category?: number;
	creator_id?: number;
	name: string;
	description: string;
	sequence: number;
	updated_at?: number;
	created_at?: number;
	chart: number;

	constructor(question: TQuestion) {
		this.id = question.id;
		this.category = question.category;
		this.creator_id = question.creator_id;
		this.name = question.name;
		this.description = question.description;
		this.sequence = question.sequence;
		this.updated_at = question.updated_at;
		this.created_at = question.created_at;
		this.chart = question.chart;
	}

	public static New(question: TQuestion): Promise<TQuestion> {
		return axiosConfig.instance.post('app/question', question).then((res) => {
			return new Question(res.data as TQuestion);
		});
	}

	public static Update(question: TQuestion): Promise<TQuestion> {
		return axiosConfig.instance.patch<TQuestion>('app/question', question).then((res) => {
			return res.data;
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/question/${id}`).then(() => {
			return true;
		});
	}

	public static Get(questionId: number, expand: (keyof TQuestion)[] = []): Promise<TQuestion> {
		return axiosConfig.instance
			.get<TQuestion>(`app/question/${questionId}`, { params: { expand } })
			.then((res) => {
				return res.data;
			});
	}

	public static GetAll(chartId?: number): Promise<TQuestion[]> {
		let url = 'app/questions';

		if (chartId) {
			url += `?chartId=${chartId}`;
		}
		return axiosConfig.instance.get<TQuestion[]>(url).then((res) => {
			return res.data?.length > 0 ? res.data : [];
		});
	}

	public static GetAllByTagId(expand: (keyof TQuestion)[] = []): Promise<TQuestion[]> {
		return axiosConfig.instance
			.get<TQuestion[]>(`app/questions`, { params: { expand } })
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}

	public static GetAllByCategoryId(
		categoryId: string,
		expand: (keyof TQuestion)[] = []
	): Promise<TQuestion[]> {
		return axiosConfig.instance
			.get<TQuestion[]>(`app/questions?categoryId=${categoryId}`, {
				params: { expand },
			})
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}
}
