import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { AlertVariant, Button, Popover } from '@patternfly/react-core';
import { useCallback, useEffect, useState } from 'react';
import './DataPeriodSelector.scss';
import { TDateRange } from '../../api/types/TDateRange';
import PopoverFooterHtml from './PopoverFooterHtml';
import DatePeriodHtml from './DatePeriodHtml';
import { useApplication } from '../user/ApplicationProvider';
import { DatePeriodFormatter } from './DatePeriodFormatter';
import { useMount } from 'react-use';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';

type Props = {
	applyDateRange: (startDateRange: TDateRange, endDateRange: TDateRange) => void;
	disabled?: boolean;
	disabledMessage?: string;
	label?: string;
};

function DatePeriodSelector(props: Props) {
	const { applyDateRange, disabled, disabledMessage } = props;
	const { currentDatePeriods } = useApplication();
	const previousSelectedStartPeriod = localStorage.getItem('currentSelectedStartPeriod');
	const previousSelectedEndPeriod = localStorage.getItem('currentSelectedEndPeriod');
	const hasPreviouslySelectedPeriod =
		previousSelectedStartPeriod != null || previousSelectedEndPeriod != null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedStartDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedStartPeriod
			? JSON.parse(previousSelectedStartPeriod)
			: null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedEndDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedEndPeriod
			? JSON.parse(previousSelectedEndPeriod)
			: null;
	const [selectedStartDate, setSelectedStartDate] = useState<TDateRange | undefined>(
		hasPreviouslySelectedPeriod && previouslySelectedStartDate
			? previouslySelectedStartDate
			: currentDatePeriods.find((dp) => dp.period == 3)
	);
	const [selectedEndDate, setSelectedEndDate] = useState<TDateRange | undefined>(
		hasPreviouslySelectedPeriod && previouslySelectedEndDate
			? previouslySelectedEndDate
			: currentDatePeriods.find((dp) => dp.period == 3)
	);
	const [dateClickCount, setDateClickCount] = useState(0);
	const [isVisible, setIsVisible] = useState(false);
	const [label, setLabel] = useState<string>();
	const { addToast } = useToast();

	useMount(() => {
		if (selectedStartDate && selectedEndDate) {
			applyDateRange(selectedStartDate, selectedEndDate);
			getLabel();
		}
		setIsVisible(false);
		localStorage.setItem('currentSelectedStartPeriod', `${JSON.stringify(selectedStartDate)}`);
		localStorage.setItem('currentSelectedEndPeriod', `${JSON.stringify(selectedEndDate)}`);
		return [selectedStartDate, selectedEndDate];
	});

	useEffect(() => {
		getLabel();
	}, [props.label]);

	const updateSelectedDates = useCallback(
		(selectedDateRange?: TDateRange) => {
			let returnObject: (TDateRange | undefined)[] = [];
			let noEndDate = false;

			if (dateClickCount != 1) {
				setSelectedStartDate(selectedDateRange);
				setSelectedEndDate(selectedDateRange);
				returnObject = [selectedDateRange, selectedDateRange];
				setDateClickCount(1);
				noEndDate = false;
			} else {
				if (
					selectedStartDate &&
					selectedDateRange &&
					selectedStartDate.begin_date <= selectedDateRange.begin_date
				) {
					setSelectedEndDate(selectedDateRange);
					returnObject = [selectedStartDate, selectedDateRange];
				} else {
					setSelectedStartDate(selectedDateRange);
					setSelectedEndDate(selectedDateRange);
					returnObject = [selectedDateRange, selectedDateRange];
					noEndDate = true;
				}
				if (noEndDate) {
					setDateClickCount(1);
				} else {
					setDateClickCount(0);
				}
			}
			return returnObject;
		},
		[selectedStartDate, selectedEndDate]
	);
	const applySelectedDates = useCallback(() => {
		if (selectedStartDate && selectedEndDate) {
			applyDateRange(selectedStartDate, selectedEndDate);
			getLabel();
		}
		setIsVisible(false);
		localStorage.setItem('currentSelectedStartPeriod', `${JSON.stringify(selectedStartDate)}`);
		localStorage.setItem('currentSelectedEndPeriod', `${JSON.stringify(selectedEndDate)}`);
		return [selectedStartDate, selectedEndDate];
	}, [selectedStartDate, selectedEndDate]);

	const getLabel = () => {
		if (!props.label) {
			let label = '';

			if (selectedStartDate) {
				if (selectedStartDate.title) {
					label = selectedStartDate.title;
				} else {
					label = DatePeriodFormatter(
						selectedStartDate.begin_date,
						selectedStartDate.period
					);
				}
			}
			if (selectedEndDate && !(selectedEndDate.id === selectedStartDate?.id)) {
				if (selectedEndDate.title) {
					label = selectedEndDate.title;
				} else {
					label = `${label} - ${DatePeriodFormatter(
						selectedEndDate.begin_date,
						selectedEndDate.period
					)}`;
				}
			}

			setLabel(label);
		} else {
			setLabel(props.label);
		}
	};

	const onModalClose = () => {
		setIsVisible(false);
		setSelectedStartDate(
			hasPreviouslySelectedPeriod && previouslySelectedStartDate
				? previouslySelectedStartDate
				: currentDatePeriods.find((dp) => dp.period == 3)
		);
		setSelectedEndDate(
			hasPreviouslySelectedPeriod && previouslySelectedEndDate
				? previouslySelectedEndDate
				: currentDatePeriods.find((dp) => dp.period == 3)
		);
	};

	return (
		<Popover
			id={'DatePopOver'}
			aria-label="Basic popover"
			isVisible={isVisible}
			position={'auto'}
			shouldOpen={() => {
				if (!disabled) {
					setIsVisible(true);
				} else {
					if (disabledMessage) {
						addToast(disabledMessage, AlertVariant.danger);
					}
				}
			}}
			shouldClose={() => onModalClose()}
			headerContent={
				<div>
					Date Period Selector{' '}
					<FontAwesomeIcon
						size="1x"
						icon={faCalendarAlt}
						className=""
					/>
					<br />
				</div>
			}
			bodyContent={
				<DatePeriodHtml
					updateSelectedDates={updateSelectedDates}
					selectedRange={[selectedStartDate, selectedEndDate]}
				/>
			}
			footerContent={
				<PopoverFooterHtml
					startDate={selectedStartDate?.begin_date}
					endDate={selectedEndDate?.end_date}
					applySelectedDates={applySelectedDates}
				/>
			}
			className="large-popover"
			maxWidth="900px"
		>
			<div>
				<Button className="btn-dateperiod nudge-left">
					<FontAwesomeIcon
						size="1x"
						icon={faCalendarAlt}
						className=""
					/>
					<strong className="nudge-left">{label}</strong>
				</Button>
			</div>
		</Popover>
	);
}

export default DatePeriodSelector;
