import { Button, Flex, FlexItem, Grid, GridItem, Text } from '@patternfly/react-core';
import './SubNavReportSummary.scss';
import { ReportSnapshotItem } from '../../helpers/sub-nav-contexts/sub-nav-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/pro-regular-svg-icons';
import ChartView from '../charts/ChartView';
import { useApplication } from '../user/ApplicationProvider';
import { selectedPeriod } from '../../helpers/selected-period.helper';
import { DateRange } from '../../api/date-period-selector/DateRange';
import { TDateRange } from '../../api/types';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
	reportSnapshot: ReportSnapshotItem;
};

const SubNavReportSnapshot = (props: Props) => {
	const {
		reportSnapshot: { title, snapshotText, chartId, noFavToggled },
	} = props;
	const { currentDatePeriods } = useApplication();
	const currentPeriod = selectedPeriod();
	const defaultPeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);
	const [selectedDate, _] = useState<TNewDateRange | undefined>({
		begin_date: currentPeriod.startPeriod
			? currentPeriod.startPeriod.begin_date
			: defaultPeriod.begin_date ?? 0,
		end_date: currentPeriod.endPeriod
			? currentPeriod.endPeriod.end_date
			: defaultPeriod.end_date ?? 0,
		period: currentPeriod.startPeriod
			? currentPeriod.startPeriod.period
			: defaultPeriod.period ?? 0,
		sequence: currentPeriod.startPeriod
			? currentPeriod.startPeriod.period
			: defaultPeriod.sequence ?? 0,
	});
	const navigator = useNavigate();

	return (
		<Flex
			direction={{ default: 'column' }}
			className="report-snapshot"
		>
			<Flex spacer={{ default: 'spacerNone' }}>
				<div className="report-container">
					<Text className="right-title font-size-md">{title}</Text>

					<div className="line-container">
						{chartId && (
							<ChartView
								chartId={chartId.toString()}
								selectedDate={selectedDate}
								height={135}
								width={650}
								allowClickNavigate
								isEdit={false}
								hideXAxis={true}
								hideTitle={true}
								transparentBackground={false}
								delayedDisplayTime={100}
								hideYAxis={true}
							/>
						)}
						{(!chartId || noFavToggled) && (
							<div className="no-report-data">
								<FontAwesomeIcon
									icon={faWarning}
									size="1x"
								/>
								<div className="no-report-data-text">
									<span>You currently have no featured chart set. </span>
									<Button
										component="a"
										onClick={() => {
											localStorage.removeItem('prevAnalyzePage');
											navigator(`/analyze/favorites`);
										}}
										variant="link"
										isInline
									>
										Click here
									</Button>
									<span> to select one.</span>
								</div>
							</div>
						)}
					</div>
				</div>
			</Flex>
			<Grid>
				<GridItem span={12}>
					<Flex className="report-chart-text nudge-right">
						<FlexItem alignSelf={{ default: 'alignSelfCenter' }}>
							{snapshotText}
						</FlexItem>
					</Flex>
				</GridItem>
			</Grid>
		</Flex>
	);
};

export default SubNavReportSnapshot;
