export enum FolderTypesEnum {
	Analyze = 'dashboards',
	Charts = 'charts',
	DataFrame = 'dataframes',
	Favorites = 'favorites',
	Folder = 'folders',
	Presentation = 'presentations',
	Queue = 'queue',
	Recents = 'recents',
	Report = 'reports',
	Schedules = 'schedules',
	Tables = 'pivot_tables',
	Setup = 'setup',
}
