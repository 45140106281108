import React, { Dispatch, useEffect, useState } from 'react';
import {
	Alert,
	AlertVariant,
	Button,
	Modal,
	ModalVariant,
	TextInput,
} from '@patternfly/react-core';
import PageTitleSubheader from '../../../layout/subheader/PageTitleSubheader';
import { AnalyzeSidebarContext } from '../../../helpers/sidebar-contexts/analyze-sidebar-context';
import { useLocation } from 'react-router-dom';
import { IConfig } from '../../../layout/Layout';
import Loader from '../../util/Loader';
import { Folder, TNewFolder } from '../../../api/folders/Folder';
import { TFolder } from '../../../api/types/folder';
import { FolderTypesEnum } from '../../../enums/folder-types-enum';
import { useNavigate } from 'react-router';
import { ReportSidebarContext } from '../../../helpers/sidebar-contexts/report-sidebar-context';
import { INavigationLink } from '../../../layout/PageNavigationLinks';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';

export type AddFoldersModalProps = {
	folderHeadingTitle: string;
	isOpen: boolean;
	folderType?: string;
	folderId?: number;
	folderName?: string;
	onClose: () => void;
	onSetSubSide: Dispatch<React.SetStateAction<IConfig>>;
	mustNotRedirect?: boolean;
	successNotification?: string;
	refreshList?: (() => void | undefined) | undefined;
};

function AddFoldersModal(props: AddFoldersModalProps) {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [folderName, setFolderName] = useState<string>('');
	const [message, setMessage] = useState<React.ReactElement | null>();

	const segments = useLocation().pathname.split('/'); // Split the URL by '/'

	const lastSegment = Number.isInteger(Number(segments[segments.length - 1]))
		? decodeURI(segments[segments.length - 2])
		: segments[segments.length - 1] === 'analyze'
		? 'dashboards'
		: segments[segments.length - 1]; // Get the last segment

	const { addToast } = useToast();

	const handleBeforeClose = (isNew = false) => {
		resetState(isNew);
		props.onClose();
	};

	const createNewFolder = () => {
		let subFolderType = props.folderType ?? FolderTypesEnum.Analyze;

		if (folderName === '') {
			setMessage(
				<Alert
					variant="danger"
					isInline
					isPlain
					title="Folder Name is required"
				/>
			);

			return;
		}

		if (segments.includes(FolderTypesEnum.Charts)) {
			subFolderType = FolderTypesEnum.Charts;
		} else if (segments.includes(FolderTypesEnum.DataFrame)) {
			subFolderType = FolderTypesEnum.DataFrame;
		} else if (segments.includes(FolderTypesEnum.Favorites)) {
			subFolderType = FolderTypesEnum.Favorites;
		} else if (segments.includes(FolderTypesEnum.Tables)) {
			subFolderType = FolderTypesEnum.Tables;
		}

		setIsLoading(true);

		//short timer to simulate backend adding the folder
		const parentFolderId = Number(segments[segments.length - 1]);
		const childMenuItem: TNewFolder = {
			name: formatString(folderName),
			parent: parentFolderId ? parentFolderId : 0,
			type: subFolderType,
		} as TFolder;

		let sidebarContext: INavigationLink[] = [];
		let url = props.folderType ? `/${props.folderType}` : '';
		const isSubFolder = window.location.href.includes('folder');
		const subFolderUrl = window.location.pathname;

		switch (subFolderType) {
			case FolderTypesEnum.Analyze:
			case FolderTypesEnum.Tables:
				sidebarContext = AnalyzeSidebarContext;
				url = '/analyze';
				break;
			case FolderTypesEnum.Charts:
				sidebarContext = AnalyzeSidebarContext;
				url = `/analyze`;
				break;
			case FolderTypesEnum.DataFrame:
				sidebarContext = AnalyzeSidebarContext;
				url = `/analyze`;
				break;
			default:
				sidebarContext = ReportSidebarContext;
				url = '/report';
		}

		props.onSetSubSide({
			sidebarContext: sidebarContext,
			folderHeadingTitle: props.folderHeadingTitle,
			isFoldersLoading: true,
		});

		// Create new folder
		Folder.NewFolder(childMenuItem)
			.then((): void => {
				if (props.successNotification) {
					addToast(props.successNotification, AlertVariant.success);
				}

				props.onSetSubSide({
					subheaderContext: (
						<PageTitleSubheader
							pageTitle={subFolderType ? subFolderType : ''}
							pageDescription="Common Actions"
						/>
					),
					sidebarContext: sidebarContext,
					folderHeadingTitle: props.folderHeadingTitle,
					folderType: props.folderType,
					reloadFolders: true,
				});

				setTimeout(() => {
					if (url) {
						navigate('/analyze/loading');

						setTimeout(() => {
							if (subFolderType == FolderTypesEnum.Tables) {
								navigate('/analyze/tables');
							} else if (
								subFolderType != FolderTypesEnum.Analyze &&
								subFolderType != FolderTypesEnum.Report
							) {
								navigate(`${url}/${subFolderType}`);
							} else {
								if (isSubFolder) {
									navigate(subFolderUrl);
								} else {
									navigate(url);
								}
							}
						}, 5);
					}
				}, 12);
			})
			.catch((): void => {
				setIsLoading(false);
			});

		//close modal
		handleBeforeClose(true);

		setMessage(null);
	};

	const updateFolder = () => {
		if (folderName === '') {
			setMessage(
				<Alert
					variant="danger"
					isInline
					isPlain
					title="Folder Name is required"
				/>
			);

			return;
		}

		setIsLoading(true);

		const folder: TFolder = {
			id: props.folderId ?? 0,
			name: formatString(folderName),
		};

		//Update folder from Dataframes
		Folder.UpdateFolder(folder)
			.then((): void => {
				if (props.successNotification) {
					addToast(props.successNotification, AlertVariant.success);
				}

				if (props.refreshList) {
					props.refreshList();
				}

				if (props.mustNotRedirect) {
					return;
				}

				setTimeout(() => {
					navigate('/analyze');

					setTimeout(() => {
						navigate('/analyze/dataframes');
					}, 100);
				}, 200);
			})
			.catch((): void => {
				setIsLoading(false);
			});

		//close modal
		handleBeforeClose();

		setMessage(null);
	};

	const resetState = (isNew = false) => {
		setIsLoading(false);
		setMessage(null);

		if (isNew) setFolderName('');
	};

	useEffect(() => {
		setFolderName(props.folderName ?? '');
	}, [props.folderId]);

	function formatString(input: string): string {
		// Capitalize the first letter of each word
		const capitalized = input
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');

		// Replace '-' characters with spaces
		return capitalized.replace(/-/g, ' ');
	}

	return (
		<Modal
			title={
				props.folderName
					? `Update Folder ${props.folderName}`
					: `Create a New Folder Linked to the ${formatString(lastSegment)} folder`
			}
			variant={ModalVariant.medium}
			isOpen={props.isOpen}
			onClose={() => handleBeforeClose()}
		>
			<div>
				{isLoading ? (
					<Loader />
				) : (
					<div>
						<br />
						{props.folderName ? (
							<div>Folder {props.folderName} will be updated</div>
						) : (
							<div>
								This folder will be created underneath the{' '}
								<strong>{`'${formatString(lastSegment)}'`}</strong> folder
							</div>
						)}
						<TextInput
							className="form-control ptb-10"
							value={folderName}
							onChange={setFolderName}
							id="folder-name"
						/>
						{message && (
							<div>
								{message} <br />
							</div>
						)}
						{props.folderName ? (
							<Button
								variant="primary"
								onClick={updateFolder}
							>
								Update Folder
							</Button>
						) : (
							<Button
								variant="primary"
								onClick={createNewFolder}
							>
								Create Folder
							</Button>
						)}
					</div>
				)}
			</div>
		</Modal>
	);
}

export default AddFoldersModal;
