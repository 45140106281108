import React, {
	createContext,
	type ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AlertVariant, Bullseye, Spinner } from '@patternfly/react-core';
import { User } from '../../api/security/User';
import { ApplicationContext } from '../../types/common/application-context';
import { DateRange } from '../../api/date-period-selector/DateRange';
import { Permission } from '../../enums/permission.enum';
import { getCSRFToken } from '../../api/security/CSRFToken';
import axiosConfig from '../../api/axiosConfig';
import { AxiosRequestConfig } from 'axios';
import {
	bannedFieldsInterceptorError,
	bannedFieldsInterceptorSuccess,
} from '../../api/axiosIntercepters';
import _ from 'lodash';
import { GenericResponse } from '../../api/types';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { KeyMeasure } from '../../api/analytics/KeyMeasure';
import { Dimension } from '../../api/analytics/Dimension';
import { UnitType } from '../../api/analytics/UnitType';
import { Period } from '../../api/analytics/Period';

export type UseApplicationHook = ApplicationContext;

const UserContext = createContext<UseApplicationHook>(ApplicationContext.Default());
const { Provider } = UserContext;

type Props = {
	children: ReactNode;
};
export default function ApplicationProvider(props: Props) {
	const [userLoaded, setUserLoaded] = useState<boolean>(false);
	// const [user, setUser] = useState<TUser>(User.Default() as TUser);
	const [applicationContext, setApplicationContext] = useState<ApplicationContext>(
		ApplicationContext.Default()
	);
	const navigate = useNavigate();
	const { addToast } = useToast();

	useEffect(() => {
		const initialize = async () => {
			if (userLoaded) {
				return;
			}

			const token = await getCSRFToken();
			axiosConfig.instance.defaults.headers['CSRF-TOKEN'] = token;

			setupInterceptors(addToast, navigate);

			Promise.all([
				User.Me(),
				DateRange.GetAll(1),
				DateRange.GetAll(2),
				DateRange.GetAll(3),
				DateRange.GetAll(4),
				KeyMeasure.GetAll(['keyMeasureFacts', 'dimensions']),
				Dimension.GetAll(['dimensionAttributes', 'keyMeasures']),
				UnitType.GetAll(),
				Period.GetAll(),
			])
				.then(
					([
						me,
						datePeriod1,
						datePeriod2,
						datePeriod3,
						datePeriod4,
						measures,
						dimensions,
						unitTypes,
						periods,
					]) => {
						const allDatePeriods = [
							...datePeriod1,
							...datePeriod2,
							...datePeriod3,
							...datePeriod4,
						];
						const currentDatePeriods =
							ApplicationContext.getCurrentPeriod(allDatePeriods);
						User.MyRole()
							.then((roleResponse) => {
								me.role = roleResponse;
								setApplicationContext({
									...applicationContext,
									user: me,
									datePeriods: allDatePeriods,
									currentDatePeriods,
									measures,
									dimensions,
									unitTypes,
									periods,
								});
								setUserLoaded(true);
							})
							.catch(() => {
								throw Error('Failed Role Permissions call.');
							});
					}
				)
				.catch((error) => {
					navigate('/login');
					throw Error(
						'The `useApplication` hook must be called from a descendent of the `ApplicationProvider`.'
					);
				});
		};

		void initialize();
	});

	if (!userLoaded) {
		return (
			<Bullseye>
				<div>
					<Spinner
						isSVG
						size={'xl'}
					/>
				</div>
			</Bullseye>
		);
	}

	return <Provider value={applicationContext}>{props.children}</Provider>;
}

function setupInterceptors(
	addToast: (
		title: string,
		variant?: AlertVariant | undefined,
		timeout?: boolean | undefined,
		secondsToRemove?: number | undefined
	) => void,
	navigate: NavigateFunction
) {
	axiosConfig.instance.interceptors.request.use(
		bannedFieldsInterceptorSuccess,
		bannedFieldsInterceptorError
	);

	axiosConfig.instance.interceptors.response.use(
		(response) => {
			if (_.has(response.data, 'success') && _.has(response.data, 'message')) {
				const data = response.data as GenericResponse<any>;
				if (data.success) {
					addToast(data.message, AlertVariant.success);
				} else {
					addToast(data.message, AlertVariant.danger);
				}
			}

			return response;
		},
		async (error) => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (error.response?.status === 401) {
				navigate('/login');
			}

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (error.response?.status === 403) {
				navigate('/restricted-access');
			}

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			const originalRequest = error.config as AxiosRequestConfig & { _retry: boolean };

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (error.response?.status === 418 && !originalRequest._retry) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				originalRequest._retry = true;

				try {
					const newCsrfToken = await getCSRFToken();
					axiosConfig.instance.defaults.headers['CSRF-TOKEN'] = newCsrfToken;
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/ban-ts-comment
					// @ts-ignore
					originalRequest.headers['CSRF-TOKEN'] = newCsrfToken;

					return axiosConfig.instance(originalRequest);
				} catch (refreshErr) {
					return Promise.reject(refreshErr);
				}
			}

			return Promise.reject(error);
		}
	);
}

export const useApplication: () => UseApplicationHook = () => {
	const ctx = useContext<UseApplicationHook>(UserContext);

	if (!ctx) {
		throw Error(
			'The `useApplication` hook must be called from a descendent of the `ApplicationProvider`.'
		);
	}

	return ctx;
};

export const useUser: () => User = () => {
	const ctx = useContext<UseApplicationHook>(UserContext);

	if (!ctx) {
		throw Error(
			'The `useApplication` hook must be called from a descendent of the `ApplicationProvider`.'
		);
	}

	return ctx.user;
};

export type isGrantedHook = (permissionToTest: Permission | Permission[]) => boolean;

export const useIsGranted: () => isGrantedHook = () => {
	const ctx = useContext<UseApplicationHook>(UserContext);

	if (!ctx) {
		throw Error(
			'The `useApplication` hook must be called from a descendent of the `ApplicationProvider`.'
		);
	}

	const permissionsSet = useMemo(() => new Set(ctx.user.permissions), [ctx.user.permissions]);

	return (permissionToTest: Permission | Permission[]) => {
		const roleID = typeof ctx.user.role === 'object' ? ctx.user.role.id : ctx.user.role;

		if (roleID === 1) {
			return true;
		}

		if (Array.isArray(permissionToTest)) {
			return permissionToTest.every((p) => permissionsSet.has(p));
		}

		return permissionsSet.has(permissionToTest);
	};
};
