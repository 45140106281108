import { faBellSlash, faLocationArrow } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Dropdown,
	DropdownItem,
	DropdownToggle,
	Modal,
	ModalBoxFooter,
	ModalVariant,
	Spinner,
	TextArea,
	Text,
	Alert,
} from '@patternfly/react-core';
import { useState, useEffect } from 'react';
import { AlertActionsEnum } from '../../enums/alert-actions.enum';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	defaultAction: string;
};

function AlertTakeActionModal(props: Props) {
	const [isLoading, _setIsLoading] = useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState(`Take Action On Alert`);
	const [actionText, setActionText] = useState('Take Action');
	const [actionHeading, setActionHeading] = useState('Action Taken');
	const [buttonText, setButtonText] = useState('Submit');
	const [alertActionValue, setAlertActionValue] = useState('');
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [validationMessage, setValidationMessage] = useState<React.ReactElement | null>();

	const handleTextAreaChange = (
		value: string,
		_event: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setAlertActionValue(value);
	};

	// Update actionText when the modal is opened
	useEffect(() => {
		if (props.isOpen) {
			setActionText('Take Action');
			setActionHeading('Action Taken');
			setModalTitle(`Take Action On Alert`);
			setButtonText('Submit');
			setValidationMessage(null);
			setAlertActionValue('');
		}
	}, [props.isOpen]);

	const onSelect = () => {
		setIsOpen(false);
		setValidationMessage(null);
	};

	const handleActionClick = (
		_event: MouseEvent | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
		action: AlertActionsEnum
	): void => {
		if (action === AlertActionsEnum.TakeAction) {
			setActionText('Take Action');
			setActionHeading('Action Taken');
			setModalTitle(`Take Action On Alert`);
			setButtonText('Submit');
		}
		if (action === AlertActionsEnum.DismissAlert) {
			setActionText('Dismiss');
			setActionHeading('Reason');
			setModalTitle(`Dismiss Alert`);
			setButtonText('Dismiss');
		}
	};

	const submitAlertAction = () => {
		if (alertActionValue === '') {
			setValidationMessage(
				<Alert
					variant="danger"
					isInline
					isPlain
					title={`${actionHeading} is required`}
				/>
			);

			return;
		} else {
			props.onClose();
		}
	};

	return (
		<div>
			<Modal
				title={modalTitle}
				variant={ModalVariant.medium}
				isOpen={props.isOpen}
				onClose={props.onClose}
			>
				<div>
					{isLoading && (
						<div className="spinner-container">
							<Spinner size={'lg'} />
						</div>
					)}
					{!isLoading && (
						<div>
							<br />
							<Dropdown
								onSelect={onSelect}
								toggle={
									<DropdownToggle onToggle={() => setIsOpen(!isOpen)}>
										{actionText}{' '}
									</DropdownToggle>
								}
								isOpen={isOpen}
								dropdownItems={[
									<DropdownItem
										key="item-chart"
										onClick={(event) =>
											handleActionClick(event, AlertActionsEnum.TakeAction)
										}
									>
										<FontAwesomeIcon icon={faLocationArrow} /> Take Action
									</DropdownItem>,
									<DropdownItem
										key="item-dashboard"
										onClick={(event) =>
											handleActionClick(event, AlertActionsEnum.DismissAlert)
										}
									>
										<FontAwesomeIcon icon={faBellSlash} /> Dismiss
									</DropdownItem>,
								]}
								className="pull-left btn-dropdown-container"
							></Dropdown>
							<br />
							{validationMessage && (
								<div>
									<br />
									{validationMessage}
								</div>
							)}
							<span className="spacer-sm"></span>
							<Text>
								{actionHeading} <span className="pf-m-danger">*</span>
							</Text>
							<TextArea
								id="taAlertAction"
								rows={4}
								value={alertActionValue}
								onChange={handleTextAreaChange}
							></TextArea>
						</div>
					)}
					<ModalBoxFooter className="pull-right">
						<Button
							variant="secondary"
							onClick={props.onClose}
						>
							Cancel
						</Button>
						<Button
							variant="primary"
							onClick={submitAlertAction}
						>
							{buttonText}
						</Button>
					</ModalBoxFooter>
				</div>
			</Modal>
		</div>
	);
}

export default AlertTakeActionModal;
