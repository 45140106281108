import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, FlexItem, Grid, GridItem } from '@patternfly/react-core';
import React from 'react';
import { PresentationSlide } from '../../types/presentation';
import { faTrash } from '@fortawesome/pro-light-svg-icons';

interface Props {
	slides: PresentationSlide[];
	selectedPage: number;
	addNewPage: () => void;
	removePage: (index: number) => void;
	updateSelectedPage: (slideNumber: number) => void;
}

const PresentationPageList = (props: Props) => {
	const { slides, addNewPage, removePage, selectedPage, updateSelectedPage } = props;
	return (
		<Grid span={12}>
			{slides.map((slide, index) => {
				return (
					<GridItem span={10}>
						<Flex
							className={`presentation-page-preview ${
								selectedPage === index ? 'selected' : ''
							}`}
							onClick={() => updateSelectedPage(index)}
						>
							<FlexItem
								alignSelf={{ default: 'alignSelfFlexStart' }}
								align={{ default: 'alignRight' }}
							>
								<FontAwesomeIcon
									icon={faTrash}
									className="delete-icon"
									onClick={() => removePage(index)}
								/>
							</FlexItem>
						</Flex>
					</GridItem>
				);
			})}
			<GridItem span={10}>
				<Flex
					className="presentation-page-preview new"
					alignItems={{ default: 'alignItemsCenter' }}
					justifyContent={{ default: 'justifyContentCenter' }}
					onClick={() => addNewPage()}
				>
					<FontAwesomeIcon
						icon={faPlus}
						size="xl"
					/>
				</Flex>
			</GridItem>
		</Grid>
	);
};

export default PresentationPageList;
